<section class="vh-100" *ngIf="profileShow">
    <div class="container-fluid py-1 d-flex justify-content-center align-items-center">
        <div class="container">
            <div class="row mt-3">
                <div class="col-md-12 text-center">
                    <img class="companyProfile" [src]="companySrcUrl">
                </div>
            </div>
            <div class="row mt-1">
                <div class="col-12">
                    <h5 class="companyName">{{currentFormData!.companyName}}</h5>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <img [src]="profileSrcUrl" class="image" crossorigin="anonymous">
                    <h4 class="fullname">{{currentFormData!.firstName}} {{currentFormData!.lastName}}</h4>
                    <h6 class="designation">{{currentFormData!.designation}}</h6>
                    <h6 class="role">{{currentFormData!.role}}</h6>
                </div>
            </div>
            <div class="row" style="background-color:  rgb(79, 79, 134);"><div class="col-1"></div><div class="col-10 mt-3"></div><div class="col-1"></div></div>
            <div class="row">
                <div class="col-1"></div>
                <div class="col-11">
                    <div class="row justify-content-center">
                        <div class="col-12 mt-2">
                            <div class="row mt-1">
                                <div class="col-2"><i class="bi bi-telephone-fill roundIcon"></i></div>
                                <div class="col-10 mt-1">
                                    <h6 class="userDetail" (click)="copyPhoneNo(currentFormData!.countryCodePhoneNo, formatNumber(currentFormData!.phoneNo))">
                                        {{currentFormData!.countryCodePhoneNo}} {{formatNumber(currentFormData!.phoneNo)}}
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-12">
                            <div class="row mt-3">
                                <div class="col-2">
                                    <a class="roundIcon" target="_blank"href="https://www.google.com/maps?q={{currentFormData!.address}}">
                                        <i class="bi bi-pin-map-fill"></i>
                                    </a>
                                </div>
                                <div class="col-10 mt-1">
                                    <h6 class="userDetail">{{currentFormData!.address}}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-12">
                            <div class="row mt-3">
                                <div class="col-2"><i class="bi bi-globe-central-south-asia roundIcon"></i></div>
                                <div class="col-10 mt-1">
                                    <h6 class="">
                                        <a href="http://{{currentFormData!.companyWebsiteName}}" class="userDetail" target="_blank">
                                            {{currentFormData!.companyWebsiteName}}
                                        </a>
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-12">
                            <div class="row mt-3">
                                <div class="col-2"><i class="bi bi-envelope-at-fill roundIcon"></i></div>
                                <div class="col-10 mt-1">
                                    <h6 class="">
                                        <a href="mailto:{{currentFormData!.email}}" class="userDetail" target="_blank">
                                            {{currentFormData!.email}}
                                        </a>
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-12">
                            <div class="row mt-3">
                                <div class="col-2"><i class="bi bi-whatsapp roundIcon"></i></div>
                                <div class="col-10 mt-1">
                                    <h6 class="userDetail" (click)="whatsApp(currentFormData!.countryCodeWhatsAppNo, currentFormData!.whatsAppNumber)">
                                        {{currentFormData!.countryCodeWhatsAppNo}} {{formatNumber(currentFormData!.whatsAppNumber)}}
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row text-center py-1 mt-2" style="border-top: 1px solid black; border-bottom: 1px solid black;">
                <div class="contact-buttons">
                    <a class="contact-button" style="border: 1px solid black;" href="tel:{{currentFormData?.countryCodePhoneNo}}{{currentFormData?.phoneNo}}">
                        <i class="bi bi-telephone mr-1"></i>
                        Call
                    </a>
                    <a class="contact-button" target="_blank" style="border: 1px solid black;" (click)="whatsApp(currentFormData?.countryCodePhoneNo, currentFormData?.phoneNo)">
                        <i class="fab fa-whatsapp mr-1"></i>
                        Whatsapp
                    </a>
                    <a class="contact-button" style="border: 1px solid black;" target="_blank" href="mailto:{{currentFormData?.email}}">
                        <i class="bi bi-envelope mr-1"></i>
                        Mail
                    </a>
                </div>
            </div>  
            <div class="py-1"></div>          
        </div>
    </div>
</section>















<section class="vh-100" *ngIf="profilehide">
    <div class="container-fluid py-1 d-flex justify-content-center align-items-center">
        <div class="container">
            <div class="row mt-4">
                <div class="col-md-12 mt-3 text-center">
                    <img class="companyProfile" [src]="companySrcUrl">
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12">
                    <h5 class="companyName">{{currentFormData!.companyName}}</h5>
                </div>
            </div>
            <div class="row mt-2" style="background-color:  rgb(79, 79, 134);"><div class="col-1"></div><div class="col-10 mt-3"></div><div class="col-1"></div></div>
            <div class="row mt-2">
                <div class="col-12 text-center">
                    <h4 class="fullname">{{currentFormData!.firstName}} {{currentFormData!.lastName}}</h4>
                    <h6 class="designation">{{currentFormData!.designation}}</h6>
                    <h6 class="role">{{currentFormData!.role}}</h6>
                </div>
            </div>
            <div class="row mt-2" style="background-color:  rgb(79, 79, 134);"><div class="col-1"></div><div class="col-10 mt-3"></div><div class="col-1"></div></div>
            <div class="row">
                <div class="col-1"></div>
                <div class="col-11">
                    <div class="row justify-content-center">
                        <div class="col-12 mt-2">
                            <div class="row mt-1">
                                <div class="col-2"><i class="bi bi-telephone-fill roundIcon"></i></div>
                                <div class="col-10 mt-1">
                                    <h6 class="userDetail" (click)="copyPhoneNo(currentFormData!.countryCodePhoneNo, formatNumber(currentFormData!.phoneNo))">
                                        {{currentFormData!.countryCodePhoneNo}} {{formatNumber(currentFormData!.phoneNo)}}
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-12">
                            <div class="row mt-3">
                                <div class="col-2">
                                    <a class="roundIcon" target="_blank"href="https://www.google.com/maps?q={{currentFormData!.address}}">
                                        <i class="bi bi-pin-map-fill"></i>
                                    </a>
                                </div>
                                <div class="col-10 mt-1">
                                    <h6 class="userDetail">{{currentFormData!.address}}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-12">
                            <div class="row mt-3">
                                <div class="col-2"><i class="bi bi-globe-central-south-asia roundIcon"></i></div>
                                <div class="col-10 mt-1">
                                    <h6 class="">
                                        <a href="http://{{currentFormData!.companyWebsiteName}}" class="userDetail" target="_blank">
                                            {{currentFormData!.companyWebsiteName}}
                                        </a>
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-12">
                            <div class="row mt-3">
                                <div class="col-2"><i class="bi bi-envelope-at-fill roundIcon"></i></div>
                                <div class="col-10 mt-1">
                                    <h6 class="">
                                        <a href="mailto:{{currentFormData!.email}}" class="userDetail" target="_blank">
                                            {{currentFormData!.email}}
                                        </a>
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-12">
                            <div class="row mt-3">
                                <div class="col-2"><i class="bi bi-whatsapp roundIcon"></i></div>
                                <div class="col-10 mt-1">
                                    <h6 class="userDetail" (click)="whatsApp(currentFormData!.countryCodeWhatsAppNo, currentFormData!.whatsAppNumber)">
                                        {{currentFormData!.countryCodeWhatsAppNo}} {{formatNumber(currentFormData!.whatsAppNumber)}}
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row text-center py-1 mt-2" style="border-top: 1px solid black; border-bottom: 1px solid black;">
                <div class="contact-buttons">
                    <a class="contact-button" style="border: 1px solid black;" href="tel:{{currentFormData?.countryCodePhoneNo}}{{currentFormData?.phoneNo}}">
                        <i class="bi bi-telephone mr-1"></i>
                        Call
                    </a>
                    <a class="contact-button" target="_blank" style="border: 1px solid black;" (click)="whatsApp(currentFormData?.countryCodePhoneNo, currentFormData?.phoneNo)">
                        <i class="fab fa-whatsapp mr-1"></i>
                        Whatsapp
                    </a>
                    <a class="contact-button" style="border: 1px solid black;" target="_blank" href="mailto:{{currentFormData?.email}}">
                        <i class="bi bi-envelope mr-1"></i>
                        Mail
                    </a>
                </div>
            </div>  
            <div class="py-1"></div>          
        </div>
    </div>
</section>





















<app-ecard-footer></app-ecard-footer>
