<section class="vh-100" style="background-color: rgb(201, 215, 228);" *ngIf="profileShow">
  <div class="container-fluid py-4  justify-content-center align-items-center"
       style="text-align: -webkit-center;">
    <div>
      <div class="page-wrapper" id="home-section">
        <div class="page-details">
          <div>
            <div class="p-10"></div>

              <div class="">
                <div class="text-center">
                  <img [src]="companySrcUrl" class="company" style="margin-top:36px" crossorigin="anonymous">
                </div>
              </div>
              <div class="mt-3">
                <h4 class="firmname fw-bolder">{{currentFormData!.companyName}}</h4>
              </div>
            <div class="" style="border-top:1px solid"></div>

            <div  class="image">
              <img [src]="profileSrcUrl" class="image mt-4"  crossorigin="anonymous">
            </div>

            <div class="row">
              <div class="col-12 mt-2 text-center">
                <h3 class="name fw-bolder">{{currentFormData!.firstName}} {{currentFormData!.lastName}}</h3>
                <h6 class="designation">{{currentFormData!.designation}}</h6>
                <h6 class="role" style="word-break: break-word;">{{currentFormData!.role}}</h6>
              </div>
            </div>



          </div>
          <div>
            <div class="contact-buttons" style="margin-top: 60px">
              <a class="contact-button mt-3"
                 href="tel:{{currentFormData!.countryCodePhoneNo}}{{currentFormData!.phoneNo}}">
                <i class="fas fa-phone fa-flip-horizontal"></i>
                Call
              </a>
              <a class="contact-button" target="_blank"
                 (click)="whatsApp(currentFormData!.countryCodePhoneNo, currentFormData!.phoneNo)">
                <i class="fab fa-whatsapp  "></i>
                Whatsapp
              </a>
              <a class="contact-button" target="_blank" href="mailto:{{currentFormData!.email}}">
                <i class="fas fa-envelope fa-flip-horizontal"></i>
                Mail
              </a>
            </div>
            <div class="row mt-1">
              <div class="col-1"></div>
              <div class="col-11">
                <div class="row mt-3">
                  <div class="col-2"><i class="bi bi-geo-alt-fill roundIcon" type="button"
                                        title="Location"></i>
                  </div>
                  <div class="col-10 mt-1"><h6 class="text-left Detail" style="max-width:98%;">{{currentFormData!.address}}</h6></div>
                </div>
                <div class="row mt-1">
                  <div class="col-2"><i class="bi bi-globe-central-south-asia roundIcon" type="button" title="Website"></i></div>
                  <div class="col-10 mt-1 text-left"><h6>
                    <a class="Detail" style="text-decoration: none; word-break: break-all;"
                       href="http://{{currentFormData!.companyWebsiteName}}"
                       target="_blank">{{currentFormData!.companyWebsiteName}}</a>
                  </h6>
                  </div>
                </div>

              </div>
              <div class="p-30 mb-5"></div>
            </div>

          </div>
        </div>
        <div class="p-30"></div>
      </div>
    </div>
  </div>
</section>









<section class="vh-100" style="background-color: rgb(201, 215, 228);" *ngIf="profilehide">
  <div class="container-fluid py-4  justify-content-center align-items-center"
       style="text-align: -webkit-center;">
    <div>
      <div class="page-wrapper" id="home-section">
        <div class="page-details">
          <div>
                <div class="text-center">
                  <img [src]="companySrcUrl" class="company mt-5 py-3 " crossorigin="anonymous">
                </div>
              <div class="">
                <h4 class="firmname fw-bolder mt-3">{{currentFormData!.companyName}}</h4>
              </div>
            <div class="mt-2" style="border-top:1px solid"></div>
            <div class="row mt-2">
              <div class="col-12 mt-2 text-center">
                <h3 class="name font-weight-bolder">{{currentFormData!.firstName}} {{currentFormData!.lastName}}</h3>
                <h6 class="designation">{{currentFormData!.designation}}</h6>
                <h6 class="role" style="word-break: break-word;">{{currentFormData!.role}}</h6>
              </div>
            </div>


          </div>
          <div>
            <div class="contact-buttons" style="margin-top: 42px">
              <a class="contact-button"
                 href="tel:{{currentFormData!.countryCodePhoneNo}}{{currentFormData!.phoneNo}}">
                <i class="fas fa-phone fa-flip-horizontal"></i>
                Call
              </a>
              <a class="contact-button" target="_blank"
                 (click)="whatsApp(currentFormData!.countryCodePhoneNo, currentFormData!.phoneNo)">
                <i class="fab fa-whatsapp  "></i>
                Whatsapp
              </a>
              <a class="contact-button" target="_blank" href="mailto:{{currentFormData!.email}}">
                <i class="fas fa-envelope fa-flip-horizontal"></i>
                Mail
              </a>
            </div>
            <div class="row">
              <div class="col-1"></div>
              <div class="col-11">
                <div class="row mt-3">
                  <div class="col-2"><i class="bi bi-geo-alt-fill roundIcon" type="button"
                                        title="Location"></i>
                  </div>
                  <div class="col-10 mt-1"><h6 class="text-left Detail" style="max-width:98%;">{{currentFormData!.address}}</h6></div>
                </div>
                <div class="row mt-3">
                  <div class="col-2"><i class="bi bi-globe-central-south-asia roundIcon" type="button" title="Website"></i></div>
                  <div class="col-10 mt-1 text-left"><h6>
                    <a class="Detail" style="text-decoration: none; word-break: break-all;"
                       href="http://{{currentFormData!.companyWebsiteName}}"
                       target="_blank">{{currentFormData!.companyWebsiteName}}</a>
                  </h6>
                  </div>
                </div>

              </div>
              <div class="p-30 mb-5"></div>
            </div>

          </div>
        </div>
        <div class="p-30"></div>
      </div>
    </div>
  </div>
</section>









<ngx-spinner bdColor="rgba(255, 255, 255, 1)" size="large" color="#FFFFFF"
             [fullScreen]="true">
  <figure class="loader">
    <div class="dot white"></div>
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
  </figure>
</ngx-spinner>
<app-ecard-footer></app-ecard-footer>
