<!-- <div class="container-fluid">
  <section class="">
    <div class="container mt-2 mb-2 h-90">
      <div class="row d-flex justify-content-center align-items-center h-100">
        <div class="col-lg-6 col-12 mb-lg-0">
          <div class="card mb-3" style="border-radius: .5rem;">
            <div class="row g-0" >
              <div class="col-md-4 gradient-custom text-center text-white" >
                <div class="" style="background-image: url(../../../../assets/img/backgroundImages/background2.jpeg);">

                  <div class="row mt-1">
                    <div class="col-md-12">
                      <img class="" style="width:90px;" alt="avatar2" alt="Responsive image" [src]="companySrcUrl"/>
                    </div>
                  </div>
                  <div class="row p-2 m-2">
                    <div class="col-md-12">
                      <h4 class="text-dark fw-bolder">{{currentFormData!.companyName}}</h4>
                    </div>
                  </div>
                  <div class="row mb-2" *ngIf="profileShow">
                    <div class="col-md-12" >
                      <img [src]="profileSrcUrl" class="rounded-circle my-1 img-fluid"
                           style="width:90px;" alt="Avatar"/>
                    </div>
                  </div>
                  
                </div>
              </div>
              <div class="col-md-8 d-flex" style="border-top-right-radius: .5rem; border-bottom-right-radius: .5rem;">
                
                <div class="card-body p-4">
                  <div class="row ">
                    <div class="col-md-12">
                      <h3 class="text-center fw-bolder">{{currentFormData!.firstName}} {{currentFormData!.lastName}}</h3>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <h6 class="text-center">{{currentFormData!.designation}}</h6>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <h6 class="text-center">{{currentFormData!.role}}</h6>
                    </div>
                  </div>

                  <div class="ml-auto mr-auto">


                    <div class="row mt-2">
                      <div class="d-flex">
                        <i title="Phone No" class="bi bi-phone-fill icon"></i>
                          <h6 class="ml-3 mt-1" (click)="copyPhoneNo(currentFormData!.countryCodePhoneNo, formatNumber(currentFormData!.phoneNo))">
                        {{currentFormData!.countryCodePhoneNo}} {{formatNumber(currentFormData!.phoneNo)}}</h6>
                        </div>
                    </div>
                    
                    <div class="row mt-2">
                      <div class="d-flex">
                        <i title="Website" class="bi bi-globe icon"></i>
                        <h6 class="text-dark mt-1 ml-3" style=""><a class="text-dark"
                          href="http://{{currentFormData!.companyWebsiteName}}"target="_blank">
                          {{currentFormData!.companyWebsiteName}}</a></h6>
                        </div>
                    </div>
  
  
                    <div class="row mt-2">
                      <div class="d-flex justify-content-start">
                        <i title="Email" class="bi bi-envelope-fill icon"></i>
                        <h6 class="ml-3 mt-1"><a class=" text-dark"
                          href="mailto:{{currentFormData!.email}}"target="_blank"> 
                          <span class="" style="word-break: break-all;text-align: center;">{{currentFormData!.email}}</span></a></h6>
                        </div>
                    </div>
  
                    <div class="row mt-2">
                      <div class="d-flex justify-content-start">
                        <i title="Location" class="bi bi-geo-alt-fill icon"></i>
                        <h6 class="ml-3 mt-1">{{currentFormData!.address}}</h6>
                        </div>
                    </div>
  
                    <div class="row mt-2">
                      <div class="d-flex justify-content-start">
                        <i title="WhatsApp" class="bi bi-whatsapp icon"></i>
                        <h6 class="ml-3 mt-1" 
                        (click)="whatsApp(currentFormData!.countryCodeWhatsAppNo, currentFormData!.whatsAppNumber)">
                      {{currentFormData!.countryCodeWhatsAppNo}} {{formatNumber(currentFormData!.whatsAppNumber)}}</h6>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div> -->



<div class="container-fluid" *ngIf="profileShow">
  <section class="">
    <div class="container mt-2 mb-2 h-90">
      <div class=" d-flex justify-content-center align-items-center h-100">
        <div class="col-lg-6 card col-12">
          <div class="" style="">
            <div class="row" >
              <div class="col-md-4 gradient-custom text-center text-white" style="background-size: cover;">
                <!-- <div class="gradient-custom" style="height: 37vh; margin-top: -5px; background-size: cover;"> -->
                    <div class="row mt-1">
                      <div class="col-md-12">
                        <img class="" style="width:90px;" alt="avatar2" alt="Responsive image" [src]="companySrcUrl"/>
                      </div>
                    </div>
                    <div class="row p-2 m-2">
                      <div class="col-md-12">
                        <h4 class="text-dark fw-bolder">{{currentFormData!.companyName}}</h4>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-md-12" >
                        <img [src]="profileSrcUrl" class="rounded-circle my-1 img-fluid"
                             style="width:90px;" alt="Avatar"/>
                      </div>
                    </div>
                
                  
              </div>
              <div class="col-md-8 d-flex" >
                
                <div class="card-body p-4">
                  <div class="row ">
                    <div class="col-md-12">
                      <h3 class="text-center fw-bolder">{{currentFormData!.firstName}} {{currentFormData!.lastName}}</h3>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <h6 class="text-center">{{currentFormData!.designation}}</h6>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <h6 class="text-center">{{currentFormData!.role}}</h6>
                    </div>
                  </div>

                  <div class="">


                    <div class="row mt-2">
                      <div class="d-flex">
                        <i title="Phone No" class="bi bi-phone-fill icon"></i>
                          <h6 class="ml-3 mt-1" (click)="copyPhoneNo(currentFormData!.countryCodePhoneNo, formatNumber(currentFormData!.phoneNo))">
                        {{currentFormData!.countryCodePhoneNo}} {{formatNumber(currentFormData!.phoneNo)}}</h6>
                        </div>
                    </div>
                    
                    <div class="row mt-2">
                      <div class="d-flex">
                        <i title="Website" class="bi bi-globe icon"></i>
                        <h6 class="text-dark mt-1 ml-3" style=""><a class="text-dark"
                          href="http://{{currentFormData!.companyWebsiteName}}"target="_blank">
                          {{currentFormData!.companyWebsiteName}}</a></h6>
                        </div>
                    </div>
  
  
                    <div class="row mt-2">
                      <div class="d-flex justify-content-start">
                        <i title="Email" class="bi bi-envelope-fill icon"></i>
                        <h6 class="ml-3 mt-1"><a class=" text-dark"
                          href="mailto:{{currentFormData!.email}}"target="_blank"> 
                          <span class="" style="word-break: break-all;text-align: center;">{{currentFormData!.email}}</span></a></h6>
                        </div>
                    </div>
  
                    <div class="row mt-2">
                      <div class="d-flex justify-content-start">
                        <i title="Location" class="bi bi-geo-alt-fill icon"></i>
                        <h6 class="ml-3 mt-1">{{currentFormData!.address}}</h6>
                        </div>
                    </div>
  
                    <div class="row mt-2">
                      <div class="d-flex justify-content-start">
                        <i title="WhatsApp" class="bi bi-whatsapp icon"></i>
                        <h6 class="ml-3 mt-1" 
                        (click)="whatsApp(currentFormData!.countryCodeWhatsAppNo, currentFormData!.whatsAppNumber)">
                      {{currentFormData!.countryCodeWhatsAppNo}} {{formatNumber(currentFormData!.whatsAppNumber)}}</h6>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>












<div class="container-fluid" *ngIf="profileHide">
  <section class="">
    <div class="container mt-2 mb-2 h-90">
      <div class=" d-flex justify-content-center align-items-center h-100">
        <div class="col-lg-6 card col-12">
          <div class="" style="">
            <div class="row" >
              <div class="col-md-4 gradient-custom text-center text-white" style="background-size: cover;">
                <!-- <div class="gradient-custom" style="height: 37vh; margin-top: -5px; background-size: cover;"> -->
                    <div class="row mt-1">
                      <div class="col-md-12">
                        <img class="" style="width:90px;" alt="avatar2" alt="Responsive image" [src]="companySrcUrl"/>
                      </div>
                    </div>
                    <div class="row p-2 m-2">
                      <div class="col-md-12">
                        <h4 class="text-dark fw-bolder">{{currentFormData!.companyName}}</h4>
                      </div>
                    </div>
                    <div class="row mb-2" >
                      <div class="col-md-12" >
                      </div>
                    </div>
                
                  
              </div>
              <div class="col-md-8 d-flex" >
                
                <div class="card-body p-4">
                  <div class="row ">
                    <div class="col-md-12">
                      <h3 class="text-center fw-bolder">{{currentFormData!.firstName}} {{currentFormData!.lastName}}</h3>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <h6 class="text-center">{{currentFormData!.designation}}</h6>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <h6 class="text-center">{{currentFormData!.role}}</h6>
                    </div>
                  </div>

                  <div class="">


                    <div class="row mt-2">
                      <div class="d-flex">
                        <i title="Phone No" class="bi bi-phone-fill icon"></i>
                          <h6 class="ml-3 mt-1" (click)="copyPhoneNo(currentFormData!.countryCodePhoneNo, formatNumber(currentFormData!.phoneNo))">
                        {{currentFormData!.countryCodePhoneNo}} {{formatNumber(currentFormData!.phoneNo)}}</h6>
                        </div>
                    </div>
                    
                    <div class="row mt-2">
                      <div class="d-flex">
                        <i title="Website" class="bi bi-globe icon"></i>
                        <h6 class="text-dark mt-1 ml-3" style=""><a class="text-dark"
                          href="http://{{currentFormData!.companyWebsiteName}}"target="_blank">
                          {{currentFormData!.companyWebsiteName}}</a></h6>
                        </div>
                    </div>
  
  
                    <div class="row mt-2">
                      <div class="d-flex justify-content-start">
                        <i title="Email" class="bi bi-envelope-fill icon"></i>
                        <h6 class="ml-3 mt-1"><a class=" text-dark"
                          href="mailto:{{currentFormData!.email}}"target="_blank"> 
                          <span class="" style="word-break: break-all;text-align: center;">{{currentFormData!.email}}</span></a></h6>
                        </div>
                    </div>
  
                    <div class="row mt-2">
                      <div class="d-flex justify-content-start">
                        <i title="Location" class="bi bi-geo-alt-fill icon"></i>
                        <h6 class="ml-3 mt-1">{{currentFormData!.address}}</h6>
                        </div>
                    </div>
  
                    <div class="row mt-2">
                      <div class="d-flex justify-content-start">
                        <i title="WhatsApp" class="bi bi-whatsapp icon"></i>
                        <h6 class="ml-3 mt-1" 
                        (click)="whatsApp(currentFormData!.countryCodeWhatsAppNo, currentFormData!.whatsAppNumber)">
                      {{currentFormData!.countryCodeWhatsAppNo}} {{formatNumber(currentFormData!.whatsAppNumber)}}</h6>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>






<ngx-spinner bdColor="rgba(255, 255, 255, 1)" size="large" color="#FFFFFF"
             [fullScreen]="true">
  <figure class="loader">
    <div class="dot white"></div>
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
  </figure>
</ngx-spinner>
<app-ecard-footer></app-ecard-footer>
