<section class="vh-100 d-flex" style="background-color: rgb(254 242 242)" *ngIf="profileShow">
    <div class="container-fluid py-2">
        <div class="container">
            
            <div class="row" style="margin-top: 14%;">
                <div class="col-5">
                    <div class="row mt-2">
                        <div class="col-md-12">
                            <img class="companyProfile" [src]="companySrcUrl">
                        </div>
                    </div>
                </div>
                <div class="col-5"></div>
                <div class="col-1"></div>
            </div>
            <div class="row mt-2">
                <div class="col-12">
                    <h4 class="text-center" style="margin-left: 10px;"><b>{{currentFormData!.companyName}}</b></h4>
                </div>
            </div>

            <div class="text-center mt-1" style="border: 1px solid black"></div>

            <div class="row mt-3">
                <div class="col-md-1"></div>

                <div class="col-md-2">
                    <div class="row mt-1" >
                        <div class="col-md-12 text-center">
                            <img class="Profile" [src]="profileSrcUrl">
                        </div>
                    </div>
                </div>
                <div class="col-1"></div>
                <div class="col-md-7">
                    <div class="row mt-2">
                        <div class="col-12 text-center">
                            <h3 class="">{{currentFormData!.firstName}} {{currentFormData!.lastName}}</h3>
                            <h6 class="designation">{{currentFormData!.designation}}</h6>
                            <h6 class="text-center">{{currentFormData!.role}}</h6>
                        </div>
                    </div>
                </div>

                <div class="col-md-1"></div>
            </div>
           

            <div class="text-center mt-1" style="border: 1px solid black"></div>
           
            <div class="row mt-1">
                <div class="col-1"></div>
                <div class="col-11">
                    <div class="row mt-1">
                        <div class="col-2"><i class="bi bi-telephone roundIcon" type="button" title="Call"></i></div>
                        <div class="col-10 mt-1"><h6 class="" style="color: black;" (click)="copyPhoneNo(currentFormData!.countryCodePhoneNo, formatNumber(currentFormData!.phoneNo))">
                            {{currentFormData!.countryCodePhoneNo}} {{formatNumber(currentFormData!.phoneNo)}}</h6></div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-2"><i class="bi bi-geo-alt roundIcon" type="button" title="Location"></i></div>
                        <div class="col-10 mt-1"><h6 class="" style="color: black;">{{currentFormData!.address}}</h6></div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-2"><i class="bi bi-globe2 roundIcon" type="button" title="Website"></i></div>
                        <div class="col-10 mt-1"><h6 class="">
                            <a class=" opacity-75" style="color: black;text-decoration: none;" href="http://{{currentFormData!.companyWebsiteName}}" target="_blank">{{currentFormData!.companyWebsiteName}}</a>
                        </h6>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-2"><i class="bi bi-envelope roundIcon" type="button" title="Mail"></i></div>
                        <div class="col-10 mt-1">
                            <h6 class=" "><a class="opacity-75" style="color: black;text-decoration: none;"
                                                     href="mailto:{{currentFormData!.email}}"
                                                     target="_blank">{{currentFormData!.email}}</a>
                            </h6>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-2"><i title="WhatsApp" class="bi bi-whatsapp roundIcon fs-6"></i>
                        </div>
                        <div class="col-10 ">
                            <h6 class="  mt-1" type="button" style="margin-left: 1px ;color: black;"
                                (click)="whatsApp(currentFormData!.countryCodeWhatsAppNo, currentFormData!.whatsAppNumber)">
                                {{currentFormData!.countryCodeWhatsAppNo}} {{formatNumber(currentFormData!.whatsAppNumber)}}</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-5"></div>
            <div class="row mt-3"></div>
        </div>
    </div>
</section>
















<section class="vh-100 d-flex" style="background-color: rgb(254 242 242)"*ngIf="profilehide">
    <div class="container-fluid py-2">
        <div class="container">
            
            <div class="row" style="margin-top: 14%;">
                <div class="col-5">
                    <div class="row mt-2">
                        <div class="col-md-12">
                            <img class="companyProfile" [src]="companySrcUrl">
                        </div>
                    </div>
                </div>
                <div class="col-5"></div>
                <div class="col-1"></div>
            </div>
            <div class="row mt-2">
                <div class="col-12">
                    <h4 class="text-center" style="margin-left: 10px;"><b>{{currentFormData!.companyName}}</b></h4>
                </div>
            </div>

            <div class="text-center mt-1" style="border: 1px solid black"></div>

            <div class="row mt-3">
                <div class="col-md-1"></div>

                <div class="col-md-2">
                    <div class="row mt-1" >
                        <div class="col-md-12 text-center">
                        </div>
                    </div>
                </div>
                <div class="col-1"></div>
                <div class="col-md-7">
                    <div class="row mt-2">
                        <div class="col-12 text-center">
                            <h3 class="">{{currentFormData!.firstName}} {{currentFormData!.lastName}}</h3>
                            <h6 class="designation">{{currentFormData!.designation}}</h6>
                            <h6 class="text-center">{{currentFormData!.role}}</h6>
                        </div>
                    </div>
                </div>

                <div class="col-md-1"></div>
            </div>
           

            <div class="text-center mt-1" style="border: 1px solid black"></div>
           
            <div class="row mt-1">
                <div class="col-1"></div>
                <div class="col-11">
                    <div class="row mt-1">
                        <div class="col-2"><i class="bi bi-telephone roundIcon" type="button" title="Call"></i></div>
                        <div class="col-10 mt-1"><h6 class="" style="color: black;" (click)="copyPhoneNo(currentFormData!.countryCodePhoneNo, formatNumber(currentFormData!.phoneNo))">
                            {{currentFormData!.countryCodePhoneNo}} {{formatNumber(currentFormData!.phoneNo)}}</h6></div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-2"><i class="bi bi-geo-alt roundIcon" type="button" title="Location"></i></div>
                        <div class="col-10 mt-1"><h6 class="" style="color: black;">{{currentFormData!.address}}</h6></div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-2"><i class="bi bi-globe2 roundIcon" type="button" title="Website"></i></div>
                        <div class="col-10 mt-1"><h6 class="">
                            <a class=" opacity-75" style="color: black;text-decoration: none;" href="http://{{currentFormData!.companyWebsiteName}}" target="_blank">{{currentFormData!.companyWebsiteName}}</a>
                        </h6>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-2"><i class="bi bi-envelope roundIcon" type="button" title="Mail"></i></div>
                        <div class="col-10 mt-1">
                            <h6 class=" "><a class="opacity-75" style="color: black;text-decoration: none;"
                                                     href="mailto:{{currentFormData!.email}}"
                                                     target="_blank">{{currentFormData!.email}}</a>
                            </h6>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-2"><i title="WhatsApp" class="bi bi-whatsapp roundIcon fs-6"></i>
                        </div>
                        <div class="col-10 ">
                            <h6 class="  mt-1" type="button" style="margin-left: 1px ;color: black;"
                                (click)="whatsApp(currentFormData!.countryCodeWhatsAppNo, currentFormData!.whatsAppNumber)">
                                {{currentFormData!.countryCodeWhatsAppNo}} {{formatNumber(currentFormData!.whatsAppNumber)}}</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-5"></div>
            <div class="row mt-3"></div>
        </div>
    </div>
</section>






















<ngx-spinner bdColor="rgba(255, 255, 255, 1)" size="large" color="#FFFFFF"
             [fullScreen]="true">
    <figure class="loader">
        <div class="dot white"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
    </figure>
</ngx-spinner>
<app-ecard-footer></app-ecard-footer>
