<section class="vh-100" style="background-image: url(../../assets/img/backgroundImages/pxfuel.jpg)">
  <div class="container-fluid  py-3 justify-content-center align-items-center"
       style="text-align: -webkit-center;">

    <div>
      <div class="page-wrapper" id="home-section">
        <div class="page-details">
          <div>
            <div class="p-10"></div>
            <div>
              <img [src]="companySrcUrl" class="companyProfile mt-2" crossorigin="anonymous">
            </div>

            <h4 class="firmname fw-bolder">{{currentFormData!.companyName}}</h4>
            <div *ngIf="profileShow">
              <img [src]="profileSrcUrl" class="image" crossorigin="anonymous">
            </div>

            <h5 class="name mt-1">{{currentFormData!.firstName}} {{currentFormData!.lastName}}</h5>
            <h6 class="designation" >{{currentFormData!.designation}}</h6>
            <h6 class="role">{{currentFormData!.role}}</h6>

          </div>

          <div>
            <div class="row mt-3">
              <div class="col-1"></div>
              <div class="col-11">

                <div class="row ">
                  <div class="col-2">
                    <a target="_blank" class=""
                       href="tel:{{currentFormData!.countryCodePhoneNo}}{{currentFormData!.phoneNo}}">
                      <i class="fas fa-phone fa-flip-horizontal roundIcon"></i>
                    </a>
                  </div>
                  <div class="col-10 mt-1 detailText"><h6 class="" style="text-align: left;"
                                                          (click)="copyPhoneNo(currentFormData!.countryCodePhoneNo, formatNumber(currentFormData!.phoneNo))">
                    {{currentFormData!.countryCodePhoneNo}} {{formatNumber(currentFormData!.phoneNo)}}</h6>
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-2">
                    <i class="bi bi-geo-alt-fill roundIcon" type="button" title="Location">
                    </i>
                  </div>
                  <div class="col-10  detailText"><h6 class="" style="text-align:left;">{{currentFormData!.address}}</h6></div>
                </div>

                <div class="row mt-2">
                  <div class="col-2">
                    <a class="" target="_blank" href="http://{{currentFormData!.companyWebsiteName}}">
                      <i class="fas fa-globe roundIcon"></i>
                    </a>
                  </div>
                  <div class="col-10 mt-1 detailText"><h6>
                    <a class="opacity-100 fw-bolder detailText"
                       href="http://{{currentFormData!.companyWebsiteName}}"
                       target="_blank"><h6 style="text-align:left;">{{currentFormData!.companyWebsiteName}}</h6></a>
                  </h6>
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-2">
                    <a class="" href="mailto:{{currentFormData!.email}}">
                      <i class="fas fa-envelope roundIcon"></i>
                    </a>
                  </div>
                  <div class="col-10 ">
                    <h6 class="mt-1"><a class="opacity-100   detailText"
                                        style="line-break: anywhere;text-align:left;"
                                        href="mailto:{{currentFormData!.email}}" target="_blank"><h6>{{currentFormData!.email}}</h6></a>
                    </h6>
                  </div>
                </div>
              </div>

              <div class="col-1"></div>

            </div>
            <div class="contact-buttons">
              <a class="contact-button"
                 href="tel:{{currentFormData!.countryCodePhoneNo}}{{currentFormData!.phoneNo}}">
                <i class="bi bi-telephone fs-6"></i>
                Call
              </a>
              <a class="contact-button"
                 target="whatsapp"
                 (click)="whatsApp(currentFormData!.countryCodeWhatsAppNo, currentFormData!.whatsAppNumber)">
                <i class="fab fa-whatsapp fs-6"></i>
                Whatsapp
              </a>
              <a class="contact-button" target="_blank" href="mailto:{{currentFormData!.email}}">
                <i class="bi bi-envelope fs-6"></i>
                Mail
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<ngx-spinner bdColor="rgba(255, 255, 255, 1)" size="large" color="#FFFFFF"
             [fullScreen]="true">
  <figure class="loader">
    <div class="dot white"></div>
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
  </figure>
</ngx-spinner>
<app-ecard-footer></app-ecard-footer>
