<pf-dropdown class="user-menu" hide-arrow>
  <div slot="button">
    <a class="nav-link">
      <pf-image
        [src]="user.picture"
        alt="User Image"
        class="user-image-small"
        fallback-src="assets/img/default-profile.png"
        height="25"
        rounded
        width="25"
      ></pf-image>
    </a>
  </div>
  <div slot="menu">
    <li class="user-header bg-primary">
      <pf-image
        [src]="user.picture"
        alt="User Image"
        class="user-image-big"
        fallback-src="assets/img/default-profile.png"
        height="90"
        rounded
        width="90"
      ></pf-image>

      <p>
        <span>{{username}}</span>
        <small>
          <!-- <span class="mr-2">Member s<div class="content-header">
            <div class="container-fluid">
              <div class="row mb-2">
                <div class="col-sm-6">
                  <h1 class="m-0 text-dark">Dashboard</h1>
                </div>
                <div class="col-sm-6">
                  <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">Dashboard v1</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>ince</span> -->
          <!-- <span>{{ formatDate(user.createdAt) }}</span> -->
        </small>
      </p>
    </li>
    <!-- Menu Body -->
    <!-- <li class="user-body">
      <div class="row">
        <div class="col-4 text-center">
          <a [routerLink]="['/']">Followers</a>
        </div>
        <div class="col-4 text-center">
          <a [routerLink]="['/']">Sales</a>
        </div>
        <div class="col-4 text-center">
          <a [routerLink]="['/']">Friends</a>
        </div>
      </div>
    </li> -->
    <!-- Menu Footer-->
    <li class="user-footer">
      <a [routerLink]="['/main/dash']" class="btn btn-default btn-flat bg-success">
        Home
      </a>
      <a (click)="logout()" class="btn btn-default btn-flat float-right bg-danger">
        Sign out
      </a>
    </li>
  </div>
</pf-dropdown>
