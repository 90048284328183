<section class="body vh-100" style="background-color:  rgb(172, 172, 172);">
    <div class="container-fluid py-2 justify-content-center align-items-center"
         style="text-align: -webkit-center;">
        <div>
            <div class="page-wrapper" id="home-section">
                <div class="page-details">
                    <div>
                        <div class="p-10"></div>
                        <div>
                            <img [src]="companySrcUrl" class="image  mt-2" crossorigin="anonymous">
                        </div>

                        <h4 class="firmname mt-2 mb-1"><b>{{currentFormData!.companyName}}</b></h4>
                        <div style="border-bottom:#e6ff03 2px solid;"></div>
                        <div class="col-md-12 mt-2 text-center" *ngIf="profileShow">
                            <img [src]="profileSrcUrl" class="image" crossorigin="anonymous">
                        </div>
                        <h4 class="name mt-1"><b>{{currentFormData!.firstName}} {{currentFormData!.lastName}}</b></h4>
                        <h6 class="designation mt-1"><b>{{currentFormData!.designation}}</b></h6>
                        <h6 class="role mt-1"><b>{{currentFormData!.role}} </b></h6>


                        <div style="width:80%;background-color:#e6ff03;height:2px;margin-top: 5px;"></div>
                    </div>
                    <div>
                        <div class="p-10"></div>
                        <div class="contact-buttons">
                            <a class="contact-button"style="color: black;border-left: 4px solid;border-right:4px solid"
                               href="tel:{{currentFormData!.countryCodePhoneNo}}{{currentFormData!.phoneNo}}">
                                <i class="fas fa-phone fa-flip-horizontal"></i>
                                Call
                            </a>
                            <a class="contact-button" style=" margin-top:15px;border-left: 4px solid;border-right:4px solid" target="_blank"
                               (click)="whatsApp(currentFormData!.countryCodePhoneNo, currentFormData!.phoneNo)">
                                <i class="fab fa-whatsapp"></i>
                                Whatsapp
                            </a>
                            <a class="contact-button" style="color: black;border-left: 4px solid;border-right:4px solid" target="_blank" href="mailto:{{currentFormData!.email}}">
                                <i class="fas fa-envelope fa-flip-horizontal"></i>
                                Mail
                            </a>
                        </div>

                        <table class="contact-action-table">
                            <tbody> 
                            <tr>
                                <td>
                                    <i class="fas fa-map-marker-alt contact-action-container-icon" style="border-right: 4px solid;border-left: 4px solid;"></i>
                                </td>
                                <td>
                                    <a class="contact-action-container-text mt-3" style="word-wrap: break-word;">
                                       <b>{{currentFormData!.address}}</b>
                                    </a>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <a href="mailto:{{currentFormData!.email}}">
                                        <i class="fas fa-envelope contact-action-container-icon mt-2" style="border-right: 4px solid;border-left: 4px solid;"></i>
                                    </a>
                                </td>
                                <td>
                                    <a href="mailto:{{currentFormData!.email}}"
                                       class="contact-action-container-text mt-2"style="">
                                        <b>{{currentFormData!.email}}</b>
                                    </a>
                                    <a href="mailto:" class="contact-action-container-text">
                                    </a>
                                </td>
                            </tr>


                            <tr>
                                <td>
                                    <a target="_blank" href="http://{{currentFormData!.companyWebsiteName}}">
                                        <i class="fas fa-globe contact-action-container-icon mt-2" style="border-right: 4px solid;border-left: 4px solid;"></i>
                                    </a>
                                </td>
                                <td>
                                    <a target="_blank" href="http://{{currentFormData!.companyWebsiteName}}"
                                       class="contact-action-container-text mt-2">
                                        <b>{{currentFormData!.companyWebsiteName}}</b>
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a target="_blank" style="font-weight: bolder;"
                                       href="tel:{{currentFormData!.countryCodePhoneNo}}{{currentFormData!.phoneNo}}">
                                        <i class="fas fa-phone fa-flip-horizontal contact-action-container-icon mt-2" style="border-left: 4px solid;border-right: 4px solid;"></i>
                                    </a>
                                </td>
                                <td>
                                    <a target="_blank"
                                       href="tel:{{currentFormData!.countryCodePhoneNo}}{{currentFormData!.phoneNo}}"
                                       class="contact-action-container-text mt-2">
                                        <b>{{currentFormData!.countryCodePhoneNo}} {{currentFormData!.phoneNo}}</b>
                                    </a>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div class="p  py-3"></div>
                        <div class="p-30"></div>
                        <div class="p-20"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<ngx-spinner bdColor="rgba(255, 255, 255, 1)" size="large" color="#FFFFFF"
             [fullScreen]="true">
    <figure class="loader">
        <div class="dot white"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
    </figure>
</ngx-spinner>
<app-ecard-footer></app-ecard-footer>

