import {Component, HostListener, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import {DateTime} from 'luxon';
import AOS from "aos";
import {Meta, Title} from "@angular/platform-browser";

@Component({
  selector: 'website',
  templateUrl: './website.component.html',
  styleUrls: ['./website.component.scss']
})

export class WebsiteComponent implements OnInit {
  public currentYear: string = DateTime.now().toFormat('y');

  showContent = false;
  showContentTwo = false;
  showContentThree = false;

  constructor(private router: Router,private meta: Meta, private titleService: Title,) {

    this.titleService.setTitle('Free Visiting Card Design Online In India | Free Business Cards Online');
    this.meta.addTag({
      name: 'description',
      content: 'Elevate your brand with free business cards online. Design, customize, and stand out effortlessly. Unleash your creativity at eCard Maker today.'
    });
    this.meta.addTag({
      name: 'keywords',
      content: 'business card design free, business card maker free, design business card free online, business card design online free, business card designer near me, business card makers near me, free business card design, design visiting card free online'
    });
  }

  @HostListener('window:scroll', [])
  onScroll() {
    // Adjust this threshold as needed for when you want the content to appear.
    if (window.scrollY > 300) {
      this.showContent = true;
    } if(window.scrollY > 500) {
      this.showContentTwo = true;
    }if(window.scrollY > 900) {
      this.showContentThree = true;
    }
  }

  ngOnInit() {
    AOS.init();
  }

  register() {
    this.router.navigate(['/register'])
  }

  login() {
    this.router.navigate(['/login'])
  }
}
