<section class="vh-100" style="background-color: rgb(201, 215, 228);">
    <div class="container-fluid py-4  justify-content-center align-items-center"
         style="text-align: -webkit-center;">
        <div>
            <div class="page-wrapper" id="home-section">
                <div class="page-details">
                    <div>
                        <div class="p-10"></div>
                        <div>
                            <img [src]="companySrcUrl" class="company mt-3" crossorigin="anonymous">
                          <h5 class="firmname fw-bolder mt-2">{{currentFormData!.companyName}}</h5>
                        </div>

                          <div  class="image">
                            <img [src]="profileSrcUrl" class="image mt-2" *ngIf="profileShow" crossorigin="anonymous">
                          </div>
                          <h4 class="name mt-1 fw-bolder">{{currentFormData!.firstName}} {{currentFormData!.lastName}}</h4>
                          <h6 class="designation">{{currentFormData!.designation}}</h6>
                          <h6 class="role" style="word-break: break-word;">{{currentFormData!.role}}</h6>
                    </div>
                    <div>
<!--                        <div class="contact-buttons mt-2">-->
<!--                            <a class="contact-button"-->
<!--                               href="tel:{{currentFormData!.countryCodePhoneNo}}{{currentFormData!.phoneNo}}">-->
<!--                                <i class="fas fa-phone fa-flip-horizontal"></i>-->
<!--                                Call-->
<!--                            </a>                     -->
<!--                            <a class="contact-button" target="_blank" href="mailto:{{currentFormData!.email}}">-->
<!--                                <i class="fas fa-envelope fa-flip-horizontal"></i>-->
<!--                                Mail-->
<!--                            </a>-->
<!--                        </div>-->
                      <div class=""  style="border-top:1px solid #00005a"></div>
                        <div class="row mt-3">
                            <div class="col-1"></div>
                            <div class="col-11">
                                <div class="row mt-1">
                                    <div class="col-2"><i class="bi bi-telephone roundIcon" type="button"
                                        title="Call"></i></div>
                                    <div class="col-10 mt-1"><h6 class="text-left Detail" type="button" style=""
                                                                 (click)="copyPhoneNo(currentFormData!.countryCodePhoneNo, formatNumber(currentFormData!.phoneNo))">
                                        {{currentFormData!.countryCodePhoneNo}} {{formatNumber(currentFormData!.phoneNo)}}</h6>
                                    </div>

                                </div>
                                <div class="row mt-1">
                                    <div class="col-2"><i class="bi bi-geo-alt roundIcon" type="button"
                                        title="Location"></i>
                                    </div>
                                    <div class="col-10 mt-1"><h6 class="text-left Detail" style="max-width:98%;">{{currentFormData!.address}}</h6></div>
                                </div>
                                <div class="row mt-1">
                                    <div class="col-2"><i class="bi bi-globe2 roundIcon" type="button" title="Website"></i></div>
                                    <div class="col-10 mt-1 text-left"><h6>
                                        <a class="Detail" style="text-decoration: none; word-break: break-all;"
                                           href="http://{{currentFormData!.companyWebsiteName}}"
                                           target="_blank">{{currentFormData!.companyWebsiteName}}</a>
                                    </h6>
                                    </div>
                                </div>
                                <div class="row mt-1">
                                    <div class="col-2"><i class="bi bi-envelope roundIcon" type="button" title="Mail"></i></div>
                                    <div class="col-10 mt-1">
                                        <h6 class="text-left"><a class="Detail" style="line-break: anywhere;text-decoration: none;"
                                                                 href="mailto:{{currentFormData!.email}}"
                                                                 target="_blank">{{currentFormData!.email}}</a>
                                        </h6>
                                    </div>

                                </div>
                                <div class="row mt-1">
                                    <div class="col-2"><i title="WhatsApp" class="bi bi-whatsapp roundIcon fs-6"></i>
                                    </div>
                                    <div class="col-10 mt-2">
                                        <h6 class="text-left Detail" type="button"
                                            (click)="whatsApp(currentFormData!.countryCodeWhatsAppNo, currentFormData!.whatsAppNumber)">
                                            {{currentFormData!.countryCodeWhatsAppNo}} {{formatNumber(currentFormData!.whatsAppNumber)}}</h6>
                                    </div>
                                  <div class="row">
                                    <p class="mb-3 mt-2" style="color: transparent">.</p>
                                  </div>
                                </div>
                            </div>
                          <div class="p-30 mb-3"></div>
                        </div>
<!--                        <div class="lastline">-->
<!--                            <a class="contact" target="_blank"-->
<!--                               (click)="whatsApp(currentFormData!.countryCodeWhatsAppNo, currentFormData!.whatsAppNumber)">-->
<!--                                <i class="fab fa-whatsapp fs-6"></i><b class="ml-2">WhatsApp</b>-->
<!--                            </a>-->
<!--                            <div class="row "></div>-->
<!--                        </div>-->
                    </div>
                </div>
                <div class="p-30"></div>
            </div>
        </div>
    </div>
</section>
<ngx-spinner bdColor="rgba(255, 255, 255, 1)" size="large" color="#FFFFFF"
             [fullScreen]="true">
    <figure class="loader">
        <div class="dot white"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
    </figure>
</ngx-spinner>
<app-ecard-footer></app-ecard-footer>
