import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from '@modules/main/main.component';
import { BlankComponent } from '@pages/blank/blank.component';
import { LoginComponent } from '@modules/login/login.component';
import { ProfileComponent } from '@pages/profile/profile.component';
import { RegisterComponent } from '@modules/register/register.component';
import { DashboardComponent } from '@pages/dashboard/dashboard.component';
import { AuthGuard } from '@guards/auth.guard';
import { NonAuthGuard } from '@guards/non-auth.guard';
import { ForgotPasswordComponent } from '@modules/forgot-password/forgot-password.component';
import { RecoverPasswordComponent } from '@modules/recover-password/recover-password.component';
import { SubMenuComponent } from '@pages/main-menu/sub-menu/sub-menu.component';
import { ECardFormComponent } from '@modules/ecard/e-card-form/e-card-form.component';
import { MainScreenComponent } from '@modules/ecard/main-screen/main-screen.component';
import { PreviewFifteenComponent } from '@modules/ecard/preview-fifteen/preview-fifteen.component';
import { PreviewFourteenComponent } from '@modules/ecard/preview-fourteen/preview-fourteen.component';
import { PreviewThirteenComponent } from '@modules/ecard/preview-thirteen/preview-thirteen.component';
import { PreviewTwelveComponent } from '@modules/ecard/preview-twelve/preview-twelve.component';
import { PreviewElevenComponent } from '@modules/ecard/preview-eleven/preview-eleven.component';
import { PreviewTenComponent } from '@modules/ecard/preview-ten/preview-ten.component';
import { PreviewNineComponent } from '@modules/ecard/preview-nine/preview-nine.component';
import { PreviewEightComponent } from '@modules/ecard/preview-eight/preview-eight.component';
import { PreviewSixComponent } from '@modules/ecard/preview-six/preview-six.component';
import { PreviewFiveComponent } from '@modules/ecard/preview-five/preview-five.component';
import { PreviewThreeComponent } from '@modules/ecard/preview-three/preview-three.component';
import { PreviewFourComponent } from '@modules/ecard/preview-four/preview-four.component';
import { VisitingCardComponent } from '@modules/ecard/preview-Seven/visiting-card.component';
import { ECardComponent } from '@modules/ecard/e-card/e-card.component';
import { BusinessCardComponent } from '@modules/ecard/business-card/business-card.component';
import { PreviewSixteenComponent } from '@modules/ecard/Preview-sixteen/Preview-sixteen.component';
import { PreviewSeventeenComponent } from '@modules/ecard/preview-seventeen/preview-seventeen.component';
import { PreviewEighteenComponent } from '@modules/ecard/preview-eighteen/preview-eighteen.component';
import { PreviewNineteenComponent } from '@modules/ecard/preview-nineteen/preview-nineteen.component';
import { PreviewTwentyComponent } from '@modules/ecard/preview-twenty/preview-twenty.component';
import { PreviewTwentyOneComponent } from '@modules/ecard/preview-twenty-one/preview-twenty-one.component';
import { PreviewTwentyTwoComponent } from '@modules/ecard/preview-twenty-two/preview-twenty-two.component';
import { PreviewTwentyThreeComponent } from '@modules/ecard/preview-twenty-three/preview-twenty-three.component';
import { PreviewTwentyFourComponent } from '@modules/ecard/preview-twenty-four/preview-twenty-four.component';
import { PreviewTwentyFiveComponent } from '@modules/ecard/preview-twenty-five/preview-twenty-five.component';
import { PreviewTwentySixComponent } from '@modules/ecard/preview-twenty-six/preview-twenty-six.component';
import { PreviewTwentySevenComponent } from '@modules/ecard/preview-twenty-seven/preview-twenty-seven.component';
import { PreviewTwentyEightComponent } from '@modules/ecard/preview-twenty-eight/preview-twenty-eight.component';
import { PreviewTwentyNineComponent } from '@modules/ecard/preview-twenty-nine/preview-twenty-nine.component';
import { PreviewThirtyComponent } from '@modules/ecard/preview-thirty/preview-thirty.component';
import { PreviewThirtyOneComponent } from '@modules/ecard/preview-thirty-one/preview-thirty-one.component';
import { PreviewThirtyTwoComponent } from '@modules/ecard/preview-thirty-two/preview-thirty-two.component';
import { PreviewThirtyThreeComponent } from '@modules/ecard/preview-thirty-three/preview-thirty-three.component';
import { PreviewThirtyFourComponent } from '@modules/ecard/preview-thirty-four/preview-thirty-four.component';
import { PreviewThirtyFiveComponent } from '@modules/ecard/preview-thirty-five/preview-thirty-five.component';
import { PreviewThirtySixComponent } from '@modules/ecard/preview-thirty-six/preview-thirty-six.component';
import { PreviewThirtySevenComponent } from '@modules/ecard/preview-thirty-seven/preview-thirty-seven.component';
import { PreviewThirtyEightComponent } from '@modules/ecard/preview-thirty-eight/preview-thirty-eight.component';
import { PreviewThirtyNineComponent } from '@modules/ecard/preview-thirty-nine/preview-thirty-nine.component';
import { PreviewFortyComponent } from '@modules/ecard/preview-forty/preview-forty.component';
import { PreviewFortyOneComponent } from '@modules/ecard/preview-forty-one/preview-forty-one.component';
import { PreviewFortyTwoComponent } from '@modules/ecard/preview-forty-two/preview-forty-two.component';
import { PreviewFortyThreeComponent } from '@modules/ecard/preview-forty-three/preview-forty-three.component';
import { PreviewFortyFourComponent } from '@modules/ecard/preview-forty-four/preview-forty-four.component';
import { PreviewFortyFiveComponent } from '@modules/ecard/preview-forty-five/preview-forty-five.component';
import { PreviewFortySixComponent } from '@modules/ecard/preview-forty-six/preview-forty-six.component';
import { PreviewFortySevenComponent } from '@modules/ecard/preview-forty-seven/preview-forty-seven.component';
import { PreviewFortyEightComponent } from '@modules/ecard/preview-forty-eight/preview-forty-eight.component';
import { PreviewFortyNineComponent } from '@modules/ecard/preview-forty-nine/preview-forty-nine.component';
import { PreviewFiftyComponent } from '@modules/ecard/preview-fifty/preview-fifty.component';
import { PreviewFiftyOneComponent } from '@modules/ecard/preview-fifty-one/preview-fifty-one.component';
import { PreviewFiftyTwoComponent } from '@modules/ecard/preview-fifty-two/preview-fifty-two.component';
import { PreviewFiftyThreeComponent } from '@modules/ecard/preview-fifty-three/preview-fifty-three.component';
import { PreviewFiftyFourComponent } from '@modules/ecard/preview-fifty-four/preview-fifty-four.component';
import { PreviewFiftyFiveComponet } from '@modules/ecard/preview-fifty-five/preview-fifty-five.component';
import { PreviewFiftySixComponent } from '@modules/ecard/preview-fifty-six/preview-fifty-six.component';
import { PreviewFiftySevenComponent } from '@modules/ecard/preview-fifty-seven/preview-fifty-seven.component';
import { PreviewFiftyEightComponent } from '@modules/ecard/preview-fifty-eight/preview-fifty-eight.component';
import { PreviewFiftyNineComponent } from '@modules/ecard/preview-fifty-nine/preview-fifty-nine.component';
import { PreviewSixtyComponent } from '@modules/ecard/preview-sixty/preview-sixty.component';
import { PreviewSixtyOneComponent } from '@modules/ecard/preview-sixty-one/preview-sixty-one.component';
import {WebsiteComponent} from '@modules/website/website.component';
import {TermsComponent} from '@modules/terms-and-conditions/terms.component';
import {PrivacyPolicyComponent} from '@modules/privacy-policy/privacy-policy.component';
import { VssplComponent } from '@modules/ecard/vsspl/vsspl.component';
import { TraceFarmComponent } from '@modules/ecard/traceFarm/traceFarm.component';
import { VssplECardFormComponent } from '@modules/ecard/vsspl-e-card-form/vsspl-e-card-form.component';
import { PreviewMssiComponent } from '@modules/ecard/mssi/preview-mssi.component';
import {BlogComponent} from "@modules/blog/blog.component";

const routes: Routes = [
  {
    path: '',
    component: WebsiteComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      // path: '',
      // component: MainComponent,
      // canActivate: [AuthGuard],
      // canActivateChild: [AuthGuard],
      // children: [
      //   {
      //     path: 'profile',
      //     component: ProfileComponent
      //   },
      //   {
      //     path: 'blank',
      //     component: BlankComponent
      //   },
      //   {
      //     path: 'sub-menu-1',
      //     component: SubMenuComponent
      //   },
      //   {
      //     path: 'sub-menu-2',
      //     component: BlankComponent
      //   },
      //   {
      //     path: 'dash',
      //     component: DashboardComponent
      //   }
    ]
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
  {
    path: '',
    component:WebsiteComponent,
  },
  {
    path: 'design-a-business-card-for-free',
    component: BlogComponent
  },
  {
    path: 'terms',
    component:TermsComponent,
  },
  {
    path: 'privacy',
    component:PrivacyPolicyComponent,
  },
  {
    path: 'main',
    component: MainComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'profile',
        component: ProfileComponent
      },
      {
        path: 'blank',
        component: BlankComponent
      },
      {
        path: 'sub-menu-1',
        component: SubMenuComponent
      },
      {
        path: 'sub-menu-2',
        component: BlankComponent
      },
      {
        path: 'dash',
        component: DashboardComponent
      },
      {
        path: 'eCardForm',
        component: ECardFormComponent
      },
      {
        path : 'vsspleCardForm',
        component : VssplECardFormComponent
      }
    ]
  },
  {
    path: 'register',
    component: RegisterComponent,
    // canActivate: [NonAuthGuard]
  },

  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [NonAuthGuard]
  },
  {
    path: 'recover-password',
    component: RecoverPasswordComponent,
    canActivate: [NonAuthGuard]
  },
  {
    path: 'businessCard',
    component: BusinessCardComponent
},
{
    path: 'eCard',
    component: ECardComponent
},
{
    path: 'previewSeven',
    component: VisitingCardComponent
},
{
    path: 'previewThree',
    component: PreviewThreeComponent
},
{
    path: 'previewFour',
    component: PreviewFourComponent
},
{
    path: 'previewFive',
    component: PreviewFiveComponent
},
{
    path: 'previewSix',
    component: PreviewSixComponent
},
{
    path: 'previewEight',
    component: PreviewEightComponent
},
{
    path: 'previewNine',
    component: PreviewNineComponent
},
{
    path: 'previewTen',
    component: PreviewTenComponent
},
{
    path: 'previewEleven',
    component: PreviewElevenComponent
},
{
    path: 'previewTwelve',
    component: PreviewTwelveComponent
},
{
    path: 'previewThirteen',
    component: PreviewThirteenComponent
},
{
    path: 'previewFourteen',
    component: PreviewFourteenComponent
},
{
    path: 'previewFifteen',
    component: PreviewFifteenComponent
},
{
  path: 'previewSixteen',
  component: PreviewSixteenComponent
},
{
  path: 'previewSeventeen',
  component: PreviewSeventeenComponent
},
{
  path : 'previewEighteen',
  component : PreviewEighteenComponent
},
{
  path : 'previewNineteen',
  component : PreviewNineteenComponent
},
{
  path : 'previewTwenty',
  component : PreviewTwentyComponent
},
{
  path : 'previewTwentyOne',
  component : PreviewTwentyOneComponent
},
{
  path : 'previewTwentyTwo',
  component : PreviewTwentyTwoComponent
},
{
  path : 'previewTwentyThree',
  component : PreviewTwentyThreeComponent
},
{
  path : 'previewTwentyFour',
  component : PreviewTwentyFourComponent
},
{
  path : 'previewTwentyFive',
  component : PreviewTwentyFiveComponent
},
{
  path : 'previewTwentySix',
  component : PreviewTwentySixComponent
},
{
  path : 'previewTwentySeven',
  component : PreviewTwentySevenComponent
},
{
  path : 'previewTwentyEight',
  component : PreviewTwentyEightComponent
},
{
  path : 'previewTwentyNine',
  component : PreviewTwentyNineComponent
},
{
  path : 'previewThirty',
  component : PreviewThirtyComponent
},
{
  path : 'previewThirtyOne',
  component : PreviewThirtyOneComponent
},
{
  path : 'previewThirtyTwo',
  component : PreviewThirtyTwoComponent
},
{
  path : 'previewThirtyThree',
  component : PreviewThirtyThreeComponent
},
{
  path : 'previewThirtyFour',
  component : PreviewThirtyFourComponent
},
{
  path : 'previewThirtyFive',
  component : PreviewThirtyFiveComponent
},
{
  path : 'previewThirtySix',
  component : PreviewThirtySixComponent
},
{
  path : 'previewThirtySevenComponent',
  component : PreviewThirtySevenComponent
},
{
  path : 'previewThirtyEight',
  component : PreviewThirtyEightComponent
},
{
  path : 'previewThirtyNine',
  component : PreviewThirtyNineComponent
},
{
  path : 'previewForty',
  component : PreviewFortyComponent
},
{
  path : 'previewFortyOne',
  component : PreviewFortyOneComponent
},
{
  path : 'previewFortyTwo',
  component : PreviewFortyTwoComponent
},
{
  path : 'previewFortyThree',
  component : PreviewFortyThreeComponent
},
{
  path : 'previewFortyFour',
  component : PreviewFortyFourComponent
},
{
  path :'previewFortyFive',
  component : PreviewFortyFiveComponent
},
{
  path : 'previewFortySix',
  component : PreviewFortySixComponent
},
{
  path : 'previewFortySeven',
  component : PreviewFortySevenComponent
},
{
  path : 'previewFortyEight',
  component : PreviewFortyEightComponent
},
{
  path : 'previewFortyNine',
  component : PreviewFortyNineComponent
},
{
  path : 'previewFiftyComponent',
  component : PreviewFiftyComponent
},
{
  path : 'previewFiftyOneComponent',
  component : PreviewFiftyOneComponent
},
{
  path : 'previewFiftyTwo',
  component : PreviewFiftyTwoComponent
},
{
  path : 'previewFiftyThree',
  component : PreviewFiftyThreeComponent
},
{
  path : 'previewFiftyFour',
  component : PreviewFiftyFourComponent
},
{
  path : 'previewFiftyFive',
  component : PreviewFiftyFiveComponet
},
{
  path : 'previewFiftySix',
  component : PreviewFiftySixComponent
},
{
  path : 'previewFiftySeven',
  component : PreviewFiftySevenComponent
},
{
  path : 'previewFiftyEight',
  component : PreviewFiftyEightComponent
},
{
  path : 'previewFiftyNine',
  component : PreviewFiftyNineComponent
},
{
  path : 'previewSixty',
  component : PreviewSixtyComponent
},
{
  path : 'previewSixtyOne',
  component : PreviewSixtyOneComponent
},
{
  path : 'vsspl',
  component : VssplComponent
},
{
  path : 'traceFarm',
  component : TraceFarmComponent
},
{
  path : 'mssi',
  component : PreviewMssiComponent
},
{
    path: 'e',
    component: MainScreenComponent,
},
{
    path: 'login',
    component: LoginComponent
},
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
