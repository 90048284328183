<section class="vh-100" style="background-color: rgb(254 242 242)" *ngIf="profileShow">
    <div class="container-fluid py-2">
        <div class="container">
            <div class="row ">
                <div class="col-md-12 text-center mt-3">
                    <img class="companyProfile" [src]="companySrcUrl">
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-12 justify-content-center">
                    <h5 class="text-center" style="margin-left: 10px;"><b>{{currentFormData!.companyName}}</b></h5>
                </div>
            </div>

            <div class="row mt-3">
             
                    <div class="row">
                        <div class="col-md-12 text-center">
                            <img class="Profile" [src]="profileSrcUrl">
                        </div>
                    </div>
                        
                    <div class="row ">
                        <div class="col-12 text-center">
                            <h4 class="" >{{currentFormData!.firstName}} {{currentFormData!.lastName}}</h4>
                            <h6 class="designation">{{currentFormData!.designation}}</h6>
                            <h6 class="text-center" >{{currentFormData!.role}}</h6>
                        </div>
                    </div>
       
          
            </div>

            <div class="row mt-2 mb-2" style="background-color: #eccae1">
                <div class="col-3 mt-3"></div>
                <div class="col-8">
                </div>
                <div class="col-1"></div>
            </div>

            <div class="row">
                <div class="col-1"></div>
                <div class="col-10">
                    <div class="row mt-2">
                        <div class="col-2"><i class="bi bi-telephone-fill roundIcon" type="button" title="Call"></i></div>
                        <div class="col-10 mt-1"><h6 class="" (click)="copyPhoneNo(currentFormData!.countryCodePhoneNo, formatNumber(currentFormData!.phoneNo))">
                            {{currentFormData!.countryCodePhoneNo}} {{formatNumber(currentFormData!.phoneNo)}}</h6></div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-2"><i class="bi bi-geo-alt-fill roundIcon" type="button" title="Location"></i></div>
                        <div class="col-10 mt-1"><h6 class="">{{currentFormData!.address}}</h6></div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-2"><i class="bi bi-globe2 roundIcon" type="button" title="Website"></i></div>
                        <div class="col-10 mt-1"><h6 class="">
                            <a class="text-dark" style="line-break: anywhere;text-decoration: none;"
                             href="http://{{currentFormData!.companyWebsiteName}}" target="_blank">{{currentFormData!.companyWebsiteName}}</a>
                        </h6>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-2"><i class="bi bi-envelope roundIcon" type="button" title="Mail"></i></div>
                        <div class="col-10 mt-1">
                            <h6 class=""><a class="text-dark" style="line-break: anywhere;text-decoration: none;"
                             href="mailto:{{currentFormData!.email}}" target="_blank">{{currentFormData!.email}}</a>
                            </h6>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-2"><i title="WhatsApp" class="bi bi-whatsapp roundIcon fs-6"></i>
                        </div>
                        <div class="col-10 mt-1">
                            <h6 class="mt-1" type="button" style="margin-left: 1px;"
                                (click)="whatsApp(currentFormData!.countryCodeWhatsAppNo, currentFormData!.whatsAppNumber)">
                                {{currentFormData!.countryCodeWhatsAppNo}} {{formatNumber(currentFormData!.whatsAppNumber)}}</h6>
                        </div>
                    </div>
                </div>
                <div class="col-1"></div>
            </div>
            
            <div class="p-40 py-1 mt-2"></div>
        </div>
    </div>
</section>













<section class="vh-100" style="background-color: rgb(254 242 242)" *ngIf="profilehide">
    <div class="container-fluid py-2">
        <div class="container">
            <div class="row ">
                <div class="col-md-12 text-center mt-3">
                    <img class="companyProfile" [src]="companySrcUrl">
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-12 justify-content-center">
                    <h5 class="text-center" style="margin-left: 10px;"><b>{{currentFormData!.companyName}}</b></h5>
                </div>
            </div>

            <div class="row mt-1">

                <!-- <div class="row mt-2 mb-2" style="background-color: #eccae1;"></div> -->
                <div class="     mt-2 mb-2" style="background-color: #eccae1">
                    <div class="col-3 mt-3"></div>
                </div>
                                     
                    <div class="row ">
                        <div class="col-12 text-center">
                            <h4 class="" >{{currentFormData!.firstName}} {{currentFormData!.lastName}}</h4>
                            <h6 class="designation">{{currentFormData!.designation}}</h6>
                            <h6 class="text-center" >{{currentFormData!.role}}</h6>
                        </div>
                    </div>
       
          
            </div>

            <div class="row mt-2 mb-2" style="background-color: #eccae1">
                <div class="col-3 mt-3"></div>
                <div class="col-8">
                </div>
                <div class="col-1"></div>
            </div>

            <div class="row">
                <div class="col-1"></div>
                <div class="col-10">
                    <div class="row mt-2">
                        <div class="col-2"><i class="bi bi-telephone-fill roundIcon" type="button" title="Call"></i></div>
                        <div class="col-10 mt-1"><h6 class="" (click)="copyPhoneNo(currentFormData!.countryCodePhoneNo, formatNumber(currentFormData!.phoneNo))">
                            {{currentFormData!.countryCodePhoneNo}} {{formatNumber(currentFormData!.phoneNo)}}</h6></div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-2"><i class="bi bi-geo-alt-fill roundIcon" type="button" title="Location"></i></div>
                        <div class="col-10 mt-1"><h6 class="">{{currentFormData!.address}}</h6></div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-2"><i class="bi bi-globe2 roundIcon" type="button" title="Website"></i></div>
                        <div class="col-10 mt-1"><h6 class="">
                            <a class="text-dark" style="line-break: anywhere;text-decoration: none;"
                             href="http://{{currentFormData!.companyWebsiteName}}" target="_blank">{{currentFormData!.companyWebsiteName}}</a>
                        </h6>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-2"><i class="bi bi-envelope roundIcon" type="button" title="Mail"></i></div>
                        <div class="col-10 mt-1">
                            <h6 class=""><a class="text-dark" style="line-break: anywhere;text-decoration: none;"
                             href="mailto:{{currentFormData!.email}}" target="_blank">{{currentFormData!.email}}</a>
                            </h6>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-2"><i title="WhatsApp" class="bi bi-whatsapp roundIcon fs-6"></i>
                        </div>
                        <div class="col-10 mt-1">
                            <h6 class="mt-1" type="button" style="margin-left: 1px;"
                                (click)="whatsApp(currentFormData!.countryCodeWhatsAppNo, currentFormData!.whatsAppNumber)">
                                {{currentFormData!.countryCodeWhatsAppNo}} {{formatNumber(currentFormData!.whatsAppNumber)}}</h6>
                        </div>
                    </div>
                </div>
                <div class="col-1"></div>
            </div>
            
            <div class="p-40 py-1 mt-2"></div>
        </div>
    </div>
</section>




















<ngx-spinner bdColor="rgba(255, 255, 255, 1)" size="large" color="#FFFFFF"
             [fullScreen]="true">
    <figure class="loader">
        <div class="dot white"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
    </figure>
</ngx-spinner>
<app-ecard-footer></app-ecard-footer>
