import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import {AppService} from '@services/app.service';
import {DateTime} from 'luxon';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  public user;
  username: string | undefined;
  showTooltip : boolean = false;
  constructor(private appService: AppService,private router:Router) {
  }

  ngOnInit(): void {
    this.user = this.appService.user;
    this.username=localStorage.getItem('username')
  }

  show() {
    this.showTooltip=true;
  }

  logout() {
    // this.appService.logout();
    this.router.navigate([''])
    localStorage.removeItem('username')
  }

  formatDate(date) {
    return DateTime.fromISO(date).toFormat('dd LLL yyyy');
  }

  reload(){
    window.location.reload();
  }
  home(){
    this.router.navigate(['/main/dash'])
  }
}
