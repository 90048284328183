import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ReactiveFormsModule } from '@angular/forms';
import { Subject, takeUntil } from "rxjs";
import { ECardForm } from "../modal/eCardForm";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';
import { Banner } from "../modal/banner";
import { ignoreElements } from "rxjs/operators";
import { ApiService } from '@services/api.service';
import { FormDataService } from '@services/formDataService';
import { AppService } from '@services/app.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-e-card-form',
    templateUrl: './e-card-form.component.html',
    styleUrls: ['./e-card-form.component.css']
})
export class ECardFormComponent implements OnInit, OnDestroy {
    event: any;
    isModalShow = false;
    isQRShow = false;
    isWhatsAppShow = false;
    currentFormData: ECardForm | undefined;
    profileFileList: File[] = [];
    companyFileList: File[] = [];
    listOfFiles: any[] = [];
    cardDetails: ECardForm[] = [];
    emailIds: string[] = [];
    url: string = ""
    selectCardName: string = ""
    saveButton = true;
    showDropdown = false;
    eventID: string | undefined
    eventDetails: ECardForm[] = [];
    userId: string | undefined;
    eCardName: string[] = [];
    eCardDisplayName: string[] = [];
    isUpdate = false;
    isSidebarOpen = false;
    images: string = "";
    showECardName  = false
    updateCard = false
    profileimages: string = "";
    showDisplayName = false;
    previewtype : string = ""
    localpreviewtype : string = ""
    copyUrl : string = ""
    ecardDisplayName : string = ""
    showCardName = false;
    /* // @ts-ignore
     banner: Banner;*/

    destroy$: Subject<boolean> = new Subject<boolean>();
    private localStorage: any;
    private isEditMode: boolean = false;

    constructor(private appService: AppService, private dataService: FormDataService,
        private router: Router, private spinner: NgxSpinnerService,private tostar : ToastrService,
        private elRef: ElementRef) {
        /*this.banner = new Banner(
            'ca-pub-8228159457289501',
            8223850896,
            'auto',
            true
        )*/
    }

    registrationForm = new UntypedFormGroup({
        companyName: new UntypedFormControl("", Validators.required),
        companyLogo: new UntypedFormControl("", Validators.required),
        companyWebsiteName: new UntypedFormControl("",[Validators.required,Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]),
        address: new UntypedFormControl("", Validators.required),
        firstName: new UntypedFormControl("", Validators.required),
        lastName: new UntypedFormControl("", Validators.required),
        role: new UntypedFormControl("", Validators.required),
        phoneNo: new UntypedFormControl("", Validators.required),
        telephone: new UntypedFormControl("", Validators.required),
        email: new UntypedFormControl("", [Validators.required, Validators.email]),
        profileUpload: new UntypedFormControl("", Validators.required),
        profileShow: new UntypedFormControl("", Validators.required),
        profilePath: new UntypedFormControl("", Validators.required),
        companyLogoPath: new UntypedFormControl("", Validators.required),
        ecardFormId: new UntypedFormControl("", Validators.required),
        ecardName: new UntypedFormControl("", Validators.required),
        previewType: new UntypedFormControl("", Validators.required),
        whatsAppNoYn: new UntypedFormControl("", Validators.required),
        whatsAppNumber: new UntypedFormControl("", Validators.required),
        designation: new UntypedFormControl("", Validators.required),
        countryCodePhoneNo: new UntypedFormControl("", Validators.required),
        countryCodeWhatsAppNo: new UntypedFormControl("", Validators.required),
        cardDisplayName: new UntypedFormControl("", Validators.required),
        createdBy: new UntypedFormControl("", Validators.required),
        createdAt: new UntypedFormControl("", Validators.required),
    })
    profileError = {
        companyName: '',
        companyLogo: '',
        companyWebsiteName: '',
        companyAddress: '',
        firstName: '',
        lastName: '',
        role: '',
        phoneNo: '',
        telPhone: '',
        whatsAppNoYn: '',
        email: '',
        profileUpload: '',
        profileShow: '',
        countryCodePhone: '',
        countryCodeWhatsApp: '',
        previewType: '',
        cardDisplayName: ''
    }

    ngOnInit() {
        // this.spinner.show()
        if (localStorage.getItem("EcardName")) {
            this.updateCard = true
            this.UpdateAlert()
            this.getUserDetails()
        } else {
            this.getAllDetails();
            // this.registrationForm.controls['countryCodePhoneNo'].setValue('+91');
            // this.registrationForm.controls['countryCodeWhatsAppNo'].setValue('+91');
            this.showECardName = true
            // this.getUserDetails();
            this.userId = String(localStorage.getItem('userId'));
            console.log("user Reg ID::", this.userId)
        }
    }

    ngAfterViewInit() {
        const inputElement = this.elRef.nativeElement.querySelector('#cardDisplayName');
        inputElement.focus();
      }

    onSubmit() {
        this.profileValid();
    }

    profileValid() {
        this.profileError.companyName = ''
        this.profileError.companyLogo = ''
        this.profileError.companyWebsiteName = ''
        this.profileError.companyAddress = ''
        this.profileError.firstName = ''
        this.profileError.lastName = ''
        this.profileError.role = ''
        this.profileError.phoneNo = ''
        this.profileError.whatsAppNoYn = ''
        this.profileError.telPhone = ''
        this.profileError.email = ''
        // this.profileError.profileUpload = ''
        // this.profileError.profileShow = ''
        this.profileError.countryCodePhone = ''
        this.profileError.countryCodeWhatsApp = ''

        let hasError = false;

        if (this.registrationForm.get('companyName')?.invalid) {
            this.profileError.companyName = "Company Name is required";
            hasError = true;
        }
        if (this.registrationForm.get('companyLogo')?.invalid && !this.isUpdate) {
            this.profileError.companyLogo = "Company Logo is required";
            hasError = true;
        }
        if (this.registrationForm.get('companyWebsiteName')?.invalid) {
            if (this.registrationForm.get('companyWebsiteName').errors.required) {
                this.profileError.companyWebsiteName = "*Company Website is required";
              }
              if (this.registrationForm.get('companyWebsiteName').errors.pattern) {
                this.profileError.companyWebsiteName = "*Please, Enter Your Correct Website Link.";
              }
              hasError = true;
            }
        if (this.registrationForm.get('address')?.invalid) {
            this.profileError.companyAddress = "Company Address is required";
            hasError = true;
        }
        if (this.registrationForm.get('firstName')?.invalid) {
            this.profileError.firstName = "First Name is required";
            hasError = true;
        }
        if (this.registrationForm.get('lastName')?.invalid) {
            this.profileError.lastName = "Last Name is required";
            hasError = true;
        }
        if (this.registrationForm.get('role')?.invalid) {
            this.profileError.role = "Role is required";
            hasError = true;
        }
        if (this.registrationForm.get('phoneNo')?.invalid) {
            this.profileError.phoneNo = "Phone No is required";
            hasError = true;
        }

        /*const value = this.registrationForm.get('phoneNo')?.value
        if (value.length < 10) {
          this.profileError.phoneNo = "Enter 10 digit Number";
          hasError = true;
        }*/
        /*if (this.registrationForm.get('whatsAppNoYn')?.invalid) {
            this.profileError.whatsAppNoYn = "This field is required";
            hasError = true;
        }*/

        if (this.registrationForm.get('email')?.invalid) {
            this.profileError.email = "email is required";
            hasError = true;
        }

        if (this.registrationForm.get('emgetUserDetailsail')?.errors) {
            this.profileError.email = "*Enter Valid Email.(Ex:example@gmail.com)";
            hasError = true
        }

        // const email = this.registrationForm.get('email')?.value
        // if (this.emailIds.includes(email) && !this.isUpdate) {
        //     this.profileError.email = "Already Exist"
        //     hasError = true;
        // }

        // if (this.registrationForm.get('profileUpload')?.invalid && !this.isUpdate) {
        //     this.profileError.profileUpload = "Profile Upload is required";
        //     hasError = true;
        // }

        // if (this.registrationForm.get('profileShow')?.invalid) {
        //     this.profileError.profileShow = "Profile Picture Show Or Not is required";
        //     hasError = true;
        // }

        if (this.companyFileList.length < 1 && !this.isUpdate) {
            this.profileError.companyLogo = "Company Logo is required"
            hasError = true;
        }

        if (this.registrationForm.get('countryCodePhoneNo')?.invalid) {
            this.profileError.countryCodePhone = "Country Code is required"
            hasError = true;
        }

        if (this.registrationForm.get('whatsAppNumber')?.valid) {
            if (this.registrationForm.get('countryCodeWhatsAppNo')?.invalid) {
                this.profileError.countryCodeWhatsApp = "Country Code is required"
                hasError = true;
            }
        }

        // if (this.profileFileList.length < 1 && !this.isUpdate) {
        //     this.profileError.profileUpload = "Profile Upload is required"
        //     hasError = true;
        // }

        if (!hasError) {
            this.saveForm();
        }
    }



    //Enter number only event function for Phone Number fields
    numberOnly(event: any): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    /*whatsApp(event: any) {
        const value = event.target.value;
        console.log("value::", value);
        /!*if (value == "Yes") {
            this.isWhatsAppShow = false;
            this.registrationForm.controls['countryCodeWhatsAppNo'].setValue('')
            this.registrationForm.controls['whatsAppNumber'].setValue('')
        } else if (value == "No") {
            this.isWhatsAppShow = true;
            this.registrationForm.controls['countryCodeWhatsAppNo'].setValue('+91')
        }*!/
    }*/

    //When Select Country code in Phone Number field set value to WhatsApp Country code field
    copyCountryCodeToWhatsApp(event: any) {
        const value = event.target.value;
        this.registrationForm.controls['countryCodeWhatsAppNo'].setValue(value);
    }


    //When enter the Phone to auto entered in whatsApp field , if other number in whatsApp number user change edit the number
    phoneNumberCopy(event: any) {
        const value = event.target.value;
        this.registrationForm.controls['whatsAppNumber'].setValue(value);
    }


    //New E card Save & Update Functionality
    saveForm() {

        if (this.registrationForm.get('whatsAppNumber')?.invalid) {
            this.registrationForm.controls['countryCodeWhatsAppNo'].setValue('')
        }
        this.registrationForm.controls['createdBy'].setValue(localStorage.getItem('userId'));

        const email = this.registrationForm.get('email')?.value
        console.log("form details::", this.registrationForm.value)
        this.spinner.show();
        if (this.isUpdate) {
            this.registrationForm.controls['previewType'].setValue(this.previewtype)
            if(this.registrationForm.get('previewType').value == ""){
            this.registrationForm.controls['previewType'].setValue(this.localpreviewtype)
            }
            this.registrationForm.get('email')?.enable()
            this.appService.updateCardDetails(this.registrationForm.value)
                .pipe(takeUntil(this.destroy$))
                .subscribe((data) => {
                    console.log("Update form data:", data)
                    this.currentFormData = data;
                    // @ts-ignore
                    var resData: ECardForm = data;
                    this.tostar.success("Your Details Updated Successfully")
                    this.url = 'https://tomcat3.varnik.cloud:8443/ecard-ws/qr/getPreviewScreenQR?eCardName=' + resData.ecardName;
                    this.copyUrl = 'https://ecard.varnik.cloud/#/e?CardName=' + resData.ecardName
                    console.log("card details::", this.copyUrl)
                    localStorage.setItem('eCardName', resData.ecardName)
                    if (this.profileFileList.length > 0) {
                        const profileFormData = new FormData();
                        for (const file of this.profileFileList) {
                            profileFormData.append('file', file, file.name);
                        }
                        this.appService.fileUpload(profileFormData, resData.ecardName, "profile")
                            .pipe(takeUntil(this.destroy$))
                            .subscribe(data => {
                                console.log("upload status:", data)
                            }, (error: any) => {
                                this.spinner.hide();
                                console.log("Upload status:", error)
                            })
                    }
                    if (this.companyFileList.length > 0) {
                        const companyFormData = new FormData();
                        for (const file of this.companyFileList) {
                            companyFormData.append('file', file, file.name);
                        }
                        this.appService.fileUpload(companyFormData, resData.ecardName, "companyLogo")
                            .pipe(takeUntil(this.destroy$))
                            .subscribe(data => {
                                this.spinner.hide();
                                this.registrationForm.reset();
                                console.log("upload status:", data)
                            }, (error: any) => {
                                this.spinner.hide();
                                console.log("Upload status:", error)
                                this.registrationForm.reset();
                                this.errorPopup();
                            })
                    }
                    this.spinner.hide();
                    this.isQRShow = true;
                    /*this.updatePopup();
                    window.location.reload();*/
                    /* this.isQRShow = true;*/
                    /*if (resData.previewType == "previewOne") {
                      this.router.navigate(["/businessCard"])
                    } else if (resData.previewType == "previewTwo") {
                      this.router.navigate(["/eCard"])
                    }*/
                }, (error: any) => {
                    this.spinner.hide();
                    this.registrationForm.reset();
                    console.log("something wrong:", error)
                    this.errorPopup();
                })
        } else {
            this.registrationForm.controls['previewType'].setValue(localStorage.getItem('currentPreviewType'))
            this.registrationForm.controls['cardDisplayName'].setValue(this.registrationForm.get("cardDisplayName").value)
            console.log("savedata::",this.registrationForm.value)
            this.appService.saveCardDetails(this.registrationForm.value)
                .pipe(takeUntil(this.destroy$))
                .subscribe((data) => {
                    console.log("saved form data:", data)
                    this.currentFormData = data;
                    // @ts-ignore
                    var resData: ECardForm = data;
                    this.url = 'https://tomcat3.varnik.cloud:8443/ecard-ws/qr/getPreviewScreenQR?eCardName=' + resData.ecardName;
                    this.copyUrl = 'https://ecard.varnik.cloud/#/e?CardName=' + resData.ecardName
                    localStorage.setItem('eCardName', resData.ecardName)
                    const profileFormData = new FormData();
                    for (const file of this.profileFileList) {
                        profileFormData.append('file', file, file.name);
                    }
                    this.appService.fileUpload(profileFormData, resData.ecardName, "profile")
                        .pipe(takeUntil(this.destroy$))
                        .subscribe(data => {
                            console.log("upload status:", data)
                        }, (error: any) => {
                            this.spinner.hide();
                            console.log("Upload status:", error)
                        })
                    const companyFormData = new FormData();
                    for (const file of this.companyFileList) {
                        companyFormData.append('file', file, file.name);
                    }
                    this.appService.fileUpload(companyFormData, resData.ecardName, "companyLogo")
                        .pipe(takeUntil(this.destroy$))
                        .subscribe(data => {
                            this.spinner.hide();
                            this.registrationForm.reset();
                            console.log("upload status:", data)
                            this.successPopup();
                        }, (error: any) => {
                            this.spinner.hide();
                            console.log("Upload status:", error)
                            this.registrationForm.reset();
                            this.errorPopup();
                        })
                        this.spinner.hide();
                        this.isQRShow = true;
                    /*if (resData.previewType == "previewOne") {
                      this.router.navigate(["/businessCard"])
                    } else if (resData.previewType == "previewTwo") {
                      this.router.navigate(["/eCard"])
                    }*/
                }, (error: any) => {
                    this.spinner.hide();
                    this.registrationForm.reset();
                    console.log("something wrong:", error)
                    this.errorPopup();
                })
        }
    }


    // E card copy link URL link
    copyURL() {
        if (!this.isUpdate) {
            const url = 'https://ecard.varnik.cloud/#/e?CardName=' + this.currentFormData!.ecardName
            const textArea = document.createElement('textarea');
            textArea.value = url;
            document.body.appendChild(textArea);
            textArea.select();
            document.execCommand('copy');
            document.body.removeChild(textArea);
            // alert('URL copied to clipboard');
            this.tostar.success("URL copied to clipboard")
        }
        else {
            const url = 'https://ecard.varnik.cloud/#/e?CardName=' + this.selectCardName
            const textArea = document.createElement('textarea');
            textArea.value = url;
            document.body.appendChild(textArea);
            textArea.select();
            document.execCommand('copy');
            document.body.removeChild(textArea);
            // alert('URL copied to clipboard');
            this.tostar.success("URL copied to clipboard")
        }
    }


    // QR code Image Download
    downloadQR() {
        const link = document.createElement('a');
        link.href = this.url;
        link.download = 'image.png'; // Set the desired file name
        link.click();
    }

    //reload
    reload() {
        // window.location.reload();
        this.router.navigate(['/main/dash'])
        localStorage.removeItem("EcardName")
    }


    // Open Modal popup
    openModal() {
        this.isModalShow = true;
    }


    //select preview template card
    perviewPopup() {
        Swal.fire({
            position: 'center',
            icon: 'warning',
            title: 'Select the Preview Image',
            showConfirmButton: false,
            timer: 1500
        })
    }



    //Close Modal Popup
    closeModal() {
        this.profileError.previewType = ""
        let hasError = false
        if (this.registrationForm.get('previewType')?.invalid) {
            this.perviewPopup()
            hasError = true
        }
        if (!hasError) {
            this.isModalShow = false;
            // this.saveButton = true;
        }
    }



    ngOnDestroy() {
        this.dataService.formData = this.currentFormData;
    }


    //Login based get all E card Details
    getAllDetails() {
        this.appService.getAllCardDetails()
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                console.log("all data:", data)
                this.cardDetails = data;
                for (let card of this.cardDetails) {
                    this.emailIds.push(card.email)
                }
            }, (err: any) => {
                console.log("something went wrong:", err)
            })
    }


    //Image under 1MB resolution change Functionality
    onFileChangeProfile(event: any) {
        this.profileFileList = [];
        for (var i = 0; i <= event.target.files.length - 1; i++) {
            var selectedFile = event.target.files[i];
            console.log("select file size ::" + selectedFile);
            var fileSizeInMb = Math.round((selectedFile.size * 100) / (1024 * 1024)) / 100;
            console.log("select file convert size ::" + fileSizeInMb);
            if (fileSizeInMb <= 1) {
                if (event.target.files && event.target.files[0]) {
                    var reader = new FileReader();
                    reader.onload = (event: any) => {
                        this.profileimages = event.target.result;
                    };
                    reader.readAsDataURL(event.target.files[0]);
                }
                this.profileFileList.push(selectedFile);
                this.listOfFiles.push(selectedFile.name);
                console.log("File list::", this.profileFileList.length)
            } else {
                console.log("File size is large:", this.profileFileList.length)
                Swal.fire({
                    title: 'File size is large. Please upload below 1MB',
                    showClass: {
                        popup: 'animate_animated animate_fadeInDown'
                    },
                    hideClass: {
                        popup: 'animate_animated animate_fadeOutUp'
                    }
                })
                this.profileFileList = [];
                this.profileimages = "";
                this.registrationForm.controls["profileUpload"].setValue('')
            }
        }
    }


    //Company logo profile image functionality
    onFileChangeCompany(event: any) {
        this.companyFileList = [];
        for (var i = 0; i <= event.target.files.length - 1; i++) {
            var selectedFile = event.target.files[i];
            console.log("select file size ::" + selectedFile);
            var fileSizeInMb = Math.round((selectedFile.size * 100) / (1024 * 1024)) / 100;
            console.log("select file convert size ::" + fileSizeInMb);
            if (fileSizeInMb <= 1) {
                if (event.target.files && event.target.files[0]) {
                    var reader = new FileReader();
                    reader.onload = (event: any) => {
                        this.images = event.target.result;
                    };
                    reader.readAsDataURL(event.target.files[0]);
                }
                this.companyFileList.push(selectedFile);
                this.listOfFiles.push(selectedFile.name);
                console.log("File list::", this.companyFileList.length)
            } else {
                console.log("File size is large:", this.companyFileList.length)
                Swal.fire({
                    title: 'File size is large. Please upload below 1MB',
                    showClass: {
                        popup: 'animate_animated animate_fadeInDown'
                    },
                    hideClass: {
                        popup: 'animate_animated animate_fadeOutUp'
                    }
                })
                this.images = "";
                this.companyFileList = []
                this.registrationForm.controls["companyLogo"].setValue('')
            }
        }
    }


    // Sweet alert success popup functionality
    successPopup() {
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Your Card Details Saved Successfully',
            showConfirmButton: false,
            timer: 1500

        })
    }

    updatePopup() {
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Your Card Update Successfully',
            showConfirmButton: false,
            timer: 1500
        })
    }

    errorPopup() {
        Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'something went wrong, Please try again later',
            showConfirmButton: false,
            timer: 1500
        })
    }


    open() {
        this.showDropdown = true;
    }

    close() {
        this.showDropdown = false;
    }

    eCardNameclose(){
        this.profileError.cardDisplayName = ''

        let hasError = false

  if (this.registrationForm.get('cardDisplayName')?.invalid) {
            this.profileError.cardDisplayName = "eCard Display Name is required"
            hasError = true
        }

        if(!hasError){
            this.showECardName = false
            this.showCardName = true
            this.ecardDisplayName = this.registrationForm.get('cardDisplayName').value
        }
    }

    //Login based E cards show functionality
    getUserDetails() {
        // @ts-ignore
        this.appService.getUserDetails(localStorage.getItem('userId'))
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                console.log("user Reg ID::", this.userId)
                console.log("ECardForm:", data)
                this.eventDetails = data;
                console.log("UserECardForm:", data)
                for (var userECard of this.eventDetails) {
                    this.eCardName.push(userECard.ecardName)
                    this.eCardDisplayName.push(userECard.cardDisplayName)
                    console.log("eCardName::", this.eCardName)
                    console.log(":", data)
                }

            })

    }

    selectpopupCard(name: string) {
        const value = name;
        console.log("Event Name::", value)
        this.showDropdown = false; // Hide the dropdown when closing the modal
        for (var card of this.eventDetails) {
            if (value == card.ecardName) {
                this.isUpdate = true;
                // this.registrationForm.get('email')?.disable();
                console.log("cardName::", card.ecardName)
                this.registrationForm.controls["ecardFormId"].setValue(card.ecardFormId)
                this.registrationForm.controls["companyName"].setValue(card.companyName);
                this.registrationForm.controls["ecardName"].setValue(card.ecardName);
                this.registrationForm.controls["firstName"].setValue(card.firstName);
                this.registrationForm.controls["lastName"].setValue(card.lastName);
                this.registrationForm.controls["designation"].setValue(card.designation);
                this.registrationForm.controls["role"].setValue(card.role);
                this.registrationForm.controls["phoneNo"].setValue(card.phoneNo);
                this.registrationForm.controls["telephone"].setValue(card.telephone);
                this.registrationForm.controls["email"].setValue(card.email);
                this.registrationForm.controls["address"].setValue(card.address);
                this.registrationForm.controls["profileShow"].setValue(card.profileShow)
                this.registrationForm.controls["profilePath"].setValue(card.profilePath)
                this.registrationForm.controls["companyLogoPath"].setValue(card.companyLogoPath)
                this.registrationForm.controls["companyWebsiteName"].setValue(card.companyWebsiteName);
                this.registrationForm.controls["whatsAppNoYn"].setValue(card.whatsAppNoYn);
                this.registrationForm.controls["countryCodePhoneNo"].setValue(card.countryCodePhoneNo);
                this.registrationForm.controls["countryCodeWhatsAppNo"].setValue(card.countryCodeWhatsAppNo);
                this.registrationForm.controls["createdAt"].setValue(card.createdAt);
                this.registrationForm.controls["createdBy"].setValue(card.createdBy);
                this.registrationForm.controls["previewType"].setValue(card.previewType);
                this.registrationForm.controls["cardDisplayName"].setValue(card.cardDisplayName);
                this.registrationForm.controls["whatsAppNumber"].setValue(card.whatsAppNumber);
                this.getLogo(card.ecardName)
                this.getProfile(card.ecardName)
                if (card.whatsAppNoYn !== "Yes") {
                    this.registrationForm.controls["whatsAppNumber"].setValue(card.whatsAppNumber);
                    this.isWhatsAppShow = true;
                } else {
                    this.isWhatsAppShow = false;
                }
                this.selectCardName = card.ecardName
                this.localpreviewtype = card.previewType
                // this.UpdateAlert()
            }
        }

    }


    //when select a card auto fetch to all details in input fields
    selectCard(event: any) {
        const value = event.target.value;
        console.log("Event Name::", value)
        this.showDropdown = false; // Hide the dropdown when closing the modal
        for (var card of this.eventDetails) {
            if (value == card.cardDisplayName) {
                this.isUpdate = true;
                // this.registrationForm.get('email')?.disable();
                console.log("cardName::", card.ecardName)
                this.registrationForm.controls["ecardFormId"].setValue(card.ecardFormId)
                this.registrationForm.controls["companyName"].setValue(card.companyName);
                this.registrationForm.controls["ecardName"].setValue(card.ecardName);
                this.registrationForm.controls["firstName"].setValue(card.firstName);
                this.registrationForm.controls["lastName"].setValue(card.lastName);
                this.registrationForm.controls["designation"].setValue(card.designation);
                this.registrationForm.controls["role"].setValue(card.role);
                this.registrationForm.controls["phoneNo"].setValue(card.phoneNo);
                this.registrationForm.controls["telephone"].setValue(card.telephone);
                this.registrationForm.controls["email"].setValue(card.email);
                this.registrationForm.controls["address"].setValue(card.address);
                this.registrationForm.controls["profileShow"].setValue(card.profileShow)
                this.registrationForm.controls["profilePath"].setValue(card.profilePath)
                this.registrationForm.controls["companyLogoPath"].setValue(card.companyLogoPath)
                this.registrationForm.controls["companyWebsiteName"].setValue(card.companyWebsiteName);
                this.registrationForm.controls["whatsAppNoYn"].setValue(card.whatsAppNoYn);
                this.registrationForm.controls["countryCodePhoneNo"].setValue(card.countryCodePhoneNo);
                this.registrationForm.controls["countryCodeWhatsAppNo"].setValue(card.countryCodeWhatsAppNo);
                this.registrationForm.controls["createdAt"].setValue(card.createdAt);
                this.registrationForm.controls["createdBy"].setValue(card.createdBy);
                this.registrationForm.controls["previewType"].setValue(card.previewType);
                this.registrationForm.controls["cardDisplayName"].setValue(card.cardDisplayName)
                this.registrationForm.controls["whatsAppNumber"].setValue(card.whatsAppNumber);
                this.getLogo(card.ecardName)
                this.getProfile(card.ecardName)
                if (card.whatsAppNoYn !== "Yes") {
                    this.registrationForm.controls["whatsAppNumber"].setValue(card.whatsAppNumber);
                    this.isWhatsAppShow = true;
                } else {
                    this.isWhatsAppShow = false;
                }
                this.selectCardName = card.ecardName
                this.localpreviewtype = card.previewType
                this.UpdateAlert()
            }
        }

    }

    getLogo(eCardName: string) {
        // this.spinner.show();
        return this.appService.fileGet(eCardName, "companyLogo")
            .pipe(takeUntil(this.destroy$))
            .subscribe((data: Blob) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    // this.spinner.hide();
                    const base64data = reader.result as string;
                    this.images = base64data;
                    console.log("img url::", this.images);
                };
                reader.readAsDataURL(data);
            },(err:any) =>{
                console.log("Something Went Worng")
                // this.spinner.hide()
            });
    }

    getProfile(eCardName: string) {
        // this.spinner.show();
        return this.appService.fileGet(eCardName, "profile")
            .pipe(takeUntil(this.destroy$))
            .subscribe((data: Blob) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    // this.spinner.hide();
                    const base64data = reader.result as string;
                    this.profileimages = base64data;
                    console.log("img url::", this.profileimages);
                };
                reader.readAsDataURL(data);
            },(err:any) =>{
                console.log("Something Went Worng")
                // this.spinner.hide()
            });
    }

    getPreviewType(preiewType : string){
        const value = preiewType
        // console.log("getPreviewtype::",value)
        // this.profileError.previewType = ""
        // let hasError = false
        // if (this.registrationForm.get('previewType')?.invalid) {
        //     this.perviewPopup()
        //     hasError = true
        // }
        // if (!hasError) {
            this.previewtype = value
            console.log("getPreviewtype::",this.previewtype)
            this.isModalShow = false;
            // this.saveButton = true;
        // }

    }
    addcard() {
        window.location.reload()
    }
    toggleSidebar() {
        this.isSidebarOpen = true;
    }

    openNav() {
        const sidebar = document.getElementById("mySidebar") as HTMLElement;
        const main = document.getElementById("main") as HTMLElement;

        if (sidebar && main) {
            if (sidebar.style.width === "250px") {
                sidebar.style.width = "0";
                main.style.marginLeft = "0";
            } else {
                sidebar.style.width = "250px";
                main.style.marginLeft = "250px";
            }
        }
    }

    UpdateAlert() {
        Swal.fire({
            title: " Do you want to Edit & Update",
            icon: "question",
            showDenyButton: true,
            confirmButtonColor: "#4fab69",
            denyButtonColor: "#c73636",
            confirmButtonText: "Edit",
            denyButtonText: "Copy URL ?",
            showClass: {
                popup: "animate_animated animate_fadeInDown",
            },
            hideClass: {
                popup: "animate_animated animate_fadeOutUp",
            },
            backdrop: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.selectpopupCard(localStorage.getItem("EcardName"));
          } else if (result.isDenied) {
            this.selectpopupCard(localStorage.getItem("EcardName"));
            this.isQRShow = true;
            this.url =
              'https://tomcat3.varnik.cloud:8443/ecard-ws/qr/getPreviewScreenQR?eCardName=' +
              this.selectCardName;
            console.log("url::", this.url);
            this.copyUrl =
              'https://ecard.varnik.cloud/#/e?CardName=' + this.selectCardName;
          }
        });
    }

  closeNamePopup() {
    this.router.navigate(['/main/dash']);
    localStorage.removeItem("currentPreviewType")
  }
}
