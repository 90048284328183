<section class="vh-100" style="background-image: url(../../assets/img/backgroundImages/backFourtyThree.png);">
    <div class="container-fluid py-4  d-flex ">
        <div class="container">
            <div class="row mt-2">
                <div class="col-md-12 text-center">
                    <img class="companyProfile" [src]="companySrcUrl">
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12 justify-content-center">
                    <h5 class="text-center" style="margin-left: 10px;">{{currentFormData!.companyName}}</h5>
                </div>
            </div>
            <!-- <div class="id" style="width:50%;background-color:rgb(255, 174, 69);height:2px;margin-top: 15px; float: right;"></div>
            <div style="width:50%;background-color:transparent;height:2px;margin-top: 15px; float: right;"></div> -->
            <!-- User Profile Pic -->
            <div class="col-md-12 text-center " *ngIf="profileShow">
                <img [src]="profileSrcUrl" class="image mt-4">
            </div>
            <div class="row mt-2">
                <div class="col-12 text-center">
                    <h6 class="fw-bolder">{{currentFormData!.firstName}} {{currentFormData!.lastName}}</h6>
                    <span class="designation" style="margin-top:15px;"><b>{{currentFormData!.designation}}</b></span>
                    <p class="text-center">{{currentFormData!.role}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-1"></div>
                <div class="col-11">
                    <div class="row mt-2">
                        <div class="col-2"><i class="bi bi-telephone roundIcon" type="button" title="Call"></i></div>
                        <div class="col-10 mt-1"><h6 class="fw-bolder"  (click)="copyPhoneNo(currentFormData!.countryCodePhoneNo, formatNumber(currentFormData!.phoneNo))">
                            {{currentFormData!.countryCodePhoneNo}} {{formatNumber(currentFormData!.phoneNo)}}</h6></div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-2"><i class="bi bi-geo-alt roundIcon" type="button" title="Location"></i></div>
                        <div class="col-10 mt-1"><h6 class="fw-bolder" >{{currentFormData!.address}}</h6></div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-2"><i class="bi bi-globe2 roundIcon" type="button" title="Website"></i></div>
                        <div class="col-10 mt-1"><h6 class="fw-bolder">
                            <a class="opacity-75 fw-bolder text-bg-light" style="text-decoration: none; color: rgb(0, 0, 0);" href="http://{{currentFormData!.companyWebsiteName}}" target="_blank">{{currentFormData!.companyWebsiteName}}</a>
                        </h6>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-2"><i class="bi bi-envelope roundIcon" type="button" title="Mail"></i></div>
                        <div class="col-10 mt-1">
                            <h6 class="fw-bolder text-bg-light" style="max-width: 50%;"><a class="opacity-75  fw-bolder" style="text-decoration: none; color:rgb(0, 0, 0);"
                                                     href="mailto:{{currentFormData!.email}}"
                                                     target="_blank">{{currentFormData!.email}}</a>
                            </h6>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-2"><i title="WhatsApp" class="bi bi-whatsapp roundIcon fs-6"></i>
                        </div>
                        <div class="col-10 mt-1">
                            <h6 class="fw-bolder mt-1" type="button" style="margin-left: 1px; " *ngIf="currentFormData!.whatsAppNoYn == 'Yes'"
                                (click)="whatsApp(currentFormData!.countryCodePhoneNo, currentFormData!.phoneNo)">
                                {{currentFormData!.countryCodePhoneNo}} {{formatNumber(currentFormData!.phoneNo)}}</h6>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div style="width:50%;background-color:transparent;height:2px;margin-top: 15px; float: right;"></div>
            <div style="width:50%;background-color:rgb(255, 174, 69);height:2px;margin-top: 15px; float: right;"></div> -->
        </div>
    </div>
</section>
<ngx-spinner bdColor="rgba(255, 255, 255, 1)" size="large" color="#FFFFFF"
             [fullScreen]="true">
    <figure class="loader">
        <div class="dot white"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
    </figure>
</ngx-spinner>
<app-ecard-footer></app-ecard-footer>
