<section class="vh-100" style="background-color:  rgb(172, 172, 172);" *ngIf="profileShow">
  <div class="container-fluid py-2 d-flex justify-content-center align-items-center"
       style="text-align: -webkit-center;">
    <div>
      <div class="page-wrapper" id="home-section">
        <div class="page-details">
          <div>
            <div>
              <img [src]="companySrcUrl" class="image mt-4" crossorigin="anonymous">
            </div>

            <h4 class="firmname fw-bolder mt-2"style="margin-top:15px;">{{currentFormData!.companyName}}</h4>
            <div class="" style="border-bottom:white 2px solid;margin-top: 15px;margin-bottom: 15px;"></div>
            <div class="col-md-12 mt-1 text-center">
              <img [src]="profileSrcUrl" class="image" crossorigin="anonymous">
            </div>
            <h3 class="name mt-2" ><b>{{currentFormData!.firstName}} {{currentFormData!.lastName}}</b></h3>
            <h6 class="designation " ><b>{{currentFormData!.designation}}</b></h6>
            <h6 class="role mt-1" ><b>{{currentFormData!.role}}</b></h6>
          </div>


          <div>
            <div class="contact-buttons mt-3">
              <a class="contact-button"  style="border: 1px solid black;"
                 href="tel:{{currentFormData!.countryCodePhoneNo}}{{currentFormData!.phoneNo}}">
                <i class="fas fa-phone fa-flip-horizontal mr-1"></i>
                <b>Call</b>
              </a>

              <a class="contact-button" target="_blank" style="border: 1px solid black;"
                 (click)="whatsApp(currentFormData!.countryCodePhoneNo, currentFormData!.phoneNo)">
                <i class="fab fa-whatsapp mr-1"></i>
                <b>Whatsapp</b>
              </a>

              <a class="contact-button" style="border: 1px solid black;"
                 target="_blank" href="mailto:{{currentFormData!.email}}">
                <i class="fas fa-envelope fa-flip-horizontal mr-1"></i>
                <b>Mail</b>
              </a>
            </div>
            <table class="contact-action-table mt-3">
              <tbody class="table-stripped">
              <tr>
                <td >
                  <i class="fas fa-map-marker-alt contact-action-container-icon"></i>
                </td>
                <td>
                  <a class="contact-action-container-text mt-3" style="max-width: 220px;">
                    {{currentFormData!.address}}
                  </a>
                </td>
              </tr>
              <tr></tr>
              <tr>
                <td>
                  <a href="mailto:{{currentFormData!.email}}">
                    <i class="fas fa-envelope contact-action-container-icon"></i>
                  </a>
                </td>
                <td>
                  <a href="mailto:{{currentFormData!.email}}"
                     class="contact-action-container-text mt-4">
                    {{currentFormData!.email}}
                  </a><br>
                  <a href="mailto:" class="contact-action-container-text">
                  </a><br>
                </td>
              </tr>
              <tr>
                <td>
                  <a target="_blank" href="http://{{currentFormData!.companyWebsiteName}}">
                    <i class="fas fa-globe contact-action-container-icon"></i>
                  </a>
                </td>
                <td>
                  <a target="_blank" href="http://{{currentFormData!.companyWebsiteName}}"
                     class="contact-action-container-text">
                    {{currentFormData!.companyWebsiteName}}
                  </a>
                </td>
              </tr>
              </tbody>
            </table>
            <div class="p-30 py-2"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

















<section class="vh-100" style="background-color:  rgb(172, 172, 172);" *ngIf="profilehide">
  <div class="container-fluid py-2 d-flex justify-content-center align-items-center"
       style="text-align: -webkit-center;">
    <div>
      <div class="page-wrapper" id="home-section">
        <div class="page-details">
          <div>
            <div>
              <img [src]="companySrcUrl" class="companyimage mt-4" crossorigin="anonymous">
            </div>

            <h4 class="firmname fw-bolder mt-3"style="margin-top:15px;">{{currentFormData!.companyName}}</h4>
            <div class="" style="border-bottom:white 2px solid;margin-top: 15px;margin-bottom: 15px;"></div>
            <div class="col-md-12 mt-1 text-center">
            </div>
            <h3 class="name mt-2" ><b>{{currentFormData!.firstName}} {{currentFormData!.lastName}}</b></h3>
            <h6 class="designation " ><b>{{currentFormData!.designation}}</b></h6>
            <h6 class="role mt-1" ><b>{{currentFormData!.role}}</b></h6>
          </div>


          <div>
            <div class="row mt-3"></div>
            <div class="contact-buttons mt-3">
              <a class="contact-button"  style="border: 1px solid black;"
                 href="tel:{{currentFormData!.countryCodePhoneNo}}{{currentFormData!.phoneNo}}">
                <i class="fas fa-phone fa-flip-horizontal mr-1"></i>
                <b>Call</b>
              </a>

              <a class="contact-button" target="_blank" style="border: 1px solid black;"
                 (click)="whatsApp(currentFormData!.countryCodePhoneNo, currentFormData!.phoneNo)">
                <i class="fab fa-whatsapp mr-1"></i>
                <b>Whatsapp</b>
              </a>

              <a class="contact-button" style="border: 1px solid black;"
                 target="_blank" href="mailto:{{currentFormData!.email}}">
                <i class="fas fa-envelope fa-flip-horizontal mr-1"></i>
                <b>Mail</b>
              </a>
            </div>
            <table class="contact-action-table mt-3">
              <tbody class="table-stripped">
              <tr>
                <td >
                  <i class="fas fa-map-marker-alt contact-action-container-icon"></i>
                </td>
                <td>
                  <a class="contact-action-container-text mt-3" style="max-width: 220px;">
                    {{currentFormData!.address}}
                  </a>
                </td>
              </tr>
              <tr></tr>
              <tr>
                <td>
                  <a href="mailto:{{currentFormData!.email}}">
                    <i class="fas fa-envelope contact-action-container-icon"></i>
                  </a>
                </td>
                <td>
                  <a href="mailto:{{currentFormData!.email}}"
                     class="contact-action-container-text mt-4">
                    {{currentFormData!.email}}
                  </a><br>
                  <a href="mailto:" class="contact-action-container-text">
                  </a><br>
                </td>
              </tr>
              <tr>
                <td>
                  <a target="_blank" href="http://{{currentFormData!.companyWebsiteName}}">
                    <i class="fas fa-globe contact-action-container-icon"></i>
                  </a>
                </td>
                <td>
                  <a target="_blank" href="http://{{currentFormData!.companyWebsiteName}}"
                     class="contact-action-container-text">
                    {{currentFormData!.companyWebsiteName}}
                  </a>
                </td>
              </tr>
              </tbody>
            </table>
            <div class="p-30 py-2"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<ngx-spinner bdColor="rgba(255, 255, 255, 1)" size="large" color="#FFFFFF"
             [fullScreen]="true">
  <figure class="loader">
    <div class="dot white"></div>
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
  </figure>
</ngx-spinner>
<app-ecard-footer></app-ecard-footer>

