<section class="vh-100 bg-opacity-10">
  <div class="container py-5 h-100">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col-12 col-md-8 col-lg-6 col-xl-5">
        <div class="card shadow" style="border-radius: 1rem;">
          <div class="text-center">
            <img src="../../../assets/img/backgroundImages/ecard.png" style="height: 100px" >
          </div>
          <div class="card-body">
            <div class="">
              <h5 class="mb-4 text-center fw-bolder">Good to see you again</h5>
              <form [formGroup]="login" (ngSubmit)="onSubmit()">
                <div class="form-floating">
                  <input type="text" class="form-control" id="userName" formControlName="userName"
                         placeholder="Username" autocomplete="off" />
                  <label class="fw-semibold" for="userName">Username</label>
                  <span *ngIf="loginPage.userName"
                        class="text-danger"><b>{{loginPage.userName}}</b></span>
                </div>
                <div class="form-floating  input-group-prepend mt-3">
                  <input type="password" class="form-control" id="password" formControlName="password"
                         placeholder="password" autocomplete="off">
                  <label class="fw-semibold" for="password">Password</label>
                  <span class="input-group-text"><i class="toggle-password fa fa-lock-open"
                                                    data-toggle="tooltip" data-placement="top" title="Click to Show Password"
                                                    (click)="togglePasswordVisibility()"
                                                    [ngClass]="{'fa-lock-open': isPasswordVisible, 'fa-lock': !isPasswordVisible}"
                                                    aria-hidden="true"></i></span>
                </div>

                <span *ngIf="loginPage.password"
                      class="text-danger"><b>{{loginPage.password}}</b></span>
                <div class="row">
                  <div class="col-md-12 text-center">
                    <button type="submit" class="mt-3 btn btn-primary btn-lg">Login</button>
                  </div>
                </div>
              </form>
              <div class="mt-3 text-center">
                Not a Member? <a [routerLink]="['/register']"
                                 class="fw-bolder text-primary" (click)="register()">Register</a>
              </div>
            </div>
          </div>
          <!-- <a target="_blank" href="https://www.amazon.in/gp/bestsellers/office?_encoding=UTF8&linkCode=ib1&tag=ecardvarnikcl-21&linkId=z7b5638deb81af5805803e8dc94cfdez&ref_=ihub_curatedcontent_8f708d9a-64ce-4bed-85b7-94f94d716dc2">Best Sellers in Office Products</a> -->
        </div>
      </div>
    </div>
  </div>
</section>
<ngx-spinner bdColor="rgba(255, 255, 255, 1)" size="large" color="#FFFFFF" [fullScreen]="true">
  <main>
    <svg xmlns="http://www.w3.org/2000/svg" height="128px" width="256px" viewBox="0 0 256 128" class="ip">
      <defs>
        <linearGradient y2="0" x2="1" y1="0" x1="0" id="grad1">
          <stop stop-color="#5ebd3e" offset="0%"></stop>
          <stop stop-color="#ffb900" offset="33%"></stop>
          <stop stop-color="#f78200" offset="67%"></stop>
          <stop stop-color="#e23838" offset="100%"></stop>
        </linearGradient>
        <linearGradient y2="0" x2="0" y1="0" x1="1" id="grad2">
          <stop stop-color="#e23838" offset="0%"></stop>
          <stop stop-color="#973999" offset="33%"></stop>
          <stop stop-color="#009cdf" offset="67%"></stop>
          <stop stop-color="#5ebd3e" offset="100%"></stop>
        </linearGradient>
      </defs>
      <g stroke-width="16" stroke-linecap="round" fill="none">
        <g stroke="#ddd" class="ip__track">
          <path d="M8,64s0-56,60-56,60,112,120,112,60-56,60-56"></path>
          <path d="M248,64s0-56-60-56-60,112-120,112S8,64,8,64"></path>
        </g>
        <g stroke-dasharray="180 656">
          <path d="M8,64s0-56,60-56,60,112,120,112,60-56,60-56" stroke-dashoffset="0" stroke="url(#grad1)"
                class="ip__worm1"></path>
          <path d="M248,64s0-56-60-56-60,112-120,112S8,64,8,64" stroke-dashoffset="358" stroke="url(#grad2)"
                class="ip__worm2"></path>
        </g>
      </g>
    </svg>
  </main>
</ngx-spinner>
