<section class="vh-100" style="background-color: rgb(254 242 242)" *ngIf="profileShow">
    <div class="container-fluid py-2 ">
        <div class="container">
            <div class="row mt-2">
                <div class="col-md-12 text-center">
                    <img class="companyPro" [src]="companySrcUrl">
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12 justify-content-center">
                    <h4 class="company"><b>{{currentFormData!.companyName}}</b></h4>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-md-12 text-center">
                    <img class="Profile" [src]="profileSrcUrl">
                </div>
            </div>

            <div class="mt-2" style="border-top: 2px solid;"></div>

            <div class="row  mt-2">
                <div class="col-12 text-center">
                    <h3 class="name"><b>{{currentFormData!.firstName}} {{currentFormData!.lastName}}</b></h3>
                    <h6 class="Work">{{currentFormData!.designation}}</h6>
                    <h6 class="post">{{currentFormData!.role}}</h6>
                </div>
            </div>

            <div class="row mt-1 mb-3">
                <div class="tab">    
                </div>
            </div>

            <div class="row mt-3">   
                <div class="col-1"></div>
                <div class="col-10">
                    <div class="row ">
                        <div class="col-2"><i class="bi bi-telephone-fill roundIcon" type="button" title="Call"></i></div>
                        <div class="col-10 mt-1"><h6 class="" style="color:rgb(255, 255, 255) ;" (click)="copyPhoneNo(currentFormData!.countryCodePhoneNo, formatNumber(currentFormData!.phoneNo))">
                            {{currentFormData!.countryCodePhoneNo}} {{formatNumber(currentFormData!.phoneNo)}}</h6></div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-2"><i class="bi bi-geo-alt-fill roundIcon" type="button" title="Location"></i></div>
                        <div class="col-10 mt-1"><h6 class="" style="color:rgb(255, 255, 255) ;">{{currentFormData!.address}}</h6></div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-2"><i class="bi bi-globe2 roundIcon" type="button" title="Website"></i></div>
                        <div class="col-10 mt-1"><h6 class="" >
                            <a class=" opacity-75" style="color:rgb(255, 255, 255);line-break: anywhere;text-decoration: none;" 
                            href="http://{{currentFormData!.companyWebsiteName}}" target="_blank">{{currentFormData!.companyWebsiteName}}</a>
                        </h6>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-2"><i class="bi bi-envelope roundIcon" type="button" title="Mail"></i></div>
                        <div class="col-10 mt-1">
                            <h6 class="" ><a class="opacity-75 " style="color:rgb(255, 255, 255);line-break: anywhere;text-decoration: none;"
                            href="mailto:{{currentFormData!.email}}" target="_blank">{{currentFormData!.email}}</a>
                            </h6>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-2"><i title="WhatsApp" class="bi bi-whatsapp roundIcon"></i>
                        </div>
                        <div class="col-10 mt-1">
                            <h6 class="mt-1" type="button" style="margin-left: 1px;color: rgb(255, 255, 255);"
                                (click)="whatsApp(currentFormData!.countryCodeWhatsAppNo, currentFormData!.whatsAppNumber)">
                                {{currentFormData!.countryCodeWhatsAppNo}} {{formatNumber(currentFormData!.whatsAppNumber)}}</h6>
                        </div>
                    </div>
                </div>
                <div class="col-1"></div>
            </div>

            <div class="row">
                <div class="col-3"></div>
                <div class="col-8 contact-buttons">
                    <a class="contact-button"
                       href="tel:{{currentFormData!.countryCodePhoneNo}}{{currentFormData!.phoneNo}}">
                        <i class="fas fa-phone fa-flip-horizontal" style="color: #ffffff"></i>
                    </a>
                    <a class="contact-button" target="_blank"
                       (click)="whatsApp(currentFormData!.countryCodeWhatsAppNo, currentFormData!.whatsAppNumber)">
                        <i class="fab fa-whatsapp" style="color: rgb(255, 255, 255);"></i>
                    </a>
                    <a class="contact-button" target="_blank" href="mailto:{{currentFormData!.email}}">
                        <i class="fas fa-envelope fa-flip-horizontal" style="color: #ffffff"></i>
                    </a>
                </div>
                <div class="col-1"></div>
            </div>
        </div>
    </div>
</section>
















<section class="vh-100" style="background-color: rgb(254 242 242)" *ngIf="profilehide">
    <div class="container-fluid py-2 ">
        <div class="container">
            <div class="row mt-1">
                <div class="col-md-12 text-center">
                    <img class="companyPro" [src]="companySrcUrl">
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-12 justify-content-center">
                    <h4 class="company"><b>{{currentFormData!.companyName}}</b></h4>
                </div>
            </div>

            <div class="mt-2" style="border-top: 2px solid;"></div>

            <div class="row  mt-1">
                <div class="col-12 text-center mt-3">
                    <h3 class="name"><b>{{currentFormData!.firstName}} {{currentFormData!.lastName}}</b></h3>
                    <h6 class="Work">{{currentFormData!.designation}}</h6>
                    <h6 class="post">{{currentFormData!.role}}</h6>
                </div>
            </div>

            <div class="row mb-3">
                <div class="tab">    
                </div>
            </div>

            <div class="row">   
                <div class="col-1"></div>
                <div class="col-10">
                    <div class="row ">
                        <div class="col-2"><i class="bi bi-telephone-fill roundIcon" type="button" title="Call"></i></div>
                        <div class="col-10 mt-1"><h6 class="" style="color:rgb(255, 255, 255) ;" (click)="copyPhoneNo(currentFormData!.countryCodePhoneNo, formatNumber(currentFormData!.phoneNo))">
                            {{currentFormData!.countryCodePhoneNo}} {{formatNumber(currentFormData!.phoneNo)}}</h6></div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-2"><i class="bi bi-geo-alt-fill roundIcon" type="button" title="Location"></i></div>
                        <div class="col-10 mt-1"><h6 class="" style="color:rgb(255, 255, 255) ;">{{currentFormData!.address}}</h6></div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-2"><i class="bi bi-globe2 roundIcon" type="button" title="Website"></i></div>
                        <div class="col-10 mt-1"><h6 class="" >
                            <a class=" opacity-75" style="color:rgb(255, 255, 255);line-break: anywhere;text-decoration: none;" 
                            href="http://{{currentFormData!.companyWebsiteName}}" target="_blank">{{currentFormData!.companyWebsiteName}}</a>
                        </h6>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-2"><i class="bi bi-envelope roundIcon" type="button" title="Mail"></i></div>
                        <div class="col-10 mt-1">
                            <h6 class="" ><a class="opacity-75 " style="color:rgb(255, 255, 255);line-break: anywhere;text-decoration: none;"
                            href="mailto:{{currentFormData!.email}}" target="_blank">{{currentFormData!.email}}</a>
                            </h6>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-2"><i title="WhatsApp" class="bi bi-whatsapp roundIcon"></i>
                        </div>
                        <div class="col-10 mt-1">
                            <h6 class="mt-1" type="button" style="margin-left: 1px;color: rgb(255, 255, 255);"
                                (click)="whatsApp(currentFormData!.countryCodeWhatsAppNo, currentFormData!.whatsAppNumber)">
                                {{currentFormData!.countryCodeWhatsAppNo}} {{formatNumber(currentFormData!.whatsAppNumber)}}</h6>
                        </div>
                    </div>
                </div>
                <div class="col-1"></div>
            </div>

            <div class="row">
                <div class="col-3"></div>
                <div class="col-8 contact-buttons">
                    <a class="contact-button"
                       href="tel:{{currentFormData!.countryCodePhoneNo}}{{currentFormData!.phoneNo}}">
                        <i class="fas fa-phone fa-flip-horizontal" style="color: #ffffff"></i>
                    </a>
                    <a class="contact-button" target="_blank"
                       (click)="whatsApp(currentFormData!.countryCodeWhatsAppNo, currentFormData!.whatsAppNumber)">
                        <i class="fab fa-whatsapp" style="color: rgb(255, 255, 255);"></i>
                    </a>
                    <a class="contact-button" target="_blank" href="mailto:{{currentFormData!.email}}">
                        <i class="fas fa-envelope fa-flip-horizontal" style="color: #ffffff"></i>
                    </a>
                </div>
                <div class="col-1"></div>
            </div>
        </div>
    </div>
</section>











<ngx-spinner bdColor="rgba(255, 255, 255, 1)" size="large" color="#FFFFFF"
             [fullScreen]="true">
    <figure class="loader">
        <div class="dot white"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
    </figure>
</ngx-spinner>
<app-ecard-footer></app-ecard-footer>




