<section class="vh-100" style="background-color: #d5eeff" *ngIf="profileShow">
    <div class="container-fluid py-2  d-flex ">
        <div class="container">
            <div class="row">
                <div class="col-md-12 text-center mt-3">
                    <img class="companyProfile" [src]="companySrcUrl">
                </div>
            </div>
            <div class="row">
                <div class="col-12 mt-2 justify-content-center">
                    <h5 class="text-center fw-bolder"><b>{{currentFormData!.companyName}}</b></h5>
                </div>
            </div>
            <div class="col-md-12 text-center mt-2">
                <img [src]="profileSrcUrl" class="image" crossorigin="anonymous">
            </div>
            <div class="row mt-2">
                <div class="col-12 text-center">
                    <h4 class="fullName">{{currentFormData!.firstName}} {{currentFormData!.lastName}}</h4>
                    <h6 class="designation">{{currentFormData!.designation}}</h6>
                    <h6 class="role">{{currentFormData!.role}}</h6>
                </div>
            </div>

            <div class="row py-1 text-center" style="border-bottom:1px solid black;">
                <div class="contact-buttons">     
                    <a class="contact-button" target="_blank" style="border: 1px solid black;"
                       (click)="whatsApp(currentFormData!.countryCodePhoneNo, currentFormData!.phoneNo)">
                        <i class="fab fa-whatsapp mr-1"></i>
                        <b>Whatsapp</b>
                    </a>
            </div>
        </div>

            <div class="row mt-2">
                <div class="col-1"></div>
                <div class="col-11">
                    <div class="row">
                        <div class="col-2">
                            <i class="bi bi-telephone roundIcon" type="button" title="Call"></i>
                        </div>
                        <div class="col-10 mt-1">
                            <h6 class="" style="color: black;" (click)="copyPhoneNo(currentFormData!.countryCodePhoneNo, formatNumber(currentFormData!.phoneNo))">
                                {{currentFormData!.countryCodePhoneNo}} {{formatNumber(currentFormData!.phoneNo)}}
                            </h6>
                        </div>
                    </div>

                    <div class="row mt-2">
                        <div class="col-2">
                            <i class="bi bi-geo-alt roundIcon" type="button" title="Location"></i>
                        </div>
                        <div class="col-10 mt-1">
                            <h6 class="" style="color: black;">{{currentFormData!.address}}</h6>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-2">
                            <i class="bi bi-globe2 roundIcon" type="button" title="Website"></i>
                        </div>
                        <div class="col-10">
                            <h6 class="mt-1">
                                <a class="" style="text-decoration: none;word-wrap: break-word;color: black;" 
                                href="http://{{currentFormData!.companyWebsiteName}}" target="_blank">{{currentFormData!.companyWebsiteName}}</a>
                            </h6>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-2">
                            <i class="bi bi-envelope roundIcon" type="button" title="Mail"></i>
                        </div>
                        <div class="col-10">
                            <h6 class="mt-1">
                                <a class="q" style="text-decoration: none;word-wrap: break-word;color: black;" 
                                href="mailto:{{currentFormData!.email}}" target="_blank">{{currentFormData!.email}}</a>
                            </h6>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-2">
                            <i title="WhatsApp" class="bi bi-whatsapp roundIcon"></i>
                        </div>
                        <div class="col-10">
                            <h6 class="mt-1" style="color: black;" type="button" (click)="whatsApp(currentFormData!.countryCodePhoneNo, currentFormData!.phoneNo)">
                                {{currentFormData!.countryCodePhoneNo}} {{formatNumber(currentFormData!.phoneNo)}}
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row  text-center py-1 mt-2" style="border-top:1px solid black;border-bottom:1px solid black;">
                <div class="contact-buttons">
                    <a class="contact-button"  style="border: 1px solid black;"
                       href="tel:{{currentFormData!.countryCodePhoneNo}}{{currentFormData!.phoneNo}}">
                        <i class="fas fa-phone fa-flip-horizontal mr-1"></i>
                       <b>Call</b>
                    </a>
                    <a class="contact-button" style="border: 1px solid black;"
                    target="_blank" href="mailto:{{currentFormData!.email}}">
                        <i class="fas fa-envelope fa-flip-horizontal mr-1"></i>
                        <b>Mail</b>
                    </a>
            </div>
        </div>

            <div class="p mt-3"></div>
        </div>
    </div>
</section>




















<section class="vh-100" style="background-color: #d5eeff" *ngIf="profilehide">
    <div class="container-fluid py-2  d-flex ">
        <div class="container">
            <div class="row">
                <div class="col-md-12 text-center mt-3">
                    <img class="companyProfile" [src]="companySrcUrl">
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-12 mt-2 justify-content-center">
                    <h5 class="text-center">{{currentFormData!.companyName}}</h5>
                </div>
            </div>
            <div class="mt-2" style="border-bottom:2px solid;"></div>
            <div class="col-md-12 text-center mt-2">
            </div>
            <div class="row mt-3">
                <div class="col-12 text-center">
                    <h4 class="fullName">{{currentFormData!.firstName}} {{currentFormData!.lastName}}</h4>
                    <h6 class="designation">{{currentFormData!.designation}}</h6>
                    <h6 class="role">{{currentFormData!.role}}</h6>
                </div>
            </div>

            <div class="row py-1 text-center" style="border-bottom:1px solid black;">
                <div class="contact-buttons">     
                    <a class="contact-button" target="_blank" style="border: 1px solid black;"
                       (click)="whatsApp(currentFormData!.countryCodePhoneNo, currentFormData!.phoneNo)">
                        <i class="fab fa-whatsapp mr-1"></i>
                        <b>Whatsapp</b>
                    </a>
            </div>
        </div>

            <div class="row mt-2">
                <div class="col-1"></div>
                <div class="col-11">
                    <div class="row">
                        <div class="col-2">
                            <i class="bi bi-telephone roundIcon" type="button" title="Call"></i>
                        </div>
                        <div class="col-10 mt-1">
                            <h6 class="" style="color: black;" (click)="copyPhoneNo(currentFormData!.countryCodePhoneNo, formatNumber(currentFormData!.phoneNo))">
                                {{currentFormData!.countryCodePhoneNo}} {{formatNumber(currentFormData!.phoneNo)}}
                            </h6>
                        </div>
                    </div>

                    <div class="row mt-2">
                        <div class="col-2">
                            <i class="bi bi-geo-alt roundIcon" type="button" title="Location"></i>
                        </div>
                        <div class="col-10 mt-1">
                            <h6 class="" style="color: black;">{{currentFormData!.address}}</h6>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-2">
                            <i class="bi bi-globe2 roundIcon" type="button" title="Website"></i>
                        </div>
                        <div class="col-10">
                            <h6 class="mt-1">
                                <a class="" style="text-decoration: none;word-wrap: break-word;color: black;" 
                                href="http://{{currentFormData!.companyWebsiteName}}" target="_blank">{{currentFormData!.companyWebsiteName}}</a>
                            </h6>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-2">
                            <i class="bi bi-envelope roundIcon" type="button" title="Mail"></i>
                        </div>
                        <div class="col-10">
                            <h6 class="mt-1">
                                <a class="q" style="text-decoration: none;word-wrap: break-word;color: black;" 
                                href="mailto:{{currentFormData!.email}}" target="_blank">{{currentFormData!.email}}</a>
                            </h6>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-2">
                            <i title="WhatsApp" class="bi bi-whatsapp roundIcon"></i>
                        </div>
                        <div class="col-10">
                            <h6 class="mt-1" style="color: black;" type="button" (click)="whatsApp(currentFormData!.countryCodePhoneNo, currentFormData!.phoneNo)">
                                {{currentFormData!.countryCodePhoneNo}} {{formatNumber(currentFormData!.phoneNo)}}
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row  text-center py-1 mt-2" style="border-top:1px solid black;border-bottom:1px solid black;">
                <div class="contact-buttons">
                    <a class="contact-button"  style="border: 1px solid black;"
                       href="tel:{{currentFormData!.countryCodePhoneNo}}{{currentFormData!.phoneNo}}">
                        <i class="fas fa-phone fa-flip-horizontal mr-1"></i>
                       <b>Call</b>
                    </a>
                    <a class="contact-button" style="border: 1px solid black;"
                    target="_blank" href="mailto:{{currentFormData!.email}}">
                        <i class="fas fa-envelope fa-flip-horizontal mr-1"></i>
                        <b>Mail</b>
                    </a>
            </div>
        </div>

            <div class="p mt-3"></div>
        </div>
    </div>
</section>




















<ngx-spinner bdColor="rgba(255, 255, 255, 1)" size="large" color="#FFFFFF"
             [fullScreen]="true">
    <figure class="loader">
        <div class="dot white"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
    </figure>
</ngx-spinner>
<app-ecard-footer></app-ecard-footer>