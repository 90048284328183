<section class="vh-100" style="background-image: url(../../assets/img/backgroundImages/pxfuel.jpg)" *ngIf="profileShow">
    <div class="container-fluid py-2  justify-content-center align-items-center vh-100"
         style="text-align: -webkit-center;">
        <div>
            <div class="page-wrapper" id="home-section">
                <div class="page-details">
                    <div>
                        <div>
                            <img [src]="companySrcUrl" class="companySrc mt-3" crossorigin="anonymous">
                        </div>

                        <h4 class="firmname" style="color: #ffffff;font-weight: bolder;border-bottom: 1px solid #ffffff;">{{currentFormData!.companyName}}</h4>

                        <div>
                            <img [src]="profileSrcUrl" class="image"   crossorigin="anonymous">
                        </div>

                        <h3 class="name " style="color: #ffffff">{{currentFormData!.firstName}} {{currentFormData!.lastName}}</h3>
                        <h6 class="designation" style="color: #ffffff;">{{currentFormData!.designation}}</h6>
                        <h6 class="role "  style="color: #ffffff;">{{currentFormData!.role}}</h6>
                    </div>

                    <div>
                        
                        <div class="contact-buttons">
                            <a class="contacts mr-5"
                               href="tel:{{currentFormData!.countryCodePhoneNo}}{{currentFormData!.phoneNo}}">
                                <i class="fas fa-phone fa-flip-horizontal"  style="color: #0085C4;"></i>      
                            </a>
                            <a class="contacts mr-5" target="_blank"
                               (click)="whatsApp(currentFormData!.countryCodeWhatsAppNo, currentFormData!.whatsAppNumber)">
                                <i class="fab fa-whatsapp" style="color: #0085C4;"></i>
                            </a>
                            <a class="contacts mr-2 ml-2" target="_blank" href="mailto:{{currentFormData!.email}}">
                                <i class="fas fa-envelope fa-flip-horizontal"  style="color:#0085C4;"></i>
                            </a>
                        </div>
                       
                        <div class="row mt-3">
                            <div class="col-1"></div>  
                            <div class="col-10">
                                
                              <div class="row ">
                                <div class="col-2"> 
                                    <a target="_blank" class=" roundIcon"
                                       href="tel:{{currentFormData!.countryCodePhoneNo}}{{currentFormData!.phoneNo}}">
                                        <i class="fas fa-phone fa-flip-horizontal roundIcon"></i>
                                    </a>
                                </div>
                                <div class="col-10 mt-1 detailText"><h6 class="" style="text-align: left;color: #00005f;"
                                    (click)="copyPhoneNo(currentFormData!.countryCodePhoneNo, formatNumber(currentFormData!.phoneNo))">
                                 {{currentFormData!.countryCodePhoneNo}} {{formatNumber(currentFormData!.phoneNo)}}</h6>
                               </div>
                              </div>

                              <div class="row mt-2">
                                <div class="col-2">
                                    <i class="bi bi-geo-alt-fill roundIcon" type="button" title="Location">
                                    </i>
                                </div>
                                <div class="col-10  detailText"><h6 class="" style="text-align:left;color: #00005f;">{{currentFormData!.address}}</h6></div>   
                            </div>  

                              <div class="row mt-2"> 
                                <div class="col-2">
                                    <a class="" target="_blank" href="http://{{currentFormData!.companyWebsiteName}}">
                                        <i class="fas fa-globe roundIcon"></i>
                                    </a>
                                </div>
                                <div class="col-10 mt-1 detailText"><h6>
                                  <a class="opacity-100 fw-bolder detailText"
                                     href="http://{{currentFormData!.companyWebsiteName}}"
                                     target="_blank"><h6 style="text-align:left;color: #00005f;">{{currentFormData!.companyWebsiteName}}</h6></a>
                                </h6>
                                </div>
                              </div>

                              <div class="row mt-2">
                                <div class="col-2">
                                    <a class="" href="mailto:{{currentFormData!.email}}">
                                        <i class="fas fa-envelope roundIcon"></i>
                                    </a>                                    
                                </div>
                                <div class="col-10 ">
                                  <h6 class="mt-1"><a class="opacity-100   detailText"
                                    style="line-break: anywhere;text-align:left;color: #00005f;"
                                    href="mailto:{{currentFormData!.email}}" target="_blank"><h6>{{currentFormData!.email}}</h6></a>
                                  </h6>
                                </div>
                              </div>

                              <div class="row mt-2">
                                <div class="col-2">                                    
                                    <a class="roundIcon" target="_blank" (click)="whatsApp(currentFormData!.countryCodeWhatsAppNo, currentFormData!.whatsAppNumber)">
                                        <i class="fab fa-whatsapp  roundIcon"></i> 
                                    </a>
                                </div>
                                <div class="col-10 mt-2 detailText">
                                  <h6 class="" type="button" style="text-align:left;color: #00005f;"
                                      (click)="whatsApp(currentFormData!.countryCodeWhatsAppNo, currentFormData!.whatsAppNumber)">
                                    {{currentFormData!.countryCodeWhatsAppNo}} {{formatNumber(currentFormData!.whatsAppNumber)}}</h6>
                                </div>
                              </div>
                            </div>
                            <div class="col-1"></div>
                          </div>
                        <div class="p-30 py-2"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>








<section class="vh-100" style="background-image: url(../../assets/img/backgroundImages/pxfuel.jpg)" *ngIf="profilehide">
    <div class="container-fluid py-2  justify-content-center align-items-center vh-100"
         style="text-align: -webkit-center;">
        <div>
            <div class="page-wrapper" id="home-section">
                <div class="page-details">
                    <div>
                        <div>
                            <img [src]="companySrcUrl" class="companySrc mt-3" crossorigin="anonymous">
                        </div>

                        <h4 class="firmname" style="color: #ffffff;font-weight: bolder;border-bottom: 1px solid #ffffff;">{{currentFormData!.companyName}}</h4>

                        
                        <h3 class="name mt-3" style="color: #ffffff">{{currentFormData!.firstName}} {{currentFormData!.lastName}}</h3>
                        <h6 class="designation" style="color: #ffffff;">{{currentFormData!.designation}}</h6>
                        <h6 class="role "  style="color: #ffffff;">{{currentFormData!.role}}</h6>
                    </div>

                    <div>
                        
                        <div class="contact-buttons">
                            <a class="contacts mr-5"
                               href="tel:{{currentFormData!.countryCodePhoneNo}}{{currentFormData!.phoneNo}}">
                                <i class="fas fa-phone fa-flip-horizontal"  style="color: #0085C4;"></i>      
                            </a>
                            <a class="contacts mr-5" target="_blank"
                               (click)="whatsApp(currentFormData!.countryCodeWhatsAppNo, currentFormData!.whatsAppNumber)">
                                <i class="fab fa-whatsapp" style="color: #0085C4;"></i>
                            </a>
                            <a class="contacts mr-2 ml-2" target="_blank" href="mailto:{{currentFormData!.email}}">
                                <i class="fas fa-envelope fa-flip-horizontal"  style="color:#0085C4;"></i>
                            </a>
                        </div>
                       
                        <div class="row mt-3">
                            <div class="col-1"></div>  
                            <div class="col-10">
                                
                              <div class="row ">
                                <div class="col-2"> 
                                    <a target="_blank" class=" roundIcon"
                                       href="tel:{{currentFormData!.countryCodePhoneNo}}{{currentFormData!.phoneNo}}">
                                        <i class="fas fa-phone fa-flip-horizontal roundIcon"></i>
                                    </a>
                                </div>
                                <div class="col-10 mt-1 detailText"><h6 class="" style="text-align: left;color: #00005f;"
                                    (click)="copyPhoneNo(currentFormData!.countryCodePhoneNo, formatNumber(currentFormData!.phoneNo))">
                                 {{currentFormData!.countryCodePhoneNo}} {{formatNumber(currentFormData!.phoneNo)}}</h6>
                               </div>
                              </div>

                              <div class="row mt-2">
                                <div class="col-2">
                                    <i class="bi bi-geo-alt-fill roundIcon" type="button" title="Location">
                                    </i>
                                </div>
                                <div class="col-10  detailText"><h6 class="" style="text-align:left;color: #00005f;">{{currentFormData!.address}}</h6></div>   
                            </div>  

                              <div class="row mt-2"> 
                                <div class="col-2">
                                    <a class="" target="_blank" href="http://{{currentFormData!.companyWebsiteName}}">
                                        <i class="fas fa-globe roundIcon"></i>
                                    </a>
                                </div>
                                <div class="col-10 mt-1 detailText"><h6>
                                  <a class="opacity-100 fw-bolder detailText"
                                     href="http://{{currentFormData!.companyWebsiteName}}"
                                     target="_blank"><h6 style="text-align:left;color: #00005f;">{{currentFormData!.companyWebsiteName}}</h6></a>
                                </h6>
                                </div>
                              </div>

                              <div class="row mt-2">
                                <div class="col-2">
                                    <a class="" href="mailto:{{currentFormData!.email}}">
                                        <i class="fas fa-envelope roundIcon"></i>
                                    </a>                                    
                                </div>
                                <div class="col-10 ">
                                  <h6 class="mt-1"><a class="opacity-100   detailText"
                                    style="line-break: anywhere;text-align:left;color: #00005f;"
                                    href="mailto:{{currentFormData!.email}}" target="_blank"><h6>{{currentFormData!.email}}</h6></a>
                                  </h6>
                                </div>
                              </div>

                              <div class="row mt-2">
                                <div class="col-2">                                    
                                    <a class="roundIcon" target="_blank" (click)="whatsApp(currentFormData!.countryCodeWhatsAppNo, currentFormData!.whatsAppNumber)">
                                        <i class="fab fa-whatsapp  roundIcon"></i> 
                                    </a>
                                </div>
                                <div class="col-10 mt-2 detailText">
                                  <h6 class="" type="button" style="text-align:left;color: #00005f;"
                                      (click)="whatsApp(currentFormData!.countryCodeWhatsAppNo, currentFormData!.whatsAppNumber)">
                                    {{currentFormData!.countryCodeWhatsAppNo}} {{formatNumber(currentFormData!.whatsAppNumber)}}</h6>
                                </div>
                              </div>
                            </div>
                            <div class="col-1"></div>
                          </div>
                        <div class="p-30 py-2"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>








<ngx-spinner bdColor="rgba(255, 255, 255, 1)" size="large" color="#FFFFFF"
             [fullScreen]="true">
    <figure class="loader">
        <div class="dot white"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
    </figure>
</ngx-spinner>
<app-ecard-footer></app-ecard-footer>