import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Gatekeeper } from 'gatekeeper-client-sdk';
import { HttpClient } from '@angular/common/http';
import { ECardForm } from '@modules/ecard/modal/eCardForm';
import { Register } from '@modules/ecard/modal/register';
import { LoginResponse } from '@modules/ecard/modal/loginResponse';
import { LocalDataService } from './localDataService';
import { FormGroup } from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class AppService {

    constructor(private http: HttpClient, private localData: LocalDataService,
        private router: Router) {
    }

    public user: any = null;
    // endPoint = "http://localhost:8084";
//     endPoint1 = "https://136.233.82.215:8080/ecard-ws";
    // endPoint = "http://136.233.82.212:8080/ecard-ws";
    endPoint = "https://tomcat3.varnik.cloud:8443/ecard-ws"

    saveCardDetails(userForm: FormGroup) {
        return this.http.post<ECardForm>(
            this.endPoint + "/api/eCardForm/add", userForm,
            { headers: this.localData.getHeaders() }
        );
    }

    getCardDetails(eCardName: string) {
        return this.http.get<ECardForm>(
            this.endPoint + "/api/eCardForm/getBy/" + eCardName
        )
    }

    fileUpload(formData: FormData, eCardName: string, logo: string) {
        return this.http.post<String[]>(
            this.endPoint + "/api/eCard/fileUpload?eCardName=" + eCardName + "&logo=" + logo, formData,
            { headers: this.localData.getHeaders() }
        )
    }

    fileGet(eCardName: string, logo: string) {
        return this.http.get(
            this.endPoint + "/api/eCard/getProfilePic/" + eCardName + "/" + logo,
            { responseType: "blob" }
        )
    }

    getAllCardDetails() {
        /* const headers = new HttpHeaders()
             .set('Authorization', 'Bearer ' + localStorage.getItem('authHeader'))
             .set('Content-Type', 'application/json');
         console.log("header:", { headers: this.localData.getHeaders()})*/
        return this.http.get<ECardForm[]>(
            this.endPoint + "/api/eCardForm/getAll",
            { headers: this.localData.getHeaders() }
        )
        /*const url = this.endPoint + "/api/eCardForm/getAll"
        // return this.http.get<ECardForm[]>(url, { withCredentials: false, headers })

        const headers = new HttpHeaders({
          'Authorization': `Bearer ${this.token}`
        });

        const requestOptions = {
          headers: headers
        };

        return this.http.get<ECardForm[]>(url, requestOptions)
            .pipe(
                catchError(error => {
                  console.error('Error in getDepartmentData:', error);
                  return throwError(error);
                })
            );*/
    }

    async getProfile() {
        try {
            const token = localStorage.getItem('authToken');

            if (token == '') {
                this.user = null;
                this.router.navigate(['/']);
            } else {
                this.user = token

            }
        } catch (error) {
            this.logout();
            throw error;
        }
    }

    logout() {
        localStorage.removeItem('authToken');
        this.user = null;
        this.router.navigate(['/login']);
    }

    saveRegisterPage(register: FormGroup) {
        return this.http.post<Register>(
            this.endPoint + "/api/register", register
        )
    }

    saveLoginDetails(login: FormGroup) {
        return this.http.post<LoginResponse>(
            this.endPoint + "/api/login", login
        )
    }

    getUserDetails(userId: string) {
        return this.http.get<ECardForm[]>(
            this.endPoint + "/api/eCardForm/getById/" + userId,
        );
    }

    updateCardDetails(updateForm: FormGroup) {
        return this.http.put<ECardForm>(
            this.endPoint + "/api/eCardForm/update", updateForm
        )
    }

    /*Email OTP Generate API */

    sendEmailOtp(userForm: FormGroup) {
        console.log("otp send json::", userForm)
        return this.http.post(
            this.endPoint + "/User/send-otp", userForm,
        )
    }
    /*Email Verify OTP Generate API */

    verifyEmailOtp(email: string, otp: string) {
        return this.http.post(
            this.endPoint + "/User/verify-otp?email=" + email + "&otp=" + otp, ""
        )
    }

    /* Get UserDetails API */

    getRegisterUserDetails(userId: number) {
        return this.http.get<Register>(
            this.endPoint + "/api/getUserDetails/" + userId,
        )
    }

    /* Get AllUserDetails API */
    getAllUserDetails(){
        return this.http.get<Register[]>(
            this.endPoint +"/api/getAllUsers"
        )
    }
}
