import {Component, HostBinding, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators,ReactiveFormsModule} from '@angular/forms';
import { Router } from '@angular/router';
import { OtpResponse } from '@modules/ecard/modal/otpResponse';
import { Register } from '@modules/ecard/modal/register';
import { AppService } from '@services/app.service';
import { NgxSpinnerService } from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
    phoneNumberOTP = false;
    emailIdOTP = false;
    isPasswordVisible = false;
    verifiedOTP = false
    otpResponse: OtpResponse  | undefined;
    firstValue: string = '';
    secondValue: string = '';
    thirdValue: string = '';
    fourthValue: string = '';
    firstValue1: string = '';
    secondValue2: string = '';
    thirdValue3: string = '';
    fourthValue4: string = '';
    email :string = ''
    allUser : Register [] = []
    getEmailId : string [] = []

    constructor(private appService: AppService, private route: Router, private spinner: NgxSpinnerService,
        private toastr: ToastrService) {
    }


    register = new UntypedFormGroup({
        userName: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        phoneNumber: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        emailId: new UntypedFormControl('', [Validators.required,Validators.nullValidator, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
        password: new UntypedFormControl('', Validators.required,),
        primeCount: new UntypedFormControl('', Validators.required,)
    })
    otpInput = new UntypedFormGroup({
        otp: new UntypedFormControl('', [Validators.required, Validators.nullValidator])
    })

    currentEmail = new UntypedFormGroup({
        email: new UntypedFormControl('', [Validators.required, Validators.nullValidator])
    })

    public registerForm = {
        userName: ' ',
        phoneNumber: ' ',
        emailId: ' ',
        password: ' ',
    }

    onSubmit() {
        console.log(this.register.value)
        this.registerValidation()
    }

    ngOnInit() {
        this.getUserDetails()
        /*
                window.location.reload()
        */
    }

    registerValidation() {
        this.registerForm.userName = " ";
        this.registerForm.phoneNumber = " ";
        this.registerForm.emailId = " ";
        this.registerForm.password = " ";

        let hasError = false;

        if (this.register.get("userName")?.invalid) {
            this.registerForm.userName = "*Username is required";
            hasError = true
        }
        if (this.register.get("phoneNumber")?.invalid) {
            this.registerForm.phoneNumber = "*Phone Number is required";
            hasError = true
        }
        if (this.register.get("emailId")?.invalid) {
          if(this.register.get('emailId').errors.required)
            this.registerForm.emailId = "*Email Address is required";
            hasError = true
          if (this.register.get('emailId').errors.pattern) {
            this.registerForm.emailId = "*Enter Valid Email.(Ex:example@gmail.com)";
            hasError = true;
        }
        }
        if (this.register.get("password")?.invalid) {
            this.registerForm.password = "*Password is required";
            hasError = true
        }
        if (!hasError) {
            this.saveRegisterForm()
        }
    }

    numberOnly(event: any) {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 58)) {
            return false;
        }
        return true;
    }

    moveFocus(nextInput: string) {
        const nextInputField = document.getElementById(nextInput) as HTMLInputElement;
        if (nextInputField) {
            nextInputField.focus();
        }
    }

    saveRegisterForm() {
        if(this.verifiedOTP){
        // console.log("save Event:", this.register.value)
        this.register.controls['primeCount'].setValue(2)
        console.log("save Event:", this.register.value)
        this.spinner.show();
        this.appService
            .saveRegisterPage(this.register.value)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                console.log("save data:", data);
                this.spinner.hide();
                this.SuccessAlert();
                this.route.navigate(['/login'])
            },
                (err: any) => {
                    this.ErrorAlert();
                    this.spinner.hide();
                })
        console.log('HTTP request completed.')
            } else {
                this.toastr.error("Email Not Verified..!")
            }
    }


    getUserDetails(){
        this.appService.getAllUserDetails()
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
            console.log("userAllDetails::",data)
            this.allUser = data
            console.log("AllUserDetails::",this.allUser)
            var localemilid : string [] = []
            for(let ec of this.allUser){
                localemilid.push(ec.emailId)
            }
            this.getEmailId = localemilid
            console.log("getEmailId::",this.getEmailId)
        }, (err : any) => {
            console.log("something went wrong")
        })
        console.log('HTTP request completed.')
    }

    SuccessAlert() {
        Swal.fire({
            title: "Registered Successfully. \n Thank You",
            icon: "success",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
            showClass: {
                popup: "animate_animated animate_fadeInDown",
            },
            hideClass: {
                popup: "animate_animated animate_fadeOutUp",
            },
        }).then((result) => {
            if (result.isConfirmed) {
                window.location.reload()
            }
        });
    }

    ErrorAlert() {
        Swal.fire({
            title: "Sorry, Something Went Wrong Please Try Again Later.",
            icon: "error",
            showConfirmButton: false,
            timer: 1500,
            /*confirmButtonColor: "#3085d6",
            cancelButtonColor: "#dd3333",
            confirmButtonText: "OK",*/
            showClass: {
                popup: "animate_animated animate_fadeInDown",
            },
            hideClass: {
                popup: "animate_animated animate_fadeOutUp",
            },
        });
    }

    phonenumberOtp() {
        this.registerForm.phoneNumber = " ";

        let hasError = false

        if (this.register.get("phoneNumber")?.invalid) {
            this.registerForm.phoneNumber = "*Enter PhoneNumber";
            hasError = true
        }
        if (!hasError) {
            this.phoneNumberOTP = true;
        }
    }

    submitPhoneNumberOtp() {

    }

    PhoneNumberclose() {
        this.phoneNumberOTP = false;
    }

    emailOtp(event : any) {
        const value = event.target.value
        this.registerForm.emailId = " ";

        let hasError = false
        var localemailId = this.register.get('emailId').value
        console.log("emaildid::",localemailId)
        if(!this.register.get('emailId')?.invalid){
          console.log("emailid2::",localemailId)
        if(this.getEmailId.includes(localemailId)){
          this.registerForm.emailId = "*Email id is already existing";
          console.log("emailid3::",localemailId)
            hasError = true;
        }
      }
      if (this.register.get("emailId")?.invalid) {
        if(this.register.get('emailId').errors.required)
          this.registerForm.emailId = "*Email Address is required";
        hasError = true
        if (this.register.get('emailId').errors.pattern) {
          this.registerForm.emailId = "*Enter Valid Email.(Ex:example@gmail.com)";
          hasError = true;
        }
      }
        if (!hasError) {
            this.email = value
            this.currentEmail.controls['email'].setValue(value)
            this.emailIdOTP = true;
            this.appService.sendEmailOtp(this.currentEmail.value)
                .pipe(takeUntil(this.destroy$))
                .subscribe(data => {
                    console.log("get Email Response::", data)
                    // @ts-ignore
                    this.otpResponse = data
                    console.log("this.otpResponse::", this.otpResponse)
                }, (error: any) => {
                    console.log("Something went wrong");
                })
        }
    }

     //Only Enter Number Input box Event
     OnlyNumbers($event) {
        let regex: RegExp = new RegExp(/^[0-9]{1,}$/g);
        let specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowRight', 'ArrowLeft'];
        if (specialKeys.indexOf($event.key) !== -1) {
            return;
        } else {
            if (regex.test($event.key)) {
                return true;
            } else {
                return false;
            }
        }
    }

    submitEmailidOtp() {
        let hasError = false;

        if (this.firstValue == "") {
            this.toastr.error("Please enter OTP")
            hasError = true;
        }
        if (this.secondValue == "") {
            this.toastr.error("Please enter OTP")
            hasError = true;
        }
        if (this.thirdValue == "") {
            this.toastr.error("Please enter OTP")
            hasError = true;
        }
        if (this.fourthValue == "") {
            this.toastr.error("Please enter OTP")
            hasError = true;
        }
        if(!hasError){
        console.log('Submitted OTP:', this.otpInput.get('otp')?.value);
        console.log('Submitted OTP1:', this.firstValue);
        this.currentEmail.controls['email'].setValue(this.register.get('emailId')?.value)
    const otp = this.firstValue + this.secondValue + this.thirdValue + this.fourthValue
    console.log('Submitted OTP:', otp);
    if (otp != null || otp != undefined || otp != "") {
      this.appService.verifyEmailOtp(this.register.get('emailId')?.value, otp)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data: OtpResponse) => {
            console.log("OTP Verify status:", data);

            this.otpResponse = data;
            console.log("this.otpResponse:", this.otpResponse);

            if (data.success == true) {
                this.verifiedOTP = true;
                this.toastr.success("OTP Verified");
                console.log("OTP Is verify");
                this.emailIdOTP = false;
            } else {
                this.toastr.error("OTP is Incorrect");
                console.log("OTP Is Incorrect");
            }
        }, (error: any) => {
            console.log("Something went wrong");
        });
    } else {
    //   this.toastr.error("Please Enter the OTP");
    }
}
    }

    emailIdclose() {
        this.emailIdOTP = false;
    }

    togglePasswordVisibility() {
        const passwordShow = document.getElementById('password') as HTMLInputElement;
        if (passwordShow.type == 'password') {
            passwordShow.type = 'text';
        } else {
            passwordShow.type = 'password';
        }
        this.isPasswordVisible = !this.isPasswordVisible;
    }
}
