<section class="vh-100">
    <div class="container-fluid py-5  justify-content-center align-items-center"
         style="text-align: -webkit-center;">
        <div>
            <div class="page-wrapper" id="home-section">
                <div class="page-details">
                    <div>
                        <div class="p-10"></div>
                        <!--<div class="views-label"><i class="fas fa-eye"></i> Views: <b>3467</b></div>-->

                        <!-- User Profile Pic -->
                        <div>
                            <img [src]="companySrcUrl" class="image mt-4" crossorigin="anonymous">
                        </div>

                        <!-- User Company Name -->
                        <h1 class="firmname">{{currentFormData!.companyName}}</h1>
                        <hr style="border-bottom: black 1px solid">
                        <div *ngIf="profileShow">
                            <img [src]="profileSrcUrl" class="image mt-4" crossorigin="anonymous">
                        </div>

                        <!-- User First Name and Last Name -->
                        <h1 class="name">{{currentFormData!.firstName}} {{currentFormData!.lastName}}<br>
                            <span class="designation">{{currentFormData!.designation}}</span><br>
                            <span class="role">{{currentFormData!.role}}</span></h1>


                        <div style="width:60px;background-color:#0085C4;height:2px;margin-top: 15px;"></div>
                        <!-- Cover Photo, Photo, Name and Profession section completed -->
                    </div>
                    <div>
                        <!-- FRONT CONTACT ACTIONS START-->
                        <div class="p-10"></div>
                        <div class="contact-buttons">
                            <a class="contact-button"
                               href="tel:{{currentFormData!.countryCodePhoneNo}}{{currentFormData!.phoneNo}}">
                                <i class="fas fa-phone fa-flip-horizontal"></i>
                                Call
                            </a>
                            <!--<a class="contact-button" target="_blank"
                               (click)="whatsApp(currentFormData!.countryCodePhoneNo, currentFormData!.phoneNo)">
                                <i class="fab fa-whatsapp"></i>
                                Whatsapp
                            </a>-->
                            <a class="contact-button" target="_blank"
                               (click)="whatsApp(currentFormData!.countryCodeWhatsAppNo, currentFormData!.whatsAppNumber)">
                                <i class="fab fa-whatsapp"></i>
                                Whatsapp
                            </a>
                            <a class="contact-button" target="_blank" href="mailto:{{currentFormData!.email}}">
                                <i class="fas fa-envelope fa-flip-horizontal"></i>
                                Mail
                            </a>
                        </div>
                        <table class="contact-action-table">
                            <tbody>
                            <tr>
                                <td>
                                    <i class="fas fa-map-marker-alt contact-action-container-icon"></i>
                                </td>
                                <td>
                                    <a class="contact-action-container-text" style="max-width: 220px;">
                                        {{currentFormData!.address}}
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a href="mailto:{{currentFormData!.email}}">
                                        <i class="fas fa-envelope contact-action-container-icon"></i>
                                    </a>
                                </td>
                                <td>
                                    <a href="mailto:{{currentFormData!.email}}"
                                       class="contact-action-container-text mt-4">
                                        {{currentFormData!.email}}
                                    </a><br>
                                    <a href="mailto:" class="contact-action-container-text">
                                    </a><br>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a target="_blank" href="http://{{currentFormData!.companyWebsiteName}}">
                                        <i class="fas fa-globe contact-action-container-icon"></i>
                                    </a>
                                </td>
                                <td>
                                    <a target="_blank" href="http://{{currentFormData!.companyWebsiteName}}"
                                       class="contact-action-container-text">
                                        {{currentFormData!.companyWebsiteName}}
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a target="_blank"
                                       href="tel:{{currentFormData!.countryCodePhoneNo}}{{currentFormData!.phoneNo}}">
                                        <i class="fas fa-phone fa-flip-horizontal contact-action-container-icon"></i>
                                    </a>
                                </td>
                                <td>
                                    <a target="_blank"
                                       href="tel:{{currentFormData!.countryCodePhoneNo}}{{currentFormData!.phoneNo}}"
                                       class="contact-action-container-text mt-3" style="margin-bottom: 5px">
                                        {{currentFormData!.countryCodePhoneNo}} {{currentFormData!.phoneNo}}
                                    </a>
                                    <br><a target="_blank" href="tel:{{currentFormData!.telephone}}"
                                           class="contact-action-container-text">
                                    {{currentFormData!.telephone}}
                                </a>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div class="p-30"></div>
                        <div class="p-30 mt-4"></div>
                        <ul class="inprofile share-buttons">
                        </ul>
                        <div class="p-20"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<ngx-spinner bdColor="rgba(255, 255, 255, 1)" size="large" color="#FFFFFF"
             [fullScreen]="true">
    <figure class="loader">
        <div class="dot white"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
    </figure>
</ngx-spinner>
<app-ecard-footer></app-ecard-footer>
