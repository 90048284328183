<section class="vh-100" style="background-image: url(../../assets/img/backgroundImages/pxfuel.jpg)">
    <div class="container-fluid py-2  justify-content-center align-items-center"
         style="text-align: -webkit-center;">
        <div>
            <div class="page-wrapper" id="home-section">
                <div class="page-details">
                    <div>
                        <div class="p-10"></div>
                        <div class="">
                            <img [src]="companySrcUrl" class="image  mt-2"  crossorigin="anonymous">
                            <h3 class="firmname mt-2">{{currentFormData!.companyName}}</h3>
                        </div>
                        <div   style="color: white;">
                            <img [src]="profileSrcUrl"  *ngIf="profileShow" class="profiel mt-2" crossorigin="anonymous">
                        </div>
                        <h4 class="name mt-3">{{currentFormData!.firstName}} {{currentFormData!.lastName}}</h4>
                            <h6 class="designation">{{currentFormData!.designation}}</h6>
                            <h6 class="role">{{currentFormData!.role}}</h6>


                    </div>
                    <div>
                        <div class="p-10"></div>
                        <div class="contact-buttons">
                            <a class="btn btn-primary mr-2" href="tel:{{currentFormData!.countryCodePhoneNo}}{{currentFormData!.phoneNo}}">
                                <i class="fas fa-phone fa-flip-horizontal"></i>
                            </a>
                            <a class="btn btn-success mr-2" target="_blank" (click)="whatsApp(currentFormData!.countryCodeWhatsAppNo, currentFormData!.whatsAppNumber)">
                                <i class="fab fa-whatsapp"></i><span class="ml-2 fw-bolder">whatsApp</span>
                            </a>
                            <a class="btn btn-primary" target="_blank" href="mailto:{{currentFormData!.email}}">
                                <i class="fas fa-envelope"></i>
                            </a>
                        </div>
                        <table class="contact-action-table py-5">
                            <tbody class="table-stripped"> 
                            <tr>
                                <td >
                                    <i class="fas fa-map-marker-alt contact-action-container-icon"></i>
                                </td>
                                <td>
                                    <a class="contact-action-container-text mt-3" style="max-width: 220px;">
                                        {{currentFormData!.address}}
                                    </a>
                                </td>
                            </tr>
                            <tr></tr>
                            <tr>
                                <td>
                                    <a href="mailto:{{currentFormData!.email}}">
                                        <i class="fas fa-envelope contact-action-container-icon"></i>
                                    </a>
                                </td>
                                <td>
                                    <a href="mailto:{{currentFormData!.email}}"
                                       class="contact-action-container-text mt-4">
                                        {{currentFormData!.email}}
                                    </a><br>
                                    <a href="mailto:" class="contact-action-container-text">
                                    </a><br>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a target="_blank" href="http://{{currentFormData!.companyWebsiteName}}">
                                        <i class="fas fa-globe contact-action-container-icon"></i>
                                    </a>
                                </td>
                                <td>
                                    <a target="_blank" href="http://{{currentFormData!.companyWebsiteName}}"
                                       class="contact-action-container-text">
                                        {{currentFormData!.companyWebsiteName}}
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a target="_blank"
                                       href="tel:{{currentFormData!.countryCodePhoneNo}}{{currentFormData!.phoneNo}}">
                                        <i class="bi bi-telephone-fill contact-action-container-icon mb-1"></i>
                                    </a>
                                </td>
                                <td>
                                    <a target="_blank"href="tel:{{currentFormData!.countryCodePhoneNo}}{{currentFormData!.phoneNo}}"
                                       class="contact-action-container-text mt-4" style="margin-bottom: 5px;">
                                        {{currentFormData!.countryCodePhoneNo}} {{currentFormData!.phoneNo}}
                                    </a>
                                    <br>
                                    <a target="_blank" href="tel:{{currentFormData!.telephone}}"class="contact-action-container-text">
                                </a>
                                </td>
                            </tr>
                          </tbody>
                        </table>
                        <div class="p-30 py-2"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<ngx-spinner bdColor="rgba(255, 255, 255, 1)" size="large" color="#FFFFFF"
             [fullScreen]="true">
    <figure class="loader">
        <div class="dot white"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
    </figure>
</ngx-spinner>
<app-ecard-footer></app-ecard-footer>