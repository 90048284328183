<!-- <div class="float-right d-none d-sm-block"><b>Version</b> {{ appVersion }}</div>
<strong>
  <span>Copyright &copy; {{ currentYear }}</span>
  <a
    href="https://erdkse.com"
    rel="noopener noreferrer"
    style="margin: 0"
    target="_blank"
  >
    erdkse.com</a
  >
  <span>.</span>
</strong>
<span> All rights reserved.</span> -->
<div class="footer">
  <footer class="text-center text-white" style="background-color: rgba(241,241,241,0.18);">
    <div class="text-center text-dark" style="background-color: rgba(0,0,0,0);">
            <span>Designed & Developed by <a class="text-primary fw-bolder" href="https://varnikcloud.com/"
                                             target="_blank">VarNik Systems</a>, Trichy @Copyrights - 2023. All Rights Reserved</span>
    </div>
  </footer>
</div>
