<section class="vh-100">
  <div class="container py-5 h-100">
    <div class="row d-flex justify-content-center align-items-center vh-90">
      <div class="col-12 col-md-8 col-lg-6 col-xl-5">
        <div class="card shadow bg-white">
          <div class="text-center">
            <!-- <span class="fw-bolder text-center" style="font-size: 25px;">ECard</span> -->
            <img src="../../../assets/img/backgroundImages/ecard.png" style="height: 90px" >
          </div>
          <div class="card-body p-3">
            <div class="p-3">
              <h6 class="text-center fw-bolder">WELCOME TO E-CARD</h6>
              <h6 class="mt-3 text-center "></h6>
              <form [formGroup]="register" (ngSubmit)="onSubmit()">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="floatingInput"
                         formControlName="userName" placeholder="userName" autocomplete="off">
                  <label for="floatingInput"><b>Username</b></label>
                  <p class="ms-3 mt-1 text-muted" style="font-size: 12px">Note : <span class="text-danger">*</span>Give any name of
                    your own</p>
                  <span *ngIf="registerForm.userName"
                        class="text-danger"><b>{{registerForm.userName}}</b></span>
                </div>
                <div class="row mt-2">
                  <div class="col-md-12">
                    <div class="form-floating mb-3">
                      <input type="text" (keypress)="numberOnly($event)" class="form-control"
                             minlength="10" maxlength="10" formControlName="phoneNumber"
                             placeholder="Phone Number" autocomplete="off">
                      <label for="floatingInput"><b>Phone Number</b></label>
                      <span *ngIf="registerForm.phoneNumber"
                            class="text-danger"><b>{{registerForm.phoneNumber}}</b></span>
                    </div>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-12">
                    <div class="form-floating mb-3">
                      <input type="text" class="form-control" formControlName="emailId"
                             placeholder="Email Address" (change)="emailOtp($event)" autocomplete="off">
                      <label for=""><b>Email Address</b></label>
                      <span *ngIf="registerForm.emailId"
                            class="text-danger"><b>{{registerForm.emailId}}</b></span>
                    </div>
                  </div>
                  <!-- <div class="col-md-4 mt-2">
                      <button type="button" class="btn btn-success otp" (click)="emailOtp()">Send
                          OTP</button>
                  </div> -->
                </div>
                <div class="form-floating input-group-prepend mb-3 mt-2">
                  <input type="password" class="form-control" id="password" formControlName="password"
                         placeholder="Password" autocomplete="off">
                  <label for="floatingPassword"><b>Password</b></label>
                  <span class="input-group-text"><i class="toggle-password fa fa-lock-open"
                                                    data-toggle="tooltip" data-placement="top" title="Click to Show Password"
                                                    (click)="togglePasswordVisibility()"
                                                    [ngClass]="{'fa-lock-open': isPasswordVisible, 'fa-lock': !isPasswordVisible}"
                                                    aria-hidden="true"></i></span>
                </div>
                <span *ngIf="registerForm.password"
                      class="text-danger"><b>{{registerForm.password}}</b></span>
                <div class="col-12 text-center mt-3">
                  <button type="submit" class="btn btn-success button"><b>Register</b></button>
                </div>
                <div class="mt-3 text-center">
                  Already a Member? <a [routerLink]="['/login']"
                                       class="fw-bolder text-primary">LogIn</a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div [style.display]="phoneNumberOTP ? 'block' : 'none'" class="modal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <!--<h4 class="modal-title mt-3" style="text-align: center;">NOTE</h4>-->
      <div class="modal-header">
        <h5 class="modal-title" style="margin-left: 165px" id="otpModalLabel"><b>Enter the OTP</b></h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" (click)="PhoneNumberclose()">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form id="otpInput">
          <div class="form-group">
            <!--<label for="otp">OTP</label>-->
            <input type="text" class="form-control" id="phonrnumberotp" formControlName="phonrnumberotp"
                   name="phonrnumberotp" maxlength="4" required>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="submitPhoneNumberOtp()">Submit OTP</button>
      </div>
    </div>
  </div>
</div>
<!-- <div [style.display]="emailIdOTP ? 'block' : 'none'" class="modal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
      <div class="modal-content">
          <div class="card bg-white mb-5 mt-2 border-0" style="box-shadow: 0 12px 15px rgba(0, 0, 0, 0.02);">
            <div class="card-body p-5 text-center">
              <h4>Verify</h4>
              <p>Your code was sent to you via :<b> {{email}}</b></p>

            <div id="emailOtp" class="otp-field inputs d-flex flex-row justify-content-center mt-2" (keypress)="OnlyNumbers($event)">
                <input class="m-2 text-center  otpInput" type="text"  maxlength="1"[(ngModel)]="firstValue" (ngModelChange)="moveFocus('second')"/>
                <input class="m-2 text-center  otpInput" type="text"  maxlength="1" [(ngModel)]="secondValue" (ngModelChange)="moveFocus('third')"/>
                <input class="m-2 text-center  otpInput" type="text"  maxlength="1" [(ngModel)]="thirdValue" (ngModelChange)="moveFocus('fourth')"/>
                <input class="m-2 text-center  otpInput" type="text"  maxlength="1" [(ngModel)]="fourthValue"/>
                </div>


              <button class="btn btn-primary" (click)="submitEmailidOtp()">
                Verify
              </button>
            </div>
          </div>
      </div>
  </div>
</div> -->
<div [style.display]="emailIdOTP ? 'block' : 'none'" class="modal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <section>
        <svg width="250" height="200" viewBox="0 0 292 208" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_1_45)">
            <path d="M152.106 208C201.536 208 241.606 167.93 241.606 118.5C241.606 69.0706 201.536 29 152.106 29C102.676 29 62.6058 69.0706 62.6058 118.5C62.6058 167.93 102.676 208 152.106 208Z" fill="#C5FFFF" />
            <path d="M117.144 64.4241C113.81 64.4241 111.108 67.1261 111.108 70.46V167.057C111.108 170.391 113.81 173.093 117.144 173.093H186.572C189.906 173.093 192.608 170.391 192.608 167.057V92.382L163.507 64.4241H117.144Z" fill="#91E4FF" />
            <path d="M192.608 92.382H169.544C166.21 92.382 163.508 89.68 163.508 86.3461V64.4241L192.608 92.382Z" fill="#0CB4EA" />
            <path d="M162.304 131.646C162.304 135.494 159.185 138.613 155.339 138.613H104.483C100.635 138.613 97.5186 135.494 97.5186 131.646V110.363C97.5186 106.515 100.635 103.397 104.483 103.397H155.339C159.185 103.397 162.304 106.515 162.304 110.363V131.646Z" fill="#0CB4EA" />
            <path d="M117.094 114.409C118.563 114.409 119.825 114.707 120.876 115.302C121.93 115.897 122.728 116.745 123.267 117.843C123.807 118.941 124.079 120.23 124.079 121.712C124.079 122.808 123.932 123.803 123.635 124.697C123.338 125.592 122.894 126.369 122.302 127.025C121.71 127.681 120.981 128.184 120.119 128.532C119.257 128.879 118.266 129.053 117.153 129.053C116.044 129.053 115.054 128.875 114.178 128.518C113.302 128.16 112.571 127.657 111.985 127.005C111.398 126.354 110.956 125.572 110.656 124.658C110.358 123.744 110.208 122.755 110.208 121.692C110.208 120.604 110.364 119.604 110.676 118.697C110.99 117.788 111.442 117.017 112.034 116.378C112.627 115.739 113.349 115.253 114.198 114.914C115.047 114.574 116.012 114.409 117.094 114.409ZM121.17 121.692C121.17 120.655 121.003 119.756 120.669 118.997C120.334 118.238 119.856 117.663 119.233 117.273C118.612 116.883 117.899 116.688 117.093 116.688C116.521 116.688 115.991 116.795 115.504 117.012C115.017 117.228 114.599 117.542 114.247 117.954C113.897 118.367 113.621 118.893 113.416 119.534C113.214 120.176 113.113 120.895 113.113 121.694C113.113 122.499 113.214 123.226 113.416 123.877C113.621 124.527 113.907 125.067 114.277 125.495C114.647 125.923 115.073 126.244 115.552 126.456C116.031 126.668 116.558 126.775 117.131 126.775C117.866 126.775 118.54 126.592 119.154 126.224C119.77 125.857 120.259 125.29 120.623 124.524C120.988 123.757 121.17 122.813 121.17 121.692Z" fill="white" />
            <path d="M134.976 117.018H131.846V127.306C131.846 127.898 131.713 128.338 131.45 128.625C131.187 128.912 130.844 129.054 130.425 129.054C130 129.054 129.654 128.909 129.388 128.619C129.121 128.33 128.987 127.892 128.987 127.305V117.017H125.856C125.366 117.017 125.003 116.909 124.765 116.693C124.528 116.477 124.408 116.192 124.408 115.838C124.408 115.47 124.532 115.181 124.779 114.969C125.028 114.757 125.387 114.649 125.858 114.649H134.977C135.473 114.649 135.842 114.76 136.082 114.977C136.326 115.196 136.446 115.483 136.446 115.836C136.446 116.189 136.323 116.475 136.078 116.691C135.834 116.907 135.466 117.018 134.976 117.018Z" fill="white" />
            <path d="M143.642 123.297H141.015V127.306C141.015 127.879 140.879 128.313 140.609 128.61C140.339 128.907 139.997 129.054 139.584 129.054C139.152 129.054 138.804 128.907 138.542 128.614C138.279 128.322 138.146 127.891 138.146 127.324V116.409C138.146 115.777 138.291 115.326 138.581 115.056C138.871 114.786 139.331 114.65 139.963 114.65H143.643C144.733 114.65 145.568 114.734 146.154 114.902C146.734 115.063 147.235 115.33 147.657 115.703C148.079 116.077 148.399 116.534 148.619 117.076C148.84 117.617 148.947 118.224 148.947 118.901C148.947 120.344 148.503 121.437 147.615 122.182C146.726 122.926 145.4 123.297 143.642 123.297ZM142.945 116.804H141.014V121.133H142.945C143.622 121.133 144.188 121.062 144.64 120.921C145.095 120.78 145.44 120.548 145.678 120.226C145.917 119.904 146.036 119.483 146.036 118.959C146.036 118.335 145.853 117.826 145.485 117.433C145.074 117.013 144.228 116.804 142.945 116.804Z" fill="white" />
            <rect x="233.582" y="79" width="10" height="10" rx="1" transform="rotate(27.2727 233.582 79)" fill="#91A3FF" />
            <circle cx="74" cy="139" r="5" fill="#FF91B9" />
            <circle cx="79" cy="43" r="5" fill="#91E5FF" />
            <circle cx="188" cy="203" r="5" fill="#FF9191" />
          </g>
          <circle cx="220" cy="15" r="5" fill="#FFC691" />
          <circle cx="119.606" cy="5" r="5" fill="#91FFAF" />
          <rect x="250.606" y="163" width="10" height="10" rx="1" fill="#E991FF" />
          <rect x="274" y="47.0925" width="10" height="10" rx="1" transform="rotate(-24.1576 274 47.0925)" fill="#FF9191" />
          <rect y="68.5666" width="10" height="10" rx="1" transform="rotate(-27.1716 0 68.5666)" fill="#91A3FF" />
          <path d="M33.0121 175.265L40.7499 180.821L32.0689 184.744L33.0121 175.265Z" fill="#FF9191" />
          <path d="M15.077 128.971L16.567 138.38L7.67356 134.966L15.077 128.971Z" fill="#FD91FF" />
          <path d="M286.447 120.204L287.505 129.672L278.777 125.854L286.447 120.204Z" fill="#FF91BF" />
          <defs>
            <clipPath id="clip0_1_45">
              <rect width="179" height="179" fill="white" transform="translate(62.6058 29)" />
            </clipPath>
          </defs>
        </svg>
        <div class="title">Verification Code</div>
        <!--<p class="para">We have sent a verification code to your email address</p>-->
        <div class="modal-body">
          <div class="text-center"><span>A code has been sent to {{email}} </span></div>
          <div id="emailOtp" class="inputs d-flex flex-row justify-content-center mt-2" (keypress)="OnlyNumbers($event)"><input
            class="m-2 text-center  otpInput" type="text" id="first" maxlength="1"
            [(ngModel)]="firstValue" (ngModelChange)="moveFocus('second')"/> <input
            class="m-2 text-center  otpInput" type="text" id="second" maxlength="1"
            [(ngModel)]="secondValue" (ngModelChange)="moveFocus('third')"/> <input
            class="m-2 text-center  otpInput" type="text" id="third" maxlength="1"
            [(ngModel)]="thirdValue" (ngModelChange)="moveFocus('fourth')"/> <input
            class="m-2 text-center  otpInput" type="text" id="fourth" maxlength="1" [(ngModel)]="fourthValue"/></div>
          <div class="d-flex justify-content-center col-md-12">
            <button type="button" class="btn btn-primary otpButton" (click)="submitEmailidOtp()">Verify OTP</button></div>
        </div>
      </section>
    </div>
  </div>
</div>
<ngx-spinner  size="large">
  <main>
    <svg xmlns="http://www.w3.org/2000/svg" height="128px" width="256px" viewBox="0 0 256 128" class="ip">
      <defs>
        <linearGradient y2="0" x2="1" y1="0" x1="0" id="grad1">
          <stop stop-color="#5ebd3e" offset="0%"></stop>
          <stop stop-color="#ffb900" offset="33%"></stop>
          <stop stop-color="#f78200" offset="67%"></stop>
          <stop stop-color="#e23838" offset="100%"></stop>
        </linearGradient>
        <linearGradient y2="0" x2="0" y1="0" x1="1" id="grad2">
          <stop stop-color="#e23838" offset="0%"></stop>
          <stop stop-color="#973999" offset="33%"></stop>
          <stop stop-color="#009cdf" offset="67%"></stop>
          <stop stop-color="#5ebd3e" offset="100%"></stop>
        </linearGradient>
      </defs>
      <g stroke-width="16" stroke-linecap="round" fill="none">
        <g stroke="#ddd" class="ip__track">
          <path d="M8,64s0-56,60-56,60,112,120,112,60-56,60-56"></path>
          <path d="M248,64s0-56-60-56-60,112-120,112S8,64,8,64"></path>
        </g>
        <g stroke-dasharray="180 656">
          <path d="M8,64s0-56,60-56,60,112,120,112,60-56,60-56" stroke-dashoffset="0" stroke="url(#grad1)"
                class="ip__worm1"></path>
          <path d="M248,64s0-56-60-56-60,112-120,112S8,64,8,64" stroke-dashoffset="358" stroke="url(#grad2)"
                class="ip__worm2"></path>
        </g>
      </g>
    </svg>
  </main>
</ngx-spinner>
