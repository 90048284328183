<section class="vh-100" style="background-image: url(../../assets/img/backgroundImages/pxfuel.jpg)" *ngIf="profileShow">
    <div class="container-fluid py-2 vh-00 justify-content-center align-items-center"
         style="text-align: -webkit-center;">
        <div>
            <div class="page-wrapper"  id="home-section">
                <div class="page-details">
                    <div>
                        <div class="p-10"></div>
                        <div>
                            <img [src]="companySrcUrl" class="companyimage py-2" crossorigin="anonymous">
                        </div>
                        <h5 class="firmname mt-3"><b>{{currentFormData!.companyName}}</b></h5>
                        <div class="row py-3" style="border-top:2px solid;">
                                <div class="">
                                    <img [src]="profileSrcUrl" class="image"  crossorigin="anonymous">
                                </div>
                                <h4 class="name mt-2">{{currentFormData!.firstName}} {{currentFormData!.lastName}}</h4>
                                    <h6 class="designation">{{currentFormData!.designation}}</h6>
                                    <h6 class="role ">{{currentFormData!.role}}</h6>
                            </div>
                                
                                
                    </div>
                    <div class="py-1 mt-2" ></div>
                    <div class="p-10 py-2"></div>
                    <div class="p-10"></div>
                    <div class="details">
                        <table class="contact-action-table">
                            <tbody class="table-stripped "> 
                            <tr>
                                <td >
                                    <i class="fas fa-map-marker-alt contact-action-container-icon"></i>
                                </td>
                                <td>
                                    <a class="contact-action-container-text" style="max-width: 220px; color: white;">
                                        {{currentFormData!.address}}
                                    </a>
                                </td>
                            </tr>
                            <tr></tr>
                            <tr>
                                <td>
                                    <a href="mailto:{{currentFormData!.email}}">
                                        <i class="fas fa-envelope contact-action-container-icon"></i>
                                    </a>
                                </td>
                                <td>
                                    <a href="mailto:{{currentFormData!.email}}"
                                       class="contact-action-container-text mt-4" style="color: white;line-break: anywhere;">
                                        {{currentFormData!.email}}
                                    </a><br>
                                    <a href="mailto:" class="contact-action-container-text">
                                    </a><br>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a target="_blank" href="http://{{currentFormData!.companyWebsiteName}}">
                                        <i class="fas fa-globe contact-action-container-icon"></i>
                                    </a>
                                </td>
                                <td>
                                    <a target="_blank" href="http://{{currentFormData!.companyWebsiteName}}"
                                       class="contact-action-container-text"  style="color: white;line-break: anywhere;">
                                        {{currentFormData!.companyWebsiteName}}
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a target="_blank"
                                       href="tel:{{currentFormData!.countryCodePhoneNo}}{{currentFormData!.phoneNo}}">
                                        <i class="bi bi-telephone-fill contact-action-container-icon"></i>
                                    </a>
                                </td>
                                <td>
                                    <a target="_blank"href="tel:{{currentFormData!.countryCodePhoneNo}}{{currentFormData!.phoneNo}}"
                                       class="contact-action-container-text mt-3" style="margin-bottom: 5px; color: white;">
                                        {{currentFormData!.countryCodePhoneNo}} {{currentFormData!.phoneNo}}
                                    </a>
                                    <br>
                                    <a target="_blank" href="tel:{{currentFormData!.telephone}}"class="contact-action-container-text" style="color: white;">
                                    {{currentFormData!.telephone}}
                                </a>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        <div class="lastline mt-2">
                            <a class="contact-button" target="_blank"
                               (click)="whatsApp(currentFormData!.countryCodeWhatsAppNo, currentFormData!.whatsAppNumber)">
                                <i class="fab fa-whatsapp fs-6"></i><b>WhatsApp</b>
                            </a>
                            <div class="row"></div>
                        </div>

                        <div class="p-30"></div>
                        <ul class="inprofile share-buttons">
                        </ul>
                        <div class="p-20"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>









<section class="vh-100" style="background-image: url(../../assets/img/backgroundImages/pxfuel.jpg)" *ngIf="profilehide">
    <div class="container-fluid py-2 vh-00 justify-content-center align-items-center"
         style="text-align: -webkit-center;">
        <div>
            <div class="page-wrapper"  id="home-section">
                <div class="page-details ">
                    <div>
                        <div class="p-10"></div>
                        <div class="py-3">
                            <img [src]="companySrcUrl" class="companyimage" crossorigin="anonymous">
                            <h5 class="firmname mt-3"><b>{{currentFormData!.companyName}}</b></h5>
                            <div class="" style="border-bottom: 2px solid rgb(0, 0, 0);"></div>
                            <div class="row mb-1">
                                <div class="col-md-1"></div>
                                <div class="col-md-10">
                                    <h4 class="name mt-3" style="color: black;"><b>{{currentFormData!.firstName}} {{currentFormData!.lastName}}</b></h4>
                                        <h6 class="designation" style="color: black;">{{currentFormData!.designation}}</h6>
                                        <h6 class="role " style="color: black;">{{currentFormData!.role}}</h6>
                                </div>
                                <div class="col-md-1"></div>
                            </div>
                        </div>
                        

                        
                    </div>
                    <div class="p-10"></div>
                    <div class="p-10"></div>
                    <div class="p-10 py-3"></div>
                    <div class="details">
                        <table class="contact-action-table" style="margin-top: 6%;">
                            <tbody class="table-stripped "> 
                            <tr>
                                <td >
                                    <i class="fas fa-map-marker-alt contact-action-container-icon"></i>
                                </td>
                                <td>
                                    <a class="contact-action-container-text" style="max-width: 220px; color: white;">
                                        {{currentFormData!.address}}
                                    </a>
                                </td>
                            </tr>
                            <tr></tr>
                            <tr>
                                <td>
                                    <a href="mailto:{{currentFormData!.email}}">
                                        <i class="fas fa-envelope contact-action-container-icon"></i>
                                    </a>
                                </td>
                                <td>
                                    <a href="mailto:{{currentFormData!.email}}"
                                       class="contact-action-container-text mt-4" style="color: white;line-break: anywhere;">
                                        {{currentFormData!.email}}
                                    </a><br>
                                    <a href="mailto:" class="contact-action-container-text">
                                    </a><br>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a target="_blank" href="http://{{currentFormData!.companyWebsiteName}}">
                                        <i class="fas fa-globe contact-action-container-icon"></i>
                                    </a>
                                </td>
                                <td>
                                    <a target="_blank" href="http://{{currentFormData!.companyWebsiteName}}"
                                       class="contact-action-container-text"  style="color: white;line-break: anywhere;">
                                        {{currentFormData!.companyWebsiteName}}
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a target="_blank"
                                       href="tel:{{currentFormData!.countryCodePhoneNo}}{{currentFormData!.phoneNo}}">
                                        <i class="bi bi-telephone-fill contact-action-container-icon"></i>
                                    </a>
                                </td>
                                <td>
                                    <a target="_blank"href="tel:{{currentFormData!.countryCodePhoneNo}}{{currentFormData!.phoneNo}}"
                                       class="contact-action-container-text mt-3" style="margin-bottom: 5px; color: white;">
                                        {{currentFormData!.countryCodePhoneNo}} {{currentFormData!.phoneNo}}
                                    </a>
                                    <br>
                                    <a target="_blank" href="tel:{{currentFormData!.telephone}}"class="contact-action-container-text" style="color: white;">
                                    {{currentFormData!.telephone}}
                                </a>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        <div class="lastline mt-2">
                            <a class="contact-button" target="_blank"
                               (click)="whatsApp(currentFormData!.countryCodeWhatsAppNo, currentFormData!.whatsAppNumber)">
                                <i class="fab fa-whatsapp fs-6"></i><b>WhatsApp</b>
                            </a>
                            <div class="row mb-5"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>







<ngx-spinner bdColor="rgba(255, 255, 255, 1)" size="large" color="#FFFFFF"
             [fullScreen]="true">
    <figure class="loader">
        <div class="dot white"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
    </figure>
</ngx-spinner>
<app-ecard-footer></app-ecard-footer>
