<section class="">
    <div class="container-fluid py-2 d-flex">
        <div class="container d-flex justify-content-center">
            <div class="box">
                <div class="row mt-4">
                    <div class="row mt-5"></div>
                    <div class="col-md-12 text-center">
                        <img class="companyProfile" [src]="companySrcUrl">
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-12 text-center" style="color: white;">
                        <h6 class="text-center">{{currentFormData!.companyName}}</h6>
                    </div>
                </div>
                <hr class="text-center" style="border: 1px solid white">
                <div class="col-md-12 text-center" *ngIf="profileShow">
                    <img [src]="profileSrcUrl" class="companyProfile ">
                </div>
               
                <div class="boxContent">
                    <!--<div class="row">
                        <div class="col-md-12 text-center">
                            <img [src]="profileSrcUrl" class="employeeProfile" *ngIf="profileShow" alt="Avatar"/>
                        </div>
                    </div>-->
                    <div class="row">
                        <div class="col-12 text-center" style="color: yellowgreen;">
                            <h5 class="name" >{{currentFormData!.firstName}} {{currentFormData!.lastName}}<br>
                                <span class="designation">{{currentFormData!.designation}}</span><br>
                                <span class="role">{{currentFormData!.role}}</span></h5>
                        </div>
                    </div>
                    <!-- <hr class="text-center" style="border: 1px solid white"> -->
                    <!--<div class="row justify-content-center mt-3">
                        <i class="bi bi-telephone-fill icon col-2" type="button" title="{{currentFormData!.countryCodePhoneNo}} {{formatNumber(currentFormData!.phoneNo)}}"
                           (click)="copyPhoneNo(currentFormData!.countryCodePhoneNo, formatNumber(currentFormData!.phoneNo))"></i>
                        <i class="bi bi-geo-alt-fill icon col-2" type="button" title="{{currentFormData!.address}}"></i>
                        <i class="bi bi-whatsapp icon col-2" type="button" title="{{currentFormData!.countryCodePhoneNo}} {{formatNumber(currentFormData!.phoneNo)}}" *ngIf="currentFormData!.whatsAppNoYn == 'Yes'"
                           (click)="whatsApp(currentFormData!.countryCodePhoneNo, currentFormData!.phoneNo)"></i>
                        <a class="col-2 text-dark fw-bolder"
                           href="mailto:{{currentFormData!.email}}"
                           target="_blank"><i class="bi bi-envelope icon" type="button" title="{{currentFormData!.email}}"></i></a>
                    </div>-->
                    <div class="row " >
                        <div class="col-1"></div>
                        <div class="col-11">
                            <div class="row mt-4">
                                <div class="col-2"><i class="bi bi-telephone-fill roundIcon" style="text-shadow: 1px 1px 2px black, 0 0 25px blue, 0 0 5px darkblue;" type="button"
                                                      title="Call"></i></div>
                                <div class="col-10 mt-1"><h6 class="fw-bolder" style= "color: white; "  type="button"
                                                             (click)="copyPhoneNo(currentFormData!.countryCodePhoneNo, formatNumber(currentFormData!.phoneNo))">
                                    {{currentFormData!.countryCodePhoneNo}} {{formatNumber(currentFormData!.phoneNo)}}</h6>
                                    
                                </div>
                            </div>
                           
                            <div class="row mt-3">
                                <div class="col-2"><i class="bi bi-geo-alt-fill roundIcon"style="text-shadow: 1px 1px 2px black, 0 0 25px blue, 0 0 5px darkblue;" type="button"
                                                      title="Location"></i></div>
                                <div class="col-10 mt-1"><h6 class="fw-bolder" style="color: white;" >{{currentFormData!.address}}</h6>
                                    </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-2"><i class="bi bi-globe2 roundIcon" style="text-shadow: 1px 1px 2px black, 0 0 25px blue, 0 0 5px darkblue;" type="button" title="Website"></i>
                                </div>
                                <div class="col-10 mt-1"><h6 class="fw-bolder" style="color: white;">
                                    <a class="text-white opacity-75 fw-bolder"
                                       href="http://{{currentFormData!.companyWebsiteName}}"
                                       target="_blank">{{currentFormData!.companyWebsiteName}}</a>
                                </h6>
                                
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-2"><i class="bi bi-envelope roundIcon" style="text-shadow: 1px 1px 2px black, 0 0 25px blue, 0 0 5px darkblue;" type="button" title="Mail"></i>
                                </div>
                                <div class="col-10 mt-1">
                                    <h6 class="fw-bolder" style="color: white;" ><a class="opacity-75 text-white fw-bolder"
                                                             href="mailto:{{currentFormData!.email}}"
                                                             target="_blank">{{currentFormData!.email}}</a>
                                    </h6>
                                    
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-2"><i title="WhatsApp" class="bi bi-whatsapp roundIcon  " style="text-shadow: 1px 1px 2px black, 0 0 25px blue, 0 0 5px darkblue;"></i>
                                </div>
                                <div class="col-10 mt-1">
                                    <h6 class="fw-bolder mt-1" type="button" style="margin-left: 1px" style="color: white;"  *ngIf="currentFormData!.whatsAppNoYn == 'Yes'"
                                        (click)="whatsApp(currentFormData!.countryCodePhoneNo, currentFormData!.phoneNo)">
                                        {{currentFormData!.countryCodePhoneNo}} {{formatNumber(currentFormData!.phoneNo)}}</h6>
                                        
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <hr class="text-center" style="border: 1px solid white"> -->
                </div>
            </div>
        </div>
    </div>
</section>
<ngx-spinner bdColor="rgba(255, 255, 255, 1)" size="large" color="#FFFFFF"
             [fullScreen]="true">
    <figure class="loader">
        <div class="dot white"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
    </figure>
</ngx-spinner>
<app-ecard-footer></app-ecard-footer>



