<section class="vh-100 d-flex" style="background-image: url(../../assets/img/backgroundImages/pxfuel.jpg)" *ngIf="profileShow">
    <div class="container-fluid py-2 justify-content-center align-items-center"
         style="text-align: -webkit-center;">
        <div>
            <div class="page-wrapper" id="home-section">
                <div class="page-details">
                    <div>
                        <div class="p-10"></div>
                        <div>
                            <img [src]="companySrcUrl" class="company mt-2" crossorigin="anonymous">
                        </div>

                        <h5 class="firmname fw-bolder mt-2">{{currentFormData!.companyName}}</h5>
                        <div  class="image">
                            <img [src]="profileSrcUrl" class="image"  crossorigin="anonymous">
                        </div>
                        <h4 class="name ">{{currentFormData!.firstName}} {{currentFormData!.lastName}}</h4>
                            <h6 class="designation">{{currentFormData!.designation}}</h6>
                            <h6 class="role">{{currentFormData!.role}}</h6>
                        <div style="width:80%;background-color:#b4ac9f;height:2px;margin-top: 15px;"></div>
                    </div>
                    <div>
                        <div class="p-10"></div>
                        <div class="contact-buttons mt-2">
                            <a class="contact-button"
                               href="tel:{{currentFormData!.countryCodePhoneNo}}{{currentFormData!.phoneNo}}">
                                <i class="fas fa-phone fa-flip-horizontal"></i>
                                Call
                            </a>                     
                            <a class="contact-button" target="_blank" href="mailto:{{currentFormData!.email}}">
                                <i class="fas fa-envelope fa-flip-horizontal"></i>
                                Mail
                            </a>
                        </div>
                        <div class="row ">
                            <div class="col-1"></div>
                            <div class="col-11">
                                <div class="row mt-2">
                                    <div class="col-2"><i class="bi bi-telephone roundIcon" type="button"
                                        title="Call"></i></div>
                                    <div class="col-9 mt-1"><h6 class="text-left" type="button" style=""
                                                                 (click)="copyPhoneNo(currentFormData!.countryCodePhoneNo, formatNumber(currentFormData!.phoneNo))">
                                        {{currentFormData!.countryCodePhoneNo}} {{formatNumber(currentFormData!.phoneNo)}}</h6>
                                    </div>
                                    
                                </div>
                                <div class="row mt-2">
                                    <div class="col-2"><i class="bi bi-geo-alt roundIcon" type="button"
                                        title="Location"></i>
                                    </div>
                                    <div class="col-9 mt-1"><h6 class="text-left" style="max-width:98%;">{{currentFormData!.address}}</h6></div>                                    
                                </div>
                                <div class="row mt-2">
                                    <div class="col-2"><i class="bi bi-globe2 roundIcon" type="button" title="Website"></i></div>
                                    <div class="col-9 mt-1 text-left"><h6>
                                        <a class="text-dark" style="text-decoration: none; word-break: break-all;"
                                           href="http://{{currentFormData!.companyWebsiteName}}"
                                           target="_blank">{{currentFormData!.companyWebsiteName}}</a>
                                    </h6>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-2"><i class="bi bi-envelope roundIcon" type="button" title="Mail"></i></div>
                                    <div class="col-9 mt-1">
                                        <h6 class="text-left"><a class="opacity-75 text-dark" style="line-break: anywhere;text-decoration: none;"
                                                                 href="mailto:{{currentFormData!.email}}"
                                                                 target="_blank">{{currentFormData!.email}}</a>
                                        </h6>
                                    </div>
    
                                </div>
                                <div class="row mt-2">
                                    <div class="col-2"><i title="WhatsApp" class="bi bi-whatsapp roundIcon fs-6"></i>
                                    </div>
                                    <div class="col-9 mt-2">
                                        <h6 class="text-left" type="button" style="margin-left: 1px;"
                                            (click)="whatsApp(currentFormData!.countryCodeWhatsAppNo, currentFormData!.whatsAppNumber)">
                                            {{currentFormData!.countryCodeWhatsAppNo}} {{formatNumber(currentFormData!.whatsAppNumber)}}</h6>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="lastline">
                            <a class="contact" target="_blank"
                               (click)="whatsApp(currentFormData!.countryCodeWhatsAppNo, currentFormData!.whatsAppNumber)">
                                <i class="fab fa-whatsapp fs-6"></i><b class="ml-2">WhatsApp</b>
                            </a>
                            <div class="row "></div>
                        </div>
                        <div class="p-30"></div>
                        <div class="p-30 mt-4"></div>
                        <ul class="inprofile share-buttons">
                        </ul>
                        <div class="p-20"></div>
                    </div>
                </div>
                <div class="p-30 py-5"></div>
            </div>
        </div>
    </div>
</section>












<section class="vh-100 d-flex" style="background-image: url(../../assets/img/backgroundImages/pxfuel.jpg)" *ngIf="profilehide">
    <div class="container-fluid py-2 justify-content-center align-items-center"
         style="text-align: -webkit-center;">
        <div>
            <div class="page-wrapper" id="home-section">
                <div class="page-details">
                    <div>
                        <div class="p-10"></div>
                        <div>
                            <img [src]="companySrcUrl" class="company mt-3" crossorigin="anonymous">
                        </div>

                        <h5 class="firmname fw-bolder mt-3">{{currentFormData!.companyName}}</h5>
                       
                        <div class="mt-2" style="border-top:2px solid;"></div>

                        <h4 class="name mt-2">{{currentFormData!.firstName}} {{currentFormData!.lastName}}</h4>
                            <h6 class="designation">{{currentFormData!.designation}}</h6>
                            <h6 class="role">{{currentFormData!.role}}</h6>
                        <div style="width:80%;background-color:#b4ac9f;height:2px;margin-top: 15px;"></div>
                    </div>
                    <div>
                        <div class="p-10"></div>
                        <div class="contact-buttons mt-2">
                            <a class="contact-button"
                               href="tel:{{currentFormData!.countryCodePhoneNo}}{{currentFormData!.phoneNo}}">
                                <i class="fas fa-phone fa-flip-horizontal"></i>
                                Call
                            </a>                     
                            <a class="contact-button" target="_blank" href="mailto:{{currentFormData!.email}}">
                                <i class="fas fa-envelope fa-flip-horizontal"></i>
                                Mail
                            </a>
                        </div>
                        <div class="row ">
                            <div class="col-1"></div>
                            <div class="col-11">
                                <div class="row mt-2">
                                    <div class="col-2"><i class="bi bi-telephone roundIcon" type="button"
                                        title="Call"></i></div>
                                    <div class="col-9 mt-1"><h6 class="text-left" type="button" style=""
                                                                 (click)="copyPhoneNo(currentFormData!.countryCodePhoneNo, formatNumber(currentFormData!.phoneNo))">
                                        {{currentFormData!.countryCodePhoneNo}} {{formatNumber(currentFormData!.phoneNo)}}</h6>
                                    </div>
                                    
                                </div>
                                <div class="row mt-2">
                                    <div class="col-2"><i class="bi bi-geo-alt roundIcon" type="button"
                                        title="Location"></i>
                                    </div>
                                    <div class="col-9 mt-1"><h6 class="text-left" style="max-width:98%;">{{currentFormData!.address}}</h6></div>                                    
                                </div>
                                <div class="row mt-2">
                                    <div class="col-2"><i class="bi bi-globe2 roundIcon" type="button" title="Website"></i></div>
                                    <div class="col-9 mt-1 text-left"><h6>
                                        <a class="text-dark" style="text-decoration: none; word-break: break-all;"
                                           href="http://{{currentFormData!.companyWebsiteName}}"
                                           target="_blank">{{currentFormData!.companyWebsiteName}}</a>
                                    </h6>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-2"><i class="bi bi-envelope roundIcon" type="button" title="Mail"></i></div>
                                    <div class="col-9 mt-1">
                                        <h6 class="text-left"><a class="opacity-75 text-dark" style="line-break: anywhere;text-decoration: none;"
                                                                 href="mailto:{{currentFormData!.email}}"
                                                                 target="_blank">{{currentFormData!.email}}</a>
                                        </h6>
                                    </div>
    
                                </div>
                                <div class="row mt-2">
                                    <div class="col-2"><i title="WhatsApp" class="bi bi-whatsapp roundIcon fs-6"></i>
                                    </div>
                                    <div class="col-9 mt-2">
                                        <h6 class="text-left" type="button" style="margin-left: 1px;"
                                            (click)="whatsApp(currentFormData!.countryCodeWhatsAppNo, currentFormData!.whatsAppNumber)">
                                            {{currentFormData!.countryCodeWhatsAppNo}} {{formatNumber(currentFormData!.whatsAppNumber)}}</h6>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="lastline">
                            <a class="contact" target="_blank"
                               (click)="whatsApp(currentFormData!.countryCodeWhatsAppNo, currentFormData!.whatsAppNumber)">
                                <i class="fab fa-whatsapp fs-6 mb-2"></i><b class="ml-2">WhatsApp</b>
                            </a>
                            <div class="row "></div>
                        </div>
                        <div class="p-30"></div>
                        <div class="p-30 mt-4"></div>
                        <ul class="inprofile share-buttons">
                        </ul>
                        <div class="p-20"></div>
                    </div>
                </div>
                <div class="p-30 py-5"></div>
            </div>
        </div>
    </div>
</section>
















<ngx-spinner bdColor="rgba(255, 255, 255, 1)" size="large" color="#FFFFFF"
             [fullScreen]="true">
    <figure class="loader">
        <div class="dot white"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
    </figure>
</ngx-spinner>
<app-ecard-footer></app-ecard-footer>
