<section class="vh-100" style="background-color: rgb(254 242 242)" *ngIf="profileShow">
    <div class="container-fluid py-2 ">
        <div class="container">
            <div class="row">
                <div class="col-md-12 text-center mt-2">
                    <img class="companyProfile" [src]="companySrcUrl">
                </div>
            </div>
            <div class="row mt-1" style="border-bottom: 2px solid rgb(64, 51, 85);">
                <div class="col-12 justify-content-center">
                    <h4 class="company">{{currentFormData!.companyName}}</h4>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 mt-2 text-center">
                    <img class="Profile" [src]="profileSrcUrl">
                </div>
            </div>
            <div class="row">
                <div class="col-12 mt-2 text-center">
                    <h3 class="fullname">{{currentFormData!.firstName}} {{currentFormData!.lastName}}</h3>
                    <h6 class="designation">{{currentFormData!.designation}} <br> {{currentFormData!.role}}</h6>
                    <span class="role mb-5"></span>
                </div>
            </div>



            <div class="row" style="margin-top:50px">
                <div class="col-1"></div>
                <div class="col-11">
                    <div class="row mt-1">
                        <div class="col-2"><i class="bi bi-telephone-fill roundIcon" type="button" title="Call"></i></div>
                        <div class="col-10 mt-1"><h6 class="userDetails" (click)="copyPhoneNo(currentFormData!.countryCodePhoneNo, formatNumber(currentFormData!.phoneNo))">
                            {{currentFormData!.countryCodePhoneNo}} {{formatNumber(currentFormData!.phoneNo)}}</h6></div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-2">
                        <a class="roundIcon" target="_blank"href="https://www.google.com/maps?q={{currentFormData!.address}}">
                            <i class="bi bi-geo-alt-fill"></i>
                        </a>
                        </div>
                        <div class="col-10 mt-1"><h6 class="userDetails" style="">{{currentFormData!.address}}</h6></div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-2"><i class="bi bi-globe-central-south-asia roundIcon" type="button" title="Website"></i></div>
                        <div class="col-10 mt-1"><h6 class="" >
                            <a class="userDetails" style="line-break: anywhere;text-decoration: none;"
                            href="http://{{currentFormData!.companyWebsiteName}}" target="_blank">{{currentFormData!.companyWebsiteName}}</a>
                        </h6>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-2"><i class="bi bi-envelope-fill roundIcon" type="button" title="Mail"></i></div>
                        <div class="col-10 mt-1">
                            <h6 class="" ><a class="userDetails" style="line-break: anywhere;text-decoration: none;"
                                                     href="mailto:{{currentFormData!.email}}"
                                                     target="_blank">{{currentFormData!.email}}</a>
                            </h6>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-2"><i title="WhatsApp" class="bi bi-whatsapp roundIcon"></i>
                        </div>
                        <div class="col-10">
                            <h6 class="mt-1 userDetails" type="button" style="margin-left: 1px;"
                                (click)="whatsApp(currentFormData!.countryCodeWhatsAppNo, currentFormData!.whatsAppNumber)">
                                {{currentFormData!.countryCodeWhatsAppNo}} {{formatNumber(currentFormData!.whatsAppNumber)}}</h6>
                        </div>
                    </div>
                  <div class="p py-2"></div>
                </div>
            </div>

<!--            <div class="row">-->
<!--                <div class="col-3"></div>-->
<!--                <div class="col-8 contact-buttons">-->
<!--                    <a class="contact-button"-->
<!--                       href="tel:{{currentFormData!.countryCodePhoneNo}}{{currentFormData!.phoneNo}}">-->
<!--                        <i class="fas fa-phone fa-flip-horizontal" style="color: #000000"></i>-->
<!--                    </a>-->
<!--                    <a class="contact-button" target="_blank"-->
<!--                       (click)="whatsApp(currentFormData!.countryCodeWhatsAppNo, currentFormData!.whatsAppNumber)">-->
<!--                        <i class="fab fa-whatsapp" style="color: rgb(0, 0, 0);"></i>-->
<!--                    </a>-->
<!--                    <a class="contact-button" target="_blank" href="mailto:{{currentFormData!.email}}">-->
<!--                        <i class="fas fa-envelope fa-flip-horizontal" style="color: #000000"></i>-->
<!--                    </a>-->
<!--                </div>-->
<!--                <div class="col-1"></div>-->
<!--            </div>-->
        </div>
    </div>
</section>










<section class="vh-100" style="background-color: rgb(254 242 242)"   *ngIf="profilehide">
  <div class="container-fluid py-2 ">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center mt-4">
          <img class="companylogo" [src]="companySrcUrl">
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12 justify-content-center">
          <h4 class="company mt-1"><b>{{currentFormData!.companyName}}</b></h4>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-12 text-center">
        </div>
      </div>
      <div class="row mt-2" style="border-top:2px solid black"></div>
      <div class="row mt-2">
        <div class="col-12 text-center">
          <h3 class="fullname"><b>{{currentFormData!.firstName}} {{currentFormData!.lastName}}</b></h3>
          <h6 class="designation">{{currentFormData!.designation}}<br>{{currentFormData!.role}} </h6>
<!--          <h6 class="role"></h6>-->
        </div>
      </div>
      <div class="row" style="border-bottom:2px solid black"></div>
      <div class="row" style="margin-top:47px">
        <div class="col-1"></div>
        <div class="col-11">
          <div class="row mt-1">
            <div class="col-2"><i class="bi bi-telephone-fill roundIcon" type="button" title="Call"></i></div>
            <div class="col-10 mt-1"><h6 class="" style="color: #bfbfbf;" (click)="copyPhoneNo(currentFormData!.countryCodePhoneNo, formatNumber(currentFormData!.phoneNo))">
              {{currentFormData!.countryCodePhoneNo}} {{formatNumber(currentFormData!.phoneNo)}}</h6></div>
          </div>
          <div class="row mt-2">
            <div class="col-2">
              <a class="roundIcon" target="_blank"href="https://www.google.com/maps?q={{currentFormData!.address}}">
                <i class="bi bi-geo-alt-fill"></i>
              </a>
            </div>
            <div class="col-10 mt-1"><h6 class="" style="color: #bfbfbf;">{{currentFormData!.address}}</h6></div>
          </div>
          <div class="row mt-2">
            <div class="col-2"><i class="bi bi-globe-central-south-asia roundIcon" type="button" title="Website"></i></div>
            <div class="col-10 mt-1"><h6 class="" >
              <a class="" style="color: #bfbfbf;line-break: anywhere;text-decoration: none;"
                 href="http://{{currentFormData!.companyWebsiteName}}" target="_blank">{{currentFormData!.companyWebsiteName}}</a>
            </h6>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-2"><i class="bi bi-envelope-fill roundIcon" type="button" title="Mail"></i></div>
            <div class="col-10 mt-1">
              <h6 class="" ><a class="" style="color: #bfbfbf;line-break: anywhere;text-decoration: none;"
                               href="mailto:{{currentFormData!.email}}"
                               target="_blank">{{currentFormData!.email}}</a>
              </h6>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-2"><i title="WhatsApp" class="bi bi-whatsapp roundIcon"></i>
            </div>
            <div class="col-10">
              <h6 class="mt-1" type="button" style="margin-left: 1px;color: #bfbfbf;"
                  (click)="whatsApp(currentFormData!.countryCodeWhatsAppNo, currentFormData!.whatsAppNumber)">
                {{currentFormData!.countryCodeWhatsAppNo}} {{formatNumber(currentFormData!.whatsAppNumber)}}</h6>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-3"></div>
        <div class="col-8 contact-buttons">
          <a class="contact-button"
             href="tel:{{currentFormData!.countryCodePhoneNo}}{{currentFormData!.phoneNo}}">
            <i class="fas fa-phone fa-flip-horizontal" style="color: #000000"></i>
          </a>
          <a class="contact-button" target="_blank"
             (click)="whatsApp(currentFormData!.countryCodeWhatsAppNo, currentFormData!.whatsAppNumber)">
            <i class="fab fa-whatsapp" style="color: rgb(0, 0, 0);"></i>
          </a>
          <a class="contact-button" target="_blank" href="mailto:{{currentFormData!.email}}">
            <i class="fas fa-envelope fa-flip-horizontal" style="color: #000000"></i>
          </a>
        </div>
        <div class="col-1"></div>
      </div>
    </div>
  </div>
</section>
















<div class="row-mt-5"></div>
<ngx-spinner bdColor="rgba(255, 255, 255, 1)" size="large" color="#FFFFFF"
             [fullScreen]="true">
    <figure class="loader">
        <div class="dot white"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
    </figure>
</ngx-spinner>
<app-ecard-footer></app-ecard-footer>
