<section class="py-2 bg-light"  *ngIf="profileShow">
    <div class="container-fluid">
        <div class="container">
            <div class="box">

                <div class="row">
                    <div class="col-md-12 mt-2 text-center">
                        <img class="companyProfile" [src]="companySrcUrl" alt="Company Logo">
                    </div>
                </div>

                <div class="row">
                    <div class="col-1"></div>
                    <div class="col-md-9 ms-1 mt-3 text-center" style="color: rgb(39, 76, 119);word-wrap: break-word;
                    max-width: 75%">
                        <h5 class=""><b>{{currentFormData!.companyName}}</b></h5>
                    </div>
                    <div class="col-2"></div>
                </div>
                <div class="boxContent">

                    <div class="row">
                        <div class="col-md-12 text-center">
                            <img [src]="profilrSrcUrl" class="employeeProfile mt-1" alt="Employee Avatar">
                        </div>
                    </div>

                    <div class="row mt-1">
                        <div class="col-12 text-center">
                            <h4 class="fullName">{{currentFormData!.firstName}} {{currentFormData!.lastName}}</h4>
                            <h6 class="designation">{{currentFormData!.designation}}</h6>
                            <h6 class="role">{{currentFormData!.role}}</h6>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-1"></div>
                        <div class="col-11">
                            <div class="row mt-2">
                                <div class="col-2"><i class="bi bi-telephone-fill roundIcon"></i>
                                </div>
                                <div class="col-10 mt-1">
                                    <div class="call">
                                        <h6 (click)="copyPhoneNo(currentFormData!.countryCodePhoneNo, formatNumber(currentFormData!.phoneNo))">
                                            {{currentFormData!.countryCodePhoneNo}} {{formatNumber(currentFormData!.phoneNo)}}
                                        </h6>
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-3">
                                <div class="col-2">
                                    <i class="bi bi-pin-map-fill roundIcon"></i>
                                </div>
                                <div class="col-9 mt-1">
                                    <div class="address">
                                        <h6>{{currentFormData!.address}}</h6>
                                    </div>
                                </div>
                                <div class="col-1"></div>
                            </div>

                            <div class="row mt-3">
                                <div class="col-2">
                                    <i class="bi bi-globe-central-south-asia roundIcon"></i>
                                </div>
                                <div class="col-10 mt-1">
                                    <div class="website">
                                        <h6>
                                            <a class="opacity-75" style="text-decoration: none;color: rgb(39, 76, 119)"
                                               href="http://{{currentFormData!.companyWebsiteName}}" target="_blank">
                                                {{currentFormData!.companyWebsiteName}}
                                            </a>
                                        </h6>
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-3">
                                <div class="col-2">
                                    <i class="bi bi-envelope-at-fill roundIcon"></i>
                                </div>
                                <div class="col-10 mt-1">
                                    <div class="email">
                                        <h6 class="">
                                            <a class="opacity-75" style="text-decoration: none;color: rgb(39, 76, 119)"
                                               href="mailto:{{currentFormData!.email}}" target="_blank">
                                                {{currentFormData!.email}}
                                            </a>
                                        </h6>
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-3">
                                <div class="col-2">
                                    <i class="bi bi-whatsapp roundIcon"></i>
                                </div>
                                <div class="col-10 mt-1">
                                    <div class="whatsapp">
                                        <h6 class=" mt-1" type="button"
                                            (click)="whatsApp(currentFormData!.countryCodeWhatsAppNo, currentFormData!.whatsAppNumber)">
                                            {{currentFormData!.countryCodeWhatsAppNo}} {{formatNumber(currentFormData!.whatsAppNumber)}}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-3"></div>
    <div class="row mt-3"></div>
</section>
















<section class="py-2 bg-light"  *ngIf="profilehide">
    <div class="container-fluid">
        <div class="container">
            <div class="box">

                <div class="row">
                    <div class="col-md-12 mt-4 text-center">
                        <img class="companyProfile" [src]="companySrcUrl" alt="Company Logo">
                    </div>
                </div>

                <div class="row mt-2">
                    <div class="col-1"></div>
                    <div class="col-md-9 ms-1 mt-3 text-center" style="color: rgb(39, 76, 119);word-wrap: break-word;
                    max-width: 75%">
                        <h5 class=""><b>{{currentFormData!.companyName}}</b></h5>
                    </div>
                    <div class="col-2"></div>
                </div>
                <div class="boxContent">

                    <div class="row">
                        <div class="col-md-12 text-center">
                        </div>
                    </div>

                    <hr class="bg-warning text-center" style="max-width:60%;margin-left:52px;">

                    <div class="row mt-3">
                        <div class="col-12 text-center">
                            <h4 class="fullName">{{currentFormData!.firstName}} {{currentFormData!.lastName}}</h4>
                            <h6 class="designation">{{currentFormData!.designation}}</h6>
                            <h6 class="role">{{currentFormData!.role}}</h6>
                        </div>
                    </div>

                    <div class="row mt-2">
                        <div class="col-1"></div>
                        <div class="col-11">
                            <div class="row mt-2">
                                <div class="col-2"><i class="bi bi-telephone-fill roundIcon"></i>
                                </div>
                                <div class="col-10 mt-1">
                                    <div class="call">
                                        <h6 (click)="copyPhoneNo(currentFormData!.countryCodePhoneNo, formatNumber(currentFormData!.phoneNo))">
                                            {{currentFormData!.countryCodePhoneNo}} {{formatNumber(currentFormData!.phoneNo)}}
                                        </h6>
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-3">
                                <div class="col-2">
                                    <i class="bi bi-pin-map-fill roundIcon"></i>
                                </div>
                                <div class="col-9 mt-1">
                                    <div class="address">
                                        <h6>{{currentFormData!.address}}</h6>
                                    </div>
                                </div>
                                <div class="col-1"></div>
                            </div>

                            <div class="row mt-3">
                                <div class="col-2">
                                    <i class="bi bi-globe-central-south-asia roundIcon"></i>
                                </div>
                                <div class="col-10 mt-1">
                                    <div class="website">
                                        <h6>
                                            <a class="opacity-75" style="text-decoration: none;color: rgb(39, 76, 119)"
                                               href="http://{{currentFormData!.companyWebsiteName}}" target="_blank">
                                                {{currentFormData!.companyWebsiteName}}
                                            </a>
                                        </h6>
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-3">
                                <div class="col-2">
                                    <i class="bi bi-envelope-at-fill roundIcon"></i>
                                </div>
                                <div class="col-10 mt-1">
                                    <div class="email">
                                        <h6 class="">
                                            <a class="opacity-75" style="text-decoration: none;color: rgb(39, 76, 119)"
                                               href="mailto:{{currentFormData!.email}}" target="_blank">
                                                {{currentFormData!.email}}
                                            </a>
                                        </h6>
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-3">
                                <div class="col-2">
                                    <i class="bi bi-whatsapp roundIcon"></i>
                                </div>
                                <div class="col-10 mt-1">
                                    <div class="whatsapp">
                                        <h6 class=" mt-1" type="button"
                                            (click)="whatsApp(currentFormData!.countryCodeWhatsAppNo, currentFormData!.whatsAppNumber)">
                                            {{currentFormData!.countryCodeWhatsAppNo}} {{formatNumber(currentFormData!.whatsAppNumber)}}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-3"></div>
    <div class="row mt-3"></div>
</section>
















<ngx-spinner bdColor="rgba(255, 255, 255, 1)" size="large" color="#FFFFFF" [fullScreen]="true">
    <figure class="loader">
        <div class="dot white"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
    </figure>
</ngx-spinner>
<app-ecard-footer></app-ecard-footer>