<section class="vh-100" style="background-color: rgb(254 242 242)"  *ngIf="profileShow">
    <div class="container-fluid py-2">
        <div class="container">
            <div class="row ">
                <div class="col-md-12 mt-4 text-center">
                    <img class="companyProfile" [src]="companySrcUrl">
                </div>
            </div>

            <div class="row mt-1">
                <div class="col-12 justify-content-center">
                    <h4 class="text-center" style="margin-left: 10px; color: rgb(0, 0, 0);"><b>{{currentFormData!.companyName}}</b></h4>
                </div>
            </div>
            <div class="row  ml-1 mr-1" style="background-color:  #50b9b9;">
                <div class="col-3 mt-1"></div>
            </div>           

            <div class="row mt-2">

                    <div class="row">
                        <div class="col-md-12 text-center">
                            <img class="Profile" [src]="profileSrcUrl">
                        </div>
                    </div>

                    <div class="row ">
                        <div class="col-12 text-center">
                            <h3 class="" style="color: rgb(0, 0, 0);">{{currentFormData!.firstName}} {{currentFormData!.lastName}}</h3>
                            <h6 class="designation" style="color: rgb(0, 0, 0);">{{currentFormData!.designation}}</h6>
                            <h6 class="text-center" style="color: rgb(0, 0, 0);">{{currentFormData!.role}}</h6>
                        </div>
                    </div>
                    
            </div>

            <div class="row  ml-1 mr-1" style="background-color:  #50b9b9;">
                <div class="col-3 mt-1"></div>
            </div>

            <div class="row ml-1 mt-1">

                <div class="col-10">
                    <div class="row mt-2">
                        <div class="col-2"><i class="bi bi-telephone-fill roundIcon" type="button" title="Call"></i></div>
                        <div class="col-10 mt-1"><h6 class="" style="color: rgb(0, 0, 0);" (click)="copyPhoneNo(currentFormData!.countryCodePhoneNo, formatNumber(currentFormData!.phoneNo))">
                            {{currentFormData!.countryCodePhoneNo}} {{formatNumber(currentFormData!.phoneNo)}}</h6></div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-2"><i class="bi bi-geo-alt-fill roundIcon" type="button" title="Location"></i></div>
                        <div class="col-10 mt-1"><h6 class="" style="color: rgb(0, 0, 0); word-wrap: break-word;">{{currentFormData!.address}}</h6></div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-2"><i class="bi bi-globe2 roundIcon" type="button" title="Website"></i></div>
                        <div class="col-10 mt-1"><h6 class="">
                            <a class="" style="color: rgb(0, 0, 0); word-wrap: break-word; text-decoration: none;"
                             href="http://{{currentFormData!.companyWebsiteName}}" target="_blank">{{currentFormData!.companyWebsiteName}}</a>
                        </h6>
                        </div>
                    </div>


                    <div class="row mt-3">
                        <div class="col-2"><i class="bi bi-envelope-fill roundIcon" type="button" title="Mail"></i></div>
                        <div class="col-10 mt-1">
                            <h6 class=""><a class="" style="color: rgb(0, 0, 0);text-decoration: none;word-break: break-all;"
                             href="mailto:{{currentFormData!.email}}" target="_blank">{{currentFormData!.email}}</a>
                            </h6>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-2"><i title="WhatsApp" class="bi bi-whatsapp roundIcon"></i>
                        </div>
                        <div class="col-10">
                            <h6 class="mt-1" type="button" style="margin-left: 1px;color: rgb(0, 0, 0);"
                                (click)="whatsApp(currentFormData!.countryCodeWhatsAppNo, currentFormData!.whatsAppNumber)">
                                {{currentFormData!.countryCodeWhatsAppNo}} {{formatNumber(currentFormData!.whatsAppNumber)}}</h6>
                        </div>
                    </div>
                </div>
                <div class="col-1"></div>
            </div>
            <div class="p-40 py-2 mt-2"></div>
        </div>
    </div>
</section>





















<section class="vh-100" style="background-color: rgb(254 242 242)"  *ngIf="profilehide">
    <div class="container-fluid py-2">
        <div class="container">
            <div class="row ">
                <div class="col-md-12 mt-4 text-center">
                    <img class="companyProfile" [src]="companySrcUrl">
                </div>
            </div>

            <div class="row mt-1">
                <div class="col-12 justify-content-center">
                    <h4 class="text-center" style="margin-left: 10px; color: rgb(0, 0, 0);"><b>{{currentFormData!.companyName}}</b></h4>
                </div>
            </div>
            <div class="row  ml-1 mr-1" style="background-color:  #50b9b9;">
                <div class="col-3 mt-1"></div>
            </div>           

            <div class="row mt-2">

                    <div class="row">
                        <div class="col-md-12 text-center">
                        </div>
                    </div>

                    <div class="row mt-2">
                        <div class=" text-center">
                            <h3 class="" style="color: rgb(0, 0, 0);">{{currentFormData!.firstName}} {{currentFormData!.lastName}}</h3>
                            <h6 class="designation" style="color: rgb(0, 0, 0);">{{currentFormData!.designation}}</h6>
                            <h6 class="text-center" style="color: rgb(0, 0, 0);">{{currentFormData!.role}}</h6>
                        </div>
                    </div>
                    
            </div>

            <div class="row mt-2 ml-1 mr-1" style="background-color:  #50b9b9;">
                <div class="col-3 mt-1"></div>
            </div>

            <div class="row ml-1 mt-1">

                <div class="col-10">
                    <div class="row mt-2">
                        <div class="col-2"><i class="bi bi-telephone-fill roundIcon" type="button" title="Call"></i></div>
                        <div class="col-10 mt-1"><h6 class="" style="color: rgb(0, 0, 0);" (click)="copyPhoneNo(currentFormData!.countryCodePhoneNo, formatNumber(currentFormData!.phoneNo))">
                            {{currentFormData!.countryCodePhoneNo}} {{formatNumber(currentFormData!.phoneNo)}}</h6></div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-2"><i class="bi bi-geo-alt-fill roundIcon" type="button" title="Location"></i></div>
                        <div class="col-10 mt-1"><h6 class="" style="color: rgb(0, 0, 0); word-wrap: break-word;">{{currentFormData!.address}}</h6></div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-2"><i class="bi bi-globe2 roundIcon" type="button" title="Website"></i></div>
                        <div class="col-10 mt-1"><h6 class="">
                            <a class="" style="color: rgb(0, 0, 0); word-wrap: break-word; text-decoration: none;"
                             href="http://{{currentFormData!.companyWebsiteName}}" target="_blank">{{currentFormData!.companyWebsiteName}}</a>
                        </h6>
                        </div>
                    </div>


                    <div class="row mt-3">
                        <div class="col-2"><i class="bi bi-envelope-fill roundIcon" type="button" title="Mail"></i></div>
                        <div class="col-10 mt-1">
                            <h6 class=""><a class="" style="color: rgb(0, 0, 0);text-decoration: none;word-break: break-all;"
                             href="mailto:{{currentFormData!.email}}" target="_blank">{{currentFormData!.email}}</a>
                            </h6>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-2"><i title="WhatsApp" class="bi bi-whatsapp roundIcon"></i>
                        </div>
                        <div class="col-10">
                            <h6 class="mt-1" type="button" style="margin-left: 1px;color: rgb(0, 0, 0);"
                                (click)="whatsApp(currentFormData!.countryCodeWhatsAppNo, currentFormData!.whatsAppNumber)">
                                {{currentFormData!.countryCodeWhatsAppNo}} {{formatNumber(currentFormData!.whatsAppNumber)}}</h6>
                        </div>
                    </div>
                </div>
                <div class="col-1"></div>
            </div>
            <div class="p-40 py-2 mt-2"></div>
        </div>
    </div>
</section>




















<ngx-spinner bdColor="rgba(255, 255, 255, 1)" size="large" color="#FFFFFF"
             [fullScreen]="true">
    <figure class="loader">
        <div class="dot white"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
    </figure>
</ngx-spinner>
<app-ecard-footer></app-ecard-footer>
