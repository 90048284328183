<!-- <div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1 class="m-0 text-dark">Dashboard</h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item active">Dashboard v1</li>
        </ol>
      </div>
    </div>
  </div>
</div> -->
<!-- /.content-header -->

<!-- Main content -->
<!-- <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="row">
          <div class="col">
          <button>
                 <a class="nav-link" href="#">Home</a>
                </button>
<button>
                  <a class="nav-link" href="#">About</a>
                </button>
                <button>
                  <a class="nav-link" href="#">Services</a>
                </button>
                <button>
                  <a class="nav-link" href="#">Contact</a>
                </button>
            </div>
          </div>
</nav> -->

<!-- <div class="scrollmenu" #scrollContainer style="position: fixed;">
      <button type="button" class="btn btn-light ms-1">Business</button>
      <button type="button" class="btn btn-light ms-1">Education</button>
      <button type="button" class="btn btn-light ms-1">Medical</button>
      <button type="button" class="btn btn-light ms-1">Photography</button>
      <button type="button" class="btn btn-light ms-1">Music</button>
      <button type="button" class="btn btn-light ms-1">Lawyer</button>
      <button type="button" class="btn btn-light ms-1">Movie</button>
      <button type="button" class="btn btn-light ms-1">Politician</button>
      <button type="button" class="btn btn-light ms-1">Restaurants</button>
      <button type="button" class="btn btn-light ms-1">Beautician</button>
      <button type="button" class="btn btn-light ms-1">Corporate</button>
      <button type="button" class="btn btn-light ms-1">Jewellery Business</button>
      <button type="button" class="btn btn-light ms-1">Freelancers</button>
      <button type="button" class="btn btn-light ms-1">Construction</button>
      <button type="button" class="btn btn-light ms-1">Construction</button>
      <button type="button" class="btn btn-light ms-1">Construction</button>
      <button type="button" class="btn btn-light ms-1">Construction</button>
      <button type="button" class="btn btn-light ms-1">Construction</button>
      <button type="button" class="btn btn-light ms-1">Construction</button>
      <button type="button" class="btn btn-light ms-1">Construction</button>
      <button type="button" class="btn btn-light ms-1">Construction</button>
      <button type="button" class="btn btn-light ms-1">Construction</button>
</div> -->


<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2 col-sm-6">
            <img src="../../../assets/img/home-button.png" style="height:40px; width: 60px;">
            <div class="col-sm-6">
                <h1 class="m-0 text-dark page-header">Dashboard</h1>
            </div>
        </div>
    </div>
</div>
<!-- <div class="container-fluid">

    <div class="row mt-3">
      <div class="col-lg-3 col-6">

        <div class="small-box bg-info" (click)="addCard()">
          <div class="inner">
            <h3>150</h3>

            <p>New Orders</p>
          </div>
          <div class="icon">
            <i class="ion ion-bag"></i>
          </div>
          <a class="small-box-footer" href="#">More info <i class="fas fa-arrow-circle-right"></i></a>
        </div>
      </div>

      <div class="col-lg-3 col-6">

        <div class="small-box bg-success">
          <div class="inner">
            <h3>53<sup style="font-size: 20px">%</sup></h3>

            <p>Bounce Rate</p>
          </div>
          <div class="icon">
            <i class="ion ion-stats-bars"></i>
          </div>
          <a class="small-box-footer" href="#">More info <i class="fas fa-arrow-circle-right"></i></a>
        </div>
      </div>

      <div class="col-lg-3 col-6">

        <div class="small-box bg-warning">
          <div class="inner">
            <h3>44</h3>

            <p>User Registrations</p>
          </div>
          <div class="icon">
            <i class="ion ion-person-add"></i>
          </div>
          <a class="small-box-footer" href="#">More info <i class="fas fa-arrow-circle-right"></i></a>
        </div>
      </div>

      <div class="col-lg-3 col-6">

        <div class="small-box bg-danger">
          <div class="inner">
            <h3>65</h3>

            <p>Unique Visitors</p>
          </div>
          <div class="icon">
            <i class="ion ion-pie-graph"></i>
          </div>
          <a class="small-box-footer" href="#">More info <i class="fas fa-arrow-circle-right"></i></a>
        </div>
      </div>

    </div>

  </div> -->

<div class="container-fluid">

    <div class="row" *ngIf="vssp">
        <div class="col-md-2">
            <div class="card" (click)="vsspltemplate('traceFarm')">
                <img class="w-100" alt="avatar2" alt="Responsive image"
                    src="../../../assets/img/vssplTemplate/TraceFarmTemplate.png" />
            </div>
        </div>
        <div class="col-md-2">
            <div class="card" (click)="vsspltemplate('vsspl')">
                <img class="w-100" alt="avatar2" alt="Responsive image"
                    src="../../../assets/img/vssplTemplate/vssplTemplate.png" />
            </div>
        </div>
        <div class="col-md-2">
            <div class="card" (click)="vsspltemplate('mssi')">
                <img class="w-100" alt="avatar2" alt="Responsive image"
                    src="../../../assets/img/vssplTemplate/mssi.png" />
            </div>
        </div>
    </div>

    <div class="row" *ngIf="novsspl">
        <div class="col-md-2">
            <div class="card" (click)="getPreviewType('previewOne')">
                <img class="w-100" alt="avatar2" alt="Responsive image"
                    src="../../../assets/img/previewImages/previewtwo.jpg" />
            </div>
        </div>
        <div class="col-md-2">
            <div class="card" (click)="getPreviewType('previewTwo')">
                <img class="w-100" alt="avatar2" alt="Responsive image"
                    src="../../../assets/img/previewImages/previewone.png" />
            </div>
        </div>
        <div class="col-md-2">
            <div class="card" (click)="getPreviewType('previewThree')">
                <img class="w-100" alt="avatar2" alt="Responsive image"
                    src="../../../assets/img/previewImages/preview3.png" />
            </div>
        </div>
        <div class="col-md-2">
            <div class="card" (click)="getPreviewType('previewFour')">
                <img class="w-100" alt="avatar2" alt="Responsive image"
                    src="../../../assets/img/previewImages/previewFour.png" />
            </div>
        </div>
        <div class="col-md-2">
            <div class="card" (click)="getPreviewType('previewFive')">
                <img class="w-100" alt="avatar2" alt="Responsive image"
                    src="../../../assets/img/previewImages/previewFive.png" />
            </div>
        </div>
        <div class="col-md-2">
            <div class="card" (click)="getPreviewType('previewSix')">
                <img class="w-100" alt="avatar2" alt="Responsive image"
                    src="../../../assets/img/previewImages/previewSix.png" />
            </div>
        </div>
    </div>
    <div class="row" *ngIf="novsspl">
        <div class="col-md-2">
            <div class="card" (click)="getPreviewType('previewSeven')">
                <img class="w-100" alt="avatar2" alt="Responsive image"
                    src="../../../assets/img/previewImages/preview7.png" />
            </div>
        </div>
        <div class="col-md-2">
            <div class="card" (click)="getPreviewType('previewEight')">
                <img class="w-100" alt="avatar2" alt="Responsive image"
                    src="../../../assets/img/previewImages/preview8.png" />
            </div>
        </div>
        <div class="col-md-2">
            <div class="card" (click)="getPreviewType('previewNine')">
                <img class="w-100" alt="avatar2" alt="Responsive image"
                    src="../../../assets/img/previewImages/preview9.png" />
            </div>
        </div>
        <div class="col-md-2">
            <div class="card" (click)="getPreviewType('previewTen')">
                <img class="w-100" alt="avatar2" alt="Responsive image"
                    src="../../../assets/img/previewImages/preview10.png" />
            </div>
        </div>
        <div class="col-md-2">
            <div class="card" (click)="getPreviewType('previewEleven')">
                <img class="w-100" alt="avatar2" alt="Responsive image"
                    src="../../../assets/img/previewImages/preview11.png" />
            </div>
        </div>
        <div class="col-md-2">
            <div class="card" (click)="getPreviewType('previewTwelve')">
                <img class="w-100" alt="avatar2" alt="Responsive image"
                    src="../../../assets/img/previewImages/preview12.png" />
            </div>
        </div>
    </div>
    <div class="row" *ngIf="novsspl">
        <div class="col-md-2">
            <div class="card" (click)="getPreviewType('previewThirteen')">
                <img class="w-100" alt="avatar2" alt="Responsive image"
                    src="../../../assets/img/previewImages/preview13.png" />
            </div>
        </div>
        <div class="col-md-2">
            <div class="card" (click)="getPreviewType('previewFourteen')">
                <img class="w-100" alt="avatar2" alt="Responsive image"
                    src="../../../assets/img/previewImages/preview14.png" />
            </div>
        </div>
         <div class="col-md-2">
            <div class="card" (click)="getPreviewType('previewFifteen')">
                <img class="w-100" alt="avatar2" alt="Responsive image"
                    src="../../../assets/img/previewImages/preview15.png" />
            </div>
        </div>
        <div class="col-md-2">
            <div class="card" (click)="getPreviewType('previewSixteen')">
                <img class="w-100" alt="avatar2" alt="Responsive image"
                    src="../../../assets/img/previewImages/previewSixteen.png" />
            </div>
        </div>
        <div class="col-md-2">
    <div class="card"  (click)="getPreviewType('previewSeventeen')">
        <img class="w-100"  alt="avatar2" alt="Responsive image"
        src="../../../assets/img/previewImages/previewSeventeen.png" />
    </div>
</div>
<div class="col-md-2">
    <div class="card" (click)="getPreviewType('previewTwenty')">
        <img class="w-100" alt="avatar2" alt="Responsive image"
            src="../../../assets/img/previewImages/previewTwenty.png" />
    </div>
</div>
</div>
<div class="row" *ngIf="novsspl">
    <div class="col-md-2">
        <div class="card" (click)="getPreviewType('previewTwentyOne')">
            <img class="w-100" alt="avatar2" alt="Responsive image"
                src="../../../assets/img/previewImages/previewTwentyone.png" />
        </div>
    </div>
    <div class="col-md-2">
        <div class="card" (click)="getPreviewType('previewTwentyTwo')">
            <img class="w-100" alt="avatar2" alt="Responsive image"
                src="../../../assets/img/previewImages/previewTwentyTwo.png" />
        </div>
    </div>
    <div class="col-md-2">
        <div class="card" (click)="getPreviewType('previewTwentyThree')">
            <img class="w-100" alt="avatar2" alt="Responsive image"
                src="../../../assets/img/previewImages/previewTwentythree.png" />
        </div>
    </div>
    <div class="col-md-2">
        <div class="card" (click)="getPreviewType('previewTwentyFour')">
            <img class="w-100" alt="avatar2" alt="Responsive image"
                src="../../../assets/img/previewImages/previewTwentyfour.png" />
        </div>
    </div>
    <div class="col-md-2">
        <div class="card" (click)="getPreviewType('previewTwentyFive')">
            <img class="w-100" alt="avatar2" alt="Responsive image"
                src="../../../assets/img/previewImages/previewtwentyfive.png" />
        </div>
    </div>
    <div class="col-md-2">
        <div class="card" (click)="getPreviewType('previewTwentySeven')">
            <img class="w-100" alt="avatar2" alt="Responsive image"
                src="../../../assets/img/previewImages/previewTwentyseven.png" />
        </div>
    </div>
    </div>
    <div class="row" *ngIf="novsspl">
         <div class="col-md-2">
            <div class="card" (click)="getPreviewType('previewThirty')">
                <img class="w-100" alt="avatar2" alt="Responsive image"
                    src="../../../assets/img/previewImages/previewThirty.png" />
            </div>
        </div>
        <div class="col-md-2">
            <div class="card" (click)="getPreviewType('previewThirtyOne')">
                <img class="w-100" alt="avatar2" alt="Responsive image"
                    src="../../../assets/img/previewImages/previewThirtyone.png" />
            </div>
        </div>
        <div class="col-md-2">
            <div class="card" (click)="getPreviewType('previewThirtyTwo')">
                <img class="w-100" alt="avatar2" alt="Responsive image"
                    src="../../../assets/img/previewImages/previewThirtytwo.png" />
            </div>
        </div>
        <div class="col-md-2">
            <div class="card" (click)="getPreviewType('previewThirtyFour')">
                <img class="w-100" alt="avatar2" alt="Responsive image"
                    src="../../../assets/img/previewImages/previewThirtyfour.png" />
            </div>
        </div>
        <div class="col-md-2">
            <div class="card" (click)="getPreviewType('previewThirtyFive')">
                <img class="w-100" alt="avatar2" alt="Responsive image"
                    src="../../../assets/img/previewImages/previewThirtyfive.png" />
            </div>
        </div>
        <div class="col-md-2">
            <div class="card" (click)="getPreviewType('previewThirtySix')">
                <img class="w-100" alt="avatar2" alt="Responsive image"
                    src="../../../assets/img/previewImages/previewThirtysix.png" />
            </div>
        </div>
        </div>
        <div class="row" *ngIf="novsspl">
            <div class="col-md-2">
                <div class="card" (click)="getPreviewType('previewThirtySeven')">
                    <img class="w-100" alt="avatar2" alt="Responsive image"
                        src="../../../assets/img/previewImages/previewThirtyseven.png" />
                </div>
            </div>
            <div class="col-md-2">
                <div class="card" (click)="getPreviewType('previewThirtyEight')">
                    <img class="w-100" alt="avatar2" alt="Responsive image"
                        src="../../../assets/img/previewImages/previewThirtyeight.png" />
                </div>
            </div>
            <div class="col-md-2">
                <div class="card" (click)="getPreviewType('previewThirtyNine')">
                    <img class="w-100" alt="avatar2" alt="Responsive image"
                        src="../../../assets/img/previewImages/previewThirtynine.png" />
                </div>
            </div>
            <div class="col-md-2">
                <div class="card" (click)="getPreviewType('previewForty')">
                    <img class="w-100" alt="avatar2" alt="Responsive image"
                        src="../../../assets/img/previewImages/previewForty.png" />
                </div>
            </div>
            <div class="col-md-2">
                <div class="card" (click)="getPreviewType('previewFortyOne')">
                    <img class="w-100" alt="avatar2" alt="Responsive image"
                        src="../../../assets/img/previewImages/previewFortyone.png" />
                </div>
            </div>
            <div class="col-md-2">
                <div class="card" (click)="getPreviewType('previewFortyThree')">
                    <img class="w-100" alt="avatar2" alt="Responsive image"
                        src="../../../assets/img/previewImages/perviewFortythree.png" />
                </div>
            </div>
            </div>
            <div class="row" *ngIf="novsspl">
                <div class="col-md-2">
                    <div class="card" (click)="getPreviewType('previewFortyFive')">
                        <img class="w-100" alt="avatar2" alt="Responsive image"
                            src="../../../assets/img/previewImages/previewFortyfive.png" />
                    </div>
                </div>
                </div>
                            <div class="row mt-3"></div>
    <div class="row mt-5"></div>
    <ngx-spinner size="large" [fullScreen]="true">
        <figure class="loader">
            <div class="dot white"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
        </figure>
    </ngx-spinner>
</div>
<div class="modal " easein="flipBounceXIn"  tabindex="-1" role="dialog" aria-labelledby="costumModalLabel" aria-hidden="true" [style.display]="showAddCard ? 'block' : 'none'" >
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header bg-info">
                <div class="row ms-5">
                <h5 class="text-center ms-3">Subscribe now to add a new card !</h5>
            </div>
            </div>
            <div class="modal-body">
                <div class="col-md-12">
                    <h5>Parse to a new card, please contact to the <br><b>VarNik Systems Services Pvt Ltd </b> admin team</h5>
                </div>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-md-4 card">
                        <img src="../../../assets/img/backgroundImages/vsspl.png" class="image">
                    </div>
                    <div class="col-md-8">
                    <a target="_blank" href="tel:9791396308">
                        <i class="fas fa-phone fa-flip-horizontal contact-action-container-icon"> </i> 9791396308
                    </a><br>
                    <a target="_blank" href="http://www.varnikcloud.com">
                        <i class="fas fa-globe contact-action-container-icon mt-1"></i> www.varnikcloud.com
                    </a><br>
                    <a href="mailto:karthikc@msservicesinc.com">
                        <i class="fas fa-envelope contact-action-container-icon mt-1"></i> karthikc@msservicesinc.com
                    </a><br>
                    <a href="https://api.whatsapp.com/send?phone=919791396308&text=Hello"><i class="bi bi-whatsapp contact-action-container-icon mt-1">
                    </i> +91 9791396308</a>
                </div>
                </div>
                <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="eCardNameclose()"><b>Ok</b>
                </button>
            </div>
        </div>
    </div>
</div>
<div  [style.display]="isNewUser ? 'block' : 'none'" class="modal" tabindex="-1" role="dialog" aria-labelledby="costumModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document" >
      <div class="modal-content" style="background-color:  #edf1ec;">
        <h4 class="modal-title mt-3" style="text-align: center">
          <span style="border-bottom: 3px double;">NOTE</span>
        </h4>
        <div class="modal-header note">
          <h6 class="modal-title">
            <h5 class=""><span class="text-danger">*</span>
                Please Select the eCard template to proceed
            </h5>
          </h6>
        </div>
        <!-- <div class="modal-footer note">
          <button type="button" class="btn btn-primary" (click)="saveType()">Ok</button>
        </div> -->
      </div>
    </div>
  </div>

