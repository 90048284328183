import {Injectable} from "@angular/core";
import {HttpHeaders} from "@angular/common/http";


@Injectable({
    providedIn: 'root'
})
export class LocalDataService {

    getHeaders() {
        console.log("token :", this.getToken())
        return new HttpHeaders({
            "Authorization": "Bearer " + this.getToken(),
            "Access-Control-Allow-Origin": "*"
        })
    }

    getToken() {
        return localStorage.getItem('authToken')
    }
}