<div class="container-fluid" style="font-family: 'prompt';">
  <section class="container mt-5">
    <div class="row mb-5 mt-5">
      <div class="col-12 text-center">
        <h3 class="text-bold" style="color:#5f5f5f;">Design a <span style="color: #fe7840;">business card for free online in India</span>
        </h3>
        <p class="text-muted mt-3">Please follow the steps to proceed your Digital eCard creation</p>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <h5 class="text-muted">
            Unlock professional branding with our free online business cards service. Effortlessly design and customize
            your cards to make a lasting impression. Elevate your business identity at eCard Maker. Quality cards,
            hassle-free, and at your fingertips. Create a lasting impression for your business with our free online
            business cards. Effortlessly design and customize to reflect your brand identity. Quality, convenience, and
            professionalism—all in one place.
          </h5>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <h4 class="text-orange text-bold">
            A visiting card, also known as a business card, typically includes key information about an individual or
            business. Here's a basic format you can follow when creating a visiting card:
          </h4>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12">
          <h5 class="text-orange text-bold">Header</h5>
          <p class="text-muted ml-3">Include your name or your business name in a prominent font at the top of the
            card.</p>

          <h5 class="text-orange text-bold">Job Title or Tagline</h5>
          <p class="text-muted ml-3"> Below your name, add your job title or a brief tagline that represents your role
            or business.</p>

          <h5 class="text-orange text-bold">Contact Information</h5>
          <p class="text-muted text-bold ml-3">Provide essential contact details, including</p>
          <ul class="text-muted ml-5">
            <li>Phone number</li>
            <li>Email address</li>
            <li>Physical address (optional)</li>
            <li>Website URL (optional)</li>
          </ul>

          <h5 class="text-orange text-bold">Logo or Image</h5>
          <p class="text-muted ml-3">If applicable, include your business logo or a professional photo of yourself. This
            adds a personal touch and reinforces branding.</p>

          <h5 class="text-orange text-bold">QR Code (Optional)</h5>
          <p class="text-muted ml-3">You may include a QR code that leads to your website or a specific landing page for
            quick access to more information.</p>

          <h5 class="text-orange text-bold">Social Media Icons (Optional)</h5>
          <p class="text-muted ml-3">Include small icons or links to your social media profiles (LinkedIn, Twitter,
            etc.) if relevant to your business.</p>

          <h5 class="text-orange text-bold">Color and Design</h5>
          <p class="text-muted ml-3">Choose a color scheme and design that aligns with your personal or business
            branding. Keep it professional and easy to read.</p>


          <h5 class="text-orange text-bold">Typography</h5>
          <p class="text-muted ml-3">Use clear and legible fonts. Ensure that the text is easily readable, even in
            smaller font sizes.</p>

          <h5 class="text-orange text-bold">White Space</h5>
          <p class="text-muted ml-3">Allow for sufficient white space to avoid clutter. A clean design often makes a
            more lasting impression.</p>

          <h5 class="text-orange text-bold">Card Size</h5>
          <p class="text-muted ml-3">Stick to standard business card dimensions. In many places, this is 3.5 x 2 inches
            (88.9 x 50.8 mm). However, dimensions may vary, so check the standard size in your region.</p>

          <h5 class="text-orange text-bold">Printing Considerations</h5>
          <p class="text-muted ml-3">If you plan to print the cards professionally, make sure your design adheres to the
            printing specifications of your chosen printing service.</p>

          <p class="text-muted text-bold mt-5">
            Elevate your professional image with free business cards online. Customize and design with ease to showcase
            your brand. Quality cards, zero cost, and unlimited possibilities. Start creating at eCard Maker Website for
            a powerful first impression
          </p>
        </div>
      </div>
    </div>
  </section>
</div>

<footer class="footer p-0">
  <div class="row text-center">
    <div class="col-12">
      <span>Copyright &copy; {{ currentYear }} |</span>
      <a href="https://varnikcloud.com/"
         style="margin: 0; text-decoration: none"
         target="_blank"
      ><span class="text-white"> VarNik Systems Services Pvt. Ltd</span></a>
      <span>. |</span>
      <span> All rights reserved.</span>
    </div>
    <span class="text-black mt-3 mr-2" type="button" [routerLink]="['/terms']">Terms & Conditions   |
        <span class="text-black ml-3" type="button" [routerLink]="['/privacy']">  Privacy Policy</span></span>
  </div>
</footer>
