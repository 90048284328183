<section class="vh-100" style="background-color: rgb(254 242 242)">
    <div class="container-fluid py-2  d-flex ">
        <div class="container">
            <div class="row mt-2">
                <div class="col-md-12 text-center">
                    <img class="companyProfile" [src]="companySrcUrl">
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12 justify-content-center">
                    <h5 class="text-center" style="margin-left: 10px;color:pink; text-shadow: 5px 5px 10px white;">{{currentFormData!.companyName}}</h5>
                </div>
            </div>
            <div style="width:100%;background-color:pink;height:2px;margin-top: 15px;"></div>
            <!-- User Profile Pic -->
            <div class="col-md-12 text-center" *ngIf="profileShow">
                <img [src]="profileSrcUrl" class="image mt-4">
            </div>
            <div class="row mt-2">
                <div class="col-12 text-center">
                    <h6 class="fw-bolder" style="color:pink;">{{currentFormData!.firstName}} {{currentFormData!.lastName}}</h6>
                    <span class="designation" style="margin-top:15px;color:pink;"><b>{{currentFormData!.designation}}</b></span>
                    <p class="text-center" style="color:pink;">{{currentFormData!.role}}</p>
                </div>
            </div>
            <div style="width:100%;background-color:pink;height:2px;margin-top: 15px;"></div>
            <div class="row">
                <div class="col-1"></div>
                <div class="col-11">
                    <div class="row mt-2">
                        <div class="col-2"><i class="bi bi-telephone roundIcon" type="button" title="Call"></i></div>
                        <div class="col-10 mt-1"><h6 class="fw-bolder" style="color:pink;" (click)="copyPhoneNo(currentFormData!.countryCodePhoneNo, formatNumber(currentFormData!.phoneNo))">
                            {{currentFormData!.countryCodePhoneNo}} {{formatNumber(currentFormData!.phoneNo)}}</h6></div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-2"><i class="bi bi-geo-alt roundIcon" type="button" title="Location"></i></div>
                        <div class="col-10 mt-1"><h6 class="fw-bolder" style="color:pink;">{{currentFormData!.address}}</h6></div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-2"><i class="bi bi-globe2 roundIcon" type="button" title="Website"></i></div>
                        <div class="col-10 mt-1"><h6 class="fw-bolder">
                            <a class="opacity-75 fw-bolder" style="color:pink;" href="http://{{currentFormData!.companyWebsiteName}}" target="_blank">{{currentFormData!.companyWebsiteName}}</a>
                        </h6>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-2"><i class="bi bi-envelope roundIcon" type="button" title="Mail"></i></div>
                        <div class="col-10 mt-1">
                            <h6 class="fw-bolder"><a class="opacity-75  fw-bolder" style="color:pink;"
                                                     href="mailto:{{currentFormData!.email}}"
                                                     target="_blank">{{currentFormData!.email}}</a>
                            </h6>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-2"><i title="WhatsApp" class="bi bi-whatsapp roundIcon fs-6"></i>
                        </div>
                        <div class="col-10 mt-1">
                            <h6 class="fw-bolder mt-1" type="button" style="margin-left: 1px; color: pink;"
                                (click)="whatsApp(currentFormData!.countryCodePhoneNo, currentFormData!.phoneNo)">
                                {{currentFormData!.countryCodePhoneNo}} {{formatNumber(currentFormData!.phoneNo)}}</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div style="width:100%;background-color:pink;height:2px;margin-top: 15px;"></div>
            <!--<div class="userDetails justify-content-center">
                <div class="row mt-1">
                    <div class="col-12">
                        <i class="bi bi-telephone-fill roundIcon" type="button" title="Call"></i>
                        <span class="fw-bolder" (click)="copyPhoneNo(currentFormData!.countryCodePhoneNo, formatNumber(currentFormData!.phoneNo))">
                        {{currentFormData!.countryCodePhoneNo}} {{formatNumber(currentFormData!.phoneNo)}}
                    </span>
                    </div>
                </div>
                <div class="row mt-1">
                    <div class="col-12">
                        <i class="bi bi-geo-alt-fill roundIcon" type="button" title="Location"></i>
                        <span class="fw-bolder">{{currentFormData!.address}}</span>
                    </div>
                </div>
                <div class="row mt-1">
                    <div class="col-12">
                        <i class="bi bi-globe2 roundIcon" type="button" title="Website"></i>
                        <span class="fw-bolder" style="margin-left: 2px;">
                            <a class="fw-bolder" href="https://{{currentFormData!.companyWebsiteName}}" target="_blank">{{currentFormData!.companyWebsiteName}}</a>
                        </span>
                    </div>
                </div>
                <div class="row mt-1">
                    <div class="col-12">
                        <i class="bi bi-envelope roundIcon" type="button" title="Mail"></i>
                        <span class="fw-bolder" style="margin-left: 2px; color: #059dd0;"><a class="fw-bolder"
                                                                                             href="mailto:{{currentFormData!.email}}"
                                                                                             target="_blank">{{currentFormData!.email}}</a>
                        </span>
                    </div>
                </div>
            </div>-->
        </div>
    </div>
</section>
<ngx-spinner bdColor="rgba(255, 255, 255, 1)" size="large" color="#FFFFFF"
             [fullScreen]="true">
    <figure class="loader">
        <div class="dot white"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
    </figure>
</ngx-spinner>
<app-ecard-footer></app-ecard-footer>
