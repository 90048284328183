<section class="body vh-100" style="background-color:  rgb(172, 172, 172);" *ngIf="profileShow">
    <div class="container-fluid py-2 justify-content-center align-items-center"
         style="text-align: -webkit-center;">
        <div>
            <div class="page-wrapper" id="home-section">
                <div class="page-details">
                    <div>
                        <div class="p-10"></div>
                        <div>
                            <img [src]="companySrcUrl" class="logo mt-3" style="margin-top: 15px;" crossorigin="anonymous">
                        </div>

                        <h4 class="firmname mt-2"style="color: black;margin-top:15px;">{{currentFormData!.companyName}}</h4>
                        <div style="border-bottom:#000000 2px solid;"></div>
                        <div class="col-md-12 text-center">
                            <img [src]="profileSrcUrl" class="image mt-1" crossorigin="anonymous">
                        </div>
                        <h3 class="name mt-1">{{currentFormData!.firstName}} {{currentFormData!.lastName}}</h3>
                            <h6 class="designation mt-1">{{currentFormData!.designation}}</h6>
                          <h6 class="role mt-1">{{currentFormData!.role}}</h6>


                    </div>
                    <div>
                        <div class="p-10"></div>
                        <div class="contact-buttons">
                            <a class="contact-button"style="color: black;border-left: 4px solid;border-right:4px solid"
                               href="tel:{{currentFormData!.countryCodePhoneNo}}{{currentFormData!.phoneNo}}">
                                <i class="fas fa-phone fa-flip-horizontal"></i>
                                Call
                            </a>
                            <a class="contact-button" style="color: black;border-left: 4px solid;border-right:4px solid" target="_blank" href="mailto:{{currentFormData!.email}}">
                                <i class="fas fa-envelope fa-flip-horizontal"></i>
                                Mail
                            </a>
                        </div>
                        <table class="contact-action-table">
                            <tbody > 
                            <tr>
                                <td>
                                    <i class="fas fa-map-marker-alt contact-action-container-icon mt-3" style="border-right: 4px solid;"></i>
                                </td>
                                <td>
                                    <a class="contact-action-container-text mt-4" style="max-width: 220px;">
                                       <b> {{currentFormData!.address}}</b>
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a href="mailto:{{currentFormData!.email}}">
                                        <i class="fas fa-envelope contact-action-container-icon" style="border-right: 4px solid;"></i>
                                    </a>
                                </td>
                                <td>
                                    <a href="mailto:{{currentFormData!.email}}"
                                       class="contact-action-container-text mt-4"style="">
                                        <b>{{currentFormData!.email}}</b>
                                    </a><br>
                                    <a href="mailto:" class="contact-action-container-text">
                                    </a><br>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a target="_blank" href="http://{{currentFormData!.companyWebsiteName}}">
                                        <i class="fas fa-globe contact-action-container-icon" style="border-right: 4px solid;"></i>
                                    </a>
                                </td>
                                <td>
                                    <a target="_blank" href="http://{{currentFormData!.companyWebsiteName}}"
                                       class="contact-action-container-text">
                                        <b>{{currentFormData!.companyWebsiteName}}</b>
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a target="_blank" style="font-weight: bolder;"
                                    (click)="whatsApp(currentFormData!.countryCodePhoneNo, currentFormData!.phoneNo)">
                                    <i class="bi bi-whatsapp  contact-action-container-icon mt-3" style="border-right: 4px solid;"></i>
                                    </a>
                                </td>
                                <td>
                                    <a class=" mt-3 ml-2 fw-bolder" type="button" style="color: black;"
                                      (click)="whatsApp(currentFormData!.countryCodeWhatsAppNo, currentFormData!.whatsAppNumber)">
                                    {{currentFormData!.countryCodeWhatsAppNo}} {{formatNumber(currentFormData!.whatsAppNumber)}}</a>
                                    <br>                               
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div class="p-30 py-2"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>












<section class="body vh-100" style="background-color:  rgb(172, 172, 172);" *ngIf="profilehide">
    <div class="container-fluid py-2 justify-content-center align-items-center"
         style="text-align: -webkit-center;">
        <div>
            <div class="page-wrapper" id="home-section">
                <div class="page-details">
                    <div>
                        <div class="p-10"></div>
                        <div>
                            <img [src]="companySrcUrl" class="logo mt-3" style="margin-top: 15px;" crossorigin="anonymous">
                        </div>

                        <h4 class="firmname mt-3"style="color: black;margin-top:15px;">{{currentFormData!.companyName}}</h4>
                        <div style="border-bottom:#000000 2px solid;"></div>
                        <div class="col-md-12 text-center">
                        </div>
                        <h3 class="name mt-3">{{currentFormData!.firstName}} {{currentFormData!.lastName}}</h3>
                            <h6 class="designation mt-1">{{currentFormData!.designation}}</h6>
                          <h6 class="role mt-1">{{currentFormData!.role}}</h6>


                    </div>
                    <div>
                        <div class="p-10 mt-1"></div>
                        <div class="contact-buttons">
                            <a class="contact-button"style="color: black;border-left: 4px solid;border-right:4px solid"
                               href="tel:{{currentFormData!.countryCodePhoneNo}}{{currentFormData!.phoneNo}}">
                                <i class="fas fa-phone fa-flip-horizontal"></i>
                                Call
                            </a>
                            <a class="contact-button" style="color: black;border-left: 4px solid;border-right:4px solid" target="_blank" href="mailto:{{currentFormData!.email}}">
                                <i class="fas fa-envelope fa-flip-horizontal"></i>
                                Mail
                            </a>
                        </div>
                        <table class="contact-action-table">
                            <tbody > 
                            <tr>
                                <td>
                                    <i class="fas fa-map-marker-alt contact-action-container-icon mt-3" style="border-right: 4px solid;"></i>
                                </td>
                                <td>
                                    <a class="contact-action-container-text mt-4" style="max-width: 220px;">
                                       <b> {{currentFormData!.address}}</b>
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a href="mailto:{{currentFormData!.email}}">
                                        <i class="fas fa-envelope contact-action-container-icon" style="border-right: 4px solid;"></i>
                                    </a>
                                </td>
                                <td>
                                    <a href="mailto:{{currentFormData!.email}}"
                                       class="contact-action-container-text mt-4"style="">
                                        <b>{{currentFormData!.email}}</b>
                                    </a><br>
                                    <a href="mailto:" class="contact-action-container-text">
                                    </a><br>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a target="_blank" href="http://{{currentFormData!.companyWebsiteName}}">
                                        <i class="fas fa-globe contact-action-container-icon" style="border-right: 4px solid;"></i>
                                    </a>
                                </td>
                                <td>
                                    <a target="_blank" href="http://{{currentFormData!.companyWebsiteName}}"
                                       class="contact-action-container-text">
                                        <b>{{currentFormData!.companyWebsiteName}}</b>
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a target="_blank" style="font-weight: bolder;"
                                    (click)="whatsApp(currentFormData!.countryCodePhoneNo, currentFormData!.phoneNo)">
                                    <i class="bi bi-whatsapp  contact-action-container-icon mt-3" style="border-right: 4px solid;"></i>
                                    </a>
                                </td>
                                <td>
                                    <a class=" mt-3 ml-2 fw-bolder" type="button" style="color: black;"
                                      (click)="whatsApp(currentFormData!.countryCodeWhatsAppNo, currentFormData!.whatsAppNumber)">
                                    {{currentFormData!.countryCodeWhatsAppNo}} {{formatNumber(currentFormData!.whatsAppNumber)}}</a>
                                    <br>                               
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div class="p-30 py-2"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>




<ngx-spinner bdColor="rgba(255, 255, 255, 1)" size="large" color="#FFFFFF"
             [fullScreen]="true">
    <figure class="loader">
        <div class="dot white"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
    </figure>
</ngx-spinner>
<app-ecard-footer></app-ecard-footer>

