<section class="vh-100" style="background-color: rgb(214, 230, 212)">
    <div class="container-fluid py-2 d-flex ">
        <div class="container">
            <div class="row mt-2">
                <div class="col-md-12 text-center">
                    <img [src]="companySrcUrl" class="logo mt-3" crossorigin="anonymous">
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-12">
                    <h3 class="companyame">{{currentFormData!.companyName}}</h3>
                </div>
            </div>
            <div class="col-md-12 text-center" *ngIf="profileShow">
                <img [src]="profileSrcUrl" class="image mt-1" crossorigin="anonymous">
            </div>
            <div class="row mr-2">
                <div class="col-12 text-right">
                   <div class="row">
                    <h5 class="fullname">{{currentFormData!.firstName}} {{currentFormData!.lastName}}</h5>
                    <h6 class="designation">{{currentFormData!.designation}}</h6>
                    <h6 class="role">{{currentFormData!.role}}</h6>
                   </div>
                </div>
            </div>

            <div class="row  text-center py-1 mt-2">
                <div class="contact-buttons">
                    <a class="contact-button" style="border-left:4px solid white;"
                       href="tel:{{currentFormData!.countryCodePhoneNo}}{{currentFormData!.phoneNo}}">
                        <i class="bi bi-telephone-fill  mr-3 ml-2"></i>
                    </a>
                    <a class="contact-button" target="_blank"href="https://www.google.com/maps?q={{currentFormData!.address}}">
                        <i class="bi bi-geo-alt-fill mr-3"></i>
                    </a>
                    <a class="contact-button"
                    target="_blank" href="http://{{currentFormData!.companyWebsiteName}}">
                        <i class="bi bi-globe-central-south-asia mr-3"></i>
                    </a>
                    <a class="contact-button"
                    target="_blank" href="mailto:{{currentFormData!.email}}">
                        <i class="fas fa-envelope  mr-3"></i>
                    </a>
            </div>
        </div>

            <div class="row mt-2">
                <div class="col-1"></div>
                <div class="col-11">
                    <div class="row">
                        <div class="col-2"><i class="bi bi-telephone-fill roundIcon" type="button" title="Call"></i></div>
                        <div class="col-10 mt-1"><h6 class="userDetails" (click)="copyPhoneNo(currentFormData!.countryCodePhoneNo, formatNumber(currentFormData!.phoneNo))"
                            style="font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;">
                            {{currentFormData!.countryCodePhoneNo}} {{formatNumber(currentFormData!.phoneNo)}}</h6></div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-2"><i class="bi bi-geo-alt-fill roundIcon" type="button" title="Location"></i></div>
                        <div class="col-10 mt-1"><h6 class="userDetails">{{currentFormData!.address}}</h6></div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-2"><i class="bi bi-globe-central-south-asia roundIcon" type="button" title="Website"></i></div>
                        <div class="col-10 mt-1"><h6 class="" >
                            <a class="text userDetails"style="text-decoration: none;" href="http://{{currentFormData!.companyWebsiteName}}" target="_blank">{{currentFormData!.companyWebsiteName}}</a>
                        </h6>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-2"><i class="bi bi-envelope-fill roundIcon" type="button" title="Mail"></i></div>
                        <div class="col-10 mt-1">
                            <h6 class="userDetails"><a class="text-white"Style="text-decoration:none;"
                                                     href="mailto:{{currentFormData!.email}}"
                                                     target="_blank">{{currentFormData!.email}}</a>
                            </h6>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-2"><i title="WhatsApp" class="bi bi-whatsapp roundIcon"></i>
                        </div>
                        <div class="col-10 py-1">
                            <h6 class="userDetails mt-1" type="button" 
                                (click)="whatsApp(currentFormData!.countryCodeWhatsAppNo, currentFormData!.whatsAppNumber)">
                                {{currentFormData!.countryCodeWhatsAppNo}} {{formatNumber(currentFormData!.whatsAppNumber)}}</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div class="py-2"></div>
        </div>
    </div>
</section>
<ngx-spinner bdColor="rgba(255, 255, 255, 1)" size="large" color="#FFFFFF"
             [fullScreen]="true">
    <figure class="loader">
        <div class="dot white"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
    </figure>
</ngx-spinner>
<app-ecard-footer></app-ecard-footer>

