import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {Banner} from "../modal/banner";

declare interface Window {
  adsbygoogle: any[];
}
declare var adsbygoogle: any[];

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements AfterViewInit {

  constructor() {    }

  ngAfterViewInit() {
    setTimeout(() => {
      try {
        // @ts-ignore
        (window["adsbygoogle"] = window["adsbygoogle"] || []).push({});
      } catch (e) {
        console.error("ads", e);
      }
    }, 900);
  }

}
