 <!-- <section class="vh-100 me" style="background-color: rgb(219, 219, 219);">
    <div class="container-fluid  d-flex px-2" style="position: fixed ;">
        <div class="container" style="box-sizing: content-box;" >
            <div class="phone" style="font-size:15px;font-family: Agency FB;">{{currentFormData.phoneNo}}</div>
            <img src="../../../../assets/img/vssplTemplate/vssplTemplate.png" alt="" class="bg " style="max-width: 100%; height: 90vh;" width="630px" height="1050px">
            <div class="image-text ">
                <p class="" style="font-size:15px;font-family: Arial, Helvetica, sans-serif;">{{currentFormData.firstName}}  {{currentFormData.lastName}}</p>
                <p class="" style="font-size:13px;font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;">{{currentFormData.role}}</p>
            </div>
            <div class="email" style="font-size:15px;font-family: Agency FB;">{{currentFormData.email}}</div>
        </div>
    </div>
</section>
<app-ecard-footer></app-ecard-footer>  -->

<div class="vh-100 bg-secondary">

    <div class="container-fluid">
      <div class="row text-center py-4">
        <div class="container bg-white mx-auto" style="max-width: 340px;height: auto;">
          <!-- COMPANY LOGO -->
          <div class="row mt-2 mb-3">
            <div class="image">
              <img src="../../../../assets/img/varnikLogos/MSSI.png" width="100px"  alt="">
            </div>
          </div>
<!--           COMPANY NAME-->
          <div class="row mt-2 mb-3">
            <h5 class="companyName" style="font-family: 'Ubuntu';"><b>Mass System Services Inc</b></h5>
          </div>
<!--           STAFF NAME-->
          <!-- STAFF NAME -->
          <div class="row  mt-4" style="background-color: rgb(9,31,94);">
            <div class="col-2 mt-2 text-center">
              <i class="bi bi-person-fill  icon"></i>
            </div>
            <div class="col-10 mt-2">
              <h5 class="fullName text-capitalize" style="font-family: 'Ubuntu'"><b>{{firstNameData}} {{lastNameData}} </b></h5>
              <h6 class="role text-capitalize" style="font-family: 'Ubuntu'"><b>{{roleData}}</b></h6>
            </div>
          </div>
          <div class="row" style="background-color: #2facde">
            <!-- PHONE NUMBER -->
            <div class="row mt-3">
              <div class="col-2 text-center">
                <i class="bi bi-telephone-fill icons"></i>
              </div>
              <div class="col-10">
                <p class="phone mt-2 ms-1" style="font-family: 'Varela Round';"><b>{{phoneNumberData}}</b></p>
              </div>
            </div>
            <!-- EMAIL -->
            <div class="row mt-3">
              <div class="col-2 text-center">
                <i class="bi bi-envelope-fill icons"></i>
              </div>
              <div class="col-10">
                <h6 class="email ms-1" style="font-family: 'Varela Round';"><b>{{emailData}}</b></h6>
                <p class="email ms-1" style="font-family: 'Varela Round';"><b>vsspl-affairs@msservicesinc.com<br>www.varnikcloud.com</b></p>
              </div>
            </div>
            <!-- ADDRESS -->
            <div class="row mt-3">
              <div class="col-2 text-center">
                <i class="bi bi-geo-alt-fill icons"></i>
              </div>
              <div class="col-10">
                <p class="address ms-1" style="font-family: 'Varela Round';"><b>#1, First Cross, Sundar Nagar, Trichy, Tamil Nadu - 620 021</b></p>
              </div>
            </div>
            <div class="row mt-2"></div>
          </div>
          <!-- FOOTER -->
          <div class="footer">
            <div class="row" style="background-color: rgb(9,31,94);">.</div>
          </div>
        </div>
      </div>
      </div>

  </div>
 <app-ecard-footer></app-ecard-footer>
