<section class="vh-100 d-flex" style="background-color: rgb(254 242 242)"  *ngIf="profileShow">
    <div class="container-fluid py-2">
        <div class="container">

            <div class="row mt-4">
                        <div class="col-md-12  mt-5 py-1 text-center">
                            <img class="companyProfile" [src]="companySrcUrl">
                        </div>
            </div>
            <div class="row mt-2">
                <div class="col-12">
                    <h4 class="companyname  text-center">{{currentFormData!.companyName}}</h4>
                </div>
            </div>

            <div class="text-center" style="border: 1px solid black"></div>


                        <div class="col-md-12 mt-3 text-center">
                            <img class="Profile" [src]="profileSrcUrl">
                        </div>
                    <div class="row">
                        <div class="col-12 mt-5 py-3 text-center">
                            <h4 class="fullname">{{currentFormData!.firstName}} {{currentFormData!.lastName}}</h4>
                            <h6 class="designation">{{currentFormData!.designation}}</h6>
                            <h6 class="role">{{currentFormData!.role}}</h6>
                        </div>
                    </div>


          <div class="row">
            <div class="contact-buttons">
              <a class="contact"  style="border: 1px solid  rgb(199, 156, 77);"
                 href="tel:{{currentFormData!.countryCodePhoneNo}}{{currentFormData!.phoneNo}}">
                <i class="fas fa-phone fa-flip-horizontal mr-1"></i>
                <b>Call</b>
              </a>
              <a class="contact" target="_blank" style="border: 1px solid  rgb(199, 156, 77);"
                 (click)="whatsApp(currentFormData!.countryCodePhoneNo, currentFormData!.phoneNo)">
                <i class="fab fa-whatsapp mr-1"></i>
                <b>Whatsapp</b>
              </a>
              <a class="contact" style="border: 1px solid  rgb(199, 156, 77);"
                 target="_blank" href="mailto:{{currentFormData!.email}}">
                <i class="fas fa-envelope fa-flip-horizontal mr-1"></i>
                <b>Mail</b>
              </a>

            </div>
          </div>


            <div class="row mt-3">
                <div class="col-1"></div>
                <div class="col-11">
                    <div class="row mt-1">
                        <div class="col-2"><i class="bi bi-geo-alt roundIcon" type="button" title="Location"></i></div>
                        <div class="col-10 mt-1"><h6 class="Detail">{{currentFormData!.address}}</h6></div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-2"><i class="bi bi-globe2 roundIcon" type="button" title="Website"></i></div>
                        <div class="col-10 mt-1"><h6 class="">
                            <a class="Detail" style="text-decoration: none;" href="http://{{currentFormData!.companyWebsiteName}}" target="_blank">{{currentFormData!.companyWebsiteName}}</a>
                        </h6>
                        </div>
                    </div>
                  <div class="row mt-3"></div>
                </div>
            </div>
        </div>
    </div>
</section>















<section class="vh-100 d-flex" style="background-color: rgb(254 242 242)"  *ngIf="profilehide">
  <div class="container-fluid py-2">
    <div class="container">

      <div class="row mt-4">
        <div class="col-md-12  mt-4 py-2 text-center">
          <img class="companyPro" [src]="companySrcUrl">
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <h4 class="companyname  text-center">{{currentFormData!.companyName}}</h4>
        </div>
      </div>




      <div class="row mt-5 py-2">
        <div class="col-12 mt-5 py-3 text-center">
          <h4 class="fullname mt-3">{{currentFormData!.firstName}} {{currentFormData!.lastName}}</h4>
          <h6 class="designation">{{currentFormData!.designation}}</h6>
          <h6 class="role">{{currentFormData!.role}}</h6>
        </div>
      </div>


      <div class="row">
        <div class="contact-buttons">
          <a class="contact"  style="border: 1px solid  rgb(199, 156, 77);"
             href="tel:{{currentFormData!.countryCodePhoneNo}}{{currentFormData!.phoneNo}}">
            <i class="fas fa-phone fa-flip-horizontal mr-1"></i>
            <b>Call</b>
          </a>
          <a class="contact" target="_blank" style="border: 1px solid  rgb(199, 156, 77);"
             (click)="whatsApp(currentFormData!.countryCodePhoneNo, currentFormData!.phoneNo)">
            <i class="fab fa-whatsapp mr-1"></i>
            <b>Whatsapp</b>
          </a>
          <a class="contact" style="border: 1px solid  rgb(199, 156, 77);"
             target="_blank" href="mailto:{{currentFormData!.email}}">
            <i class="fas fa-envelope fa-flip-horizontal mr-1"></i>
            <b>Mail</b>
          </a>

        </div>
      </div>


      <div class="row mt-3">
        <div class="col-1"></div>
        <div class="col-11">
  
          <div class="row mt-1">
            <div class="col-2"><i class="bi bi-geo-alt roundIcon" type="button" title="Location"></i></div>
            <div class="col-10 mt-1"><h6 class="Detail">{{currentFormData!.address}}</h6></div>
          </div>
          <div class="row mt-1">
            <div class="col-2"><i class="bi bi-globe2 roundIcon" type="button" title="Website"></i></div>
            <div class="col-10 mt-1"><h6 class="">
              <a class="Detail" style="text-decoration: none;" href="http://{{currentFormData!.companyWebsiteName}}" target="_blank">{{currentFormData!.companyWebsiteName}}</a>
            </h6>
            </div>
          </div>
          
          <div class="row mt-3"></div>
        </div>
      </div>
    </div>
  </div>
</section>















<ngx-spinner bdColor="rgba(255, 255, 255, 1)" size="large" color="#FFFFFF"
             [fullScreen]="true">
    <figure class="loader">
        <div class="dot white"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
    </figure>
</ngx-spinner>
<app-ecard-footer></app-ecard-footer>
