import { AppState } from '@/store/state';
import { UiState } from '@/store/ui/state';
import { Component, HostBinding, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ECardForm } from '@modules/ecard/modal/eCardForm';
import { Register } from '@modules/ecard/modal/register';
import { Store } from '@ngrx/store';
import { AppService } from '@services/app.service';
import { data } from 'jquery';
import { Observable, Subject, takeUntil } from 'rxjs';

const BASE_CLASSES = 'main-sidebar elevation-4';

@Component({
  selector: 'app-menu-sidebar',
  templateUrl: './menu-sidebar.component.html',
  styleUrls: ['./menu-sidebar.component.scss']
})
export class MenuSidebarComponent implements OnInit {
  @HostBinding('class') classes: string = BASE_CLASSES;
  public ui: Observable<UiState>;
  public user;
  public menu = MENU;
  public username;
  destroy$: Subject<boolean> = new Subject<boolean>();
  eventDetails: ECardForm[] = [];
  eCardName: string[] = []
  eCardDisplayName: string[] = []
  selectedItem: string | null = null;
  userId:number=0
  userregi: Register[] = [];
  mail : string = ""
  emailsplit: { BEFORE: string, AFTER: string }[] = [];
  


  constructor(
    public appService: AppService,
    private store: Store<AppState>,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.selectedItem = ""
    this.ui = this.store.select('ui');
    this.ui.subscribe((state: UiState) => {
      this.classes = `${BASE_CLASSES} ${state.sidebarSkin}`;
    });
    this.user = this.appService.user;
    this.getUserDetails()
  }

  getUserDetails() {
    // @ts-ignore
    this.appService.getUserDetails(localStorage.getItem('userId'))
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        // console.log("user Reg ID::", this.userId)
        console.log("ECardForm:", data)
        this.eventDetails = data;
        console.log("UserECardForm:", data)
        for (var userECard of this.eventDetails) {
          this.eCardName.push(userECard.ecardName)
          this.eCardDisplayName.push(userECard.cardDisplayName)
          console.log("eCardName::", this.eCardDisplayName)
          console.log(":", data)
        }
        this.username = this.eCardDisplayName
        console.log("username:", this.username)
      })

  }

  eCardForm() {
    this.router.navigate(['/main/eCardForm'])
  }

  selctEcardName(name: string) {
    const value = name
    this.selectedItem = name;
    console.log("check::",value)
    for (let item of this.eventDetails) {
      if (name == item.cardDisplayName && item.companyName == "") {
        localStorage.setItem("EcardName", item.ecardName)
        this.router.navigate(['/main/vsspleCardForm'])
        console.log("check")
      }else if(name == item.cardDisplayName){
        localStorage.setItem("EcardName", item.ecardName)
        this.router.navigate(['/main/eCardForm'])
        console.log("check1")
      }
    }
  }

getRegister(){
  this.appService
  .getRegisterUserDetails(this.userId)
  .pipe(takeUntil(this.destroy$))
  .subscribe((data) =>{
    console.log("d",data)
    this.userregi.push(data);
    for(let mails of this.userregi ){
      this.mail = mails.emailId
    }
    console.log("EMAIL ONLY::",this.mail)     
      var [ BEFORE , AFTER ] = this.mail.split('@');
      this.emailsplit.push({ BEFORE , AFTER })
      if(AFTER.includes("msservice") ){

      }
    });
}

}

export const MENU = [

  {
    name: 'Dashboard',
    iconClasses: 'fas fa-tachometer-alt',
    path: ['/main/dash']
  },
  // {
  //   name: 'Blank',
  //   iconClasses: 'fas fa-file',
  //   path: ['/blank']
  // },
  // {
  //   name: 'Main Menu',
  //   iconClasses: 'fas fa-folder',
  //   children: [
  //     {
  //       name: 'Sub Menu',
  //       iconClasses: 'far fa-address-book',
  //       path: ['/sub-menu-1']
  //     },
  //     {
  //       name: 'Blank',
  //       iconClasses: 'fas fa-file',
  //       path: ['/sub-menu-2']
  //     }
  //   ]
  // }
];

