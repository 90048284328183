<section class="py-2"  *ngIf="profileShow">
    <div class="container-fluid">
        <div class="container">
            <div class="box">

                <div class="row">
                    <div class="col-md-12 mt-2 text-center">
                        <img class="companyProfile" [src]="companySrcUrl" alt="Company Logo">
                    </div>
                </div>

                <div class="row">
                    <div class="col-1"></div>
                    <div class="col-md-9 mt-2 text-center" style="color: rgb(39, 76, 119);word-wrap: break-word;
                    max-width: 75%">
                        <h4 class="mt-1">{{currentFormData!.companyName}}</h4>
                    </div>
                    <div class="col-2"></div>
                </div>
                <div class="boxContent">
                    <div class="row">
                        <div class="col-md-12 text-center mt-1">
                            <img [src]="profileSrcUrl" class="employeeProfile" alt="Employee Avatar">
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-12 text-center">
                            <h3 class="fullName">{{currentFormData!.firstName}} {{currentFormData!.lastName}}</h3>
                            <h6 class="designation">{{currentFormData!.designation}}</h6>
                            <h6 class="role">{{currentFormData!.role}}</h6>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-1"></div>
                        <div class="col-10">
                            <div class="row mt-2">
                                <div class="col-2"><i class="bi bi-telephone-fill roundIcon"></i>
                                </div>
                                <div class="col-10 mt-1">
                                    <div class="call">
                                        <h6 (click)="copyPhoneNo(currentFormData!.countryCodePhoneNo, formatNumber(currentFormData!.phoneNo))">
                                            {{currentFormData!.countryCodePhoneNo}} {{formatNumber(currentFormData!.phoneNo)}}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-2">
                                    <i class="bi bi-geo-alt-fill roundIcon"></i>
                                </div>
                                <div class="col-9 mt-1">
                                    <div class="address">
                                        <h6>{{currentFormData!.address}}</h6>
                                    </div>
                                </div>
                                <div class="col-1"></div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-2">
                                    <a href="http://{{currentFormData!.companyWebsiteName}}" target="_blank">
                                    <i class="bi bi-globe-central-south-asia roundIcon"></i>
                                </a>
                                </div>
                                <div class="col-10 mt-1">
                                    <div class="website">
                                        <h6>
                                            <a class="" style="text-decoration: none;color: rgb(39, 76, 119);word-wrap: break-word;word-break: break-all;"
                                               href="http://{{currentFormData!.companyWebsiteName}}" target="_blank">
                                                {{currentFormData!.companyWebsiteName}}
                                            </a>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-2">
                                    <a  href="mailto:{{currentFormData!.email}}" target="_blank">
                                    <i class="bi bi-envelope-at-fill roundIcon"></i>
                                </a>
                                </div>
                                <div class="col-10 mt-1">
                                    <div class="email">
                                        <h6 class="">
                                            <a class="" style="text-decoration: none;color: rgb(39, 76, 119)"
                                               href="mailto:{{currentFormData!.email}}" target="_blank">
                                                {{currentFormData!.email}}
                                            </a>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-2">
                                    <a (click)="whatsApp(currentFormData!.countryCodePhoneNo, currentFormData!.phoneNo)">
                                    <i class="bi bi-whatsapp roundIcon"></i>
                                </a>
                                </div>
                                <div class="col-10 mt-1">
                                    <div class="whatsapp">
                                        <h6 class=" mt-1" type="button"
                                            (click)="whatsApp(currentFormData!.countryCodeWhatsAppNo, currentFormData!.whatsAppNumber)">
                                            {{currentFormData!.countryCodeWhatsAppNo}} {{formatNumber(currentFormData!.whatsAppNumber)}}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-1"></div>
                        <div class="row mt-3"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-3"></div>
    <div class="row mt-3"></div>
</section>













<section class="py-2"  *ngIf="profilehide">
    <div class="container-fluid">
        <div class="container">
            <div class="box">

                <div class="row">
                    <div class="col-md-12 mt-2 text-center">
                        <img class="companyProfile" [src]="companySrcUrl" alt="Company Logo">
                    </div>
                </div>

                <div class="row">
                    <div class="col-1"></div>
                    <div class="col-md-9 mt-2 text-center" style="color: rgb(39, 76, 119);word-wrap: break-word;
                    max-width: 75%">
                        <h4 class="mt-1">{{currentFormData!.companyName}}</h4>
                    </div>
                    <div class="col-2"></div>
                </div>
                <div class="boxContent">
                    <div class="row">
                        <div class="col-md-12 text-center mt-1">
                        </div>
                    </div>
                    <hr style="max-width:70%;color:rgb(0, 183, 255);margin-left:40px;">
                    <div class="row mt-2">
                        <div class="col-12 text-center">
                            <h3 class="fullName">{{currentFormData!.firstName}} {{currentFormData!.lastName}}</h3>
                            <h6 class="designation">{{currentFormData!.designation}}</h6>
                            <h6 class="role">{{currentFormData!.role}}</h6>
                        </div>
                    </div>
                    <hr style="max-width:70%;color:rgb(0, 183, 255);margin-left:40px;">
                    <div class="row">
                        <div class="col-1"></div>
                        <div class="col-10">
                            <div class="row mt-2">
                                <div class="col-2"><i class="bi bi-telephone-fill roundIcon"></i>
                                </div>
                                <div class="col-10 mt-1">
                                    <div class="call">
                                        <h6 (click)="copyPhoneNo(currentFormData!.countryCodePhoneNo, formatNumber(currentFormData!.phoneNo))">
                                            {{currentFormData!.countryCodePhoneNo}} {{formatNumber(currentFormData!.phoneNo)}}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-2">
                                    <i class="bi bi-geo-alt-fill roundIcon"></i>
                                </div>
                                <div class="col-9 mt-1">
                                    <div class="address">
                                        <h6>{{currentFormData!.address}}</h6>
                                    </div>
                                </div>
                                <div class="col-1"></div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-2">
                                    <a href="http://{{currentFormData!.companyWebsiteName}}" target="_blank">
                                    <i class="bi bi-globe-central-south-asia roundIcon"></i>
                                </a>
                                </div>
                                <div class="col-10 mt-1">
                                    <div class="website">
                                        <h6>
                                            <a class="" style="text-decoration: none;color: rgb(39, 76, 119);word-wrap: break-word;word-break: break-all;"
                                               href="http://{{currentFormData!.companyWebsiteName}}" target="_blank">
                                                {{currentFormData!.companyWebsiteName}}
                                            </a>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-2">
                                    <a  href="mailto:{{currentFormData!.email}}" target="_blank">
                                    <i class="bi bi-envelope-at-fill roundIcon"></i>
                                </a>
                                </div>
                                <div class="col-10 mt-1">
                                    <div class="email">
                                        <h6 class="">
                                            <a class="" style="text-decoration: none;color: rgb(39, 76, 119)"
                                               href="mailto:{{currentFormData!.email}}" target="_blank">
                                                {{currentFormData!.email}}
                                            </a>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-2">
                                    <a (click)="whatsApp(currentFormData!.countryCodePhoneNo, currentFormData!.phoneNo)">
                                    <i class="bi bi-whatsapp roundIcon"></i>
                                </a>
                                </div>
                                <div class="col-10 mt-1">
                                    <div class="whatsapp">
                                        <h6 class=" mt-1" type="button"
                                            (click)="whatsApp(currentFormData!.countryCodeWhatsAppNo, currentFormData!.whatsAppNumber)">
                                            {{currentFormData!.countryCodeWhatsAppNo}} {{formatNumber(currentFormData!.whatsAppNumber)}}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-1"></div>
                        <div class="row mt-3"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-3"></div>
    <div class="row mt-3"></div>
</section>







<ngx-spinner bdColor="rgba(255, 255, 255, 1)" size="large" color="#FFFFFF" [fullScreen]="true">
    <figure class="loader">
        <div class="dot white"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
    </figure>
</ngx-spinner>
<app-ecard-footer></app-ecard-footer>
