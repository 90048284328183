import {Component, OnInit} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ECardForm } from '@modules/ecard/modal/eCardForm';
import { Register } from '@modules/ecard/modal/register';
import { AppService } from '@services/app.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, takeUntil } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit{
  vssp=false;
  novsspl=true;
  emailsplit: { BEFORE: string, AFTER: string }[] = [];
  mail:string;
  isModalShow = false;
  showAddCard = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  eventDetails : ECardForm [] = []
  userDetails: Register[] = [];
  accessNumber : number = 0
  userId : number = 0
  isNewUser = false
  constructor(private router:Router,private appService : AppService,private spinner : NgxSpinnerService){

  }

  registrationForm = new UntypedFormGroup({
    previewType: new UntypedFormControl("", Validators.required),
  })

  profileError = {
    previewType : ''
  }

  addCard(){
    this.router.navigate(['/main/eCardForm'])
  }

  ngOnInit(): void {
    this.spinner.show()
      if(localStorage.getItem('eCardName')){
        window.location.reload()
        localStorage.removeItem("eCardName")
      }
    this.getUserDetails()
    //@ts-ignore
    this.userId = localStorage.getItem('userId')
    console.log("userid::",this.userId)
    this.getRegisterUserDetails()
    setTimeout(() => {
   this.isNewUser = false
    }, 3000);
  }

  businessCard(){
    this.isModalShow = true
  }

  //Close Modal Popup
  closeModal() {
    this.profileError.previewType = ""
    let hasError = false
    if (this.registrationForm.get('previewType')?.invalid) {
        // this.perviewPopup()
        hasError = true
    }
    if (!hasError) {
        this.isModalShow = false;
    }
}

perviewPopup() {
  Swal.fire({
      position: 'center',
      icon: 'warning',
      title: 'Selcet the Perview Image',
      showConfirmButton: false,
      timer: 1500
  })
}

getUserDetails() {
  this.appService.getUserDetails(localStorage.getItem('userId'))
    .pipe(takeUntil(this.destroy$))
    .subscribe((data) => {
      // console.log("user Reg ID::", this.userId)
      console.log("ECardForm:", data)
      this.eventDetails = data;
      console.log("UserECardForm:", this.eventDetails.length)
      this.spinner.hide()
      this.newUser()
    },(erorr : any) => {
      console.log("something went wrong")
      this.spinner.hide()
    })

  }

  getRegisterUserDetails() {
    this.appService.getRegisterUserDetails(this.userId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (Array.isArray(data)) {
          this.userDetails = data;
        } else {
          this.userDetails = [data];
        }
        console.log("getpresondetails::", data);
        var localprime: number = 0;
        for (let user of this.userDetails) {
          localprime = user.primeCount;
        }
        this.accessNumber = localprime;
        console.log("primenumber::", this.accessNumber);

      for(let mails of this.userDetails ){
        this.mail = mails.emailId
      }
      console.log("EMAIL ONLY::",this.mail)
        var [ BEFORE , AFTER ] = this.mail.split('@');
        this.emailsplit.push({ BEFORE , AFTER })
        if(AFTER.includes("msservice")){
          this.vssp=true;
          this.novsspl=false;
          this.spinner.hide()
        }
      console.log("EMAIL @ AFTER::",this.emailsplit)
      },(erorr : any) =>{
        console.log("something went wrong")
        this.spinner.hide()
      });
  }

  newUser(){
    if(this.eventDetails.length == 0 ){
      this.isNewUser = true
    }else{
      this.isNewUser = false
    }
  }

getPreviewType(preiewType : string){
  const value = preiewType
  console.log("previewType::",preiewType)
  console.log("userLength::",this.eventDetails.length)
  console.log("AccessNUmber::",this.accessNumber)
  if(this.eventDetails.length >= this.accessNumber){
    this.showAddCard = true
  }else{
  localStorage.setItem("currentPreviewType",preiewType)
  console.log("preview::",localStorage.getItem("currentPreviewType"))
  this.router.navigate(["/main/eCardForm"])
  }
}

vsspltemplate(preiewType : string){
  console.log("nv::",preiewType)
  localStorage.setItem("currentPreviewType",preiewType)
  console.log("previewvsspl::",localStorage.getItem("currentPreviewType"))
  this.router.navigate(["/main/vsspleCardForm"])
}
eCardNameclose(){
  this.showAddCard = false
}
}
