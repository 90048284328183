<section class="vh-100" style="background-image: url(../../assets/img/backgroundImages/pxfuel.jpg)">
    <div class="container-fluid py-2  justify-content-center align-items-center"
         style="text-align: -webkit-center;">
        <div>
            <div class="page-wrapper"  id="home-section">
                <div class="page-details">
                    <div>
                        <div class="p-10"></div>
                        <div>
                            <img [src]="companySrcUrl" *ngIf="profileShow" class="companyimage mt-2" crossorigin="anonymous">
                        </div>
                        <h3 class="firmname"><b>{{currentFormData!.companyName}}</b></h3>
                        
                                <div class="">
                                    <img [src]="profileSrcUrl" class="image"  crossorigin="anonymous">
                                </div>
                                <h5 class="name mt-2"><b>{{currentFormData!.firstName}} {{currentFormData!.lastName}}</b></h5>
                                    <h6 class="designation">{{currentFormData!.designation}}</h6>
                                    <h6 class="role ">{{currentFormData!.role}}</h6>
                    </div>
                    <div class="py-1" style="border-bottom: 2px solid rgb(255, 255, 255);"></div>
                    <div class="p-10"></div>
                    <div class="p-10"></div>
                    <div class="details">
                        <table class="contact-action-table">
                            <tbody class="table-stripped "> 
                            <tr>
                                <td >
                                    <i class="fas fa-map-marker-alt contact-action-container-icon"></i>
                                </td>
                                <td>
                                    <a class="contact-action-container-text mt-3" style="max-width:90%; color: rgb(255, 255, 255);">
                                        {{currentFormData!.address}}
                                    </a>
                                </td>
                            </tr>
                            <tr></tr>
                            <tr>
                                <td>
                                    <a href="mailto:{{currentFormData!.email}}">
                                        <i class="fas fa-envelope contact-action-container-icon"></i>
                                    </a>
                                </td>
                                <td>
                                    <a href="mailto:{{currentFormData!.email}}"
                                       class="contact-action-container-text mt-4" style="color: rgb(255, 255, 255);line-break: anywhere;">
                                        {{currentFormData!.email}}
                                    </a><br>
                                    <a href="mailto:" class="contact-action-container-text">
                                    </a><br>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a target="_blank" href="http://{{currentFormData!.companyWebsiteName}}">
                                        <i class="fas fa-globe contact-action-container-icon"></i>
                                    </a>
                                </td>
                                <td>
                                    <a target="_blank" href="http://{{currentFormData!.companyWebsiteName}}"
                                       class="contact-action-container-text"  style="color: rgb(255, 255, 255);line-break: anywhere;">
                                        {{currentFormData!.companyWebsiteName}}
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a target="_blank"
                                       href="tel:{{currentFormData!.countryCodePhoneNo}}{{currentFormData!.phoneNo}}">
                                        <i class="bi bi-telephone-fill contact-action-container-icon"></i>
                                    </a>
                                </td>
                                <td>
                                    <a target="_blank"href="tel:{{currentFormData!.countryCodePhoneNo}}{{currentFormData!.phoneNo}}"
                                       class="contact-action-container-text mt-4" style="color: rgb(255, 255, 255);">
                                        {{currentFormData!.countryCodePhoneNo}} {{currentFormData!.phoneNo}}
                                    </a>
                                    <br>
                                    <a target="_blank" href="tel:{{currentFormData!.telephone}}"class="contact-action-container-text" style="color: rgb(255, 255, 255);">
                                </a>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        <div class="lastline">
                            <a class="contact-button" target="_blank"
                               (click)="whatsApp(currentFormData!.countryCodeWhatsAppNo, currentFormData!.whatsAppNumber)">
                                <img src="../../../../assets/img/icons/whatsapp.png" height="25px" width="25px" alt="">
                                {{currentFormData!.countryCodeWhatsAppNo}}{{formatNumber(currentFormData!.whatsAppNumber)}}
                            </a>
                            <div class="row py-1"></div>
                        </div>

                        <div class="p-30"></div>
                        <ul class="inprofile share-buttons">
                        </ul>
                        <div class="p-20"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<ngx-spinner bdColor="rgba(255, 255, 255, 1)" size="large" color="#FFFFFF"
             [fullScreen]="true">
    <figure class="loader">
        <div class="dot white"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
    </figure>
</ngx-spinner>
<app-ecard-footer></app-ecard-footer>
