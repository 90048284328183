import {Component, HostBinding, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {AppService} from '@services/app.service';
import Swal from 'sweetalert2';
import { Subject, takeUntil } from 'rxjs';
import { LoginResponse } from '@modules/ecard/modal/loginResponse';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit{
  @HostBinding('class') class = 'login-box';
  destroy$: Subject<boolean> = new Subject<boolean>();
  loginResponse: LoginResponse | undefined
  isPasswordVisible: boolean = false;

  constructor(private appService: AppService, private router: Router, private spinner: NgxSpinnerService,
    private renderer: Renderer2,) {
  }
  // ngOnDestroy(): void {
  //   throw new Error('Method not implemented.');
  // }

  login = new UntypedFormGroup({
      userName: new UntypedFormControl('', Validators.required),
      password: new UntypedFormControl('', Validators.required)
  })
  public loginPage = {
      userName: '',
      password: '',
  }

  onSubmit() {
      this.loginValidation()
  }

  ngOnInit() {
//  this.renderer.addClass(
//       document.querySelector('app-root'),
//       'login-page'
//     );
  }

  register(){
  this.router.navigate(['/register'])
  console.log("check::")
  }

  loginValidation() {
      this.loginPage.userName = "";
      this.loginPage.password = "";

      let hasError = false;

      if (this.login.get("userName")?.invalid) {
          this.loginPage.userName = "*Enter Username";
          hasError = true;
      }
      if (this.login.get("password")?.invalid) {
          this.loginPage.password = "*Enter password";
          hasError = true;
      }
      if (!hasError) {
          this.saveLogin();
      }
  }

  saveLogin() {
      console.log("save Event:", this.login.value)
      this.spinner.show();
      this.appService
          .saveLoginDetails(this.login.value)
          .pipe(takeUntil(this.destroy$))
          .subscribe((data) => {
              console.log("save Event:", data);
              this.loginResponse = data;
              localStorage.setItem('authToken', String(this.loginResponse?.authHeader));
              localStorage.setItem('userId', String(this.loginResponse?.loginUser.regId));
              localStorage.setItem('username', (this.loginResponse?.loginUser.userName))
              this.SuccessAlert();
              this.spinner.hide();
              this.router.navigate(['/main/dash'])
          },
              (err: any) => {
                  this.ErrorAlert();
                  this.spinner.hide();
              })
      console.log('HTTP request completed.')
  }

  SuccessAlert() {
      Swal.fire({
          title: "Login Successfully",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
          showClass: {
              popup: "animate_animated animate_fadeInDown",
          },
          hideClass: {
              popup: "animate_animated animate_fadeOutUp",
          },
      });
  }

  ErrorAlert() {
      Swal.fire({
          title: "Sorry, Please Try Again Later",
          icon: "error",
          showConfirmButton: false,
          timer: 1500,
          showClass: {
              popup: "animate_animated animate_fadeInDown",
          },
          hideClass: {
              popup: "animate_animated animate_fadeOutUp",
          },
      });
  }
  togglePasswordVisibility() {
      const passwordShow = document.getElementById('password') as HTMLInputElement;
      if (passwordShow.type == 'password') {
          passwordShow.type = 'text';
      } else {
          passwordShow.type = 'password';
      }
      this.isPasswordVisible = !this.isPasswordVisible;
  }
}
