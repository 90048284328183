 <div class="container vh-100 p-0" *ngIf="profileShow">
    <div class="container  py-2 justify-content-center" style="max-width: 400px;">
      <div id="card" class=" gradient-custom" style="box-shadow: 2px 2px 20px black;max-width: 400px;">
        <div class="card mt-3 bg-white" style="text-align: center; height: 11rem">
          <div class="mt-1">
            <img class="img-fluid" style="width: 80px;" alt="avatar2" alt="Responsive image" [src]="companySrcUrl"/>
            <h4 class="fw-bolder ml-1 mr-1">{{currentFormData!.companyName}}</h4>
          </div>
          <div class="row mt-4 py-5"></div>
        </div>

        <div class="row">
          <div class="img2" ><img [src]="profileSrcUrl" alt=""></div>
        </div>

        <div class="main-text ">
          <div id="aa" class="card mb-3" >
            <div class="row col-12 mt-5"></div>
            <div class="text-center mt-2">

              <h3 class="mt-2" style="margin-bottom: 1px;"><b>{{currentFormData!.firstName}} {{currentFormData!.lastName}}</b></h3>
              <h6 style="">{{currentFormData!.designation}}</h6>
              <h6 class="" style="margin-bottom: 1px;">{{currentFormData!.role}}</h6>

              <div class="row mt-3">
                <div class="col-1"></div>
                <div class="col-1">
                  <i title="Phone No" class="bi bi-phone-fill icon"></i>
                </div>
                <div class="col-9 mt-1">
                  <h6 class="text-left" (click)="copyPhoneNo(currentFormData!.countryCodePhoneNo, formatNumber(currentFormData!.phoneNo))">
                    {{currentFormData!.countryCodePhoneNo}} {{formatNumber(currentFormData!.phoneNo)}}
                  </h6>
                </div>
              </div>
              
              <div class="row mt-3">
                <div class="col-1"></div>
                <div class="col-1" *ngIf="currentFormData!.telephone != ''">
                  <i title="Phone No" class="bi bi-telephone-fill icon"></i>
                </div>
                <div class="col-9 mt-1">
                  <h6 class=" text-left" (click)="copyTelephoneNo(currentFormData!.telephone)">
                    {{currentFormData!.telephone}}
                  </h6>
                </div>
              </div>
              
              <div class="row mt-3">
                <div class="col-1"></div>
                <div class="col-1">
                  <i class="fas fa-globe icon"></i>
                </div>
                <div class="col-9">
                  <h6 class="text-left">
                    <a class="text-dark" style="text-decoration: none;" href="http://{{currentFormData!.companyWebsiteName}}" target="_blank">
                      {{currentFormData!.companyWebsiteName}}
                    </a>
                  </h6>
                </div>
              </div>
              
              <div class="row mt-3">
                <div class="col-1"></div>
                <div class="col-1">
                  <i class="fas fa-envelope icon"></i>
                </div>
                <div class="col-9">
                  <h6 class="text-left">
                    <a class="text-dark" href="mailto:{{currentFormData!.email}}" target="_blank">{{currentFormData!.email}}</a>
                  </h6>
                </div>
              </div>
              
              <div class="row mt-3">
                <div class="col-1"></div>
                <div class="col-1">
                  <i class="fas fa-map-marker-alt icon"></i>
                </div>
                <div class="col-9">
                  <h6 class="text-left">{{currentFormData!.address}}</h6>
                </div>
              </div>
              
              <div class="row mt-3">
                <div class="col-1"></div>
                <div class="col-1">
                  <i class="bi bi-whatsapp icon" title="WhatsApp"></i>
                </div>
                <div class="col-9 mt-1">
                  <h6 class=" text-left" (click)="whatsApp(currentFormData!.countryCodeWhatsAppNo, currentFormData!.whatsAppNumber)">
                    {{currentFormData!.countryCodeWhatsAppNo}} {{formatNumber(currentFormData!.whatsAppNumber)}}
                  </h6>
                </div>
              </div>
              <div class="row py-1"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>








  <div class="container vh-100" *ngIf="noProfiel">
    <div class="container  py-3 justify-content-center" style="max-width: 400px;">
      <div id="card" class=" gradient-custom" style="box-shadow: 2px 2px 20px black;max-width: 400px;">
        <div class="card mt-3 " style="text-align: center; height: 12rem">
          <div class="mt-1">
            <img class="img-fluid mt-3"  style="width: 80px;" alt="avatar2" alt="Responsive image"
                             [src]="companySrcUrl"/>
          </div>
          <h4 class="fw-bolder mt-2 mr-1 ml-1">{{currentFormData!.companyName}}</h4>
        </div>
        <div class="main-text" style="margin-top: 22%;">
          <div id="aa" class="card mb-3">
            <div class="row col-12 py-2"></div>
            <div class="text-center">

              <h3 class="mt-1" style="margin-bottom: 1px;"><b>{{currentFormData!.firstName}} {{currentFormData!.lastName}}</b></h3>
              <h6 style="">{{currentFormData!.designation}}</h6>
              <h6 class="" style="margin-bottom: 1px;">{{currentFormData!.role}}</h6>

              <div class="row mt-3">
                <div class="col-1"></div>
                <div class="col-1">
                  <i title="Phone No" class="bi bi-phone-fill"></i>
                </div>
                <div class="col-9 mt-1">
                  <h6 class="text-left" (click)="copyPhoneNo(currentFormData!.countryCodePhoneNo, formatNumber(currentFormData!.phoneNo))">
                    {{currentFormData!.countryCodePhoneNo}} {{formatNumber(currentFormData!.phoneNo)}}
                  </h6>
                </div>
              </div>
              
              <div class="row mt-3">
                <div class="col-1"></div>
                <div class="col-1" *ngIf="currentFormData!.telephone != ''">
                  <i title="Phone No" class="bi bi-telephone-fill"></i>
                </div>
                <div class="col-9 mt-1">
                  <h6 class=" text-left" (click)="copyTelephoneNo(currentFormData!.telephone)">
                    {{currentFormData!.telephone}}
                  </h6>
                </div>
              </div>
              
              <div class="row mt-3">
                <div class="col-1"></div>
                <div class="col-1">
                  <i class="fas fa-globe"></i>
                </div>
                <div class="col-9">
                  <h6 class="text-left">
                    <a class="text-dark" style="text-decoration: none;" href="http://{{currentFormData!.companyWebsiteName}}" target="_blank">
                      {{currentFormData!.companyWebsiteName}}
                    </a>
                  </h6>
                </div>
              </div>
              
              <div class="row mt-3">
                <div class="col-1"></div>
                <div class="col-1">
                  <i class="fas fa-envelope"></i>
                </div>
                <div class="col-9">
                  <h6 class="text-left">
                    <a class="text-dark" href="mailto:{{currentFormData!.email}}" target="_blank">{{currentFormData!.email}}</a>
                  </h6>
                </div>
              </div>
              
              <div class="row mt-3">
                <div class="col-1"></div>
                <div class="col-1">
                  <i class="fas fa-map-marker-alt"></i>
                </div>
                <div class="col-9">
                  <h6 class="text-left">{{currentFormData!.address}}</h6>
                </div>
              </div>
              
              <div class="row mt-3">
                <div class="col-1"></div>
                <div class="col-1">
                  <i class="bi bi-whatsapp" title="WhatsApp"></i>
                </div>
                <div class="col-9 mt-1">
                  <h6 class=" text-left" (click)="whatsApp(currentFormData!.countryCodeWhatsAppNo, currentFormData!.whatsAppNumber)">
                    {{currentFormData!.countryCodeWhatsAppNo}} {{formatNumber(currentFormData!.whatsAppNumber)}}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row"></div>
  </div>











<ngx-spinner bdColor="rgba(255, 255, 255, 1)" size="large" color="#FFFFFF"
             [fullScreen]="true">
  <figure class="loader">
    <div class="dot white"></div>
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
  </figure>
</ngx-spinner>
<app-ecard-footer></app-ecard-footer>
