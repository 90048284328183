<section class="vh-100" style="background-color:  rgb(172, 172, 172);" *ngIf="profileShow">
    <div class="container-fluid py-2 d-flex justify-content-center align-items-center"
         style="text-align: -webkit-center;">
        <div>
            <div class="page-wrapper" id="home-section">
                <div class="page-details">
                    <div>
                        <div>
                            <img [src]="companySrcUrl" class="companyimage mt-4" crossorigin="anonymous">
                        </div>

                        <h4 class="firmname mt-2 mb-1 ms-3"style="margin-top:15px;">{{currentFormData!.companyName}}</h4>
                        <div class="ms-3" style="border-bottom:#595a50 2px solid;margin-top: 15px;margin-bottom: 15px;"></div>
                        <div class="col-md-12 text-center" >
                            <img [src]="profileSrcUrl" class="image" crossorigin="anonymous">
                        </div>
                        <h3 class="name mt-1" ><b>{{currentFormData!.firstName}} {{currentFormData!.lastName}}</b></h3>
                            <h6 class="designation " ><b>{{currentFormData!.designation}}</b></h6>
                            <h6 class="role mt-1" ><b>{{currentFormData!.role}}</b></h6>
                    </div>
                        
                    <div>
                        <div class="contact-buttons">
                            <a class="contact-button"  style="border: 1px solid black;"
                               href="tel:{{currentFormData!.countryCodePhoneNo}}{{currentFormData!.phoneNo}}">
                                <i class="fas fa-phone fa-flip-horizontal mr-1"></i>
                               <b>Call</b>
                            </a>
                            
                            <a class="contact-button" style="border: 1px solid black;"
                            target="_blank" href="mailto:{{currentFormData!.email}}">
                                <i class="fas fa-envelope fa-flip-horizontal mr-1"></i>
                                <b>Mail</b>
                            </a>
                       </div>
                        <table class="contact-action-table">
                            <tbody class="table-stripped"> 
                            <tr>
                                <td >
                                    <i class="fas fa-map-marker-alt contact-action-container-icon"></i>
                                </td>
                                <td>
                                    <a class="contact-action-container-text mt-3" style="max-width: 220px;">
                                        {{currentFormData!.address}}
                                    </a>
                                </td>
                            </tr>
                            <tr></tr>
                            <tr>
                                <td>
                                    <a href="mailto:{{currentFormData!.email}}">
                                        <i class="fas fa-envelope contact-action-container-icon"></i>
                                    </a>
                                </td>
                                <td>
                                    <a href="mailto:{{currentFormData!.email}}"
                                       class="contact-action-container-text mt-4">
                                        {{currentFormData!.email}}
                                    </a><br>
                                    <a href="mailto:" class="contact-action-container-text">
                                    </a><br>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a target="_blank" href="http://{{currentFormData!.companyWebsiteName}}">
                                        <i class="fas fa-globe contact-action-container-icon"></i>
                                    </a>
                                </td>
                                <td>
                                    <a target="_blank" href="http://{{currentFormData!.companyWebsiteName}}"
                                       class="contact-action-container-text">
                                        {{currentFormData!.companyWebsiteName}}
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a target="_blank"
                                       href="tel:{{currentFormData!.countryCodePhoneNo}}{{currentFormData!.phoneNo}}">
                                        <i class="bi bi-telephone contact-action-container-icon mt-3"></i>
                                    </a>
                                </td>
                                <td>
                                    <a target="_blank"href="tel:{{currentFormData!.countryCodePhoneNo}}{{currentFormData!.phoneNo}}"
                                       class="contact-action-container-text mt-3">
                                        {{currentFormData!.countryCodePhoneNo}} {{currentFormData!.phoneNo}}
                                    </a>
                                    <br>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="p-30  mt-4 pt-1"></div>
                                </td>
                                <td>
                                    <div class="p-30  mt-3"></div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div class="p-30"></div>
                        <div class="p-30 mt-4"></div>
                        <ul class="inprofile share-buttons">
                        </ul>
                        <div class="p-20"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>












<section class="vh-100" style="background-color:  rgb(172, 172, 172);" *ngIf="profilehide">
    <div class="container-fluid py-2 d-flex justify-content-center align-items-center"
         style="text-align: -webkit-center;">
        <div>
            <div class="page-wrapper" id="home-section">
                <div class="page-details">
                    <div>
                        <div>
                            <img [src]="companySrcUrl" class="image mt-4" crossorigin="anonymous">
                        </div>

                        <h4 class="firmname mt-3 mb-1 ms-3"style="margin-top:15px;">{{currentFormData!.companyName}}</h4>
                        <div class="ms-3 mt-2" style="border-bottom:#595a50 2px solid;margin-top: 15px;margin-bottom: 15px;"></div>
                        <div class="col-md-12 text-center" >
                        </div>
                        <h3 class="name mt-1" ><b>{{currentFormData!.firstName}} {{currentFormData!.lastName}}</b></h3>
                            <h6 class="designation " ><b>{{currentFormData!.designation}}</b></h6>
                            <h6 class="role mt-1" ><b>{{currentFormData!.role}}</b></h6>
                    </div>
                        
                    <div>
                        <div class="contact-buttons">
                            <a class="contact-button"  style="border: 1px solid black;"
                               href="tel:{{currentFormData!.countryCodePhoneNo}}{{currentFormData!.phoneNo}}">
                                <i class="fas fa-phone fa-flip-horizontal mr-1"></i>
                               <b>Call</b>
                            </a>
                            
                            <a class="contact-button" style="border: 1px solid black;"
                            target="_blank" href="mailto:{{currentFormData!.email}}">
                                <i class="fas fa-envelope fa-flip-horizontal mr-1"></i>
                                <b>Mail</b>
                            </a>
                       </div>
                        <table class="contact-action-table">
                            <tbody class="table-stripped"> 
                            <tr>
                                <td >
                                    <i class="fas fa-map-marker-alt contact-action-container-icon"></i>
                                </td>
                                <td>
                                    <a class="contact-action-container-text mt-3" style="max-width: 220px;">
                                        {{currentFormData!.address}}
                                    </a>
                                </td>
                            </tr>
                            <tr></tr>
                            <tr>
                                <td>
                                    <a href="mailto:{{currentFormData!.email}}">
                                        <i class="fas fa-envelope contact-action-container-icon"></i>
                                    </a>
                                </td>
                                <td>
                                    <a href="mailto:{{currentFormData!.email}}"
                                       class="contact-action-container-text mt-4">
                                        {{currentFormData!.email}}
                                    </a><br>
                                    <a href="mailto:" class="contact-action-container-text">
                                    </a><br>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a target="_blank" href="http://{{currentFormData!.companyWebsiteName}}">
                                        <i class="fas fa-globe contact-action-container-icon"></i>
                                    </a>
                                </td>
                                <td>
                                    <a target="_blank" href="http://{{currentFormData!.companyWebsiteName}}"
                                       class="contact-action-container-text">
                                        {{currentFormData!.companyWebsiteName}}
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a target="_blank"
                                       href="tel:{{currentFormData!.countryCodePhoneNo}}{{currentFormData!.phoneNo}}">
                                        <i class="bi bi-telephone contact-action-container-icon mt-3"></i>
                                    </a>
                                </td>
                                <td>
                                    <a target="_blank"href="tel:{{currentFormData!.countryCodePhoneNo}}{{currentFormData!.phoneNo}}"
                                       class="contact-action-container-text mt-3">
                                        {{currentFormData!.countryCodePhoneNo}} {{currentFormData!.phoneNo}}
                                    </a>
                                    <br>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="p-30  mt-5 pt-2"></div>
                                </td>
                                <td>
                                    <div class="p-30  mt-5"></div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div class="p-30"></div>
                        <div class="p-30 mt-4"></div>
                        <ul class="inprofile share-buttons">
                        </ul>
                        <div class="p-20"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>






<ngx-spinner bdColor="rgba(255, 255, 255, 1)" size="large" color="#FFFFFF"
             [fullScreen]="true">
    <figure class="loader">
        <div class="dot white"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
    </figure>
</ngx-spinner>
<app-ecard-footer></app-ecard-footer>

