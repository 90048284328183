import {Component, OnInit} from '@angular/core';
import {ECardForm} from "../modal/eCardForm";
import {Subject, takeUntil} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import { FormDataService } from '@services/formDataService';
import { AppService } from '@services/app.service';

@Component({
  selector: 'app-e-card',
  templateUrl: './e-card.component.html',
  styleUrls: ['./e-card.component.css']
})
export class ECardComponent implements OnInit {
  currentFormData: ECardForm | undefined;
  companySrcUrl: string = ""
  profileSrcUrl: string = ""
  profileShow = true;
  yesProfiel = false;
  noProfiel = false;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private dataService: FormDataService, private route: ActivatedRoute,
              private appService: AppService, private spinner: NgxSpinnerService) {
  }

  ngOnInit(): void {
      this.route.queryParams.subscribe(params => {
        const eCardName = params['CardName'];
        console.log("e card name:", eCardName);
        this.getCardData(eCardName);
        this.getLogo(eCardName);
        this.getProfile(eCardName);
      });
  }

    formatNumber(number: string) {
        const first = number.slice(0, 5)
        const second = number.slice(5, 10)
        const formattedNumber = first + ' ' + second

        return formattedNumber
    }

  copyTelephoneNo(No: string) {
    const phoneNo = document.createElement('input');
    phoneNo.value = No;
    document.body.appendChild(phoneNo);
    phoneNo.select();
    document.execCommand('copy');
    document.body.removeChild(phoneNo);
    alert('Telephone Number Copied');
  }

    copyPhoneNo(code: string, No: string) {
        const phoneNo = document.createElement('input');
        phoneNo.value = code + ' ' + No;
        document.body.appendChild(phoneNo);
        phoneNo.select();
        document.execCommand('copy');
        document.body.removeChild(phoneNo);
        alert('Phone Number Copied');
    }

  getCardData(eCardName: string) {
    this.spinner.show();
    this.appService.getCardDetails(eCardName)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.spinner.hide();
        console.log("get card data:", data)
        this.currentFormData = data;
        if (this.currentFormData?.profileShow == "Yes") {
          this.profileShow = true;
          this.noProfiel=false;
        } else if (this.currentFormData?.profileShow == "No") {
          this.profileShow = false;
          this.noProfiel=true;      
        }
      }, (error: any) => {
        this.spinner.hide();
        console.log("something went wrong:", error)
      })
  }

  getLogo(eCardName: string) {
    this.spinner.show();
    return this.appService.fileGet(eCardName, "companyLogo")
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: Blob) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          this.spinner.hide();
          const base64data = reader.result as string;
          this.companySrcUrl = base64data;
          console.log("img url::", this.companySrcUrl);
        };
        reader.readAsDataURL(data);
      }, (error: any) => {
        this.spinner.hide();
        console.log("Something went wrong file upload", error)
      });
  }

  getProfile(eCardName: string) {
    return this.appService.fileGet(eCardName, "profile")
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: Blob) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64data = reader.result as string;
          this.profileSrcUrl = base64data;
          console.log("img url::", this.profileSrcUrl);
        };
        reader.readAsDataURL(data);
      });
  }

  whatsApp(code: string, number: string) {
    const href = "https://wa.me/" + code + number
    window.open(href);
  }

}
