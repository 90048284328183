<!--
<ng-container *ngIf="showAd">
    <ins class="adsbygoogle"
         style="display:block"
         data-ad-client="banner.adClient"
         data-ad-slot="banner.adSlot"
         data-ad-format="banner.adFormat"
         data-full-width-responsive="banner.fullWidthResponsive">
    </ins>
</ng-container>-->

<!--
<div class="text-center">
    <ins
            class="adsbygoogle ads_resp"
            style="display: inline-block"
            data-ad-client="ca-pub-8228159457289501"
            data-ad-slot="8223850896"
            data-full-width-responsive="true"
    ></ins>
</div>-->

<div class="ad-container">
    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?google_adtest=on"></script>
    <ins class="adsbygoogle"
         style="display:block"
         data-ad-client="ca-pub-8228159457289501"
    data-ad-slot="8223850896"
    data-ad-format="auto"></ins>
    <script>
        (adsbygoogle = window.adsbygoogle || []).push({});
    </script>
</div>
