import {Component, OnInit} from '@angular/core';
import {ECardForm} from "../modal/eCardForm";
import {ActivatedRoute} from "@angular/router";
import {Subject, takeUntil} from "rxjs";
import {NgxSpinner, NgxSpinnerService} from "ngx-spinner";
import { FormDataService } from '@services/formDataService';
import { AppService } from '@services/app.service';

@Component({
  selector: 'app-business-card',
  templateUrl: './business-card.component.html',
  styleUrls: ['./business-card.component.css']
})
export class BusinessCardComponent implements OnInit {
  currentFormData: ECardForm | undefined;
  companySrcUrl: string = ""
  profileSrcUrl: string = ""
  profileShow = true;
  profileHide = false;

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private dataService: FormDataService, private route: ActivatedRoute,
              private appService: AppService, private spinner: NgxSpinnerService) {
  }

  ngOnInit(): void {
      console.log("url function:")
      this.route.queryParams.subscribe(params => {
        const eCardName = params['CardName'];
        console.log("e card name:", eCardName);
        this.getCardData(eCardName);
        this.getLogo(eCardName);
        this.getProfile(eCardName);
      });
  }

  formatNumber(number: string) {
      const first = number.slice(0, 5)
      const second = number.slice(5, 10)
      const formattedNumber = first + ' ' + second

      return formattedNumber
  }

  copyPhoneNo(code: string, No: string) {
    const phoneNo = document.createElement('input');
    phoneNo.value = code + ' ' + No;
    document.body.appendChild(phoneNo);
    phoneNo.select();
    document.execCommand('copy');
    document.body.removeChild(phoneNo);
    alert('Phone Number Copied');
  }

    copyTelephoneNo(No: string) {
        const phoneNo = document.createElement('input');
        phoneNo.value = No;
        document.body.appendChild(phoneNo);
        phoneNo.select();
        document.execCommand('copy');
        document.body.removeChild(phoneNo);
        alert('Telephone Number Copied');
    }

  getCardData(eCardName: string) {
    this.spinner.show();
    this.appService.getCardDetails(eCardName)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.spinner.hide();
        console.log("get card data:", data)
        this.currentFormData = data;
        if (this.currentFormData?.profileShow == "Yes") {
          this.profileShow = true;
          this.profileHide = false;
        } else if (this.currentFormData?.profileShow == "No") {
          this.profileShow = false;
          this.profileHide = true;
        }
      }, (error: any) => {
        console.log("something went wrong:", error)
      })
  }

  getLogo(eCardName: string) {
    this.spinner.show();
    return this.appService.fileGet(eCardName, "companyLogo")
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: Blob) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          this.spinner.hide();
          const base64data = reader.result as string;
          this.companySrcUrl = base64data;
          console.log("img url::", this.companySrcUrl);
        };
        reader.readAsDataURL(data);
      });
  }

  getProfile(eCardName: string) {
    this.spinner.show();
    return this.appService.fileGet(eCardName, "profile")
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: Blob) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          this.spinner.hide();
          const base64data = reader.result as string;
          this.profileSrcUrl = base64data;
          console.log("img url::", this.profileSrcUrl);
        };
        reader.readAsDataURL(data);
      });
  }

  whatsApp(code: string, number: string) {
    const href = "https://wa.me/" + code + number
    window.open(href);
  }

}
