import { Component, OnInit } from '@angular/core';
import { ECardForm } from "../modal/eCardForm";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject, takeUntil } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { AppService } from '@services/app.service';
import { FormDataService } from '@services/formDataService';


@Component({
  selector: 'app-preview-twenty-seven',
  templateUrl: './preview-twenty-seven.component.html',
  styleUrls: ['./preview-twenty-seven.component.css']
})
export class PreviewTwentySevenComponent implements OnInit {
  companySrcUrl:string=""
    currentFormData:ECardForm|undefined;
    profileSrcUrl:string=""
    profileShow=false;
    profilehide=false;

    destroy$:Subject<boolean>=new Subject<boolean>();


  constructor(private spinner:NgxSpinnerService, private appService:AppService,private dataService:FormDataService,private route:ActivatedRoute) { }

  ngOnInit(): void {
    console.log("url function:")
        this.route.queryParams.subscribe(params =>{
            const eCardName=params['CardName']
            console.log ("e Card Name:",eCardName);
            this.getCardData(eCardName);
            this.getLogo(eCardName);
            this.getProfile(eCardName);
        });
  }
  formatNumber(number:string){
    const first=number.slice(0,5)
    const second=number.slice(5,10)
    const formattedNumber=first+''+second;
    return formattedNumber
}

copyPhoneNo( code:string,No:string){
    const phoneNo=document.createElement('input')
    phoneNo.value=code + '' + No;
    document.body.appendChild(phoneNo);
    phoneNo.select();
    document.execCommand('copy');
    document.body.removeChild(phoneNo);
    alert('phone number copied')


}
copyTelePhoneNo(No:string){
    const phoneNo=document.createElement('input')
    phoneNo.value=No;
    document.body.appendChild(phoneNo);
    phoneNo.select();document.execCommand('copy');document.body.remove
}
whatsApp(code:string,No:string){
    const href="https://wa.me/"+ code + No
    window.open(href)
}
getCardData(eCardName:string){
    this.spinner.show();
    this.appService.getCardDetails(eCardName)
    .pipe(takeUntil(this.destroy$))
    .subscribe(data=>{
        this.spinner.hide();
        console.log("get card data:",data)
        this.currentFormData=data;
        if(this.currentFormData?.profileShow == "Yes" )
        {
            this.profileShow=true;
            this.profilehide=false;
        }
        else if(this.currentFormData?.profileShow == "No")
        {
            this.profileShow=false;
            this.profilehide=true;
        }

    }
    ,(error:any)=>{
        console.log("something went error:",error)
    })


}
getLogo(eCardName:string){
    this.spinner.show();
    return this.appService.fileGet(eCardName,"companyLogo")
    .pipe(takeUntil(this.destroy$))
    .subscribe((data:Blob)=>{
        const reader=new FileReader();
        reader.onload=()=>{
            this.spinner.hide();
            const base64data=reader.result as string;
            this.companySrcUrl=base64data;
            console.log("img url:",this.companySrcUrl)
};
reader.readAsDataURL(data);
        

    });

}
getProfile(eCardName:string){
    this.spinner.show();
    return this.appService.fileGet(eCardName,"profile")
    .pipe(takeUntil(this.destroy$))
    .subscribe((data:Blob)=>{
        const reader=new FileReader();
        reader.onload=()=>{
            this.spinner.hide();
            const base64data=reader.result as string;
            this.profileSrcUrl=base64data;
            console.log("img url:",this.profileSrcUrl)
        }
        reader.readAsDataURL(data);
    })

}


}
