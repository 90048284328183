<nav class="navbar bg-white fixed-top">
  <div class="container-fluid">
    <a [routerLink]="['/website']"><img src="assets/img/ecardlogo.png"></a>
  </div>
</nav>
<div class="container-fluid" style="font-family: 'prompt';background-color: #fcf5ed">
  <section class="slider-main" style="background-image: url(./assets/img/website-slider.png);">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-xs-12 col-sm-12 col-md-7">
          <div class="text">
            <span class="text-white text-bold ml-3">Do you want to</span>
            <div class="heading">
              <h2 class="mt-3"> GROW YOUR BUSINESS</h2>
            </div>
            <div class="h4 mt-3"><p>Get Create your Digital eCard</p></div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-12 mt-3">
              <!--<button type="button" class="btn rounded-pill text-white text-bold" style="background-color: black;"
                      (click)="register()">Register Here
              </button>-->
              <button class="buttonReg" (click)="register()">Register</button>
            </div>
            <div class="col-lg-4 col-12 mt-3">
             <!-- <button type="button" class="btn rounded-pill text-black text-bold" style="background-color: white;"
                      (click)="login()">Login Here
              </button>-->
              <button class="buttonLogin" (click)="login()">Login</button>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-xs-12 col-sm-12 col-md-7">
          <div class="mobiles-section finite bounce delay-9s">
            <img src="./assets/img/mobile.png" class="img-fluid animated" alt="image"></div>
        </div>
      </div>
    </div>
  </section>
  <section class="container mt-5">
    <div class="row mb-5 mt-5">
      <div *ngIf="showContent" data-aos="flip-left" data-aos-duration="1200">
      <div class="col-12 text-center">
        <h3 class="text-bold" style="color:#5f5f5f;">How It <span style="color: #fe7840;">Works</span></h3>
        <a class="h5 text-decoration-none mt-3" style="color: #fe7840;" type="button" [routerLink]="['/design-a-business-card-for-free']">Click to read our blog to help to create your E Card</a>
        <p class="text-muted mt-3">Please follow the steps to proceed your Digital eCard creation</p>
      </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12">
        <div *ngIf="showContent" data-aos="zoom-in-right" data-aos-duration="1200">
        <div class="row">
          <div class="col-lg-4 col-xs-12 col-sm-6 col-md-4 text-center">
            <img src="./assets/img/create-account-2.png" alt="image" class="img-fluid mt-3">
            <h4 class="text-bold mt-5" style="color:#5f5f5f;">Create Account</h4>
            <p class="text-muted mt-3">Create your account using register option with our application. Enter your
              details and proceed</p>
          </div>
          <div class="col-lg-4 col-xs-12 col-sm-6 col-md-4 text-center">
            <img src="./assets/img/customize.png" alt="image" class="img-fluid mt-3">
            <h4 class="text-bold mt-5" style="color:#5f5f5f;">Customize</h4>
            <p class="text-muted mt-3">Craft Your Promotions Your Way!</p>
          </div>
          <div class="col-lg-4 col-xs-12 col-sm-6 col-md-4 text-center mt-3">
            <img src="./assets/img/share.png" alt="image" class="img-fluid">
            <h4 class="text-bold mt-5" style="color:#5f5f5f;">Done! Share your eCard</h4>
            <p class="text-muted mt-3">Share your eCard far and wide with family, friends, and colleagues</p>
          </div>
        </div>
        </div>
      </div>
    </div>
  </section>

  <section class="mt-5">
    <div class="container mt-5">
      <div class="row mt-5">
        <div class="col-lg-6 col-xs-12 col-sm-6 col-md-6">
          <div class="section mt-5">
            <img src="./assets/img/digitalcard.png" alt="image" class="img-fluid">
          </div>
        </div>
        <div class="col-lg-6 col-xs-12 col-sm-6 col-md-6">
          <div class="section mt-5">
            <div class="row mt-5">
              <h1 class="text-bold" style="color:#5f5f5f;"><span style="color:#fe7840">About Our</span> Digital Card
              </h1>
              <p class="text-muted mt-4">eCard.VarNik.Cloud is a digital platform that enables users to create and share
                their own customized
                digital business cards. The platform allows users to create and design their digital cards, add their
                contact information, and include links to their social media profiles or websites.</p>
            </div>
            <ul class="row">
              <div class="col-md-3 col-lg-4"><i class="fa fa-check-circle" style="color:#fe7840"></i><span class="ml-2"
                                                                                                           style="color:#5f5f5f;">Create</span>
              </div>
              <div class="col-md-3 col-lg-4"><i class="fa fa-check-circle" style="color:#fe7840"></i><span class="ml-2"
                                                                                                           style="color:#5f5f5f;">Customize</span>
              </div>
              <div class="col-md-3 col-lg-4"><i class="fa fa-check-circle" style="color:#fe7840"></i><span class="ml-2"
                                                                                                           style="color:#5f5f5f;">Connect</span>
              </div>
            </ul>
            <div class="col-lg-6 col-12">
              <button type="button" class="btn text-white rounded-pill text-bold buttonHover" style="background-color: #fe7840" (click)="register()">
                visit our eCard website
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>


  <div class="container-fluid bg-light m-2" style="font-family: 'prompt';background-color: #fcf5ed">
    <section>
      <div class="row mt-5" style="background-color: #fcf5ed">
        <div class="col-12 text-center">
          <h2 class="text-bold mt-5" style="color:#5f5f5f; margin-top:30px"><span style="color:#fe7840">ECard</span>
            Design</h2>
          <p class="text-muted" style="color:#5f5f5f; margin-top:30px">Attractive 50+ Templates to go with.........</p>
        </div>
      </div>

      <!-- <div class="row mt-5">
        <div class="client-slider mb-5">
          <div class="client-slide-track">
            <div class="client-slide">
              <img src="./assets/img/previewImages/previewFifty.png" height="400" width="250" alt=""/>
            </div>
            <div class="client-slide">
              <img src="./assets/img/previewImages/previewEighteen.png" height="400" width="250" alt=""/>
            </div>
            <div class="client-slide">
              <img src="./assets/img/previewImages/previewFifteen.png" height="400" width="250" alt=""/>
            </div>
            <div class="client-slide">
              <img src="./assets/img/previewImages/preview7.png" height="400" width="250" alt=""/>
            </div>
            <div class="client-slide">
              <img src="./assets/img/previewImages/previewThirty.png" height="400" width="250" alt=""/>
            </div>
            <div class="client-slide">
              <img src="./assets/img/previewImages/previewThirtyeight.png" height="400" width="250" alt=""/>
            </div>
            <div class="client-slide">
              <img src="./assets/img/previewImages/previewTen.png" height="400" width="250" alt=""/>
            </div>
            <div class="client-slide">
              <img src="./assets/img/previewImages/previewTwenty.png" height="400" width="250" alt=""/>
            </div>
            <div class="client-slide">
              <img src="./assets/img/previewImages/previewThirtyeight.png" height="400" width="250" alt=""/>
            </div>
            <div class="client-slide">
              <img src="./assets/img/previewImages/previewFour.png" height="400" width="250" alt=""/>
            </div>
            <div class="client-slide">
              <img src="./assets/img/previewImages/previewSixteen.png" height="400" width="250" alt=""/>
            </div>
            <div class="client-slide">
              <img src="./assets/img/previewImages/previewTwentynine.png" height="400" width="250" alt=""/>
            </div>
            <div class="client-slide">
              <img src="./assets/img/previewImages/previewTwentythree.png" height="400" width="250" alt=""/>
            </div>
            <div class="client-slide">
              <img src="./assets/img/previewImages/previewThirtyeight.png" height="400" width="250" alt=""/>
            </div>
          </div>
        </div>
      </div> -->
      <div class="row" style="font-family: 'prompt';background-color: #fcf5ed">
        <div class="client-slider">
            <div class="client-slide-track">
                  <div class="client-slide">
                   <iframe  src="https://drive.google.com/embeddedfolderview?id=1hwxngogHZb-vet94AUECAHrw2JGOVw-l#grid"
                    style="width:100%; height:950px;" alt=""   [style.pointer-events]="'none'"></iframe>
                  </div>
            </div>
        </div>
      </div>
    </section>
  </div>

  <section class="mt-5">
    <div class="container mt-3" *ngIf="showContentTwo" data-aos="flip-right" data-aos-duration="1000">
      <div class="row text-center">
        <h3 class="text-bold" style="color:#5f5f5f;"><span style="color:#fe7840">Awesome</span> Features</h3>
        <p class="text-muted">Offering Custom Digital eCard For You</p>
      </div>
    </div>
    <div class="container mt-3">
      <div *ngIf="showContentTwo" data-aos="zoom-in-down" data-aos-duration="1000">
      <div class="row">
        <div class="col-lg-3 col-xs-12 col-md-3 col-sm-2">
          <div class="card-container text-center">
            <img src="./assets/img/custom%20design.png" alt="image" class="img-fluid mt-3">
            <h6 class="mt-3">Custom Design</h6>
            <p class="text-muted p-3">We have developed a set of impressive features aimed at creating eCards, the best
              digital eCard.</p>
          </div>
        </div>
        <div class="col-lg-3 col-xs-12 col-md-3 col-sm-2">
          <div class="card-container text-center">
            <img src="./assets/img/fully%20response.png" alt="image" class="img-fluid mt-3">
            <h6 class="mt-3">Fully Responsive</h6>
            <p class="text-muted p-3">With this feature you can able to view digital eCards responsive over your
              computer,mobile and tablets</p>
          </div>
        </div>
        <div class="col-lg-3 col-xs-12 col-md-3 col-sm-2">
          <div class="card-container text-center">
            <img src="./assets/img/social%20share.png" alt="image" class="img-fluid mt-3">
            <h6 class="mt-3">Social Sharing</h6>
            <p class="text-muted p-3">We have developed a set of impressive features aimed at creating eCards, the best
              digital eCard.</p>
          </div>
        </div>
        <div class="col-lg-3 col-xs-12 col-md-3 col-sm-2">
          <div class="card-container text-center">
            <img src="./assets/img/click%20to%20connect.png" alt="image" class="img-fluid mt-3">
            <h6 class="mt-3">Click to Connect</h6>
            <p class="text-muted p-3">We have developed a set of impressive features aimed at creating eCards, the best
              digital eCard.</p>
          </div>
        </div>
      </div>
      </div>
    </div>
  </section>

  <section class=" mt-5">
    <div class="container mt-5">
      <div *ngIf="showContentThree" data-aos="zoom-in-down" data-aos-duration="1000">
      <div class="row mt-5">
        <div class="col-lg-6 col-xs-12 col-sm-6 col-md-6">
          <div class="row mt-5">
            <h1 class="text-bold mt-5" style="color:#5f5f5f;"><span style="color:#fe7840">Frequently</span> Asked Question
            </h1>
          </div>
          <div class="row mt-5">
            <div class="accordion" id="accordionExample">
              <div class="accordion-item mb-3">
                <h2 class="accordion-header bg-white accordion-button collapsed" id="headingOne" type="button"
                    data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false"
                    aria-controls="collapseOne">
                  What Is My Digital ECard?
                </h2>
                <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                     data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p class="text-muted">
                      Digital eCards, or virtual business cards, simplify contact sharing. Create and customize them on
                      any device. They're eco-friendly and cost-effective. Share your details effortlessly, anytime.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item mb-3">
                <h2 class="accordion-header bg-white accordion-button collapsed" id="headingTwo" type="button"
                    data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false"
                    aria-controls="collapseTwo">
                  Do I Need Any Coding Knowledge To Create My ECard?
                </h2>
                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                     data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p class="text-muted">Nope! You don't need to learn coding or hire a web developer to use eCard.</p>
                  </div>
                </div>
              </div>
              <div class="accordion-item mb-3">
                <h2 class="accordion-header bg-white accordion-button collapsed" id="headingThree" type="button"
                    data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false"
                    aria-controls="collapseThree">
                  Is My Digital ECard Mobile-Friendly?
                </h2>
                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                     data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p class="text-muted">
                      Absolutely! Every eCard you create is mobile-responsive, working perfectly on all devices, not
                      just
                      mobile phones. We understand that your audience is on the move, so we've made sure our digital
                      cards
                      provide an excellent experience on mobile. Our platform handles most of the mobile optimization
                      for
                      you, but you can also preview your card on a mobile screen while editing to ensure it looks just
                      right. This design feature helps you create an attractive card, generate leads, and bring in more
                      visitors.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item mb-3">
                <h2 class="accordion-header bg-white accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour" id="headingFour">
                  How Do I Create A Digital ECard With Ecard.Varnik.Cloud?
                </h2>
                <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                     data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p class="text-muted">
                      Creating your digital card with eCard is a breeze! Just sign up at ecard.varnik.cloud, customize
                      the
                      design, and add your contact info, social media links, and more. When you're ready, save and share
                      your digital card instantly.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item mb-3">
                <h2 class="accordion-header bg-white accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix" id="headingSix">
                  Does ECard.VarNik.Cloud Require PAID Subscription?
                </h2>
                <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix"
                     data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p class="text-muted">
                      No need to worry about subscriptions here! eCard.VarNik.Cloud is completely free to use. We
                      believe
                      in providing an accessible and user-friendly platform for everyone to create their digital
                      business
                      cards without any hidden costs or commitments. Enjoy the benefits of eCard without spending a
                      dime!
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item mb-3">
                <h2 class="accordion-header bg-white accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive" id="headingFive">
                  Do I Need Any Apps To Access My ECard?
                </h2>
                <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                     data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p class="text-muted">
                      Accessing your eCard is a breeze! No need to clutter your phone with extra apps. eCard is
                      accessible
                      through your web browser on both desktop and mobile devices. You can effortlessly create,
                      customize,
                      and update your digital business card directly from your browser. It's all online, so you can
                      manage
                      your card from any device with internet access, no app required!
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item mb-3">
                <h2 class="accordion-header bg-white accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven"
                    id="headingSeven">
                  How Many Digital Cards I Can Create?
                </h2>
                <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven"
                     data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p class="text-muted">With a free account, you can create up to TWO digital business cards. If you
                      need more, consider upgrading to our additional plans. For more information, contact us at +91
                      9791
                      266 218</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-xs-12 col-sm-6 col-md-6 mt-5">
          <div class="section mt-5">
            <img src="./assets/img/digitalcard.png" alt="image" class="img-fluid">
          </div>
        </div>
      </div>
    </div>
    </div>
  </section>

  <!--<div class="container-fluid mt-5 p-2 m-2 bg-light mb-5">
  <div class="mb-5">
    <div class="row mb-5">
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
        <div class="row" style="padding: 80px 0px; background-color: #f8f8f8">
          <div class="">
            <span style="color:#fe7840">Testimonial</span>
            <h2 class="client-heading text-bold mt-3" style="font-family:'prompt'">What our Client Say</h2>
            <p class="text-muted">We pride ourselves on providing an exceptional service to
              our clients, but you don’t just have to take our word for it. R
              ead what our clients have to say about working with us.</p>
          </div>
        </div>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
       &lt;!&ndash; <div class="client-slider-test">
          <div class="client-slider-testing">
            <div class="client-sliding">
              <img src="./assets/img/testimonal/testimonal-suresh-kumar-aftc.png" alt="" />
            </div>
            <div class="client-sliding">
              <img src="./assets/img/testimonal/testi-bmp.png" alt="" />
            </div>
          </div>
        </div>&ndash;&gt;
        <div class="image-container ml-5">
          <img src="./assets/img/testimonal/testimonal-suresh-kumar-aftc.png" alt="client-1" style="height:300px"/>
          <img src="./assets/img/testimonal/testi-bmp.png" alt="client-2"/>
        </div>
      </div>
    </div>
    <div class="row mb-5"></div>
  </div>
  </div>-->
</div>
<section class="container-fluid p-0" style="font-family:'prompt';background-color: #fcf5ed;">
  <div class="row mb-3">
    <div class="col-md-6 col-12 text-center mt-5">
      <div class="mt-5" style="padding:10px;">
        <h1 class="mt-5" style="color:#fe7840">Testimonial</h1>
        <h2 class="client-heading text-bold mt-3" style="font-family:'prompt'">What our Client Say</h2>
        <p class="text-muted p-3">We pride ourselves on providing an exceptional service to
          our clients, but you don’t just have to take our word for it. R
          ead what our clients have to say about working with us.</p>
      </div>
    </div>
    <div class="col-md-6 col-12 mt-5 mb-5">
      <div class="image-container bg-white">
        <img src="./assets/img/testimonal/testimonal-suresh-kumar-aftc.png" alt="client-1" class="img-fluid"/>
        <img src="./assets/img/testimonal/testi-bmp.png" class="img-fluid" alt="client-2"/>
      </div>
    </div>
  </div>
  <div class="row mt-5 mb-5"></div>
  <div class="row mt-5 mb-5"></div>

  <div class="row mt-5 mb-5"></div>

  <div class="row mt-5 mb-5"></div>

  <div class="row mt-5 mb-5"></div>
  <footer class="footer p-0">
    <div class="row text-center">
      <div class="col-12">
        <span>Copyright &copy; {{ currentYear }} |</span>
        <a href="https://varnikcloud.com/"
           style="margin: 0; text-decoration: none"
           target="_blank"
        ><span class="text-white"> VarNik Systems Services Pvt. Ltd</span></a>
        <span>. |</span>
        <span> All rights reserved.</span>
      </div>
      <span class="text-black mt-3 mr-2" type="button" [routerLink]="['/terms']">Terms & Conditions   |
        <span class="text-black ml-3" type="button" [routerLink]="['/privacy']">  Privacy Policy</span></span>
    </div>
  </footer>
</section>
