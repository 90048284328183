<section class="vh-100" style="background-color: rgb(254 242 242)"  *ngIf="profileShow">
  <div class="container-fluid py-2 ">
    <div class="container">
      <div class="row mt-1">
        <div class="col-md-12 mt-1 text-center">
          <img class="companyProfile " [src]="companySrcUrl">
        </div>
      </div>
      <div class="row mt-2" style="border-bottom: 2px solid">
        <div class="col-12 justify-content-center">
          <h4 class="text-center fw-bolder">{{currentFormData!.companyName}}</h4>
        </div>
      </div>

        <div class="text-center mt-3">
          <img class="Profile" [src]="profileSrcUrl">
        </div>

        <div class=" text-center mt-2">
          <h3 class="fullname fw-bolder">{{currentFormData!.firstName}} {{currentFormData!.lastName}}</h3>
          <h6 class="designation">{{currentFormData!.designation}} <br> {{currentFormData!.role}}</h6>
          <h6 class="role"></h6>
        </div>




      <div class="row mt-5">
        <div class="text-center mt-4">
          <div class="contact-buttons">
            <a class="contact-button"  style="border: 1px solid black;"
               href="tel:{{currentFormData!.countryCodePhoneNo}}{{currentFormData!.phoneNo}}">
              <i class="fas fa-phone fa-flip-horizontal mr-1"></i>
              <b>Call</b>
            </a>

            <a class="contact-button" style="border: 1px solid black;"
               target="_blank" href="mailto:{{currentFormData!.email}}">
              <i class="fas fa-envelope fa-flip-horizontal mr-1"></i>
              <b>Mail</b>
            </a>
          </div>
        </div>
        <div class="col-1 mt-2"></div>
        <div class="col-11">
          <div class="row mt-1">
            <div class="col-2"><i class="bi bi-telephone-fill roundIcon" type="button" title="Call"></i>
            </div>
            <div class="col-10 mt-1"><h6 class="" style="color: white;"
                                         (click)="copyPhoneNo(currentFormData!.countryCodePhoneNo, formatNumber(currentFormData!.phoneNo))">
              {{currentFormData!.countryCodePhoneNo}} {{formatNumber(currentFormData!.phoneNo)}}</h6>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-2"><i class="bi bi-geo-alt-fill roundIcon" type="button" title="Location"></i>
            </div>
            <div class="col-10 mt-1"><h6 class="" style="color: white;" >{{currentFormData!.address}}</h6></div>
          </div>
          <div class="row mt-2">
            <div class="col-2"><i class="bi bi-globe2 roundIcon" type="button" title="Website"></i></div>
            <div class="col-10 mt-2"><h6 class="">
              <a class="" style="color: white;"
                 href="http://{{currentFormData!.companyWebsiteName}}"
                 target="_blank">{{currentFormData!.companyWebsiteName}}</a>
            </h6>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-2"><i class="bi bi-envelope roundIcon" type="button" title="Mail"></i></div>
            <div class="col-10 mt-1">
              <h6 class=""><a class="" style="color: white;"
                                       href="mailto:{{currentFormData!.email}}"
                                       target="_blank">{{currentFormData!.email}}</a>
              </h6>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-2"><i title="WhatsApp" class="bi bi-whatsapp roundIcon fs-6"></i>
            </div>
            <div class="col-10 mt-1">
              <h6 class="mt-1" type="button" style="color: white;"
                  (click)="whatsApp(currentFormData!.countryCodeWhatsAppNo, currentFormData!.whatsAppNumber)">
                {{currentFormData!.countryCodeWhatsAppNo}} {{formatNumber(currentFormData!.whatsAppNumber)}}</h6>
            </div>
          </div>
        </div>
      </div>
      <div class="row py-1"></div>
    </div>
  </div>
</section>



















<section class="vh-100" style="background-color: rgb(254 242 242)"  *ngIf="profilehide">
  <div class="container-fluid py-2 ">
    <div class="container">
      <div class="row mt-1">
        <div class="col-md-12 mt-3 text-center">
          <img class="companyPro" [src]="companySrcUrl">
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 justify-content-center">
          <h4 class="text-center"> <b>{{currentFormData!.companyName}}</b> </h4>
        </div>
      </div>

      <div class="row" style="border-bottom: 2px solid">
      </div>
      <div class="row mt-4">
        <div class="col-12 text-center">
          <h3 class="fullname"><b>{{currentFormData!.firstName}} {{currentFormData!.lastName}}</b></h3>
          <h6 class="designation">{{currentFormData!.designation}}</h6>
          <h6 class="role">{{currentFormData!.role}}</h6>
        </div>
      </div>

      <div class="row" style="margin-top:60px">
        <div class="text-center mt-3">
          <div class="contact-buttons">
            <a class="contact-button"  style="border: 1px solid black;"
               href="tel:{{currentFormData!.countryCodePhoneNo}}{{currentFormData!.phoneNo}}">
              <i class="fas fa-phone fa-flip-horizontal mr-1"></i>
              <b>Call</b>
            </a>

            <a class="contact-button" style="border: 1px solid black;"
               target="_blank" href="mailto:{{currentFormData!.email}}">
              <i class="fas fa-envelope fa-flip-horizontal mr-1"></i>
              <b>Mail</b>
            </a>
          </div>
        </div>
        <div class="col-1"></div>
        <div class="col-11">
          <!-- <div class="row mt-3">
            <div class="col-2"><i class="bi bi-telephone-fill roundIcon" type="button" title="Call"></i>
            </div>
            <div class="col-10 mt-1"><h6 class="" style="color: white;"
                                         (click)="copyPhoneNo(currentFormData!.countryCodePhoneNo, formatNumber(currentFormData!.phoneNo))">
              {{currentFormData!.countryCodePhoneNo}} {{formatNumber(currentFormData!.phoneNo)}}</h6>
            </div>
          </div> -->
          <div class="row mt-3">
            <div class="col-2">
              <a class="" target="_blank"href="https://www.google.com/maps?q={{currentFormData!.address}}">
                <i class="bi bi-geo-alt-fill roundIcon"></i>
              </a>
<!--              <i class="bi bi-geo-alt-fill roundIcon" type="button" title="Location"></i>-->
            </div>
            <div class="col-10 mt-1"><h6 class="" style="color: white;" >{{currentFormData!.address}}</h6></div>
          </div>
          <div class="row mt-3">
            <div class="col-2"><i class="bi bi-globe2 roundIcon" type="button" title="Website"></i></div>
            <div class="col-10 mt-1"><h6 class="">
              <a class="" style="color: white;"
                 href="http://{{currentFormData!.companyWebsiteName}}"
                 target="_blank">{{currentFormData!.companyWebsiteName}}</a>
            </h6>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-2"><i class="bi bi-envelope roundIcon" type="button" title="Mail"></i></div>
            <div class="col-10 mt-1">
              <h6 class=""><a class="" style="color: white;"
                              href="mailto:{{currentFormData!.email}}"
                              target="_blank">{{currentFormData!.email}}</a>
              </h6>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-2"><i title="WhatsApp" class="bi bi-whatsapp roundIcon fs-6"></i>
            </div>
            <div class="col-10 mt-1">
              <h6 class="mt-1" type="button" style="color: white;"
                  (click)="whatsApp(currentFormData!.countryCodeWhatsAppNo, currentFormData!.whatsAppNumber)">
                {{currentFormData!.countryCodeWhatsAppNo}} {{formatNumber(currentFormData!.whatsAppNumber)}}</h6>
            </div>
          </div>
        </div>
      </div>
      <div class="row py-1"></div>
    </div>
  </div>
</section>










<ngx-spinner bdColor="rgba(255, 255, 255, 1)" size="large" color="#FFFFFF"
             [fullScreen]="true">
  <figure class="loader">
    <div class="dot white"></div>
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
  </figure>
</ngx-spinner>
<app-ecard-footer></app-ecard-footer>
