<!-- <app-header></app-header> -->
<!-- <div id="mySidebar" class="sidebar">
    <div class="row mt-5"></div>
    <div class="a" (click)="addcard()"><i class="bi bi-file-earmark-plus"></i> Add Card</div>
    <div class="row" *ngFor="let name of eCardDisplayName">
        <div class="a"><label (click)="selectpopupCard(name)"><i class="bi bi-pencil-fill"></i> {{name}}</label></div>
    </div>
</div>
<div id="main">
    <button class="openbtn" (click)="openNav()">☰</button>
</div> -->
<div class="container-fluid">
    <form [formGroup]="registrationForm" (ngSubmit)="onSubmit()">
        <div class="row col-md-12">
            <div class="col-md-9">
                <h4 class="mt-4">Company Details</h4>
            </div>
            <div class="col-md-3" *ngIf="showCardName">
                <p class="mt-4 text-primary texts">eCard Display Name : <b>{{ecardDisplayName}}</b></p>
            </div>
            <!-- <div class=" mt-4 col-md-3">
                <button type="button" class="btn btn-primary" (click)="open()">Edit Your Card</button>
            </div> -->
        </div>
        <hr>
        <div class="form-group mt-2">
            <div class="row">
                <div class="col-md-2" style="text-align-last: left">
                    <label for="company-name">Company Name :<span class="text-danger">*</span></label>
                </div>
                <div class="col-md-3">
                    <input type="text" class="form-control" id="company-name" formControlName="companyName" autocomplete="off">
                    <span *ngIf="profileError.companyName" class="text-danger">{{profileError.companyName}}</span>
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-2" style="text-align-last: left">
                    <label for="company-logo">Company Logo :<span class="text-danger">*</span></label>
                </div>
                <div class="col-md-2">
                    <input type="file" class="form-control" (change)="onFileChangeCompany($event)" id="company-logo"
                        formControlName="companyLogo" autocomplete="off">
                    <span class="text-danger">Note: Upload below 1 MB Image only</span><br>
                    <span *ngIf="profileError.companyLogo" class="text-danger">{{profileError.companyLogo}}</span>
                </div>
                <div class="col-md-1">
                    <img class="image-preview circle" [src]="images" width="100" height="100">
                </div>
            </div>

        </div>
        <div class="form-group mt-3">
            <div class="row">
                <div class="col-md-2" style="text-align-last: left">
                    <label for="company-website">Company Website :<span class="text-danger">*</span></label>
                </div>
                <div class="col-md-3">
                    <input type="text" class="form-control" id="company-website" formControlName="companyWebsiteName" autocomplete="off">
                    <span *ngIf="profileError.companyWebsiteName"
                        class="text-danger">{{profileError.companyWebsiteName}}</span>
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-2" style="text-align-last: left">
                    <label for="company-address">Company Address :<span class="text-danger">*</span></label>
                </div>
                <div class="col-md-3">
                    <input type="text" class="form-control" id="company-address" formControlName="address" autocomplete="off">
                    <span *ngIf="profileError.companyAddress" class="text-danger">{{profileError.companyAddress}}</span>
                </div>
            </div>
        </div>
        <!--<ins class="adsbygoogle" style="display:block" data-ad-client="ca-pub-6761442663932713"
            data-ad-slot="4129064347" data-ad-format="auto" data-full-width-responsive="true"></ins>
        <script>
            (adsbygoogle = window.adsbygoogle || []).push({});
        </script>-->

      <!--AD SENSE-->
      <script async="async" data-cfasync="false" src="//pl21298496.toprevenuegate.com/dd62b079ca34b6003becdf408c267efc/invoke.js"></script>
      <div id="container-dd62b079ca34b6003becdf408c267efc"></div>

        <h4 class="mt-2">Profile Details </h4>
        <hr>
        <div class="form-group mt-3">
            <div class="row">
                <div class="col-md-2" style="text-align-last: left">
                    <label for="first-name">First Name :<span class="text-danger">*</span></label>
                </div>
                <div class="col-md-3">
                    <input type="text" class="form-control" id="first-name" formControlName="firstName" autocomplete="off">
                    <span *ngIf="profileError.firstName" class="text-danger">{{profileError.firstName}}</span>
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-2" style="text-align-last: left">
                    <label for="last-name">Last Name :<span class="text-danger">*</span></label>
                </div>
                <div class="col-md-3">
                    <input type="text" class="form-control" id="last-name" formControlName="lastName" autocomplete="off">
                    <span *ngIf="profileError.lastName" class="text-danger">{{profileError.lastName}}</span>
                </div>
            </div>
        </div>
        <div class="form-group mt-3">
            <div class="row">
                <div class="col-md-2" style="text-align-last: left">
                    <label for="designation">Qualification :</label>
                </div>
                <div class="col-md-3">
                    <input type="text" class="form-control" id="designation" formControlName="designation" autocomplete="off">
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-2" style="text-align-last: left">
                    <label for="role">Job Role :<span class="text-danger">*</span></label>
                </div>
                <div class="col-md-3">
                    <input type="text" class="form-control" id="role" formControlName="role" autocomplete="off">
                    <span *ngIf="profileError.role" class="text-danger">{{profileError.role}}</span>
                </div>
            </div>
        </div>
        <div class="form-group mt-3">
            <div class="row">
                <div class="col-md-2" style="text-align-last: left">
                    <label for="phone-no">Phone No :<span class="text-danger">*</span></label>
                </div>
                <div class="col-md-3">
                    <div class="row">
                        <div class="col-md-6">
                            <select class="form-control" formControlName="countryCodePhoneNo" autocomplete="off" placeholder="Select Countrycode"
                                (change)="copyCountryCodeToWhatsApp($event);">
                                <option value="" disabled selected>Select Country Code</option>
                                <option selected value="+91">(+91) India</option>
                                <option value="+1">(+1) United States</option>
                                <option value="+93">(+93) Afghanistan</option>
                                <option value="+355">(+355) Albania (+355)</option>
                                <option value="+213">(+213) Algeria</option>
                                <option value="+1">(+1)American Samoa</option>
                                <option value="+376">(+376) Andorra</option>
                                <option value="+244">(+244) Angola</option>
                                <option value="+1">(+1) Anguilla</option>
                                <option value="+1">(+1) Antigua and Barbuda</option>
                                <option value="+54">(+54) Argentina</option>
                                <option value="+374">(+374) Armenia</option>
                                <option value="+297">(+297) Aruba</option>
                                <option value="+247">(+247) Ascension Island</option>
                                <option value="+61">(+61) Australia</option>
                                <option value="+672">(+672) Australian Antarctic Territory</option>
                                <option value="+43">(+43) Austria</option>
                                <option value="+994">(+994) Azerbaijan</option>
                                <option value="+1">(+1) Bahamas</option>
                                <option value="+973">(+973) Bahrain</option>
                                <option value="+880">(+880) Bangladesh</option>
                                <option value="+1">(+1) Barbados</option>
                                <option value="+375">(+375) Belarus</option>
                                <option value="+32">(+32) Belgium</option>
                                <option value="+501">(+501) Belize</option>
                                <option value="+229">(+229) Benin</option>
                                <option value="+1">(+1) Bermuda</option>
                                <option value="+975">(+975) Bhutan</option>
                                <option value="+591">(+591) Bolivia</option>
                                <option value="+387">(+387) Bosnia and Herzegovina</option>
                                <option value="+267">(+267) Botswana</option>
                                <option value="+55">(+55) Brazil</option>
                                <option value="+246">(+246) British Indian Ocean Territory</option>
                                <option value="+1">(+1) British Virgin Islands</option>
                                <option value="+673">(+673) Brunei</option>
                                <option value="+359">(+359) Bulgaria</option>
                                <option value="+226">(+226) Burkina Faso</option>
                                <option value="+257">(+257) Burundi</option>
                                <option value="+855">(+855) Cambodia</option>
                                <option value="+237">(+237) Cameroon</option>
                                <option value="+1">(+1) Canada</option>
                                <option value="+238">(+238) Cape Verde</option>
                                <option value="+1">(+1) Caribbean Netherlands</option>
                                <option value="+1">(+1) Cayman Islands</option>
                                <option value="+236">(+236)C entral African Republic</option>
                                <option value="+235">(+235) Chad</option>
                                <option value="+56">(+56) Chile</option>
                                <option value="+86">(+86) China</option>
                                <option value="+61">(+61) Christmas Island</option>
                                <option value="+61">(+61) Cocos [Keeling] Islands</option>
                                <option value="+57">(+57) Colombia</option>
                                <option value="+269">(+269) Comoros</option>
                                <option value="+682">(+682) Cook Islands</option>
                                <option value="+506">(+506) Costa Rica</option>
                                <option value="+385">(+385) Croatia</option>
                                <option value="+53">(+53) Cuba</option>
                                <option value="+599">(+599) Curaçao</option>
                                <option value="+357">(+357) Cyprus</option>
                                <option value="+420">(+420) Czechia</option>
                                <option value="+243">(+243) Democratic Republic of the Congo</option>
                                <option value="+45">(+45) Denmark</option>
                                <option value="+246">(+246) Diego Garcia</option>
                                <option value="+253">(+253) Djibouti</option>
                                <option value="+1">(+1) Dominica</option>
                                <option value="+1">(+1) Dominican Republic</option>
                                <option value="+593">(+593) Ecuador</option>
                                <option value="+20">(+20) Egypt</option>
                                <option value="+503">(+503) El Salvador</option>
                                <option value="+240">(+240) Equatorial Guinea</option>
                                <option value="+291">(+291) Eritrea</option>
                                <option value="+372">(+372) Estonia</option>
                                <option value="+251">(+251) Ethiopia</option>
                                <option value="+500">(+500) Falkland Islands</option>
                                <option value="+298">(+298) Faroe Islands</option>
                                <option value="+679">(+679) Fiji</option>
                                <option value="+358">(+358) Finland</option>
                                <option value="+33">(+33) France</option>
                                <option value="+594">(+594) French Guiana</option>
                                <option value="+689">(+689) French Polynesia</option>
                                <option value="+241">(+241) Gabon</option>
                                <option value="+220">(+220) Gambia</option>
                                <option value="+995">(+995) Georgia</option>
                                <option value="+49">(+49) Germany</option>
                                <option value="+233">(+233) Ghana</option>
                                <option value="+350">(+350) Gibraltar</option>
                                <option value="+30">(+30) Greece</option>
                                <option value="+299">(+299) Greenland</option>
                                <option value="+1">(+1) Grenada</option>
                                <option value="+590">(+590) Guadeloupe</option>
                                <option value="+1">(+1)Guam</option>
                                <option value="+502">(+502) Guatemala</option>
                                <option value="+44">(+44) Guernsey</option>
                                <option value="+224">(+224) Guinea</option>
                                <option value="+245">(+245) Guinea-Bissau</option>
                                <option value="+592">(+592) Guyana</option>
                                <option value="+509">(+509) Haiti</option>
                                <option value="+504">(+504) Honduras</option>
                                <option value="+852">(+852) Hong Kong</option>
                                <option value="+36">(+36) Hungary</option>
                                <option value="+354">(+354) Iceland</option>
                                <option value="+62">(+62) Indonesia</option>
                                <option value="+98">(+98) Iran</option>
                                <option value="+964">(+964) Iraq</option>
                                <option value="+353">(+353) Ireland</option>
                                <option value="+44">(+44) Isle of Man</option>
                                <option value="+972">(+972) Israel</option>
                                <option value="+39">(+39) Italy</option>
                                <option value="+225">(+225) Ivory Coast</option>
                                <option value="+1">(+1) Jamaica</option>
                                <option value="+81">(+81) Japan</option>
                                <option value="+44">(+44) Jersey</option>
                                <option value="+962">(+962) Jordan</option>
                                <option value="+7">(+7) Kazakhstan</option>
                                <option value="+254">(+254) Kenya</option>
                                <option value="+686">(+686) Kiribati</option>
                                <option value="+383">(+383) Kosovo</option>
                                <option value="+965">(+965) Kuwait</option>
                                <option value="+996">(+996) Kyrgyzstan</option>
                                <option value="+856">(+856) Laos</option>
                                <option value="+371">(+371) Latvia</option>
                                <option value="+961">(+961) Lebanon</option>
                                <option value="+266">(+266) Lesotho</option>
                                <option value="+231">(+231) Liberia</option>
                                <option value="+218">(+218) Libya</option>
                                <option value="+423">(+423) Liechtenstein</option>
                                <option value="+370">(+370) Lithuania</option>
                                <option value="+352">(+352) Luxembourg</option>
                                <option value="+853">(+853) Macau</option>
                                <option value="+389">(+389) Macedonia</option>
                                <option value="+261">(+261) Madagascar</option>
                                <option value="+265">(+265) Malawi</option>
                                <option value="+60">(+60) Malaysia</option>
                                <option value="+960">(+960) Maldives</option>
                                <option value="+223">(+223) Mali</option>
                                <option value="+356">(+356) Malta</option>
                                <option value="+692">(+692) Marshall Islands</option>
                                <option value="+596">(+596) Martinique</option>
                                <option value="+222">(+222) Mauritania</option>
                                <option value="+230">(+230) Mauritius</option>
                                <option value="+262">Mayotte</option>
                                <option value="+52">(+262) Mexico (+52)</option>
                                <option value="+691">(+691) Micronesia</option>
                                <option value="+373">(+373) Moldova</option>
                                <option value="+377">(+377) Monaco</option>
                                <option value="+976">(+976) Mongolia</option>
                                <option value="+382">(+382) Montenegro</option>
                                <option value="+1">(+1) Montserrat</option>
                                <option value="+212">(+212) Morocco</option>
                                <option value="+258">(+258) Mozambique</option>
                                <option value="+95">(+95) Myanmar [Burma]</option>
                                <option value="+264">(+264) Namibia</option>
                                <option value="+674">(+674)Nauru</option>
                                <option value="+977">(+977) Nepal</option>
                                <option value="+31">(+31) Netherlands</option>
                                <option value="+599">(+599) Netherlands Antilles</option>
                                <option value="+687">(+687) New Caledonia</option>
                                <option value="+64">(+64) New Zealand</option>
                                <option value="+505">(+505) Nicaragua</option>
                                <option value="+227">(+227) Niger</option>
                                <option value="+234">(+234)Nigeria</option>
                                <option value="+683">(+683) Niue</option>
                                <option value="+672">(+672) Norfolk Island</option>
                                <option value="+850">(+850) North Korea</option>
                                <option value="+1">(+1) Northern Mariana Islands</option>
                                <option value="+47">(+47) Norway</option>
                                <option value="+968">(+968) Oman</option>
                                <option value="+92">(+92) Pakistan</option>
                                <option value="+680">(+680) Palau</option>
                                <option value="+970">(+970) Palestine</option>
                                <option value="+507">(+507) Panama</option>
                                <option value="+675">(+675) Papua New Guinea</option>
                                <option value="+595">(+595) Paraguay</option>
                                <option value="+51">(+51) Peru</option>
                                <option value="+63">(+63) Philippines</option>
                                <option value="+64">(+64) Pitcairn Islands</option>
                                <option value="+48">(+48) Poland</option>
                                <option value="+351">(+351) Portugal</option>
                                <option value="+1">(+1) Puerto Rico</option>
                                <option value="+974">(+974) Qatar</option>
                                <option value="+242">(+242) Republic of the Congo</option>
                                <option value="+262">(+262) Réunion</option>
                                <option value="+40">(+40) Romania</option>
                                <option value="+7">(+7) Russia</option>
                                <option value="+250">(+250) Rwanda</option>
                                <option value="+590">(+590) Saint Barthélemy</option>
                                <option value="+290">(+290) Saint Helena</option>
                                <option value="+1">(+1) Saint Kitts and Nevis</option>
                                <option value="+1">(+1) Saint Lucia</option>
                                <option value="+590">(+590) Saint Martin</option>
                                <option value="+508">(+508) Saint Pierre and Miquelon</option>
                                <option value="+1">(+1) Saint Vincent and the Grenadines</option>
                                <option value="+685">(+685) Samoa</option>
                                <option value="+378">(+378) San Marino</option>
                                <option value="+239">(+239) São Tomé and Príncipe</option>
                                <option value="+966">(+966) Saudi Arabia</option>
                                <option value="+221">(+221) Senegal</option>
                                <option value="+381">(+381) Serbia</option>
                                <option value="+248">(+248) Seychelles</option>
                                <option value="+232">(+232) Sierra Leone</option>
                                <option value="+65">(+65) Singapore</option>
                                <option value="+1">(+1) Sint Maarten</option>
                                <option value="+421">(+421) Slovakia</option>
                                <option value="+386">(+386) Slovenia</option>
                                <option value="+677">(+677) Solomon Islands</option>
                                <option value="+252">(+252) Somalia</option>
                                <option value="+27">(+27) South Africa</option>
                                <option value="+82">(+82) South Korea</option>
                                <option value="+211">(+211)South Sudan</option>
                                <option value="+34">(+34) Spain</option>
                                <option value="+94">(+94) Sri Lanka</option>
                                <option value="+249">(+249) Sudan</option>
                                <option value="+597">(+597) Suriname</option>
                                <option value="+47">(+47) Svalbard and Jan Mayen</option>
                                <option value="+268">(+268) Swaziland</option>
                                <option value="+46">(+46) Sweden</option>
                                <option value="+41">(+41) Switzerland</option>
                                <option value="+963">(+963) Syria</option>
                                <option value="+886">(+886) Taiwan</option>
                                <option value="+992">(+992) Tajikistan</option>
                                <option value="+255">(+255) Tanzania</option>
                                <option value="+66">(+66) Thailand</option>
                                <option value="+670">(+670) Timor-Leste</option>
                                <option value="+228">(+228) Togo</option>
                                <option value="+690">(+690) Tokelau</option>
                                <option value="+676">(+676) Tonga</option>
                                <option value="+1">(+1) Trinidad and Tobago</option>
                                <option value="+216">(+216) Tunisia</option>
                                <option value="+90">(+90) Turkey</option>
                                <option value="+993">(+993) Turkmenistan</option>
                                <option value="+1">(+1) Turks and Caicos Islands</option>
                                <option value="+688">(+688) Tuvalu</option>
                                <option value="+1">(+1) U.S. Virgin Islands</option>
                                <option value="+256">(+256) Uganda</option>
                                <option value="+380">(+380) Ukraine</option>
                                <option value="+971">(+971) United Arab Emirates</option>
                                <option value="+44">(+44) United Kingdom</option>
                                <option value="+598">(+598) Uruguay</option>
                                <option value="+998">(+998) Uzbekistan</option>
                                <option value="+678">(+678) Vanuatu</option>
                                <option value="+39">(+39) Vatican City</option>
                                <option value="+58">(+58) Venezuela</option>
                                <option value="+84">(+84) Vietnam</option>
                                <option value="+681">(+681) Wallis and Futuna</option>
                                <option value="+967">(+967) Yemen</option>
                                <option value="+260">(+260) Zambia</option>
                                <option value="+263">(+263) Zimbabwe</option>
                            </select>
                            <span *ngIf="profileError.countryCodePhone"
                                class="text-danger">{{profileError.countryCodePhone}}</span>
                        </div>
                        <div class="col-md-6">
                            <input type="text" class="form-control" id="phone-no" (keypress)="numberOnly($event)"
                                formControlName="phoneNo" maxlength="10" (input)="phoneNumberCopy($event)" autocomplete="cc-number">
                            <span *ngIf="profileError.phoneNo" class="text-danger">{{profileError.phoneNo}}</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-2" style="text-align-last: left">
                    <label for="phone-no">WhatsApp No :</label>
                </div>
                <div class="col-md-3">
                    <div class="row">
                        <div class="col-md-6">
                            <select class="form-control" formControlName="countryCodeWhatsAppNo" >
                                <option value="" disabled selected>Select Country Code</option>
                                <option selected value="+91">(+91) India</option>
                                <option value="+1">(+1) United States</option>
                                <option value="+93">(+93) Afghanistan</option>
                                <option value="+355">(+355) Albania (+355)</option>
                                <option value="+213">(+213) Algeria</option>
                                <option value="+1">(+1)American Samoa</option>
                                <option value="+376">(+376) Andorra</option>
                                <option value="+244">(+244) Angola</option>
                                <option value="+1">(+1) Anguilla</option>
                                <option value="+1">(+1) Antigua and Barbuda</option>
                                <option value="+54">(+54) Argentina</option>
                                <option value="+374">(+374) Armenia</option>
                                <option value="+297">(+297) Aruba</option>
                                <option value="+247">(+247) Ascension Island</option>
                                <option value="+61">(+61) Australia</option>
                                <option value="+672">(+672) Australian Antarctic Territory</option>
                                <option value="+43">(+43) Austria</option>
                                <option value="+994">(+994) Azerbaijan</option>
                                <option value="+1">(+1) Bahamas</option>
                                <option value="+973">(+973) Bahrain</option>
                                <option value="+880">(+880) Bangladesh</option>
                                <option value="+1">(+1) Barbados</option>
                                <option value="+375">(+375) Belarus</option>
                                <option value="+32">(+32) Belgium</option>
                                <option value="+501">(+501) Belize</option>
                                <option value="+229">(+229) Benin</option>
                                <option value="+1">(+1) Bermuda</option>
                                <option value="+975">(+975) Bhutan</option>
                                <option value="+591">(+591) Bolivia</option>
                                <option value="+387">(+387) Bosnia and Herzegovina</option>
                                <option value="+267">(+267) Botswana</option>
                                <option value="+55">(+55) Brazil</option>
                                <option value="+246">(+246) British Indian Ocean Territory</option>
                                <option value="+1">(+1) British Virgin Islands</option>
                                <option value="+673">(+673) Brunei</option>
                                <option value="+359">(+359) Bulgaria</option>
                                <option value="+226">(+226) Burkina Faso</option>
                                <option value="+257">(+257) Burundi</option>
                                <option value="+855">(+855) Cambodia</option>
                                <option value="+237">(+237) Cameroon</option>
                                <option value="+1">(+1) Canada</option>
                                <option value="+238">(+238) Cape Verde</option>
                                <option value="+1">(+1) Caribbean Netherlands</option>
                                <option value="+1">(+1) Cayman Islands</option>
                                <option value="+236">(+236)C entral African Republic</option>
                                <option value="+235">(+235) Chad</option>
                                <option value="+56">(+56) Chile</option>
                                <option value="+86">(+86) China</option>
                                <option value="+61">(+61) Christmas Island</option>
                                <option value="+61">(+61) Cocos [Keeling] Islands</option>
                                <option value="+57">(+57) Colombia</option>
                                <option value="+269">(+269) Comoros</option>
                                <option value="+682">(+682) Cook Islands</option>
                                <option value="+506">(+506) Costa Rica</option>
                                <option value="+385">(+385) Croatia</option>
                                <option value="+53">(+53) Cuba</option>
                                <option value="+599">(+599) Curaçao</option>
                                <option value="+357">(+357) Cyprus</option>
                                <option value="+420">(+420) Czechia</option>
                                <option value="+243">(+243) Democratic Republic of the Congo</option>
                                <option value="+45">(+45) Denmark</option>
                                <option value="+246">(+246) Diego Garcia</option>
                                <option value="+253">(+253) Djibouti</option>
                                <option value="+1">(+1) Dominica</option>
                                <option value="+1">(+1) Dominican Republic</option>
                                <option value="+593">(+593) Ecuador</option>
                                <option value="+20">(+20) Egypt</option>
                                <option value="+503">(+503) El Salvador</option>
                                <option value="+240">(+240) Equatorial Guinea</option>
                                <option value="+291">(+291) Eritrea</option>
                                <option value="+372">(+372) Estonia</option>
                                <option value="+251">(+251) Ethiopia</option>
                                <option value="+500">(+500) Falkland Islands</option>
                                <option value="+298">(+298) Faroe Islands</option>
                                <option value="+679">(+679) Fiji</option>
                                <option value="+358">(+358) Finland</option>
                                <option value="+33">(+33) France</option>
                                <option value="+594">(+594) French Guiana</option>
                                <option value="+689">(+689) French Polynesia</option>
                                <option value="+241">(+241) Gabon</option>
                                <option value="+220">(+220) Gambia</option>
                                <option value="+995">(+995) Georgia</option>
                                <option value="+49">(+49) Germany</option>
                                <option value="+233">(+233) Ghana</option>
                                <option value="+350">(+350) Gibraltar</option>
                                <option value="+30">(+30) Greece</option>
                                <option value="+299">(+299) Greenland</option>
                                <option value="+1">(+1) Grenada</option>
                                <option value="+590">(+590) Guadeloupe</option>
                                <option value="+1">(+1)Guam</option>
                                <option value="+502">(+502) Guatemala</option>
                                <option value="+44">(+44) Guernsey</option>
                                <option value="+224">(+224) Guinea</option>
                                <option value="+245">(+245) Guinea-Bissau</option>
                                <option value="+592">(+592) Guyana</option>
                                <option value="+509">(+509) Haiti</option>
                                <option value="+504">(+504) Honduras</option>
                                <option value="+852">(+852) Hong Kong</option>
                                <option value="+36">(+36) Hungary</option>
                                <option value="+354">(+354) Iceland</option>
                                <option value="+62">(+62) Indonesia</option>
                                <option value="+98">(+98) Iran</option>
                                <option value="+964">(+964) Iraq</option>
                                <option value="+353">(+353) Ireland</option>
                                <option value="+44">(+44) Isle of Man</option>
                                <option value="+972">(+972) Israel</option>
                                <option value="+39">(+39) Italy</option>
                                <option value="+225">(+225) Ivory Coast</option>
                                <option value="+1">(+1) Jamaica</option>
                                <option value="+81">(+81) Japan</option>
                                <option value="+44">(+44) Jersey</option>
                                <option value="+962">(+962) Jordan</option>
                                <option value="+7">(+7) Kazakhstan</option>
                                <option value="+254">(+254) Kenya</option>
                                <option value="+686">(+686) Kiribati</option>
                                <option value="+383">(+383) Kosovo</option>
                                <option value="+965">(+965) Kuwait</option>
                                <option value="+996">(+996) Kyrgyzstan</option>
                                <option value="+856">(+856) Laos</option>
                                <option value="+371">(+371) Latvia</option>
                                <option value="+961">(+961) Lebanon</option>
                                <option value="+266">(+266) Lesotho</option>
                                <option value="+231">(+231) Liberia</option>
                                <option value="+218">(+218) Libya</option>
                                <option value="+423">(+423) Liechtenstein</option>
                                <option value="+370">(+370) Lithuania</option>
                                <option value="+352">(+352) Luxembourg</option>
                                <option value="+853">(+853) Macau</option>
                                <option value="+389">(+389) Macedonia</option>
                                <option value="+261">(+261) Madagascar</option>
                                <option value="+265">(+265) Malawi</option>
                                <option value="+60">(+60) Malaysia</option>
                                <option value="+960">(+960) Maldives</option>
                                <option value="+223">(+223) Mali</option>
                                <option value="+356">(+356) Malta</option>
                                <option value="+692">(+692) Marshall Islands</option>
                                <option value="+596">(+596) Martinique</option>
                                <option value="+222">(+222) Mauritania</option>
                                <option value="+230">(+230) Mauritius</option>
                                <option value="+262">Mayotte</option>
                                <option value="+52">(+262) Mexico (+52)</option>
                                <option value="+691">(+691) Micronesia</option>
                                <option value="+373">(+373) Moldova</option>
                                <option value="+377">(+377) Monaco</option>
                                <option value="+976">(+976) Mongolia</option>
                                <option value="+382">(+382) Montenegro</option>
                                <option value="+1">(+1) Montserrat</option>
                                <option value="+212">(+212) Morocco</option>
                                <option value="+258">(+258) Mozambique</option>
                                <option value="+95">(+95) Myanmar [Burma]</option>
                                <option value="+264">(+264) Namibia</option>
                                <option value="+674">(+674)Nauru</option>
                                <option value="+977">(+977) Nepal</option>
                                <option value="+31">(+31) Netherlands</option>
                                <option value="+599">(+599) Netherlands Antilles</option>
                                <option value="+687">(+687) New Caledonia</option>
                                <option value="+64">(+64) New Zealand</option>
                                <option value="+505">(+505) Nicaragua</option>
                                <option value="+227">(+227) Niger</option>
                                <option value="+234">(+234)Nigeria</option>
                                <option value="+683">(+683) Niue</option>
                                <option value="+672">(+672) Norfolk Island</option>
                                <option value="+850">(+850) North Korea</option>
                                <option value="+1">(+1) Northern Mariana Islands</option>
                                <option value="+47">(+47) Norway</option>
                                <option value="+968">(+968) Oman</option>
                                <option value="+92">(+92) Pakistan</option>
                                <option value="+680">(+680) Palau</option>
                                <option value="+970">(+970) Palestine</option>
                                <option value="+507">(+507) Panama</option>
                                <option value="+675">(+675) Papua New Guinea</option>
                                <option value="+595">(+595) Paraguay</option>
                                <option value="+51">(+51) Peru</option>
                                <option value="+63">(+63) Philippines</option>
                                <option value="+64">(+64) Pitcairn Islands</option>
                                <option value="+48">(+48) Poland</option>
                                <option value="+351">(+351) Portugal</option>
                                <option value="+1">(+1) Puerto Rico</option>
                                <option value="+974">(+974) Qatar</option>
                                <option value="+242">(+242) Republic of the Congo</option>
                                <option value="+262">(+262) Réunion</option>
                                <option value="+40">(+40) Romania</option>
                                <option value="+7">(+7) Russia</option>
                                <option value="+250">(+250) Rwanda</option>
                                <option value="+590">(+590) Saint Barthélemy</option>
                                <option value="+290">(+290) Saint Helena</option>
                                <option value="+1">(+1) Saint Kitts and Nevis</option>
                                <option value="+1">(+1) Saint Lucia</option>
                                <option value="+590">(+590) Saint Martin</option>
                                <option value="+508">(+508) Saint Pierre and Miquelon</option>
                                <option value="+1">(+1) Saint Vincent and the Grenadines</option>
                                <option value="+685">(+685) Samoa</option>
                                <option value="+378">(+378) San Marino</option>
                                <option value="+239">(+239) São Tomé and Príncipe</option>
                                <option value="+966">(+966) Saudi Arabia</option>
                                <option value="+221">(+221) Senegal</option>
                                <option value="+381">(+381) Serbia</option>
                                <option value="+248">(+248) Seychelles</option>
                                <option value="+232">(+232) Sierra Leone</option>
                                <option value="+65">(+65) Singapore</option>
                                <option value="+1">(+1) Sint Maarten</option>
                                <option value="+421">(+421) Slovakia</option>
                                <option value="+386">(+386) Slovenia</option>
                                <option value="+677">(+677) Solomon Islands</option>
                                <option value="+252">(+252) Somalia</option>
                                <option value="+27">(+27) South Africa</option>
                                <option value="+82">(+82) South Korea</option>
                                <option value="+211">(+211)South Sudan</option>
                                <option value="+34">(+34) Spain</option>
                                <option value="+94">(+94) Sri Lanka</option>
                                <option value="+249">(+249) Sudan</option>
                                <option value="+597">(+597) Suriname</option>
                                <option value="+47">(+47) Svalbard and Jan Mayen</option>
                                <option value="+268">(+268) Swaziland</option>
                                <option value="+46">(+46) Sweden</option>
                                <option value="+41">(+41) Switzerland</option>
                                <option value="+963">(+963) Syria</option>
                                <option value="+886">(+886) Taiwan</option>
                                <option value="+992">(+992) Tajikistan</option>
                                <option value="+255">(+255) Tanzania</option>
                                <option value="+66">(+66) Thailand</option>
                                <option value="+670">(+670) Timor-Leste</option>
                                <option value="+228">(+228) Togo</option>
                                <option value="+690">(+690) Tokelau</option>
                                <option value="+676">(+676) Tonga</option>
                                <option value="+1">(+1) Trinidad and Tobago</option>
                                <option value="+216">(+216) Tunisia</option>
                                <option value="+90">(+90) Turkey</option>
                                <option value="+993">(+993) Turkmenistan</option>
                                <option value="+1">(+1) Turks and Caicos Islands</option>
                                <option value="+688">(+688) Tuvalu</option>
                                <option value="+1">(+1) U.S. Virgin Islands</option>
                                <option value="+256">(+256) Uganda</option>
                                <option value="+380">(+380) Ukraine</option>
                                <option value="+971">(+971) United Arab Emirates</option>
                                <option value="+44">(+44) United Kingdom</option>
                                <option value="+598">(+598) Uruguay</option>
                                <option value="+998">(+998) Uzbekistan</option>
                                <option value="+678">(+678) Vanuatu</option>
                                <option value="+39">(+39) Vatican City</option>
                                <option value="+58">(+58) Venezuela</option>
                                <option value="+84">(+84) Vietnam</option>
                                <option value="+681">(+681) Wallis and Futuna</option>
                                <option value="+967">(+967) Yemen</option>
                                <option value="+260">(+260) Zambia</option>
                                <option value="+263">(+263) Zimbabwe</option>
                            </select>
                            <span *ngIf="profileError.countryCodeWhatsApp"
                                class="text-danger">{{profileError.countryCodeWhatsApp}}</span>
                        </div>
                        <div class="col-md-6">
                            <input type="text" class="form-control" (keypress)="numberOnly($event)"
                                formControlName="whatsAppNumber" maxlength="10" autocomplete="off">
                        </div>
                    </div>
                </div>
                <!-- <div class="form-group mt-3"> &lt;!&ndash;[style.display]="isWhatsAppShow ? 'block' : 'none'"&ndash;&gt;
                    <div class="row">
                    </div>
                </div>-->
                <!--<div class="col-md-3" style="text-align-last: left">
                    <div class="row">
                        <div class="col-md-8 ml-3">
                            <label>is this WhatsApp Number?<span style="color:red;">*</span></label>
                        </div>
                        <div class="col-md-2 form-check">
                            <input class="form-check-input" type="radio"
                                   formControlName="whatsAppNoYn" value="Yes" (change)="whatsApp($event)">
                            <label class="form-check-label">Yes</label>
                        </div>
                        <div class="col-md-2 form-check">
                            <input class="form-check-input" type="radio"
                                   formControlName="whatsAppNoYn" value="No" (change)="whatsApp($event)">
                            <label class="form-check-label">No</label>
                        </div>
                        <span *ngIf="profileError.whatsAppNoYn"
                              class="text-danger">{{profileError.whatsAppNoYn}}</span>
                    </div>
                </div>-->
            </div>
        </div>
        <div class="form-group mt-3">
            <div class="row">

                <div class="col-md-2" style="text-align-last: left">
                    <label for="tel-no">Tel No :<span class="text-danger"></span></label>
                </div>
                <div class="col-md-3">
                    <input type="text" class="form-control" id="tel-no" formControlName="telephone" autocomplete="off">
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-2" style="text-align-last: left">
                    <label for="email">Email :<span class="text-danger">*</span></label>
                </div>
                <div class="col-md-3">
                    <input type="text" class="form-control" formControlName="email" autocomplete="off">
                    <span *ngIf="profileError.email" class="text-danger">{{profileError.email}}</span>
                </div>
            </div>
        </div>
        <div class="form-group mt-3">
            <div class="row">
                <div class="col-md-2" style="text-align-last: left">
                    <label for="profile-upload">Profile Pic Upload:</label>
                </div>
                <div class="col-md-2">
                    <input type="file" class="form-control" (change)="onFileChangeProfile($event)" id="profile-upload"
                        formControlName="profileUpload" autocomplete="off">
                    <span class="text-danger">Note: Upload below 1 MB Image only</span><br>
                    <!-- <span *ngIf="profileError.profileUpload" class="text-danger">{{profileError.profileUpload}}</span> -->
                </div>
                <div class="col-md-1">
                    <img class="image-preview circle" [src]="profileimages" width="100" height="100">
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-3" style="text-align-last: left">
                    <div class="row">
                        <div class="col-md-8">
                            <label>profile picture visibility</label>
                        </div>
                        <div class="col-md-2 form-check">
                            <input class="form-check-input" type="radio" formControlName="profileShow" value="Yes">
                            <label class="form-check-label">Yes</label>
                        </div>
                        <div class="col-md-2 form-check">
                            <input class="form-check-input" type="radio" formControlName="profileShow" value="No">
                            <label class="form-check-label">No</label>
                        </div>
                        <!-- <span *ngIf="profileError.profileShow" class="text-danger">{{profileError.profileShow}}</span> -->
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="form-group mt-3">
            <div class="row">
                <div class="col-md-2">
                    <label for="email">DisPlay CardName :<span class="text-danger">*</span></label>
                </div>
                <div class="col-md-3">
                    <input type="text" class="form-control" id="email" formControlName="cardDisplayName">
                    <span *ngIf="profileError.cardDisplayName"
                        class="text-danger">{{profileError.cardDisplayName}}</span>
                </div>
            </div>
        </div> -->

        <div class="row mt-3 col-12 text-center" *ngIf="updateCard">
            <div class="text-center">
                <button type="button" class="btn btn-success" (click)="openModal()">If you want to change
                    template</button>
            </div>
        </div>
        <div class="row col-12 mt-2 mb-5">
            <div class="text-center">
                <button *ngIf="saveButton && !isUpdate" type="submit" class="btn btn-primary">save</button>
                <button *ngIf="isUpdate" type="submit" class="btn btn-primary">Update</button>
            </div>
        </div>
        <div class="row mt-5"></div>
        <div class="row mt-5"></div>
        <div class="row mt-2"></div>
        <div class="modal" tabindex="-1" role="dialog" [style.display]="isModalShow ? 'block' : 'none'">
            <div class="modal-dialog modal-lg  modal-dialog-scrollable">
                <div class="modal-content" style="background-color: #d1dbf6">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Select Your Card Design</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                            (click)="isModalShow = false;">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row mt-4">
                            <div class="col-md-4 mt-2 text-center">
                                <img class="w-100" alt="avatar2" alt="Responsive image" (click)="getPreviewType('previewOne')" formControlName="previewType"
                                    src="../../../../assets/img/previewImages/previewtwo.jpg" />
                            </div>
                            <div class="col-md-4 mt-2 text-center">
                                <img class="w-100" alt="avatar2" alt="Responsive image"   (click)="getPreviewType('previewTwo')"
                                    src="../../../../assets/img/previewImages/previewone.png" />
                            </div>
                            <div class="col-md-4 mt-2 text-center">
                                <img class="w-100" alt="avatar2" alt="Responsive image" (click)="getPreviewType('previewThree')"
                                    src="../../assets/img/previewImages/preview3.png" />
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-md-4 mt-2 text-center">
                                <img class="w-100" alt="avatar2" alt="Responsive image" (click)="getPreviewType('previewFour')"
                                    src="../../assets/img/previewImages/previewFour.png" />
                            </div>
                            <div class="col-md-4 mt-2 text-center">
                                <img class="w-100" alt="avatar2" alt="Responsive image" (click)="getPreviewType('previewFive')"
                                    src="../../assets/img/previewImages/previewFive.png" />
                            </div>
                            <div class="col-md-4 mt-2 text-center">
                                <img class="w-100" alt="avatar2" alt="Responsive image" (click)="getPreviewType('previewSix')"
                                    src="../../assets/img/previewImages/previewSix.png" />
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-md-4 mt-2 text-center">
                                <img class="w-100" alt="avatar2" alt="Responsive image" (click)="getPreviewType('previewSeven')"
                                    src="../../assets/img/previewImages/preview7.png" />
                            </div>
                            <div class="col-md-4 mt-2 text-center">
                                <img class="w-100" alt="avatar2" alt="Responsive image" (click)="getPreviewType('previewEight')"
                                    src="../../assets/img/previewImages/preview8.png" />
                            </div>
                            <div class="col-md-4 mt-2 text-center">
                                <img class="w-100" alt="avatar2" alt="Responsive image" (click)="getPreviewType('previewNine')"
                                    src="../../assets/img/previewImages/preview9.png" />
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-md-4 mt-2 text-center">
                                <img class="w-100" alt="avatar2" alt="Responsive image" (click)="getPreviewType('previewTen')"
                                    src="../../assets/img/previewImages/preview10.png" />
                            </div>
                            <div class="col-md-4 mt-2 text-center">
                                <img class="w-100" alt="avatar2" alt="Responsive image" (click)="getPreviewType('previewEleven')"
                                    src="../../assets/img/previewImages/preview11.png" />
                            </div>
                            <div class="col-md-4 mt-2 text-center">
                                <img class="w-100" alt="avatar2" alt="Responsive image" (click)="getPreviewType('previewTwelve')"
                                    src="../../assets/img/previewImages/preview12.png" />
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-md-4 mt-2 text-center">
                                <img class="w-100" alt="avatar2" alt="Responsive image" (click)="getPreviewType('previewThirteen')"
                                    src="../../assets/img/previewImages/preview13.png" />

                            </div>
                            <div class="col-md-4 mt-2 text-center">
                                <img class="w-100" alt="avatar2" alt="Responsive image" (click)="getPreviewType('previewFourteen')"
                                    src="../../assets/img/previewImages/preview14.png" />
                            </div>
                            <div class="col-md-4 mt-2 text-center">
                                <img class="w-100" alt="avatar2" alt="Responsive image" (click)="getPreviewType('previewFifteen')"
                                    src="../../assets/img/previewImages/preview15.png" />
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-md-4 mt-2 text-center">
                                <img class="w-100" alt="avatar2" alt="Responsive image" (click)="getPreviewType('previewSixteen')"
                                    src="../../../../assets/img/previewImages/previewSixteen.png" />
                            </div>

                            <div class="col-md-4 mt-2 text-center">
                                <img class="w-100" alt="avatar2" alt="Responsive image" (click)="getPreviewType('previewSeventeen')"
                                    src="../../../../assets/img/previewImages/previewSeventeen.png" />
                            </div>
                            <div class="col-md-4 mt-2 text-center">
                                <img class="w-100" alt="avatar2" alt="Responsive image" (click)="getPreviewType('previewTwenty')"
                                    src="../../../../assets/img/previewImages/previewTwenty.png" />
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-md-4 mt-2 text-center">
                                <img class="w-100" alt="avatar2" alt="Responsive image" (click)="getPreviewType('previewTwentyOne')"
                                    src="../../../../assets/img/previewImages/previewTwentyone.png" />
                            </div>
                            <div class="col-md-4 mt-2 text-center">
                                <img class="w-100" alt="avatar2" alt="Responsive image" (click)="getPreviewType('previewTwentyTwo')"
                                    src="../../../../assets/img/previewImages/previewTwentyTwo.png" />
                            </div>
                            <div class="col-md-4 mt-2 text-center">
                                <img class="w-100" alt="avatar2" alt="Responsive image" (click)="getPreviewType('previewTwentyThree')"
                                    src="../../../../assets/img/previewImages/previewTwentythree.png" />
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-md-4 mt-2 text-center">
                                <img class="w-100" alt="avatar2" alt="Responsive image" (click)="getPreviewType('previewTwentyFour')"
                                src="../../../assets/img/previewImages/previewTwentyfour.png" />
                            </div>
                            <div class="col-md-4 mt-2 text-center">
                                <img class="w-100" alt="avatar2" alt="Responsive image" (click)="getPreviewType('previewTwentyFive')"
                                    src="../../../../assets/img/previewImages/previewtwentyfive.png" />
                            </div>
                            <div class="col-md-4 mt-2 text-center">
                                <img class="w-100" alt="avatar2" alt="Responsive image" (click)="getPreviewType('previewTwentySeven')"
                                    src="../../../../assets/img/previewImages/previewTwentyseven.png" />
                            </div>
                            </div>
                        <div class="row mt-4">
                            <div class="col-md-4 mt-2 text-center">
                                <img class="w-100" alt="avatar2" alt="Responsive image" (click)="getPreviewType('previewThirty')"
                                    src="../../../../assets/img/previewImages/previewThirty.png" />
                            </div>
                            <div class="col-md-4 mt-2 text-center">
                                <img class="w-100" alt="avatar2" alt="Responsive image" (click)="getPreviewType('previewThirtyOne')"
                                    src="../../../../assets/img/previewImages/previewThirtyone.png" />
                            </div>
                            <div class="col-md-4 mt-2 text-center">
                                <img class="w-100" alt="avatar2" alt="Responsive image" (click)="getPreviewType('previewThirtyTwo')"
                                    src="../../../../assets/img/previewImages/previewThirtytwo.png" />
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-md-4 mt-2 text-center">
                                <img class="w-100" alt="avatar2" alt="Responsive image" (click)="getPreviewType('previewThirtyFour')"
                                    src="../../../../assets/img/previewImages/previewThirtyfour.png" />
                            </div>
                            <div class="col-md-4 mt-2 text-center">
                                <img class="w-100" alt="avatar2" alt="Responsive image" (click)="getPreviewType('previewThirtyFive')"
                                    src="../../../../assets/img/previewImages/previewThirtyfive.png" />
                            </div>
                            <div class="col-md-4 mt-2 text-center">
                                <img class="w-100" alt="avatar2" alt="Responsive image" (click)="getPreviewType('previewThirtySix')"
                                    src="../../../../assets/img/previewImages/previewThirtysix.png">
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-md-4 mt-2 text-center">
                                <img class="w-100" alt="avatar2" alt="Responsive image" (click)="getPreviewType('previewThirtySeven')"
                                    src="../../../../assets/img/previewImages/previewThirtyseven.png" />
                            </div>
                            <div class="col-md-4 mt-2 text-center">
                                <img class="w-100" alt="avatar2" alt="Responsive image" (click)="getPreviewType('previewThirtyEight')"
                                    src="../../../../assets/img/previewImages/previewThirtyeight.png" />
                            </div>
                            <div class="col-md-4 mt-2 text-center">
                                <img class="w-100" alt="avatar2" alt="Responsive image" (click)="getPreviewType('previewThirtyNine')"
                                    src="../../../../assets/img/previewImages/previewThirtynine.png">
                            </div>
                        </div>
                       <div class="row mt-4">
                        <div class="col-md-4 mt-2 text-center">
                            <img class="w-100" alt="avatar2" alt="Responsive image" (click)="getPreviewType('previewForty')"
                                src="../../../../assets/img/previewImages/previewForty.png" />
                        </div>
                            <div class="col-md-4 mt-2 text-center">
                                <img class="w-100" alt="avatar2" alt="Responsive image" (click)="getPreviewType('previewFortyOne')"
                                    src="../../../../assets/img/previewImages/previewFortyone.png" />
                            </div>


                       
                            <div class="col-md-4 mt-2 text-center">
                                <img class="w-100" alt="avatar2" alt="Responsive image" (click)="getPreviewType('previewFortyThree')"
                                    src="../../../../assets/img/previewImages/perviewFortythree.png" />
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-md-4 mt-2 text-center">
                                <img class="w-100" alt="avatar2" alt="Responsive image" (click)="getPreviewType('previewFortyFive')"
                                    src="../../../../assets/img/previewImages/previewFortyfive.png">
                            </div>
                        </div>
                        <div class="row mt-3"></div>
                        <!-- <div class="row mt-4">
                            <div class="col-md-4 mt-2 text-center">
                                <img class="w-100" alt="avatar2" alt="Responsive image" (click)="getPreviewType('previewTwentyFive')"
                                    src="../../../../assets/img/previewImages/previewTwentyFive.png" />
                                <div class="mt-2">
                                    <input class="form-check-input" type="radio" formControlName="previewType"
                                        value="previewSixtyOne">
                                    <label class="form-check-label" style="margin-left: 10px">Preview Fortynine</label>
                                </div>
                            </div>
                        </div> -->
                    </div>
                    <!-- <div class="modal-footer">
                        <div class="text-center">
                            <button type="button" class="btn btn-primary" (click)="closeModal()">Ok</button>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="modal" tabindex="-1" role="dialog" [style.display]="isQRShow ? 'block' : 'none'" style="background-color: rgba(227,227,227,0.6)">
            <div class="modal-dialog modal-lg modal-dialog-scrollable">
                <div class="modal-content" style="background-color: #f1f1f1;height: 300px; width:600px;">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel1">Your QR Code and URL</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="reload()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="text-center">
                            <img src="{{url}}">
                        </div>
                        <div class="text-center mt-3">
                            <a class="wa-btn"> {{copyUrl}}
                            </a>

                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="text-center">
                            <button type="button" style="margin-right: 20px" class="btn btn-primary"
                                (click)="downloadQR()">Download QR
                            </button>
                            <button type="button" class="btn btn-primary" (click)="copyURL()">Copy URL</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="modal " role="option" [style.display]="showDropdown ? 'block' : 'none'">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="model-tittle">If you want to update , select the card </h4>
                    </div>
                    <div class="modal-body">
                        <div class="col-md-12">
                            <div class="form-floating">
                                <select class="form-control " (change)="selectCard($event)" id="floatingSelectGrid"
                                    aria-label="Floating label select example">
                                    <option selected disabled>Select Your Card</option>
                                    <option *ngFor="let name of eCardDisplayName">{{name}}</option>
                                </select>
                                <label for="floatingSelectGrid"><b>select Your card</b></label>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="close()"><b>Close</b>
                        </button>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- <div class="modal " role="option" [style.display]="showDisplayName ? 'block' : 'none'">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="model-tittle">If you want to update</h4>
                    </div>
                    <div class="modal-body">
                        <div class="col-md-12">
                            <div class="form-floating">
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="close()"><b>Close</b>
                        </button>
                    </div>
                </div>
            </div>
        </div> -->
        <!--<div class="modal " role="option" [style.display]="showECardName ? 'block' : 'none'">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="model-tittle">eCard Display Name</h4>
                        <span aria-hidden="true">&times;</span>
                    </div>
                    <div class="modal-body">
                        <div class="col-md-12">
                            <div class="form-floating">
                                <input type="text" class="form-control" id="cardDisplayName"
                                    formControlName="cardDisplayName" placeholder="Ecard Display Name" autocomplete="off" autofocus>
                                <label for="">eCard Display Name<span class="text-danger">*</span></label>
                            </div>
                            <span *ngIf="profileError.cardDisplayName"
                                class="text-danger">{{profileError.cardDisplayName}}</span>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-dismiss="modal"
                            (click)="eCardNameclose()"><b>Ok</b>
                        </button>
                    </div>
                </div>
            </div>
        </div>-->

      <div class="modal" tabindex="-1" role="option" [style.display]="showECardName ? 'block' : 'none'">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">E-Card Display Name</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                      (click)="closeNamePopup()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="col-md-12">
                <div class="form-floating">
                  <input type="text" class="form-control" id="cardDisplayName"
                         formControlName="cardDisplayName" placeholder="Ecard Display Name" autocomplete="off" autofocus>
                  <label for="">eCard Display Name<span class="text-danger">*</span></label>
                </div>
                <span *ngIf="profileError.cardDisplayName"
                      class="text-danger">{{profileError.cardDisplayName}}</span>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" data-dismiss="modal"
                      (click)="eCardNameclose()"><b>Ok</b>
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
</div>
    <ngx-spinner size="large" [fullScreen]="true">
        <figure class="loader">
            <div class="dot white"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
        </figure>
    </ngx-spinner>

<!-- <app-banner></app-banner> -->
<!-- <app-footer></app-footer> -->
