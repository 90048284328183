<section class="body vh-100" style="background-color:  rgb(172, 172, 172);">
  <div class="container-fluid py-2 justify-content-center align-items-center"
       style="text-align: -webkit-center;">
    <div>
      <div class="page-wrapper" id="home-section">
        <div class="page-details">
          <div>
            <div class="p-10"></div>
            <div>
              <img [src]="companySrcUrl" class="logo mt-4" style="margin-top: 15px;" crossorigin="anonymous">
            </div>
            <h4 class="firmname mt-3 fw-bolder"style="color: white;margin-top:15px;">{{currentFormData!.companyName}}</h4>
            <div style="border-bottom:white 2px solid;"></div>
                <div class="col-md-12 mt-1 text-center" *ngIf="profileShow">
                  <img [src]="profileSrcUrl" class="image mt-1" crossorigin="anonymous">
                </div>

            <h5 class="name mt-1 fw-bolder">{{currentFormData!.firstName}} {{currentFormData!.lastName}}</h5>
            <h6 class="designation">{{currentFormData!.designation}}</h6>
            <h6 class="role">{{currentFormData!.role}}</h6>

            <div class="contact-buttons">
              <a class="contact-button"
                 href="tel:{{currentFormData!.countryCodePhoneNo}}{{currentFormData!.phoneNo}}">
                <i class="fas fa-phone fa-flip-horizontal"></i>
                Call
              </a>
            </div>

            <table class="contact-action-table" style="margin-top: -20px">
              <tbody >
              <tr>
                <td>
                  <i class="fas fa-map-marker-alt contact-action-container-icon mt-2" style="border-right: 4px solid;"></i>
                </td>
                <td>
                  <a class="contact-action-container-text mt-4" style="max-width: 220px;text-decoration: none">
                    <b> {{currentFormData!.address}}</b>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <a href="mailto:{{currentFormData!.email}}">
                    <i class="fas fa-envelope contact-action-container-icon" style="border-right: 4px solid;"></i>
                  </a>
                </td>
                <td>
                  <a href="mailto:{{currentFormData!.email}}"
                     class="contact-action-container-text mt-4" style="text-decoration: none">
                    <b>{{currentFormData!.email}}</b>
                  </a><br>
                  <a href="mailto:" class="contact-action-container-text">
                  </a><br>
                </td>
              </tr>
              <tr>
                <td>
                  <a target="_blank" href="http://{{currentFormData!.companyWebsiteName}}">
                    <i class="fas fa-globe contact-action-container-icon" style="border-right: 4px solid;"></i>
                  </a>
                </td>
                <td>
                  <a target="_blank" href="http://{{currentFormData!.companyWebsiteName}}"
                     class="contact-action-container-text" style="text-decoration: none">
                    <b>{{currentFormData!.companyWebsiteName}}</b>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <a target="_blank" style="font-weight: bolder;"
                     (click)="whatsApp(currentFormData!.countryCodePhoneNo, currentFormData!.phoneNo)">
                    <i class="bi bi-whatsapp  contact-action-container-icon mt-3" style="border-right: 4px solid;"></i>
                  </a>
                </td>
                <td>
                  <a class=" mt-3 ml-2 fw-bolder" type="button" style="color: white;text-decoration: none"
                     (click)="whatsApp(currentFormData!.countryCodeWhatsAppNo, currentFormData!.whatsAppNumber)">
                    {{currentFormData!.countryCodeWhatsAppNo}} {{formatNumber(currentFormData!.whatsAppNumber)}}</a>
                  <br>
                </td>
              </tr>
              </tbody>
            </table>
            <div class="r py-3"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<ngx-spinner bdColor="rgba(255, 255, 255, 1)" size="large" color="#FFFFFF"
             [fullScreen]="true">
  <figure class="loader">
    <div class="dot white"></div>
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
  </figure>
</ngx-spinner>
<app-ecard-footer></app-ecard-footer>

