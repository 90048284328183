<!-- Brand Logo -->
<a  class="brand-link bg-white">
  <!-- <pf-image
   
    class="brand-image"
    height="33"
    rounded
    src="../../../../assets/img/backgroundImages/ec"
    style="opacity: 0.8"
    width="33"
  ></pf-image> -->
  <span class=""><img src="../../../../assets/img/backgroundImages/ecard.png" style="height: 60px;"></span>
</a>

<!-- Sidebar -->
<div class="sidebar">
  <!-- Sidebar user (optional) -->
  <!-- <div class="user-panel mt-3 pb-3 mb-3 d-flex">
    <div class="image">
      <pf-image
        [src]="user.picture"
        alt="User Image"
        class="img-circle"
        fallback-src="assets/img/default-profile.png"
        height="34"
        rounded
        width="34"
      ></pf-image>
    </div>
    <div class="info">
      <a [routerLink]="['/profile']" class="d-block">
        {{ user.email }}
      </a>
    </div>
  </div> -->

  <div class="form-inline">
    <!-- <app-sidebar-search></app-sidebar-search> -->
  </div>

  <!-- Sidebar Menu -->
  <nav class="mt-2" style="overflow-y: hidden" >
    <ul
    class="nav nav-pills nav-sidebar flex-column"
    data-accordion="false"
    data-widget="treeview"
    role="menu"
  >
    <app-menu-item
      *ngFor="let item of menu"
      [menuItem]="item"
    ></app-menu-item>
  </ul>
    <!-- <div class="a" (click)="eCardForm()"><i class="bi bi-file-earmark-plus"></i> Add Card</div> -->
    <div class="row"  *ngFor="let item of username">
        <div class="a ms-1">
          <a  class="d-block mt-2" (click)="selctEcardName(item)"><i class="bi bi-pencil-fill"></i> {{item}}</a>
        </div>
    </div>
  </nav>
  <!-- <div class="">
    <ul class="nav flex-column">
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle"  id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
         My ECard
        </a>
        <div class="dropdown-menu" style="background-color: #00000029;" >
          <a class="dropdown-item" *ngFor="let item of username"><i class="bi bi-pencil-fill"></i> {{ item }}</a>
        </div>
      </li>
    </ul>
  </div> -->
</div>
