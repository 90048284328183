import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from '@/app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from '@modules/main/main.component';
import { LoginComponent } from '@modules/login/login.component';
import { HeaderComponent } from '@modules/main/header/header.component';
import { FooterComponent } from '@modules/main/footer/footer.component';
import { MenuSidebarComponent } from '@modules/main/menu-sidebar/menu-sidebar.component';
import { BlankComponent } from '@pages/blank/blank.component';
import { ProfileComponent } from '@pages/profile/profile.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RegisterComponent } from '@modules/register/register.component';
import { DashboardComponent } from '@pages/dashboard/dashboard.component';
import { ToastrModule } from 'ngx-toastr';
import { MessagesComponent } from '@modules/main/header/messages/messages.component';
import { NotificationsComponent } from '@modules/main/header/notifications/notifications.component';
import { HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import { UserComponent } from '@modules/main/header/user/user.component';
import { ForgotPasswordComponent } from '@modules/forgot-password/forgot-password.component';
import { RecoverPasswordComponent } from '@modules/recover-password/recover-password.component';
import { LanguageComponent } from '@modules/main/header/language/language.component';
import { MainMenuComponent } from './pages/main-menu/main-menu.component';
import { SubMenuComponent } from './pages/main-menu/sub-menu/sub-menu.component';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { ControlSidebarComponent } from './modules/main/control-sidebar/control-sidebar.component';
import { StoreModule } from '@ngrx/store';
import { authReducer } from './store/auth/reducer';
import { uiReducer } from './store/ui/reducer';
import { ProfabricComponentsModule } from '@profabric/angular-components';
import { defineCustomElements } from '@profabric/web-components/loader';
import { SidebarSearchComponent } from './components/sidebar-search/sidebar-search.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { ECardFormComponent } from '@modules/ecard/e-card-form/e-card-form.component';
import { FormDataService } from '@services/formDataService';
import { PreviewThreeComponent } from '@modules/ecard/preview-three/preview-three.component';
import { PreviewFourComponent } from '@modules/ecard/preview-four/preview-four.component';
import { PreviewFiveComponent } from '@modules/ecard/preview-five/preview-five.component';
import { PreviewSixComponent } from '@modules/ecard/preview-six/preview-six.component';
import { PreviewEightComponent } from '@modules/ecard/preview-eight/preview-eight.component';
import { PreviewNineComponent } from '@modules/ecard/preview-nine/preview-nine.component';
import { PreviewTenComponent } from '@modules/ecard/preview-ten/preview-ten.component';
import { PreviewElevenComponent } from '@modules/ecard/preview-eleven/preview-eleven.component';
import { PreviewTwelveComponent } from '@modules/ecard/preview-twelve/preview-twelve.component';
import { PreviewThirteenComponent } from '@modules/ecard/preview-thirteen/preview-thirteen.component';
import { PreviewFourteenComponent } from '@modules/ecard/preview-fourteen/preview-fourteen.component';
import { BannerComponent } from '@modules/ecard/banner/banner.component';
import { PreviewFifteenComponent } from '@modules/ecard/preview-fifteen/preview-fifteen.component';
import { MainScreenComponent } from '@modules/ecard/main-screen/main-screen.component';
import { BusinessCardComponent } from '@modules/ecard/business-card/business-card.component';
import { ECardComponent } from '@modules/ecard/e-card/e-card.component';
import { VisitingCardComponent } from '@modules/ecard/preview-Seven/visiting-card.component';
import { PreviewSixteenComponent } from '@modules/ecard/Preview-sixteen/Preview-sixteen.component';
import { PreviewSeventeenComponent } from '@modules/ecard/preview-seventeen/preview-seventeen.component';
import { PreviewEighteenComponent } from '@modules/ecard/preview-eighteen/preview-eighteen.component';
import { PreviewNineteenComponent } from '@modules/ecard/preview-nineteen/preview-nineteen.component';
import { PreviewTwentyComponent } from '@modules/ecard/preview-twenty/preview-twenty.component';
import { PreviewTwentyOneComponent } from '@modules/ecard/preview-twenty-one/preview-twenty-one.component';
import { PreviewTwentyTwoComponent } from '@modules/ecard/preview-twenty-two/preview-twenty-two.component';
import { PreviewTwentyThreeComponent } from '@modules/ecard/preview-twenty-three/preview-twenty-three.component';
import { PreviewTwentyFourComponent } from '@modules/ecard/preview-twenty-four/preview-twenty-four.component';
import { PreviewTwentyFiveComponent } from '@modules/ecard/preview-twenty-five/preview-twenty-five.component';
import { PreviewTwentySixComponent } from '@modules/ecard/preview-twenty-six/preview-twenty-six.component';
import { PreviewTwentySevenComponent } from '@modules/ecard/preview-twenty-seven/preview-twenty-seven.component';
import { PreviewTwentyEightComponent } from '@modules/ecard/preview-twenty-eight/preview-twenty-eight.component';
import { PreviewTwentyNineComponent } from '@modules/ecard/preview-twenty-nine/preview-twenty-nine.component';
import { PreviewThirtyComponent } from '@modules/ecard/preview-thirty/preview-thirty.component';
import { PreviewThirtyOneComponent } from '@modules/ecard/preview-thirty-one/preview-thirty-one.component';
import { PreviewThirtyTwoComponent } from '@modules/ecard/preview-thirty-two/preview-thirty-two.component';
import { PreviewThirtyThreeComponent } from '@modules/ecard/preview-thirty-three/preview-thirty-three.component';
import { PreviewThirtyFourComponent } from '@modules/ecard/preview-thirty-four/preview-thirty-four.component';
import { PreviewThirtyFiveComponent } from '@modules/ecard/preview-thirty-five/preview-thirty-five.component';
import { PreviewThirtySixComponent } from '@modules/ecard/preview-thirty-six/preview-thirty-six.component';
import { PreviewThirtySevenComponent } from '@modules/ecard/preview-thirty-seven/preview-thirty-seven.component';
import { PreviewThirtyEightComponent } from '@modules/ecard/preview-thirty-eight/preview-thirty-eight.component';
import { PreviewThirtyNineComponent } from '@modules/ecard/preview-thirty-nine/preview-thirty-nine.component';
import { PreviewFortyComponent } from '@modules/ecard/preview-forty/preview-forty.component';
import { PreviewFortyOneComponent } from '@modules/ecard/preview-forty-one/preview-forty-one.component';
import { PreviewFortyTwoComponent } from '@modules/ecard/preview-forty-two/preview-forty-two.component';
import { PreviewFortyThreeComponent } from '@modules/ecard/preview-forty-three/preview-forty-three.component';
import { PreviewFortyFourComponent } from '@modules/ecard/preview-forty-four/preview-forty-four.component';
import { PreviewFortyFiveComponent } from '@modules/ecard/preview-forty-five/preview-forty-five.component';
import { PreviewFortySixComponent } from '@modules/ecard/preview-forty-six/preview-forty-six.component';
import { PreviewFortySevenComponent } from '@modules/ecard/preview-forty-seven/preview-forty-seven.component';
import { PreviewFortyEightComponent } from '@modules/ecard/preview-forty-eight/preview-forty-eight.component';
import { PreviewFortyNineComponent } from '@modules/ecard/preview-forty-nine/preview-forty-nine.component';
import { PreviewFiftyComponent } from '@modules/ecard/preview-fifty/preview-fifty.component';
import { PreviewFiftyOneComponent } from '@modules/ecard/preview-fifty-one/preview-fifty-one.component';
import { PreviewFiftyTwoComponent } from '@modules/ecard/preview-fifty-two/preview-fifty-two.component';
import { PreviewFiftyThreeComponent } from '@modules/ecard/preview-fifty-three/preview-fifty-three.component';
import { PreviewFiftyFourComponent } from '@modules/ecard/preview-fifty-four/preview-fifty-four.component';
import { PreviewFiftyFiveComponet } from '@modules/ecard/preview-fifty-five/preview-fifty-five.component';
import { PreviewFiftySixComponent } from '@modules/ecard/preview-fifty-six/preview-fifty-six.component';
import { PreviewFiftySevenComponent } from '@modules/ecard/preview-fifty-seven/preview-fifty-seven.component';
import { PreviewFiftyEightComponent } from '@modules/ecard/preview-fifty-eight/preview-fifty-eight.component';
import { PreviewFiftyNineComponent } from '@modules/ecard/preview-fifty-nine/preview-fifty-nine.component';
import { PreviewSixtyComponent } from '@modules/ecard/preview-sixty/preview-sixty.component';
import { EcardFooterComponent } from '@modules/main/ecard-footer /ecard-footer.component';
import { PreviewSixtyOneComponent } from '@modules/ecard/preview-sixty-one/preview-sixty-one.component';
import {WebsiteComponent} from '@modules/website/website.component';
import {TermsComponent} from '@modules/terms-and-conditions/terms.component';
import {PrivacyPolicyComponent} from '@modules/privacy-policy/privacy-policy.component';
import { VssplComponent } from '@modules/ecard/vsspl/vsspl.component';
import { TraceFarmComponent } from '@modules/ecard/traceFarm/traceFarm.component';
import { VssplECardFormComponent } from '@modules/ecard/vsspl-e-card-form/vsspl-e-card-form.component';
import {PreviewMssiComponent} from "@modules/ecard/mssi/preview-mssi.component";
import {BlogComponent} from "@modules/blog/blog.component";


defineCustomElements();
registerLocaleData(localeEn, 'en-EN');

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    MenuSidebarComponent,
    BlankComponent,
    ProfileComponent,
    RegisterComponent,
    DashboardComponent,
    MessagesComponent,
    NotificationsComponent,
    UserComponent,
    ForgotPasswordComponent,
    RecoverPasswordComponent,
    LanguageComponent,
    MainMenuComponent,
    SubMenuComponent,
    MenuItemComponent,
    ControlSidebarComponent,
    SidebarSearchComponent,
    EcardFooterComponent,
    ECardFormComponent,
    ECardComponent,
    VisitingCardComponent,
    BusinessCardComponent,
    PreviewThreeComponent,
    PreviewFourComponent,
    PreviewFiveComponent,
    PreviewSixComponent,
    PreviewEightComponent,
    PreviewNineComponent,
    PreviewTenComponent,
    PreviewElevenComponent,
    PreviewTwelveComponent,
    PreviewThirteenComponent,
    PreviewFourteenComponent,
    BannerComponent,
    PreviewFifteenComponent,
    MainScreenComponent,
    PreviewSixteenComponent,
    PreviewSeventeenComponent,
    PreviewEighteenComponent,
    PreviewNineteenComponent,
    PreviewTwentyComponent,
    PreviewTwentyOneComponent,
    PreviewTwentyTwoComponent,
    PreviewTwentyThreeComponent,
    PreviewTwentyFourComponent,
    PreviewTwentyFiveComponent,
    PreviewTwentySixComponent,
    PreviewTwentySevenComponent,
    PreviewTwentyEightComponent,
    PreviewTwentyNineComponent,
    PreviewThirtyComponent,
    PreviewThirtyOneComponent,
    PreviewThirtyTwoComponent,
    PreviewThirtyThreeComponent,
    PreviewThirtyFourComponent,
    PreviewThirtyFiveComponent,
    PreviewThirtySixComponent,
    PreviewThirtySevenComponent,
    PreviewThirtyEightComponent,
    PreviewThirtyNineComponent,
    PreviewFortyComponent,
    PreviewFortyOneComponent,
    PreviewFortyTwoComponent,
    PreviewFortyThreeComponent,
    PreviewFortyFourComponent,
    PreviewFortyFiveComponent,
    PreviewFortySixComponent,
    PreviewFortySevenComponent,
    PreviewFortyEightComponent,
    PreviewFortyNineComponent,
    PreviewFiftyComponent,
    PreviewFiftyOneComponent,
    PreviewFiftyTwoComponent,
    PreviewFiftyThreeComponent,
    PreviewFiftyFourComponent,
    PreviewFiftyFiveComponet,
    PreviewFiftySixComponent,
    PreviewFiftySevenComponent,
    PreviewFiftyEightComponent,
    PreviewFiftyNineComponent,
    PreviewSixtyComponent,
    PreviewSixtyOneComponent,
    VssplComponent,
    PreviewMssiComponent,
    TraceFarmComponent,
    WebsiteComponent,
    TermsComponent,
    PrivacyPolicyComponent,
    VssplECardFormComponent,
    BlogComponent
  ],
  imports: [
    BrowserModule,
    StoreModule.forRoot({ auth: authReducer, ui: uiReducer }),
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      preventDuplicates: true
    }),
    ProfabricComponentsModule
  ],
  providers: [FormDataService, { provide: LocationStrategy, useClass: HashLocationStrategy }],
  bootstrap: [AppComponent]
})
export class AppModule {
}
