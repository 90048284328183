<section class=""*ngIf="profileShow">
    <div class="container-fluid py-2">
        <div class="container  justify-content-center" >
            <div class="box box">
                <div class="row">
                    <div class="col-md-12 mt-3">
                        <img class="companyProfile" [src]="companySrcUrl">
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-12 text-center">
                        <h5 class="text-center fw-bolder">{{currentFormData!.companyName}}</h5>
                    </div>
                </div>
                    <div class="boxContent">
                    <div class="row mt-1">
                        <div class="col-md-12">
                            <img class="companyProfile" [src]="profileSrcUrl">
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-12">
                            <h4 class="fullName"><b>{{currentFormData!.firstName}} {{currentFormData!.lastName}}</b></h4>
                            <h6 class="designation">{{currentFormData!.designation}}</h6>
                            <h6 class="role">{{currentFormData!.role}}</h6>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-1 text-center"></div>
                        <div class="col-11">
                            <div class="row mt-4" >
                                <div class="col-2"  ></div>
                                <div class="col-8 mt-3" style="background-color:  rgb(44, 148, 143);">
                                    <div class="lastline">
                                        <div class="row mt-3"></div>
                                    </div>
                                </div>
                                <div class="col-2"></div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-9 mt-1"><h6 class="" type="button" style="text-align: right;"
                                                             (click)="copyPhoneNo(currentFormData!.countryCodePhoneNo, formatNumber(currentFormData!.phoneNo))">
                                    {{currentFormData!.countryCodePhoneNo}} {{formatNumber(currentFormData!.phoneNo)}}</h6>
                                </div>
                                <div class="col-2"><i class="bi bi-telephone roundIcon" type="button"
                                    title="Call"></i></div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-9 mt-1"><h6 class="text-end">{{currentFormData!.address}}</h6></div>
                                <div class="col-2"><i class="bi bi-geo-alt roundIcon" type="button"
                                title="Location"></i>
                            </div>
                                
                            </div>
                            <div class="row mt-2">
                                <div class="col-9 mt-1 text-end"><h6>
                                    <a class="text-dark" style="text-decoration: none;"
                                       href="http://{{currentFormData!.companyWebsiteName}}"
                                       target="_blank">{{currentFormData!.companyWebsiteName}}</a>
                                </h6>
                                </div>
                                <div class="col-2"><i class="bi bi-globe2 roundIcon" type="button" title="Website"></i></div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-9 mt-1">
                                    <h6 class="text-end"><a class="opacity-75 text-dark" style="line-break: anywhere;text-decoration: none;"
                                                             href="mailto:{{currentFormData!.email}}"
                                                             target="_blank">{{currentFormData!.email}}</a>
                                    </h6>
                                </div>
                                <div class="col-2"><i class="bi bi-envelope roundIcon" type="button" title="Mail"></i></div>

                            </div>
                            <div class="row mt-2">
                                <div class="col-9 mt-2">
                                    <h6 class="" type="button" style="margin-left: 1px;text-align: right;"
                                        (click)="whatsApp(currentFormData!.countryCodeWhatsAppNo, currentFormData!.whatsAppNumber)">
                                        {{currentFormData!.countryCodeWhatsAppNo}} {{formatNumber(currentFormData!.whatsAppNumber)}}</h6>
                                </div>
                                <div class="col-2"><i title="WhatsApp" class="bi bi-whatsapp roundIcon fs-6"></i>
                                </div>
                            </div>

                            <div class="row mt-2" >
                                <div class="col-2"  ></div>
                                <div class="col-8" style="background-color:  rgb(44, 148, 143);">
                                    <div class="lastline">
                                        <div class="row mt-3"></div>
                                    </div>
                                </div>
                                <div class="col-2"></div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-5"></div>
    </div>
</section>




<section class="" *ngIf="profilehide">
    <div class="container-fluid py-2">
        <div class="container " >
            <div class="box ">
                <div class="row">
                    <div class="col-md-12 mt-5">
                        <img class="companyProfile" [src]="companySrcUrl">
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-12 text-center">
                        <h4 class="text-center "><b>{{currentFormData!.companyName}}</b></h4>
                    </div>
                </div>
                    <div class="boxContent">
                    
                    <div class="row mt-5">
                        <div class="col-12">
                            <h3 class="fullName">{{currentFormData!.firstName}} {{currentFormData!.lastName}}</h3>
                            <h6 class="designation">{{currentFormData!.designation}}</h6>
                            <h6 class="role">{{currentFormData!.role}}</h6>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-1 text-center"></div>
                        <div class="col-11">
                            <div class="row mt-3" >
                                <div class="col-2"  ></div>
                                <div class="col-8 mt-3" style="background-color:  rgb(44, 148, 143);">
                                    <div class="lastline">
                                        <div class="row mt-3"></div>
                                    </div>
                                </div>
                                <div class="col-2"></div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-9 mt-1"><h6 class="" type="button" style="text-align: right;"
                                                             (click)="copyPhoneNo(currentFormData!.countryCodePhoneNo, formatNumber(currentFormData!.phoneNo))">
                                    {{currentFormData!.countryCodePhoneNo}} {{formatNumber(currentFormData!.phoneNo)}}</h6>
                                </div>
                                <div class="col-2"><i class="bi bi-telephone roundIcon" type="button"
                                    title="Call"></i></div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-9 mt-1"><h6 class="text-end">{{currentFormData!.address}}</h6></div>
                                <div class="col-2"><i class="bi bi-geo-alt roundIcon" type="button"
                                title="Location"></i>
                            </div>
                                
                            </div>
                            <div class="row mt-2">
                                <div class="col-9 mt-1 text-end"><h6>
                                    <a class="text-dark" style="text-decoration: none;"
                                       href="http://{{currentFormData!.companyWebsiteName}}"
                                       target="_blank">{{currentFormData!.companyWebsiteName}}</a>
                                </h6>
                                </div>
                                <div class="col-2"><i class="bi bi-globe2 roundIcon" type="button" title="Website"></i></div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-9 mt-1">
                                    <h6 class="text-end"><a class="opacity-75 text-dark" style="line-break: anywhere;text-decoration: none;"
                                                             href="mailto:{{currentFormData!.email}}"
                                                             target="_blank">{{currentFormData!.email}}</a>
                                    </h6>
                                </div>
                                <div class="col-2"><i class="bi bi-envelope roundIcon" type="button" title="Mail"></i></div>

                            </div>
                            <div class="row mt-2">
                                <div class="col-9 mt-2">
                                    <h6 class="" type="button" style="margin-left: 1px;text-align: right;"
                                        (click)="whatsApp(currentFormData!.countryCodeWhatsAppNo, currentFormData!.whatsAppNumber)">
                                        {{currentFormData!.countryCodeWhatsAppNo}} {{formatNumber(currentFormData!.whatsAppNumber)}}</h6>
                                </div>
                                <div class="col-2"><i title="WhatsApp" class="bi bi-whatsapp roundIcon fs-6"></i>
                                </div>
                            </div>

                            <div class="row mt-2" >
                                <div class="col-2"  ></div>
                                <div class="col-8" style="background-color:  rgb(44, 148, 143);">
                                    <div class="lastline">
                                        <div class="row mt-3"></div>
                                    </div>
                                </div>
                                <div class="col-2"></div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-5"></div>
    </div>
</section>








<ngx-spinner bdColor="rgba(255, 255, 255, 1)" size="large" color="#FFFFFF"
             [fullScreen]="true">
    <figure class="loader">
        <div class="dot white"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
    </figure>
</ngx-spinner>
<app-ecard-footer></app-ecard-footer>