import {Component, Renderer2} from "@angular/core";
import {DateTime} from 'luxon';
import {Router} from "@angular/router";
import {Meta, Title} from "@angular/platform-browser";

@Component({
  selector: 'blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})

export class BlogComponent {
  public currentYear: string = DateTime.now().toFormat('y');

  constructor(private router: Router,private meta: Meta, private titleService: Title,private renderer: Renderer2) {

    this.titleService.setTitle('Free Visiting Card Design Online In India | Free Business Cards Online');
    this.meta.addTag({
      name: 'description',
      content: 'Elevate your brand with free business cards online. Design, customize, and stand out effortlessly. Unleash your creativity at eCard Maker today.'
    });
    this.meta.addTag({
      name: 'keywords',
      content: 'business card design free, business card maker free, design business card free online, business card design online free, business card designer near me, business card makers near me, free business card design, design visiting card free online'
    });
  }

  ngOnInit() {
    this.addCanonicalLink();
  }

  ngOnDestroy() {
    this.removeCanonicalLink();
  }

  private addCanonicalLink() {
    const link = this.renderer.createElement('link');
    this.renderer.setAttribute(link, 'rel', 'canonical');
    this.renderer.setAttribute(link, 'href', 'https://https://ecard.varnik.cloud/#/design-a-business-card-for-free');
    this.renderer.appendChild(document.head, link);
  }

  private removeCanonicalLink() {
    const link = document.head.querySelector('link[rel="canonical"]');
    if (link) {
      this.renderer.removeChild(document.head, link);
    }
  }

}
