import { Component, ElementRef, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { ECardForm } from '../modal/eCardForm';
import { AppService } from '@services/app.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-vsspl-e-card-form',
  templateUrl: './vsspl-e-card-form.component.html',
  styleUrls: ['./vsspl-e-card-form.component.css']
})
export class VssplECardFormComponent implements OnInit {

  cardDetails: ECardForm | undefined;
  currentFormData: ECardForm | undefined;
  destroy$: Subject<boolean> = new Subject<boolean>();
  isUpdate = false;
  url: string = ""
  selectCardName: string = ""
  showDisplayName = false;
  previewtype: string = ""
  localpreviewtype: string = ""
  copyUrl: string = ""
  ecardDisplayName: string = ""
  showCardName = false;
  isModalShow = false;
  isQRShow = false;
  eCardName: string[] = [];
  eCardDisplayName: string[] = [];
  isSidebarOpen = false;
  images: string = "";
  showECardName = false;
  updateCard = false;
  profileimages: string = "";
  userId : string = "";
  eventDetails: ECardForm[] = [];
  saveButton = false;
  ecardFormId : number = 0

  constructor(private appservice: AppService, private tostar: ToastrService,
    private router:Router,private spinner : NgxSpinnerService,private elRef: ElementRef) { }


  ecardForm = new UntypedFormGroup({
    firstName: new UntypedFormControl("", Validators.required),
    lastName: new UntypedFormControl("", Validators.required),
    countryCodePhoneNo: new UntypedFormControl("", Validators.required),
    phoneNo: new UntypedFormControl("", Validators.required),
    role: new UntypedFormControl("", Validators.required),
    email: new UntypedFormControl("", Validators.required),
    cardDisplayName: new UntypedFormControl("", Validators.required),
    ecardName: new UntypedFormControl("", Validators.required),
    previewType: new UntypedFormControl("", Validators.required),
    createdBy: new UntypedFormControl("", Validators.required),
    createdAt: new UntypedFormControl("", Validators.required),
    ecardFormId: new UntypedFormControl("", Validators.required),
  })

  ecardError = {
    firstName: '',
    lastName: '',
    countryCodePhoneNo: '',
    phoneNo: '',
    role: '',
    email: '',
    cardDisplayName: '',
  }
  onSubmit() {
    console.log("check")
    this.cardValid();
  }
  cardValid() {
    console.log("data")
    this.ecardError.firstName = '';
    this.ecardError.lastName = '';
    this.ecardError.countryCodePhoneNo = "";
    this.ecardError.phoneNo = '';
    this.ecardError.role = '';
    this.ecardError.email = '';

    let hasError = false;

    if (this.ecardForm.get('firstName')?.invalid) {
      this.ecardError.firstName = "First Name is required";
      console.log("data")
      hasError = true;
    }
    if (this.ecardForm.get('lastName')?.invalid) {
      this.ecardError.lastName = "Last Name is required";
      hasError = true;
    }
    if (this.ecardForm.get('countryCodePhoneNo')?.invalid) {
      this.ecardError.countryCodePhoneNo = "countryCodePhoneNo is required";
      hasError = true;
    }
    if (this.ecardForm.get('phoneNo')?.invalid) {
      this.ecardError.phoneNo = "phoneNo is required";
      hasError = true;
    }
    if (this.ecardForm.get('role')?.invalid) {
      this.ecardError.role = "Job Role is required";
      hasError = true;
    }
    if (this.ecardForm.get('email')?.invalid) {
      this.ecardError.email = "Email is required";
      hasError = true;
    }
    //   if (this.ecardForm.get('emgetUserDetailsail')?.errors) {
    //     this.ecardError.email = "*Enter Valid Email.(Ex:example@gmail.com)";
    //     hasError = true
    // }

    if (!hasError) {
      this.saveCardDetails()
    }

  }

  popup() {
    console.log("checking")
    this.cardValid()

  }

  ngOnInit() {
    if (localStorage.getItem("EcardName")) {
      this.updateCard = true
      this.UpdateAlert()
      this.getUserDetails()
  } else {
      // this.getAllDetails();
      // this.ecardForm.controls['countryCodePhoneNo'].setValue('+91');
      // this.ecardForm.controls['countryCodeWhatsAppNo'].setValue('+91');
      this.showECardName = true
      // this.ecardForm.get("cardDisplayName").focus();
      this.userId = String(localStorage.getItem('userId'));
      console.log("user Reg ID::", this.userId)
      this.saveButton = true
  }
  }

  ngAfterViewInit() {
    const inputElement = this.elRef.nativeElement.querySelector('#cardDisplayName');
    inputElement.focus();
  }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  saveCardDetails() {
    this.ecardForm.controls['createdBy'].setValue(localStorage.getItem('userId'));
    this.spinner.show()
    if (this.isUpdate) {
      this.ecardForm.controls['ecardFormId'].setValue(this.ecardFormId)
      this.ecardForm.controls['previewType'].setValue(this.previewtype)
      if(this.ecardForm.get('previewType').value == ""){
      this.ecardForm.controls['previewType'].setValue(this.localpreviewtype)
      }
      this.ecardForm.get('email')?.enable()
      this.appservice.updateCardDetails(this.ecardForm.value)
          .pipe(takeUntil(this.destroy$))
          .subscribe((data) => {
              console.log("Update form data:", data)
              this.currentFormData = data;
              // @ts-ignore
              var resData: ECardForm = data;
              this.tostar.success("Your Details Updated Successfully")
              this.url = 'https://tomcat3.varnik.cloud:8443/ecard-ws/qr/getPreviewScreenQR?eCardName=' + resData.ecardName;
              console.log("thisurl::",this.url)
              this.copyUrl = 'https://ecard.varnik.cloud/#/e?CardName=' + resData.ecardName
              console.log("card details::", this.copyUrl)
              localStorage.setItem('eCardName', resData.ecardName)
              this.spinner.hide();
              this.isQRShow = true;
          }, (error: any) => {
              this.spinner.hide();
              this.ecardForm.reset();
              console.log("something wrong:", error)
              this.errorPopup();
          })
  }else{
    // this.ecardForm.controls['createdBy'].setValue(localStorage.getItem('userId'));
    this.ecardForm.controls['previewType'].setValue(localStorage.getItem('currentPreviewType'))
    this.ecardForm.controls['cardDisplayName'].setValue(this.ecardForm.get("cardDisplayName").value)
    console.log("savecard::", this.ecardForm.value)
    this.appservice.saveCardDetails(this.ecardForm.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("saved form data:", data)
        this.currentFormData = data;
        // @ts-ignore
        var resData: ECardForm = data;
        this.tostar.success("Your Details saved Successfully")
        this.url = 'https://tomcat3.varnik.cloud:8443/ecard-ws/qr/getPreviewScreenQR?eCardName=' + resData.ecardName;
        this.copyUrl = 'https://ecard.varnik.cloud/#/e?CardName=' + resData.ecardName
        localStorage.setItem('eCardName', resData.ecardName)
        this.isQRShow = true;
        this.spinner.hide()
  }, (error: any) => {
    this.spinner.hide();
    this.ecardForm.reset();
    console.log("something wrong:", error)
    this.errorPopup();
  })
  }
  }


  // E card copy link URL link
  copyURL() {
    if (!this.isUpdate) {
      const url = 'https://ecard.varnik.cloud/#/e?CardName=' + this.currentFormData!.ecardName
      const textArea = document.createElement('textarea');
      textArea.value = url;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
      // alert('URL copied to clipboard');
      this.tostar.success("URL copied to clipboard")
    }
    else {
      const url = 'https://ecard.varnik.cloud/#/e?CardName=' + this.selectCardName
      const textArea = document.createElement('textarea');
      textArea.value = url;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
      // alert('URL copied to clipboard');
      this.tostar.success("URL copied to clipboard")
    }
  }


  // QR code Image Download
  downloadQR() {
    const link = document.createElement('a');
    link.href = this.url;
    console.log("qrcode::",link.href)
    link.download = 'image.png';
  }

  eCardNameclose() {
    this.ecardError.cardDisplayName = ''

    let hasError = false

    if (this.ecardForm.get('cardDisplayName')?.invalid) {
      this.ecardError.cardDisplayName = "eCard Display Name is required"
      hasError = true
    }

    if (!hasError) {
      this.showECardName = false
      this.showCardName = true
      this.ecardDisplayName = this.ecardForm.get('cardDisplayName').value
    }
  }

  reload() {
    // window.location.reload();
    this.router.navigate(['/main/dash'])
    localStorage.removeItem("EcardName")
    localStorage.removeItem('currentPreviewType')
  }

  errorPopup() {
    Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'something went wrong, Please try again later',
        showConfirmButton: false,
        timer: 1500
    })
}

// UpdateAlert() {
//   Swal.fire({
//       title: " Do you want to Edit & Update",
//       icon: "question",
//       showDenyButton: true,
//       confirmButtonColor: "#4fab69",
//       denyButtonColor: "#c73636",
//       confirmButtonText: "Edit",
//       denyButtonText: "Copy URL?",
//       showClass: {
//           popup: "animate_animated animate_fadeInDown",
//       },
//       hideClass: {
//           popup: "animate_animated animate_fadeOutUp",
//       },
//   }).then((result) => {
//       if (result.isConfirmed) {
//           this.selectpopupCard(localStorage.getItem("EcardName"))
//       } else {
//           this.selectpopupCard(localStorage.getItem("EcardName"))
//           this.isQRShow = true
//           this.url = 'https://tomcat3.varnik.cloud:8443/ecard-ws/qr/getPreviewScreenQR?eCardName=' + this.selectCardName
//           console.log("url::",this.url)
//           this.copyUrl = 'https://ecard.varnik.cloud/#/e?CardName=' + this.selectCardName

//       }
//   });
// }

UpdateAlert() {
  Swal.fire({
    title: "Do you want to Edit & Update",
    icon: "question",
    showDenyButton: true,
    confirmButtonColor: "#4fab69",
    denyButtonColor: "#c73636",
    confirmButtonText: "Edit",
    denyButtonText: "Copy URL?",
    showClass: {
      popup: "animate_animated animate_fadeInDown",
    },
    hideClass: {
      popup: "animate_animated animate_fadeOutUp",
    },
    backdrop: false,
  }).then((result) => {
    if (result.isConfirmed) {
      this.selectpopupCard(localStorage.getItem("EcardName"));
    } else if (result.isDenied) {
      this.selectpopupCard(localStorage.getItem("EcardName"));
      this.isQRShow = true;
      this.url =
        'https://tomcat3.varnik.cloud:8443/ecard-ws/qr/getPreviewScreenQR?eCardName=' +
        this.selectCardName;
      console.log("url::", this.url);
      this.copyUrl =
        'https://ecard.varnik.cloud/#/e?CardName=' + this.selectCardName;
    }
  });
}
    //Login based E cards show functionality
    getUserDetails() {
      this.appservice.getUserDetails(localStorage.getItem('userId'))
          .pipe(takeUntil(this.destroy$))
          .subscribe((data) => {
              console.log("user Reg ID::", this.userId)
              console.log("ECardForm:", data)
              this.eventDetails = data;
              console.log("UserECardForm:", data)
              for (var userECard of this.eventDetails) {
                  this.eCardName.push(userECard.ecardName)
                  this.eCardDisplayName.push(userECard.cardDisplayName)
                  console.log("eCardName::", this.eCardName)
                  console.log(":", data)
              }
          })

  }

  getPreviewType(preiewType : string){
    const value = preiewType
        this.previewtype = value
        console.log("getPreviewtype::",this.previewtype)
        this.isModalShow = false;

}

openModal() {
  this.isModalShow = true;
}

selectpopupCard(name: string) {
  const value = name;
  console.log("Event Name::", value)
  // this.showDropdown = false;
  for (var card of this.eventDetails) {
      if (value == card.ecardName) {
          this.isUpdate = true;
          // this.ecardForm.get('email')?.disable();
          console.log("cardName::", card.ecardName)
          this.ecardForm.controls["ecardName"].setValue(card.ecardName);
          this.ecardForm.controls["firstName"].setValue(card.firstName);
          this.ecardForm.controls["lastName"].setValue(card.lastName);
          this.ecardForm.controls["role"].setValue(card.role);
          this.ecardForm.controls["phoneNo"].setValue(card.phoneNo);
          this.ecardForm.controls["email"].setValue(card.email);
          this.ecardForm.controls["countryCodePhoneNo"].setValue(card.countryCodePhoneNo);
          this.ecardForm.controls["createdAt"].setValue(card.createdAt);
          this.ecardForm.controls["createdBy"].setValue(card.createdBy);
          this.ecardForm.controls["previewType"].setValue(card.previewType);
          this.ecardForm.controls["cardDisplayName"].setValue(card.cardDisplayName);
          this.ecardFormId = card.ecardFormId
          this.selectCardName = card.ecardName
          this.localpreviewtype = card.previewType
      }
  }

}
  closeNamePopup() {
    this.router.navigate(['/main/dash']);
    localStorage.removeItem('currentPreviewType')
  }


}
