import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, takeUntil } from 'rxjs';
import { ECardForm } from '../modal/eCardForm';
import { FormDataService } from '@services/formDataService';
import { AppService } from '@services/app.service';

@Component({
  selector: 'app-traceFarm',
  templateUrl: './traceFarm.component.html',
  styleUrls: ['./traceFarm.component.css']
})
export class TraceFarmComponent implements OnInit {
  currentFormData: ECardForm | undefined;
  companySrcUrl: string = ""
  profileSrcUrl: string = ""
  profileShow = false;
  firstName: string;
  lastName: string;
  role: string;
  phoneNumber: string;
  email: string;


  destroy$: Subject<boolean> = new Subject<boolean>();


  constructor(private dataService: FormDataService, private route: ActivatedRoute,
    private appService: AppService, private spinner: NgxSpinnerService) {
}

  ngOnInit() {
    console.log("url function:")
    this.route.queryParams.subscribe(params => {
      const eCardName = params['CardName'];
      console.log("e card name:", eCardName);
      this.getCardData(eCardName);
    });
  }


  copyPhoneNo(code: string, No: string) {
    const phoneNo = document.createElement('input');
    phoneNo.value = code + ' ' + No;
    document.body.appendChild(phoneNo);
    phoneNo.select();
    document.execCommand('copy');
    document.body.removeChild(phoneNo);
    alert('Phone Number Copied');
  }

  copyTelephoneNo(No: string) {
    const phoneNo = document.createElement('input');
    phoneNo.value = No;
    document.body.appendChild(phoneNo);
    phoneNo.select();
    document.execCommand('copy');
    document.body.removeChild(phoneNo);
    alert('Telephone Number Copied');
  }

  getCardData(eCardName: string) {
    this.spinner.show();
    this.appService.getCardDetails(eCardName)
        .pipe(takeUntil(this.destroy$))
        .subscribe(data => {
          // this.spinner.hide();
          console.log("get card data:", data)
          this.currentFormData = data;
          console.log("get card2 data:", this.currentFormData)
          if (this.currentFormData?.profileShow == "Yes") {
            this.profileShow = true;
          } else if (this.currentFormData?.profileShow == "No") {
            this.profileShow = false;
          }
          this.firstName = this.currentFormData.firstName;
          this.lastName = this.currentFormData.lastName;
          this.role = this.currentFormData.role;
          this.phoneNumber = this.currentFormData.phoneNo;
          this.email = this.currentFormData.email;
        }, (error: any) => {
          console.log("something went wrong:", error)
        })
  }

  getLogo(eCardName: string) {
    this.spinner.show();
    return this.appService.fileGet(eCardName, "companyLogo")
        .pipe(takeUntil(this.destroy$))
        .subscribe((data: Blob) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            this.spinner.hide();
            const base64data = reader.result as string;
            this.companySrcUrl = base64data;
            console.log("img url::", this.companySrcUrl);
          };
          reader.readAsDataURL(data);
        });
  }

  getProfile(eCardName: string) {
    this.spinner.show();
    return this.appService.fileGet(eCardName, "profile")
        .pipe(takeUntil(this.destroy$))
        .subscribe((data: Blob) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            this.spinner.hide();
            const base64data = reader.result as string;
            this.profileSrcUrl = base64data;
            console.log("img url::", this.profileSrcUrl);
          };
          reader.readAsDataURL(data);
        });
  }

}
