
 <div class="vh-100 bg-secondary">

   <div class="container-fluid">
     <div class="row text-center py-4">
       <div class="container bg-white mx-auto" style="max-width: 340px;height: auto;">
         <!-- COMPANY LOGO -->
         <div class="row mt-5 mb-3">
           <div class="image">
             <img src="../../../../assets/img/backgroundImages/vsspl.png" width="100px" alt="">
           </div>
         </div>
         <!-- COMPANY NAME -->
         <div class="row mt-2 mb-3">
           <h5 class="companyName" style="font-family: 'Ubuntu';"><b>VarNik Systems Services (P) Ltd</b></h5>
         </div>
         <!-- STAFF NAME -->
         <div class="row  mt-4" style="background-color: rgb(9,31,94);">
           <div class="col-2 mt-2 text-center">
             <i class="bi bi-person-fill  icon"></i>
           </div>
           <div class="col-10 mt-2">
             <h5 class="fullName text-capitalize" style="font-family: 'Ubuntu'"><b>{{firstNameData}} {{lastNameData}} </b></h5>
             <h6 class="role text-capitalize" style="font-family: 'Ubuntu'"><b>{{roleData}}</b></h6>
           </div>
         </div>
         <div class="row" style="background-color: #2facde">
           <!-- PHONE NUMBER -->
           <div class="row mt-3">
             <div class="col-2 text-center">
               <i class="bi bi-telephone-fill icons"></i>
             </div>
             <div class="col-10">
               <p class="phone mt-2 ms-1" style="font-family: 'Varela Round';"><b>{{phoneNumberData}}</b></p>
             </div>
           </div>
           <!-- EMAIL -->
           <div class="row mt-3">
             <div class="col-2 text-center">
               <i class="bi bi-envelope-fill icons"></i>
             </div>
             <div class="col-10">
               <h6 class="email ms-1" style="font-family: 'Varela Round';"><b>{{emailData}}</b></h6>
               <p class="email ms-1" style="font-family: 'Varela Round';"><b>vsspl-affairs@msservicesinc.com<br>www.varnikcloud.com</b></p>
             </div>
           </div>
           <!-- ADDRESS -->
           <div class="row mt-3">
             <div class="col-2 text-center">
               <i class="bi bi-geo-alt-fill icons"></i>
             </div>
             <div class="col-10">
               <p class="address ms-1" style="font-family: 'Varela Round';"><b>#1, First Cross, Sundar Nagar, Trichy, Tamil Nadu - 620 021</b></p>
             </div>
           </div>
           <div class="row mt-2"></div>
         </div>
         <!-- FOOTER -->
         <div class="footer">
           <div class="row" style="background-color: rgb(9,31,94);">.</div>
         </div>
       </div>
     </div>
   </div>

 </div>
 <app-ecard-footer></app-ecard-footer>
