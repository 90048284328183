<app-header class="sticky-top"></app-header>

<app-menu-sidebar class="sidebar-dark-primary" style="position: fixed;"></app-menu-sidebar>

<div class="content-wrapper">
  <router-outlet></router-outlet>
</div>

<app-footer></app-footer>
<app-control-sidebar></app-control-sidebar>
<div (click)="onToggleMenuSidebar()" id="sidebar-overlay"></div>
