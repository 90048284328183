import {Component, HostBinding} from '@angular/core';
import {DateTime} from 'luxon';
import packageInfo from '../../../../../package.json';

@Component({
  selector: 'app-ecard-footer',
  templateUrl: './ecard-footer.component.html',
  styleUrls: ['./ecard-footer.component.scss']
})
export class EcardFooterComponent {
  // @HostBinding('class') classes: string = 'main-footer';
  // public appVersion = packageInfo.version;
  // public currentYear: string = DateTime.now().toFormat('y');
}
