<section class="vh-100" style="background-image: url(../../assets/img/backgroundImages/pxfuel.jpg)"  *ngIf="profileShow">
    <div class="container-fluid py-2  justify-content-center align-items-center"
         style="text-align: -webkit-center;">
        <div>
            <div class="page-wrapper" id="home-section">
                <div class="page-details">
                    <div>
                        <div class="p-10"></div>
                        <div class="mb-4">
                            <img [src]="companySrcUrl" class="image  mt-2"  crossorigin="anonymous">
                            <h4 class="firmname mt-2">{{currentFormData!.companyName}}</h4>
                        </div>
                        <div   style="color: white;">
                            <img [src]="profileSrcUrl"  class="profiel" crossorigin="anonymous">
                        </div>
                        <h3 class="name " style="color: white;">{{currentFormData!.firstName}} {{currentFormData!.lastName}}</h3>
                            <span class="designation" style="color: white;">{{currentFormData!.designation}}</span><br>
                            <span class="role mb-3"  style="color: white;">{{currentFormData!.role}}</span>


                    </div>
                    <div>
                        <div class="p-10"></div>
                        <div class="contact-buttons">
                            <a class="btn btn-primary mr-2" href="tel:{{currentFormData!.countryCodePhoneNo}}{{currentFormData!.phoneNo}}">
                                <i class="fas fa-phone fa-flip-horizontal"></i> Call
                            </a>
                            <a class="btn btn-success mr-2" target="_blank" (click)="whatsApp(currentFormData!.countryCodeWhatsAppNo, currentFormData!.whatsAppNumber)">
                                <i class="fab fa-whatsapp"></i> 
                            </a>
                            <a class="btn btn-info" target="_blank" href="mailto:{{currentFormData!.email}}">
                                <i class="fas fa-envelope"></i> Mail
                            </a>
                        </div>
                        <table class="contact-action-table py-5">
                            <tbody class="table-stripped"> 
                            <tr>
                                <td >
                                    <i class="fas fa-map-marker-alt contact-action-container-icon"></i>
                                </td>
                                <td>
                                    <a class="contact-action-container-text" style="max-width: 220px; color: white;">
                                        {{currentFormData!.address}}
                                    </a>
                                </td>
                            </tr>
                            <tr></tr>
                            <tr>
                                <td>
                                    <a href="mailto:{{currentFormData!.email}}">
                                        <i class="fas fa-envelope contact-action-container-icon"></i>
                                    </a>
                                </td>
                                <td>
                                    <a href="mailto:{{currentFormData!.email}}"
                                       class="contact-action-container-text mt-4" style="color: white;">
                                        {{currentFormData!.email}}
                                    </a><br>
                                    <a href="mailto:" class="contact-action-container-text">
                                    </a><br>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a target="_blank" href="http://{{currentFormData!.companyWebsiteName}}">
                                        <i class="fas fa-globe contact-action-container-icon"></i>
                                    </a>
                                </td>
                                <td>
                                    <a target="_blank" href="http://{{currentFormData!.companyWebsiteName}}"
                                       class="contact-action-container-text"  style="color: white;">
                                        {{currentFormData!.companyWebsiteName}}
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a target="_blank"
                                       href="tel:{{currentFormData!.countryCodePhoneNo}}{{currentFormData!.phoneNo}}">
                                        <i class="fas fa-phone fa-flip-horizontal contact-action-container-icon"></i>
                                    </a>
                                </td>
                                <td>
                                    <a target="_blank"href="tel:{{currentFormData!.countryCodePhoneNo}}{{currentFormData!.phoneNo}}"
                                       class="contact-action-container-text mt-3" style="margin-bottom: 5px; color: white;">
                                        {{currentFormData!.countryCodePhoneNo}} {{currentFormData!.phoneNo}}
                                    </a>
                                    <br>
                                    <a target="_blank" href="tel:{{currentFormData!.telephone}}"class="contact-action-container-text" style="color: white;">
                                    {{currentFormData!.telephone}}
                                </a>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="p-30  mt-5 pt-2"></div>
                                </td>
                                <td>
                                    <div class="p-30  mt-5"></div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div class="p-30  mt-4"></div>
                        <div class="p-30 mt-4"></div>
                        <ul class="inprofile share-buttons">
                        </ul>
                        <div class="p-20"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
















<section class="vh-100" style="background-image: url(../../assets/img/backgroundImages/pxfuel.jpg)"  *ngIf="profilehide" >
    <div class="container-fluid py-2  justify-content-center align-items-center"
         style="text-align: -webkit-center;">
        <div>
            <div class="page-wrapper" id="home-section">
                <div class="page-details">
                    <div>
                        <div class="p-10"></div>
                        <div class="mb-4">
                            <img [src]="companySrcUrl" class="image  mt-2"  crossorigin="anonymous">
                            <h4 class="firmname mt-2">{{currentFormData!.companyName}}</h4>
                        </div>
                      <div class="" style="border-top: 2px solid white;"></div>
                        <h3 class="name mt-3" style="color: white; ">{{currentFormData!.firstName}} {{currentFormData!.lastName}}</h3>
                            <span class="designation" style="color: white;">{{currentFormData!.designation}}</span><br>
                            <span class="role mb-3"  style="color: white;">{{currentFormData!.role}}</span>


                    </div>
                    <div>
                        <div class="p-10"></div>
                        <div class="contact-buttons">
                            <a class="btn btn-primary mr-2" href="tel:{{currentFormData!.countryCodePhoneNo}}{{currentFormData!.phoneNo}}">
                                <i class="fas fa-phone fa-flip-horizontal"></i> Call
                            </a>
                            <a class="btn btn-success mr-2" target="_blank" (click)="whatsApp(currentFormData!.countryCodeWhatsAppNo, currentFormData!.whatsAppNumber)">
                                <i class="fab fa-whatsapp"></i> 
                            </a>
                            <a class="btn btn-info" target="_blank" href="mailto:{{currentFormData!.email}}">
                                <i class="fas fa-envelope"></i> Mail
                            </a>
                        </div>
                        <table class="contact-action-table py-5">
                            <tbody class="table-stripped"> 
                            <tr>
                                <td >
                                    <i class="fas fa-map-marker-alt contact-action-container-icon"></i>
                                </td>
                                <td>
                                    <a class="contact-action-container-text" style="max-width: 220px; color: white;">
                                        {{currentFormData!.address}}
                                    </a>
                                </td>
                            </tr>
                            <tr></tr>
                            <tr>
                                <td>
                                    <a href="mailto:{{currentFormData!.email}}">
                                        <i class="fas fa-envelope contact-action-container-icon"></i>
                                    </a>
                                </td>
                                <td>
                                    <a href="mailto:{{currentFormData!.email}}"
                                       class="contact-action-container-text mt-4" style="color: white;">
                                        {{currentFormData!.email}}
                                    </a><br>
                                    <a href="mailto:" class="contact-action-container-text">
                                    </a><br>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a target="_blank" href="http://{{currentFormData!.companyWebsiteName}}">
                                        <i class="fas fa-globe contact-action-container-icon"></i>
                                    </a>
                                </td>
                                <td>
                                    <a target="_blank" href="http://{{currentFormData!.companyWebsiteName}}"
                                       class="contact-action-container-text"  style="color: white;">
                                        {{currentFormData!.companyWebsiteName}}
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a target="_blank"
                                       href="tel:{{currentFormData!.countryCodePhoneNo}}{{currentFormData!.phoneNo}}">
                                        <i class="fas fa-phone fa-flip-horizontal contact-action-container-icon"></i>
                                    </a>
                                </td>
                                <td>
                                    <a target="_blank"href="tel:{{currentFormData!.countryCodePhoneNo}}{{currentFormData!.phoneNo}}"
                                       class="contact-action-container-text mt-3" style="margin-bottom: 5px; color: white;">
                                        {{currentFormData!.countryCodePhoneNo}} {{currentFormData!.phoneNo}}
                                    </a>
                                    <br>
                                    <a target="_blank" href="tel:{{currentFormData!.telephone}}"class="contact-action-container-text" style="color: white;">
                                    {{currentFormData!.telephone}}
                                </a>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="p-30  mt-5 pt-2"></div>
                                </td>
                                <td>
                                    <div class="p-30  mt-5"></div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div class="p-30  mt-4"></div>
                        <div class="p-30 mt-4"></div>
                        <ul class="inprofile share-buttons">
                        </ul>
                        <div class="p-20"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<ngx-spinner bdColor="rgba(255, 255, 255, 1)" size="large" color="#FFFFFF"
             [fullScreen]="true">
    <figure class="loader">
        <div class="dot white"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
    </figure>
</ngx-spinner>
<app-ecard-footer></app-ecard-footer>